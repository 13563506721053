import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  getSnapshot,
  getEnv,
  types,
} from 'mobx-state-tree';
import { to_f } from '../../store/utils';
import { withSetPropAction } from './helpers';
import { MarketInstance, MarketSnapshotIn } from './market-instance';
import { TradeSectionStore } from './trade-section';
import { MobileTradeEditSection as TradeEditStore } from './zoneConfig';
import { Order } from './utils';
export const ExchangeStore = types
  .model('ExchangeStore', {
    owner: types.identifier,
    markets: types.optional(types.array(MarketInstance), []),
    activeMarket: types.maybe(types.reference(MarketInstance)),
    tradeSections: types.optional(types.array(TradeSectionStore), []),
    activeTradeSection: types.maybe(types.reference(TradeSectionStore)),
    newTradeSection: types.optional(TradeEditStore, {}),
    symbol: types.optional(types.string, 'BTCUSDT'),
    useStopLoss: types.optional(types.boolean, false),
  })
  .extend((self) => {
    const emptyTradeSection = TradeSectionStore.create({
      id: `${self.owner}-${self.symbol}-empty`,
      kind: 'long',
      config: {
        entry: 0,
        stop: 0,
        size: 0,
        risk_reward: 0,
        risk_per_trade: 0,
      },
    });
    return {
      views: {
        get emptyTradeSection() {
          return emptyTradeSection;
        },
      },
      actions: {
        resetEmptyTradeSection() {
          emptyTradeSection.setProp('config', {
            entry: 0,
            stop: 0,
            size: 0,
            risk_reward: 0,
            risk_per_trade: 0,
          });
        }
      },
    };
  })
  .actions(withSetPropAction)
  .views((self) => {
    return {
      get name() {
        return self.owner.replace('_', ' ');
      },
      get section() {
        return self.activeTradeSection || self.emptyTradeSection;
      },
      get zones() {
        return self.tradeSections
          .map((o) => {
            let r = o.display;
            const places = self.activeMarket?.futureCTrader.price_places;
            return {
              ...r,
              support: to_f(r.support, places),
              resistance: to_f(r.resistance, places),
              entry: to_f(r.entry, places),
              stop: to_f(r.stop, places),
            };
          })
          .sort((a, b) => {
            return b.entry - a.entry;
          });
      },
      get activeZone() {
        const active = self.activeTradeSection?.display;
        const places = self.activeMarket?.futureCTrader.price_places;
        return active
          ? {
              ...active,
              support: to_f(active.support, places),
              resistance: to_f(active.resistance, places),
              entry: to_f(active.entry, places),
              stop: to_f(active.stop, places),
            }
          : null;
      },
      buildConfig(kind: 'long' | 'short') {
        if (this.section && self.activeMarket) {
          const { futureCTrader } = self.activeMarket;
          return this.section.buildConfig({
            kind,
            globalConfig: {
              price_places: futureCTrader?.price_places,
              decimal_places: futureCTrader?.decimal_places,
              budget: futureCTrader?.config.budget,
              currentQty: futureCTrader?.config.currentQty,
              fee: futureCTrader?.config.fee,
              increase_position: true,
              min_size: futureCTrader?.config.min_size,
              minimum_size: futureCTrader?.config.min_size,
              percent_change: futureCTrader?.config.percent_change,
              strategy: futureCTrader?.config.strategy,
              tradeSplit: futureCTrader?.config.tradeSplit,
            },
          });
        }
        return [];
      },
      get trades() {
        return this.buildConfig(this.section.kind as any);
      },
      getConfirmationMessage(kind: 'long' | 'short', orderType: 'tp' | 'sl') {
        if (self.activeTradeSection) {
          return kind === 'long'
            ? Math.max(
                self.activeTradeSection.config.entry,
                self.activeTradeSection.config.stop,
              )
            : Math.min(
                self.activeTradeSection.config.entry,
                self.activeTradeSection.config.stop,
              );
        }
        return 0;
      },
      get balance() {
        return self.markets.reduce((acc, m) => {
          return acc + m.dollarBalance;
        }, 0);
      },
      get futureCTrader() {
        return self.activeMarket?.futureCTrader;
      },
      activeMarketSummary() {
        return self.activeMarket ? self.activeMarket.summary(self.useStopLoss) : [];
      },
      tradeSummary() {
        const trades = this.trades;
        return trades.length > 0
          ? [
              {
                title: 'Avg.Entry',
                value: trades[0]?.avg_entry,
              },
              {
                title: 'TP.price',
                value: trades[0]?.sell_price,
              },
              {
                title: 'Risk',
                value: self.newTradeSection.risk,
              },
              {
                title: 'Entry count',
                value: self.newTradeSection.risk_reward,
              },
              {
                title: 'Avg.size',
                value: trades[0]?.avg_size,
              },
              {
                title: 'Expected pnl',
                value: trades[0]?.pnl,
              },
              {
                title: 'Liquidation',
                value: 40000,
              },
            ]
          : [];
      },
    };
  })
  .actions((self) => {
    const adapter = getEnv(self).adapter;

    // let socket:
    const initializeExchange = async () => {
      try {
        const [result, sections] = await Promise.all([
          adapter.getExchange({
            owner: self.owner,
            symbol: self.symbol
          }),
          adapter.getTradeSections({
            owner: self.owner,
            symbol: self.symbol
          }),
        ]);
        self.setProp('markets', result);
        self.setProp('tradeSections', sections);
        self.setProp('activeTradeSection', undefined);
      } catch (e) {
        throw e;
      }
    };
    const updateFutureConfig = () => {
      const { entry, stop, kind, gap, max_size, risk, risk_reward } =
        self.newTradeSection;
      self.futureCTrader?.config.updateFields({
        entry,
        stop,
        kind,
        gap,
        max_size,
        take_profit: entry,
        long_zones: entry,
        short_zones: stop,
        currentEntry: entry,
        risk_per_trade: risk,
        risk_reward,
      });
    };
    const fetchTradeSections = async () => {
      try {
        const result = await adapter.getTradeSections({
          owner: self.owner,
        });
        self.setProp('tradeSections', result);
      } catch (e) {
        throw e;
      }
    };
    const placeTPSLOrder = async (kind: 'long' | 'short') => {
      const orders = self.buildConfig(kind);
      self.futureCTrader?.config?.setProp('sub_accounts', [self.owner]);
      await self.futureCTrader?.placeOrder('update_signal', orders, kind);
    };
    const createTradeConfigForm = () => {
      const props = self.futureCTrader
        ? {
            support: self.futureCTrader?.config.support,
            resistance: self.futureCTrader?.config.resistance,
          }
        : null;
      if (props) {
        Object.keys(props).forEach((k: any) => {
          let u = props[k];
          if (u) {
            self.newTradeSection.setProp(k as any, u);
          }
          if (!self.activeTradeSection) {
            self.section?.updateConfig(props);
          } else {
            let { config } = self.activeTradeSection;
            self.activeTradeSection.updateConfig({
              support: Math.min(config.entry, config.stop),
              resistance: Math.max(config.entry, config.stop),
            });
          }
        });
      }
      if (self.activeTradeSection) {
        self.newTradeSection.setProp(
          'tradeSection',
          self.activeTradeSection.id,
        );
        self.newTradeSection.setProp(
          'entry',
          self.activeTradeSection.config.entry,
        );
        self.newTradeSection.setProp(
          'stop',
          self.activeTradeSection.config.stop,
        );
        self.newTradeSection.setProp(
          'risk',
          self.activeTradeSection.config.risk_per_trade,
        );
        self.newTradeSection.setProp(
          'risk_reward',
          self.activeTradeSection.config.risk_reward,
        );
      }
    };
    const placeOrders = async (kind: 'long' | 'short') => {
      const orders = self.buildConfig(kind);
      self.futureCTrader?.config?.setProp('sub_accounts', [self.owner]);
      await self.futureCTrader?.placeOrder('place_signal', orders, kind);
    };
    const cancelOrders = async (orders: Order[]) => {
      const kind = orders[0].positionSide.toLowerCase();
      self.futureCTrader?.config?.setProp('sub_accounts', [self.owner]);
      await self.futureCTrader?.placeOrder('cancel_signal', orders, kind);
    };
    const createOrEditTradeSection = async () => {
      if (!self.activeTradeSection && self.emptyTradeSection.config.entry > 0) {
        const data = getSnapshot(self.emptyTradeSection);
        // async call to create new trade section
        updateFutureConfig();
        await self.futureCTrader?.saveTradesSection();
        const index = self.tradeSections.length;
        data.id = `${self.owner}-${self.symbol}-${data.kind}-entry:${data.config.entry}-stop:${data.config.stop}-index:${index}`;
        self.setProp('tradeSections', [...self.tradeSections, data]);
        self.resetEmptyTradeSection();
      } else {
        // async call to update existing trade section
        updateFutureConfig();
        await self.futureCTrader?.saveTradesSection();
      }
    };
    const deleteTradingSection = async () => {
      if (self.activeTradeSection) {
        const existing = { ...self.activeTradeSection.config };
        // action to delete the section.
        self.newTradeSection.setProp('tradeSection', undefined);
        let id = self.activeTradeSection.id;
        self.setProp('activeTradeSection', undefined);
        await self.futureCTrader?.deleteTradingSection({
          entry: existing.entry,
          stop: existing.stop,
          kind: self.newTradeSection.kind,
        });
        self.setProp(
          'tradeSections',
          self.tradeSections.filter((o) => o.id !== id),
        );
      }
    };
    const onGetLatestResistance = async () => {};
    return {
      deleteTradingSection,
      updateFutureConfig,
      initializeSockets(callback?: any) {
        self.activeMarket?.initializeSockets(callback);
      },
      destroySockets() {
        self.activeMarket?.destroySockets();
      },
      createOrEditTradeSection,
      cancelOrders,
      onGetLatestResistance,
      placeOrders,
      placeTPSLOrder,
      createTradeConfigForm,
      initializeExchange,
      fetchTradeSections,
    };
  });

export interface Exchange extends Instance<typeof ExchangeStore> {}
export interface ExchangeSnapshotOut
  extends SnapshotOut<typeof ExchangeStore> {}
export interface ExchangeSnapshotIn extends SnapshotIn<typeof ExchangeStore> {}
