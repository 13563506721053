import {
    StatArrow,
    StatHelpText,
    StatLabel,
  } from '@chakra-ui/react';
  import { observer } from 'mobx-react-lite';
  import React, { FunctionComponent } from 'react';
  import { AlertDialogComponent } from './Sidebar';
  import StatsDisplay from './StatsDisplay';
  import type { ICalculatorStore } from '../../store/botConfig';

const LossMinimizerAnalysis: FunctionComponent<{
    store: ICalculatorStore;
    isLoading?: boolean;
    createTakeProfit?: (p: 'long' | 'short', value: any) => any;
  }> = observer(({ store, isLoading, createTakeProfit }) => {
    let stats = [];
    stats.push({
      heading: `Long Entry`,
      body: `${store.longPosition.entryPrice}`,
      footer: (
        <>
          <StatLabel>Size: {store.longPosition.quantity}</StatLabel>
        </>
      ),
    });
    stats.push({
      heading: 'Short Entry',
      body: `${store.shortPosition.entryPrice}`,
      footer: (
        <>
          <StatLabel>Size: {store.shortPosition.quantity}</StatLabel>
        </>
      ),
    });
    let { long, short } = store.profitHelper;
    //console.log({ long, short });
    let pnlAnalysis: any = store.liquidationAnalysis('pnl');
    let additionAnalysis: any = store.liquidationAnalysis('additional');
    let optimumSize: any = store.optimumPositionSize('long');
    if (long) {
      stats.push({
        heading: `Long takeProfit`,
        body: `${long.takeProfit.toFixed(4)}`,
        footer: long && (
          <>
            <StatHelpText>
              P/L:
              {long.pnl > 0 ? (
                <StatArrow ml={1} type="increase" />
              ) : (
                <StatArrow ml={1} type="decrease" />
              )}
              {long.pnl.toFixed(8)}
            </StatHelpText>
            {long.quantity ? (
              <>
                <StatLabel>
                  Size: {long.quantity.toFixed(4)}{' '}
                  <AlertDialogComponent
                    buttonText="Long TP action"
                    heading={'Create Short Take Profit'}
                    confirmSummary={`A long sell at ${long.takeProfit.toFixed(
                      4,
                    )} would be placed with size ${long.quantity.toFixed(8)}`}
                    confirmText="Create take profit"
                    onActionClick={() => {
                      createTakeProfit && createTakeProfit('long', long);
                    }}
                    isLoading={isLoading}
                    loadingText="Submitting Job"
                    colorScheme="green"
                    size="xs"
                  />
                </StatLabel>
              </>
            ) : null}
          </>
        ),
      });
    }
    if (short) {
      stats.push({
        heading: 'Short takeProfit',
        body: `${short.takeProfit.toFixed(4)}`,
        footer: short && (
          <>
            <StatHelpText>
              P/L:
              {short.pnl > 0 ? (
                <StatArrow ml={1} type="increase" />
              ) : (
                <StatArrow ml={1} type="decrease" />
              )}
              {short.pnl.toFixed(8)}
            </StatHelpText>
            {short.quantity ? (
              <StatLabel>
                Size: {short.quantity.toFixed(3)}{' '}
                <AlertDialogComponent
                  buttonText="Short TP action"
                  heading={'Create Short Take Profit'}
                  confirmSummary={`A short buy at ${short.takeProfit.toFixed(
                    4,
                  )} would be placed with size ${short.quantity.toFixed(8)}`}
                  confirmText="Create take profit"
                  onActionClick={() => {
                    createTakeProfit && createTakeProfit('short', short);
                  }}
                  isLoading={isLoading}
                  loadingText="Submitting Job"
                  mt={4}
                  colorScheme="red"
                  size="xs"
                />
              </StatLabel>
            ) : null}
          </>
        ),
      });
    }
    if (pnlAnalysis) {
      stats.push({
        heading: `Liquidation after pnl`,
        body: pnlAnalysis.liquidation && pnlAnalysis.liquidation.toFixed(4),
        footer: pnlAnalysis.balance ? (
          <StatLabel>Balance: {pnlAnalysis.balance.toFixed(8)}</StatLabel>
        ) : null,
      });
    }
    if (additionAnalysis) {
      stats.push({
        heading: `Liquidation on additional`,
        body:
          additionAnalysis.liquidation && additionAnalysis.liquidation.toFixed(4),
        footer: (
          <>
            <StatLabel>
              {additionAnalysis.long?.size
                ? `Long entry: ${additionAnalysis.long.entry.toFixed(
                    2,
                  )} size: ${additionAnalysis.long.size.toFixed(8)}`
                : ''}
            </StatLabel>
            <StatLabel>
              {additionAnalysis.short?.size
                ? `Short entry: ${additionAnalysis.short.entry.toFixed(
                    2,
                  )} size: ${additionAnalysis.short.size.toFixed(8)}`
                : ''}
            </StatLabel>
          </>
        ),
      });
    }
    if (optimumSize) {
      stats.push({
        heading: `Optimum size: ${optimumSize.size.toFixed(8)}`,
        body: optimumSize.stop.toFixed(4),
        footer: (
          <StatLabel>
            Contract size: {optimumSize.contract_size.toFixed(1)}
          </StatLabel>
        ),
      });
    }
    // let lossInfo = store.lossInfo;
    // if (lossInfo) {
    //   stats.push({
    //     heading: `size: ${lossInfo.quantity} balance: ${store.balance}`,
    //     body: `MM: ${lossInfo.maintenance_margin}`,
    //     // body: `Current/MM: ${store.currentPrice.toFixed(2)}/${lossInfo.maintenance_margin}`,
    //     footer: <StatLabel>Liquidation: {lossInfo.liquidation}</StatLabel>,
    //   });
    // }

    React.useEffect(() => {
      let positionStore = store.positionStore;
      positionStore.initializeSockets();
      return function cleanup() {
        positionStore.destroySockets();
      };
    }, []);
    console.log('currentPrice', store.positionStore.currentPrice);
    return <StatsDisplay stats={stats} />;
  });

  export default LossMinimizerAnalysis