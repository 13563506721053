import { Redirect, Route, BrowserRouter, HashRouter } from 'react-router-dom';
import { Root } from '../mobileAppView/stores';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import DashboardPage from '../mobileAppView/pages/DashboardPage';
import CreateAccountPage from '../mobileAppView/pages/CreateAccountPage';
import AccountsListPage from '../mobileAppView/pages/AccountsListPage';
import UpdateTPSLPage from '../mobileAppView/pages/UpdateTPSLPage';
import IncreasePositionSummayPage from '../mobileAppView/pages/IncreasePositionSummayPage';
import SectionConfigPage from '../mobileAppView/pages/SectionConfigPage';
import { useNavigationHook } from '../currentBot/hooks';
import AppThemeProvider from '../bootstrap';
import { AppPage } from '../mobileAppView/components/Page';
import { TransferFundPage } from '../mobileAppView/pages';

const routeConfig = [
  {
    path: '/tp-sl/:id',
    component: UpdateTPSLPage,
    exact: true,
    header: '',
    goBack: true,
    props: (s: Root, pathname = '') => {
      const orderType = pathname.replace('/tp-sl/', '');
      return { store: s.activeAccount, orderType };
    },
  },
  {
    header: 'Increase',
    path: '/increase-position',
    exact: true,
    component: IncreasePositionSummayPage,
    goBack: true,
    props: (s: Root) => ({ store: s.activeAccount }),
  },
  {
    header: '',
    path: '/trade-section',
    component: SectionConfigPage,
    goBack: true,
    exact: true,
    props: (s: Root) => ({ store: s.activeAccount }),
  },
  {
    header: 'Transfer Funds',
    path: '/transfer-funds',
    goBack: true,
    exact: true,
    component: TransferFundPage,
    props: (s: Root) => ({ store: s }),
  },
  {
    header: 'Create Account',
    exact: true,
    goBack: true,
    path: '/create-account',
    component: CreateAccountPage,
    props: (s: Root) => ({ store: s, goBack: true }),
  },
  {
    path: ['/home', '/accounts'],
    exact: true,
    tabs: [
      {
        tabName: 'home',
        href: '/home',
        label: 'Home',
        header: 'Home',
        component: DashboardPage,
      },
      {
        tabName: 'accounts',
        href: '/accounts',
        label: 'Accounts',
        header: 'Accounts',
        component: AccountsListPage,
      },
    ],
  },
];

export type AppProps = {
  store: Root;
};

export type PageContainerProps = {
  pathPrefix?: string;
  render?: (
    navigate: (path: string) => void,
    onGoBack: () => void,
    pathname: string | undefined,
  ) => React.ReactNode;
  goBack?: boolean;
  header?: string;
  tabs?: {
    tabName: string;
    href: string;
    label: string;
    header: string;
    component: React.ComponentType<any>;
  }[];
  store: Root;
};

export const SamplePageContainer = ({
  render,
  goBack,
  header,
  tabs,
  store,
  pathPrefix = '',
}: PageContainerProps) => {
  const { history, match, location } = useNavigationHook();
  const navigate = (path: string) => {
    history.push(pathPrefix + path);
  };
  useEffect(() => {
    if (store) {
      let symbol = location.search.replace('?symbol=', '') || 'BTCUSDT';
      if (symbol) {
        store.setProp('symbol', symbol);
      }
      console.log('match', symbol);
    }
  }, []);
  const onGoBack = () => {
    history.goBack();
  };
  const pathname = location.pathname;
  return (
    <AppPage
      tabs={tabs}
      header={header}
      store={store}
      navigate={navigate}
      onGoBack={goBack ? onGoBack : undefined}
    >
      {render ? render(navigate, onGoBack, pathname) : null}
    </AppPage>
  );
};

export type ApplicationWrapperProps = {
  routeMode: 'hash' | 'browser';
  children?: React.ReactNode;
  tabsLocation?: 'top' | 'bottom';
  tabs?: {
    tabName: string;
    href: string;
    label: string;
  }[];
};

export const SampleWrapper = ({
  routeMode = 'browser',
  children,
  tabsLocation = 'bottom',
  tabs,
}: ApplicationWrapperProps) => {
  const RouteComponent = routeMode === 'browser' ? BrowserRouter : HashRouter;
  return (
    <AppThemeProvider>
      <RouteComponent>{children}</RouteComponent>
    </AppThemeProvider>
  );
};

export const bootStrapApp = (
  props: ApplicationWrapperProps,
  Wrapper = SampleWrapper,
  PageContainer = SamplePageContainer,
  init = () => {},
  pathPrefix = '',
) => {
  init();
  const App = observer(({ store }: AppProps) => {
    return (
      <Wrapper {...props}>
        {routeConfig.map((route) => (
          <Route
            key={route.path}
            exact={route.exact}
            path={
              Array.isArray(route.path)
                ? route.path.map((u) => `${pathPrefix}${u}`)
                : `${pathPrefix}${route.path}`
            }
          >
            <PageContainer
              goBack={route.goBack}
              header={route.header}
              tabs={route.tabs}
              store={store}
              pathPrefix={pathPrefix}
              render={
                route.props
                  ? (navigate, onGoBack, pathname = '') => {
                      const props = route?.props(store, pathname);
                      console.log('props', props);
                      return (
                        <route.component
                          {...props}
                          navigate={navigate}
                          onGoBack={onGoBack}
                        />
                      );
                    }
                  : undefined
              }
            />
          </Route>
        ))}
        <Route exact path={`/`}>
          <Redirect to={`${pathPrefix}/home`} />
        </Route>
      </Wrapper>
    );
  });

  return App;
};
