// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { observer } from 'mobx-react-lite';

export type SwiperComponentProps<T = any> = {
  renderItem: (item: T, index: number) => React.ReactNode;
  items?: T[];
  onItemChange: (index: number) => void;
  defaultIndex?: number;
  navigation?: boolean;
};
export const SwiperComponent = observer(
  ({
    onItemChange,
    items = [],
    renderItem,
    defaultIndex = 0,
    navigation = false,
  }: SwiperComponentProps) => {
    return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        //   spaceBetween={50}
        slidesPerView={1}
        navigation={navigation}
        initialSlide={defaultIndex}
        pagination
        onSlideChange={(s) => {
          if (onItemChange) {
            onItemChange(s.activeIndex);
          }
        }}
      >
        {items.map((item, index) => (
          <SwiperSlide key={index}>{renderItem(item, index)}</SwiperSlide>
        ))}
      </Swiper>
    );
  },
);
