// function disconnectSocketStreams(streams: Array<string>) {
//     const _streams = streams.map((x) => x.toLowerCase() + "@ticker").join("/");
//     const connection = btoa(_streams);
//     // //console.log(connections.current[connection].readyState)
//     // //console.log(WebSocket.OPEN)
//     if (connections[connection].readyState === WebSocket.OPEN) {
//         connections[connection].close();
//     }

import type { Fetcher } from './types';

// }
export function disconnectAllStreams(connections: { [x: string]: WebSocket }) {
  Object.keys(connections).forEach((connection) => {
    if (connections[connection].readyState === WebSocket.OPEN) {
      connections[connection].close();
      delete connections[connection];
    }
  });
}

export function connectSocketStream(
  connections: {
    [x: string]: WebSocket;
  },
  streams: Array<string>,
) {
  const _streams = streams.map((x) => x.toLowerCase() + '@ticker').join('/');
  const connection = btoa(_streams);
  connections[connection] = new WebSocket(
    `wss://fstream.binance.com/stream?streams=${_streams}`,
    // `wss://fstream.binance.com/ws/${_streams}`
    // `wss://stream.binance.com:9443/stream?streams=${streams}`
    // `wss://stream.binance.com:9443/stream?streams=${streams}`
  );
  // connections[connection].onmessage = (evt: { data: any }) => {
  // 	const result = JSON.parse(evt.data);
  // 	// //console.log(evt.data);
  // 	addPrice(result);
  // };
  // connections[connection].onerror = (evt: any) => {
  // 	console.error(evt);
  // };
  return connections[connection];
}

export function getConnections(
  streams: Array<string>,
  connections: {
    [x: string]: WebSocket;
  },
) {
  const _streams = streams.map((x) => x.toLowerCase() + '@ticker').join('/');
  const connection = btoa(_streams);
  let result = connections[connection];
  if (!result) {
    result = connectSocketStream(connections, streams);
  }
  return result;
}
