import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { BackButton } from './BackButton';

type LAppPageProps = {
  children: React.ReactNode;
  onGoBack?: () => void;
  navigate: (path: string) => void;
  header?: string;
  tabs?: {
    tabName: string;
    href: string;
    label: string;
    component: React.ComponentType<any>;
  }[];
  store?: any;
};
export const AppPage = ({
  header,
  children,
  navigate = () => {},
  onGoBack,
  store,
  tabs = [],
}: LAppPageProps) => {
  return tabs.length > 0 ? (
    <Tabs
      isFitted
      onChange={(index) => {
        const item = tabs[index];
        navigate(item.href);
      }}
    >
      <TabPanels>
        {tabs.map((o) => (
          <TabPanel key={o.tabName}>
            <Box
              maxW={{ base: '100%', md: '7xl' }}
              mx={['5px', 'auto']}
              my={10}
            >
              <o.component
                navigate={navigate}
                onGoBack={onGoBack}
                store={store}
              />
            </Box>
          </TabPanel>
        ))}
      </TabPanels>
      <TabList position="fixed" bottom="0" w="100%">
        {tabs.map((o) => (
          <Tab _selected={{ color: 'white', bg: 'blue.500' }} key={o.tabName}>
            {o.label}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  ) : (
    <>
      <Box maxW={{ base: '100%', md: '7xl' }} mx={['5px', 'auto']} my={10}>
        {onGoBack && <BackButton onGoBack={onGoBack} header={header} />}
        <Box>{children}</Box>
      </Box>
    </>
  );
};
