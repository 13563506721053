import { Box, Flex, Stack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { TableDisplay } from './TableDisplay';
import { TradeConfig } from './TradeConfig';

export const TightTradeAnalyzer = observer(({ store }) => {
  const entry = store.config.entry;
  const kind = store.config.kind;
  let stop = store.config.stop;

  const newKind = kind === 'long' ? 'short' : 'long';

  let firstSet = store.buildTrades({ currentPrice: entry, _kind: kind }) || [];
  let secondSet =
    store.buildTrades({ currentPrice: stop, _kind: newKind, newStop: entry }) ||
    [];

  function buildTrades() {
    const { mode } = store.config;
    let result = [];
    if (mode === 'default') {
      result = store.trades as any;
    } else {
      result = store.hedge_full_orders;
    }
    return result;
  }

  return (
    <>
      {/* <Flex justifyContent={'space-between'} direction="column"> */}
      <Flex
        justifyContent={'space-between'}
        direction="column"
        mx={['', '80px', '80px']}
      >
        <TradeConfig store={store} />
        <Stack direction={['column', 'row', 'row']} spacing="20px">
          <TableDisplay kind={'long'} data={firstSet} />
          <TableDisplay kind={'short'} data={secondSet} />
        </Stack>
      </Flex>
    </>
  );
});
