import React, { useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import type { FunctionComponent } from 'react';

const MarketsSidebar: FunctionComponent<{
  isOpen: any;
  onClose: any;
  btnRef: any;
  title: string;
  size?: string;
  button: any;
  isLoading?: boolean;
  [key: string]: any;
  onSave?: any;
}> = observer(
  ({
    size = 'sm',
    title,
    isOpen,
    onClose,
    button,
    btnRef,
    onSave,
    children,
    isLoading = false,
    ...rest
  }) => {
    return (
      <>
        {button}
        <Drawer
          size={size}
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          {...rest}
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>{title}</DrawerHeader>

              <DrawerBody>{children}</DrawerBody>

              <DrawerFooter>
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isLoading={isLoading}
                  loadingText={'Saving'}
                  onClick={onSave}
                  color="blue"
                >
                  Save
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </>
    );
  },
);

export default MarketsSidebar;

export const Navigator: FunctionComponent<{}> = observer(({ children }) => {
  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      p={3}
      flexWrap="wrap"
      border="1px solid black"
    >
      {children}
    </Flex>
  );
});

export function AlertDialogComponent({
  confirmSummary,
  heading,
  confirmText = 'Delete',
  onActionClick,
  buttonText,
  renderCallback,
  ...rest
}: {
  buttonText?: string;
  heading: any;
  confirmSummary: any;
  confirmText?: any;
  onActionClick?: (params?: any) => any;
  [key: string]: any;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef<any>();
  const [internalState, setInternalState] = React.useState();
  const summary =
    typeof confirmSummary === 'function'
      ? confirmSummary(internalState)
      : confirmSummary;
  const headingView =
    typeof heading === 'function' ? heading(internalState) : heading;
  const confirmTextView =
    typeof confirmText === 'function'
      ? confirmText(internalState)
      : confirmText;
  return (
    <>
      {renderCallback ? (
        renderCallback((value: any) => {
          if (value) {
            setInternalState(value);
          }
          setIsOpen(true);
        })
      ) : (
        <Button onClick={() => setIsOpen(true)} {...rest}>
          {buttonText}
        </Button>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {headingView}
            </AlertDialogHeader>

            <AlertDialogBody>{summary}</AlertDialogBody>

            {onActionClick ? (
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme={rest.colorScheme || 'red'}
                  isLoading={rest.isLoading}
                  loadingText={rest.loadingText}
                  onClick={() => {
                    onActionClick(internalState);
                    onClose();
                  }}
                  ml={3}
                >
                  {confirmTextView}
                </Button>
              </AlertDialogFooter>
            ) : null}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
