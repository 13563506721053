import { ComponentStyleConfig } from '@chakra-ui/theme';

export const BotInterface: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      '.chakra-form__error-message': {
        fontWeight: 500,
      },
      // 'p.chakra-text':{
      //   backgroundColor:'red',
      // },
      'h2.chakra-heading': {
        // textAlign: 'center',
        pb: '10px',
      },
      padding: '20px',
      marginTop: '10px',
      // maxW:"1080px",
      // mx: 'auto',

      '.chakra-input': {
        maxW: '150px',
        marginTop: '0px',
      },

    '.chakra-spinner':{
      // mr:'100px',
      // backgroundColor:'red',
      my:"100px"
 
    },

      '@media screen and (max-width:30em)': {
        padding: '2px',
        '.chakra-input': {
          m: 0,
        },
        '.chakra-spinner':{
          mr:0,
          // backgroundColor:'red',
          my:0
     
        },
      },

      '.chakra-button': {
        mt: '19px',
        // color: 'gray',
        // backgroundColor: '#FAD6A5',
        backgroundColor: 'blue.500',
        color: '#FFFFFF',
      },

      // '@media screen and (min-width:"960px")': {
      //   marginLeft: '125px',
      //   backgroundColor: 'yellow',
      //   padding: '0px',
      // },
      // ' @media only screen and (max-device-width: 480px)': {
      //   backgroundColor: 'yellow',
      //   /* styles for mobile browsers smaller than 480px; (iPhone) */
      // },
    };
  },
  defaultProps: {
    size: 'lg',
  },
};
