import { Alert, AlertDescription } from '@chakra-ui/alert';
import { ButtonGroup, IconButton } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { FaRunning } from 'react-icons/fa';
import { useMediaQuery } from '@chakra-ui/media-query';
import { DeleteIcon, EditIcon, InfoIcon } from '@chakra-ui/icons';
import { Flex, Box, Text } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/select';
import type { FlexboxProps } from '@chakra-ui/styled-system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { HiOutlinePause, HiOutlinePlay } from 'react-icons/hi';
import type { IJob, ITrader } from '../stores/app';
import type { IJobStore } from '../../store/jobConfig';
import type { IBotStore } from '../../store/botConfig';
import { ConfirmDialog } from './primitives/ModalComponent';
import { AlertDialogComponent } from '../../currentBot/components/Sidebar';
import { BsInfoCircle } from 'react-icons/bs';

interface JobProps extends FlexboxProps {
  job?: IJobStore | any;
  store: IBotStore | any;
  onEdit?: () => any;
  onPause?: () => any;
  onDelete: () => any;
  onStatus?: (params?: any) => any;
  onRun?: (job: any) => any;
  [key: string]: any;
}
export const Job = observer(
  ({
    job,
    store,
    onEdit,
    onPause,
    onDelete,
    onRun,
    onStatus,
    ...props
  }: JobProps) => {
    const [isLesserThan700] = useMediaQuery('(max-width: 700px)');

    const icon = job.paused ? HiOutlinePause : HiOutlinePlay;
    const message = !job.paused ? 'success' : 'warning';
    const iconSize: any = 'md';

    const IconText = (text: string) =>
      isLesserThan700 ? null : (
        <Text fontSize="md" textAlign="center" mt="1">
          {text}
        </Text>
      );

    return (
      <Alert
        status={message}
        justifyContent={['initial', 'space-between']}
        flexFlow={['row wrap', 'initial']}
        {...props}
        mb="1"
      >
        <Flex>
          <Icon
            as={icon}
            fontSize={['sm', '2xl']}
            alignSelf="center"
            size={iconSize}
            mr={2}
          />
          <AlertDescription fontSize={['xs', 'md']}>
            {job.name}
          </AlertDescription>
        </Flex>
        <ButtonGroup variant="outline" ml="2" spacing="3">
          <Box>
            <IconButton
              aria-label="Edit"
              fontSize={'md'}
              colorScheme="blue"
              icon={<EditIcon />}
              onClick={onEdit}
              title="Edit"
              size={iconSize}
            />
            {IconText('Edit')}
          </Box>
          {job.paused ? (
            <Box>
              <IconButton
                aria-label="Resume"
                isLoading={store.loading}
                fontSize={'md'}
                colorScheme="green"
                onClick={onPause}
                title="Resume"
                icon={<HiOutlinePlay />}
                size={iconSize}
              />
              {IconText('Resume')}
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label="Pause"
                fontSize={'md'}
                isLoading={store.loading}
                onClick={onPause}
                title="Pause"
                colorScheme="orange"
                size={iconSize}
                icon={<HiOutlinePause />}
              />
              {IconText('Pause')}
            </Box>
          )}
          <ConfirmDialog
            heading={`Delete ${job.display}`}
            confirmAction={onDelete}
            button={
              <Box>
                <IconButton
                  colorScheme="red"
                  aria-label="Delete"
                  fontSize={'md'}
                  isLoading={job.loading}
                  icon={<DeleteIcon />}
                  title="Delete"
                  size={iconSize}
                />
                {IconText('Delete')}
              </Box>
            }
          />
          {!job.alert ? (
            <Box>
              <IconButton
                aria-label="Run"
                fontSize={'md'}
                colorScheme="blue"
                icon={<FaRunning />}
                isLoading={store.loading}
                onClick={onRun}
                title="Run"
                size={iconSize}
              />
              {IconText('Run')}
            </Box>
          ) : null}
          {!job.alert ? (
            <AlertDialogComponent
              confirmSummary={(x: any) => x}
              heading={'Status result'}
              renderCallback={(onOpenFunc: any) => {
                return (
                  <Box>
                    <IconButton
                      isLoading={store.loading}
                      aria-label="Status"
                      fontSize={'md'}
                      colorScheme="purple"
                      icon={<BsInfoCircle />}
                      onClick={() => onStatus && onStatus(onOpenFunc)}
                      title="Status"
                      size={iconSize}
                    />
                    {IconText('Status')}
                  </Box>
                );
              }}
            />
          ) : null}
        </ButtonGroup>
      </Alert>
    );
  },
);

const JobList: React.FC<{
  jobs: IJob[];
  store: ITrader | any;
  [key: string]: any;
}> = ({ jobs, store, ...rest }) => {
  return (
    <Flex direction="column">
      <Select
        my={4}
        variant="outline"
        size="lg"
        bg="white"
        placeholder="Select Market"
        defaultValue={store.currentMarket?.symbol}
        onChange={(e) => {
          let result: any = e.target.value.split(',');
          store.setCurrentMarket(result[0], result[1]);
        }}
      >
        {[...store.futureMarkets, ...store.marginMarkets].map((market) => {
          return (
            <option value={`${market.symbol},${market.kind}`}>
              {market.symbol} {market.kind}
            </option>
          );
        })}
      </Select>
      {jobs.map((job) => {
        return (
          <Job
            mb={2}
            key={job.id}
            job={job}
            store={store}
            onDelete={rest.onDelete}
          />
        );
      })}
    </Flex>
  );
};

export default observer(JobList);
