import { HStack, StackProps, Text, VStack } from '@chakra-ui/react';
import { TradeProps } from '../types';

interface TradeItemProps extends StackProps {
  trade: TradeProps;
}

export const TradeItem = ({ trade, ...rest }: TradeItemProps) => {
  return (
    <HStack justifyContent={'space-between'} {...rest}>
      <VStack px={2}>
        <Text>{trade?.entry}</Text>
        <Text color={'tomato'}>{trade?.sell_price}</Text>
      </VStack>
      <VStack px={2}>
        <Text>{trade?.quantity}</Text>
        <HStack>
          <Text color={'green.500'}>
            pnl:{parseFloat(trade?.pnl?.toFixed(2))}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
