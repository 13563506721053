import { Flex, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { loadAdapter, setAdapter } from '../adapter';
import NewJobComponent from './components/NewJobComponent';
import { Job } from '../newBot/app-components/JobList';
import { JobStore } from '../store/jobConfig';

interface Window {
  APP_DATA: any;
}
declare let window: Window;
let env: 'testing' | 'production' = 'production';
if (window.APP_DATA) {
  const environment: 'testing' | 'production' = window.APP_DATA.environment;
  env = environment || 'production';
}

const store = JobStore.create(
  {
    name: 'Reduce long entry price with size 0.001',
    conditions: [
      {
        variable: 'long.size',
        operator: '<',
        value: 0.005,
      },
      {
        variable: 'long.size',
        operator: '>',
        value: 0,
      },
    ],
    deleteAction: {
      immediate: false,
    },
    actions: [
      {
        type: 'new_order',
        variables: {
          size: {
            variable: 'long.size',
            operator: '-',
            value: 0.005,
          },
          price: {
            variable: 'long.entry',
            operator: '-',
            value: 312.5,
          },
        },
        params: {
          kind: 'long',
          size: '$size',
          price: '$price',
          side: 'buy',
          min_size: 0.001,
          spread: 312.5,
        },
      },
    ],
    job_id: 'b8ea9163aa374c259539f7e8666e1f54',
  },
  {
    adapter: setAdapter(loadAdapter(env)),
  },
);
const EditJobView = observer(({}) => {
  const toast = useToast();
  return (
    <Flex direction="column" m={5}>
      <NewJobComponent loading={false} onClose={() => {}} job={store} />
    </Flex>
  );
});

export const JobListView = Job;

export default EditJobView;
