export function determine_position_size(entry: number, stop: number, budget: number) {
  let stop_percent = Math.abs(entry - stop) / entry;
  let size = budget / stop_percent / entry;
  return size;
}
function determine_risk(entry: number, stop: number, quantity: number) {
  let stop_percent = Math.abs(entry - stop) / entry;
  let risk = quantity * stop_percent * entry;
  return risk;
}
function determine_close_price(
  entry: number,
  pnl: number,
  quantity: number,
  kind: 'long' | 'short',
  single: boolean = false,
  leverage = 1,
) {
  const dollar_value = entry / leverage;
  const position = dollar_value * quantity;
  if (position) {
    let percent = pnl / position;
    let difference = (position * percent) / quantity;
    let result;
    if (kind === 'long') {
      result = difference + entry;
    } else {
      result = entry - difference;
    }
    const pnl_quantity = pnl / (result / leverage);
    if (single) {
      return result;
    }
    return result;
    // return { quantity: pnl_quantity, close: result }
    // return { quantity: pnl_quantity, close: result }
  }
  return 0;
}
export function determine_amount_to_sell(
  entry: number,
  quantity: number,
  sell_price: number,
  pnl: number,
  kind: 'long' | 'short',
  places: string='%.3f',
) {
  const _pnl = determine_pnl(entry, sell_price, quantity, kind);
  const ratio = pnl / to_f(Math.abs(_pnl), places);
  quantity = quantity * ratio;
  return to_f(quantity, places);
}

function determine_pnl(
  entry: number,
  close_price: number,
  quantity: number,
  kind: 'long' | 'short',
  contract_size?: number,
  places = '%.2f',
) {
  if (contract_size) {
    const direction = kind === 'long' ? 1 : -1;
    return quantity * contract_size * direction * (1 / entry - 1 / close_price);
  }
  let difference = entry - close_price;
  if (kind === 'long') {
    difference = close_price - entry;
  }
  return to_f(difference * quantity, places);
}
function position(
  entry: number,
  quantity: number,
  kind: 'long' | 'short',
  leverage = 1,
) {
  const direction = { long: 1, short: -1 };
  return parseFloat(
    (direction[kind] * quantity * (entry / leverage)).toFixed(3),
  );
}

function to_f(value: number, places: string) {
  if (value) {
    let pp = parseInt(places.replace('%.', '').replace('f', ''));
    return parseFloat(value.toFixed(pp));
  }
  return value;
}

const value = {
  determine_risk,
  determine_position_size,
  determine_close_price,
  determine_pnl,
  position,
  determine_amount_to_sell,
  to_f,
};
export default value;
