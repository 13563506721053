import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { observer } from 'mobx-react-lite';
import type { IBotStore } from '../store/botConfig';
import { DropdownMenu } from './components/DropdownMenu';
import Config1Display from './components/GenericConfigDisplay';
import Sidebar, { AlertDialogComponent } from './components/Sidebar';
import React from 'react';

const ConfigurationHeader: React.FC<{ store: IBotStore }> = observer(
  ({ store }) => {
    const toast = useToast();

    const modalDisclosure = useDisclosure();
    const btnRef: any = React.useRef();
    function toastHelper(status: 'success' | 'error', title: string) {
      toast({
        title,
        status,
        description: title,
        duration: 3000,
        isClosable: true,
      });
    }
    return (
      <>
        <Sidebar
          placement="top"
          isLoading={store.loading}
          onSave={() => {
            store
              .onEditProfile()
              .then(() => {
                modalDisclosure.onClose();
              })
              .catch((error) => {
                //console.log(error);
              });
          }}
          button={
            <>
              <Button
                colorScheme="orange"
                mr={2}
                onClick={() => {
                  store.account?.defaultConfig.setEditProfile(null);
                  modalDisclosure.onOpen();
                }}
              >
                New Profile
              </Button>
              {(store.account?.defaultConfig.profiles || []).length > 0 ? (
                <DropdownMenu
                  label={
                    store.account?.defaultConfig?.editProfile?.name ||
                    'Select Profile'
                  }
                  options={
                    store.account?.defaultConfig.profiles.map((o) => o.name) ||
                    []
                  }
                  onClick={(x) => {
                    let profile = store.account?.defaultConfig.profiles.find(
                      (o) => o.name === x,
                    );
                    if (profile) {
                      store.account?.defaultConfig.setEditProfile(profile);
                    }
                  }}
                />
              ) : null}
              {store.account?.defaultConfig.editProfile ? (
                <Button colorScheme="teal" onClick={modalDisclosure.onOpen}>
                  Edit {store.account?.defaultConfig.editProfile.name}
                </Button>
              ) : null}
            </>
          }
          title={store.account?.defaultConfig?.profileTitle || 'Create Profile'}
          {...{ ...modalDisclosure, btnRef }}
        >
          <Flex flexDirection="column">
            <Flex justifyContent="space-around">
              <Config1Display
                override="config_1"
                store={store.account?.defaultConfig.currentProfile}
              />
            </Flex>
          </Flex>
        </Sidebar>
        <AlertDialogComponent
          buttonText={`Delete ${store.calculator.symbol}`}
          heading={(x: any) => `${x}`}
          confirmSummary={(x: any) => `Are you sure you want to ${x}`}
          confirmText={(x: any) => x}
          renderCallback={(onOpenFunc: any) => {
            let options = [
              'Pause all jobs',
              'Resume all jobs',
              'Delete all jobs',
              `Delete ${store.calculator.symbol}`,
            ];
            if (store.account?.defaultConfig.editProfile) {
              options.push(
                `Run jobs with profile ${store.account.defaultConfig.editProfile.name}`,
              );
            }
            return (
              <DropdownMenu
                label={`Select bulk Action`}
                options={options}
                onClick={onOpenFunc}
              />
            );
          }}
          onActionClick={(config: string) => {
            if (config === `Delete ${store.calculator.symbol}`) {
              store
                .deleteSymbol(store.calculator.symbol)
                .then(() => {
                  toastHelper('success', 'symbol deleted');
                  store.setCalculatorMode(false);
                })
                .catch((error) => {
                  //console.log(error);
                  toastHelper('error', 'Error deleteing symbol');
                });
            } else {
              store
                .bulkJobAction(config, store.calculator.symbol)
                .then(() => {
                  toastHelper('success', `${config} deleted`);
                })
                .catch((error: any) => {
                  //console.log(error);
                  toastHelper('error', `Error ${config}`);
                });
            }
          }}
          isLoading={store.loading}
          loadingText="Running"
          colorScheme="red"
        />
        {/* <AlertDialogComponent
          buttonText={`Delete ${store.calculator.symbol}`}
          heading={`Delete ${store.calculator.symbol}`}
          confirmSummary={`Are you sure you want to delete ${store.calculator.symbol}`}
          confirmText="Delete"
          onActionClick={() => {
            store
              .deleteSymbol(store.calculator.symbol)
              .then(() => {
                toastHelper('success', 'symbol deleted');
                store.setCalculatorMode(false);
              })
              .catch((error) => {
                //console.log(error);
                toastHelper('error', 'Error deleteing symbol');
              });
          }}
          isLoading={store.loading}
          loadingText="Deleting"
          colorScheme="red"
        /> */}
      </>
    );
  },
);

export default ConfigurationHeader;
