import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Skeleton,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue as mode,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import {
  BsCaretDownFill,
  BsCaretUpFill,
  BsFillCaretDownFill,
} from 'react-icons/bs';
import { IoIosAddCircle } from 'react-icons/io';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { SelectElement } from '../../../currentBot/components/FormElement';
import { ISolvedTraderConfig } from '../../../store/solvedConfig';
import { BaseDrawer, BaseModal } from '../primitives/ModalComponent';
import { AIChat } from './AIChat';
import {
  AccountTradeStat,
  CollateralStat,
  FutureTradeStat,
} from './FutureTradeStat';
import { StatIconCard } from './StatWithIcon';
import { StatProgressCard } from './StatWithProgressBar';
import { StatWithScore } from './StatWithScore';

interface IndicatorProps {
  type: 'up' | 'down';
  value: string | number;
}

const types = {
  up: { icon: BsCaretUpFill, colorScheme: 'green' },
  down: { icon: BsCaretDownFill, colorScheme: 'red' },
};

export const Indicator = observer((props: IndicatorProps) => {
  const { type, value } = props;
  return (
    <Badge
      display="flex"
      alignItems="center"
      variant="solid"
      colorScheme={types[type].colorScheme}
      rounded="base"
      px="1"
      spacing="0"
    >
      <Box
        aria-hidden
        color="currentcolor"
        as={types[type].icon}
        pos="relative"
        top={type === 'down' ? 'px' : undefined}
      />
      <Box srOnly>
        Value is {type} by {value}
      </Box>
      <Text fontSize="sm" color="white" marginStart="1">
        {value}
      </Text>
    </Badge>
  );
});

interface StatCardProps {
  data: {
    id: string;
    label: string;
    symbol?: string;
    change?: any;
    currency?: any;
    value: string | number;
    limit: number;
    description?: string;
    kind?: 'progress' | 'indicator' | 'icon';
    formattedData?: {
      value: string;
      limit: string;
    };
    display?: boolean;
  };
}

export const StatIndicatorCard: React.FC<StatCardProps> = observer((props) => {
  const {
    label,
    value,
    limit: change,
    description,
    display = true,
    formattedData,
  } = props.data;

  const isNegative = change < 0;
  const changeText = `${change * 100}%`;

  const formattedDataBadge = (value: string) =>
    value.toLowerCase().includes('long') ? (
      <Badge colorScheme="green">{value}</Badge>
    ) : value.toLowerCase().includes('short') ? (
      <Badge colorScheme="red">{value}</Badge>
    ) : null;

  return (
    <Box
      px="6"
      py="4"
      bg={mode('white', 'gray.700')}
      borderRadius="8px"
      boxShadow="md"
      color={mode('gray.800', 'white')}
    >
      <Flex
        justify="space-between"
        align="center"
        fontWeight="medium"
        fontSize="sm"
      >
        <Text fontWeight="medium" fontSize="sm">
          {label}
        </Text>
        {formattedData?.value ? formattedDataBadge(formattedData.value) : null}
      </Flex>
      <HStack spacing="4" mt="2">
        <Heading as="h4" size="xl" lineHeight="1" letterSpacing="tight">
          {value}
        </Heading>
        {display && (
          <Indicator type={isNegative ? 'down' : 'up'} value={changeText} />
        )}
      </HStack>
      {description && (
        <Flex
          justify="space-between"
          align="center"
          fontWeight="medium"
          fontSize="sm"
        >
          <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
            {description}
          </Text>
          {formattedData?.limit
            ? formattedDataBadge(formattedData.limit)
            : null}
        </Flex>
      )}
    </Box>
  );
});

export const AccountWithLoanIndicator: React.FC<{
  store: ISolvedTraderConfig;
  multipleAccount?: boolean;
}> = observer(({ store, multipleAccount }) => {
  let collateralBalance = multipleAccount
    ? store.exchanges.reduce(
        (accumulator, newObj) =>
          accumulator + newObj.currency_display('usdt').price,
        0,
      )
    : null;

  let data = [
    {
      id: 'collateral',
      label: 'Collateral',
      value: collateralBalance ? collateralBalance : store.collateral.display,
      limit: -0.025,
      description: 'You made an extra $97 this week',
      kind: 'indicator',
    },
    {
      id: 'loan_paid',
      label: 'Loan paid',
      value: store.loanPaid,
      limit: store.loan.amount,
      formattedData: {
        value: store.loan.formatValue(store.loanPaid),
        limit: store.loan.display,
      },
      kind: 'progress',
    },
    {
      id: 'break_even',
      symbol: store.coin,
      label: store.coin,
      limit: 0,
      value: store.breakEvenPrice.toFixed(2),
      change: { value: 24.98, percent: -0.73 },
      currency: '$',
      kind: 'icon',
    },
  ];
  return (
    <Box as="section" bg={mode('gray.50', 'gray.800')} p="10">
      <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing="6">
          {data.map((stat: any, idx: any) => {
            if (stat.kind === 'indicator') {
              return <StatIndicatorCard key={idx} data={stat} />;
            }
            if (stat.kind === 'progress') {
              return <StatProgressCard data={stat as any} key={stat.id} />;
            }
            if (stat.kind === 'icon') {
              return <StatIconCard key={stat.id} data={stat as any} />;
            }
            return null;
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
});

export const StatWithIndicator: React.FC<{
  type: 'account' | 'order';
  stats?: any[];
  tabList?: string[];
  loading?: boolean;
  onTabChange?: (index: number) => void;
  viewOrders?: () => void;
  store: ISolvedTraderConfig;
  accountInfo: any;
}> = observer(
  ({
    // loading,
    stats = [],
    tabList = [],
    onTabChange,
    viewOrders,
    store,
    accountInfo,
  }) => {


    const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalType, setModalType] = React.useState(null);

    const [loading, setLoading] = useState(false);
    const [customObjectsArray, setCustomObjectsArray] = useState([]);

    const toast = useToast();
    const status = 'success';

    const onCreate = (data) => {
      setLoading(true);

      setTimeout(function () {
        setLoading(false);
        toast({
          title: 'Account created.',
          description: "We've created your account for you.",
          status: status,
          duration: 3000,
          isClosable: true,
        });

        if (status === 'success') {
          onClose();
        }
        setCustomObjectsArray([
          ...customObjectsArray,
          {
            balance: 12000.0,
            pnl: 300.75,
            current_price: 29000.0,
            liquidation: 27000.0,
            additionalText: 'A new account',
            label: data.selectedAccount,
            kind: data.selectedKind,
          },
        ]);
      }, 1000);
    };

    const openModal = (type: string) => {
      setModalType(type);
      onOpen();
    };

    const closeModal = () => {
      setModalType(null);
      onClose();
    };
    const actions = [
      {
        label: 'Ask Trade AI',
        action: onOpen,
        colorScheme: 'blue',
        leftIcon: <RiQuestionnaireFill />,
      },
    ];
    return (
      <Box
        as="section"
        bg={mode('gray.50', 'gray.800')}
        py={isLargerThan600 ? '10' : '2'}
      >
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          <HStack
            display={{ base: 'none', lg: 'flex' }}
            justify={'space-between'}
          >
            <Heading
              alignSelf={{ md: 'flex-start' }}
              fontSize={{ base: '1.25rem', md: '1.5rem' }}
              fontWeight="semibold"
              color={useColorModeValue('black', 'white')}
            >
              Trade Dashboard
            </Heading>
            <Flex justifyContent={'flex-end'} py="4">
              <HStack spacing="2" direction="row" align="center">
                <Button
                  leftIcon={<RiQuestionnaireFill />}
                  size="md"
                  width={isLargerThan600 ? 'initial' : '100%'}
                  colorScheme="blue"
                  onClick={() => openModal('aiChat')}
                >
                  Ask Trade AI
                </Button>
              </HStack>
            </Flex>
          </HStack>
          <Tabs
            display={{ lg: 'none' }}
            isFitted
            variant="enclosed"
            onChange={onTabChange}
          >
            <TabList mb="1em">
              {tabList?.map((tab: any, idx: any) => (
                <Tab>{tab}</Tab>
              ))}
            </TabList>
            <TabPanels>
              {stats?.map((stat: any, idx: any) => {
                return (
                  <TabPanel p={0} key={idx}>
                    <HStack justify={'space-between'}>
                      <Heading
                        alignSelf={{ md: 'flex-start' }}
                        fontSize={{ base: '1.25rem', md: '1.5rem' }}
                        fontWeight="semibold"
                        color={useColorModeValue('black', 'white')}
                      >
                        Trade Dashboard
                      </Heading>

                      <Flex justifyContent={'flex-end'} py="4">
                        <Menu placement="bottom">
                          <MenuButton
                            as={Button}
                            variant="outline"
                            colorScheme="green"
                            rightIcon={<BsFillCaretDownFill fontSize="0.8em" />}
                          >
                            Actions
                          </MenuButton>
                          <MenuList>
                            {actions.map((action, i) => {
                              return (
                                <MenuItem
                                  key={i}
                                  icon={action.leftIcon}
                                  onClick={action.action}
                                >
                                  {action.label}
                                </MenuItem>
                              );
                            })}
                          </MenuList>
                        </Menu>
                      </Flex>
                    </HStack>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
          <Skeleton isLoaded={!loading}>
            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing="6">
              {stats?.map((stat: any, idx: any) => {
                if (stat.trade_type === 'collateral') {
                  return <CollateralStat key={idx} data={stat as any} />;
                }
                if (stat.trade_type === 'future') {
                  return <FutureTradeStat key={idx} data={stat as any} />;
                }
                if (stat.kind === 'score') {
                  return <StatWithScore key={idx} data={stat as any} />;
                }
                return null;
              })}
              {customObjectsArray.map((x, i) => (
                <AccountTradeStat key={i} data={x} />
              ))}

              <Box
                as="button"
                my="auto"
                mx={['auto', '0px', '0px ']}
                color="green"
                onClick={() => openModal('createCard')}
              >
                <IoIosAddCircle size={'70px'} />
              </Box>
            </SimpleGrid>
          </Skeleton>
        </Box>
        {modalType === 'aiChat' && (
          <AIDrawer isOpen={isOpen} onClose={closeModal} store={store.ai} />
        )}
        {modalType === 'createCard' && (
          <CreateCardModal
            isOpen={isOpen}
            onClose={closeModal}
            onCreate={onCreate}
            // onCreate={(data) => store.onAddNewAccount(data)}
            isLoading={loading}
            accounts={accountInfo.accounts}
          />
        )}
      </Box>
    );
  },
);

type DrawerType = {
  isOpen: boolean;
  onClose: () => void;
  store?: any;
  accounts?: string[];
  isLoading?: boolean;
  onCreate?: (data: any) => void;
};

export const AIDrawer = ({ isOpen, onClose, store }: DrawerType) => {
  const btnRef = React.useRef();
  return (
    <BaseDrawer
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={btnRef}
      title={`Ask Trade Question`}
      onSave={onClose}
      footerProps={{ justifyContent: 'center' }}
      displayTextButton={false}
      closeOnOverlayClick={true}
    >
      <Flex direction="column">
        <Flex justifyContent="space-around">
          <AIChat store={store} />
        </Flex>
      </Flex>
    </BaseDrawer>
  );
};
export const CreateCardModal = ({
  isOpen,
  onClose,
  accounts,
  isLoading,
  onCreate,
  store,
}: DrawerType) => {
  const [selectedObject, setSelectedObject] = useState({});
  return (
    <Flex>
      {isLoading && (
        <Flex mx={'auto'}>
          <Spinner />
        </Flex>
      )}
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={`Create a Card`}
        onSave={() => onCreate(selectedObject)}
        buttonText="create"
        closeOnOverlayClick={true}
      >
        <CardDetails onDataChange={setSelectedObject} accounts={accounts} />
      </BaseModal>
    </Flex>
  );
};

export const CardDetails: React.FC<{
  onDataChange: (data: {
    selectedAccount: string;
    selectedKind: string;
  }) => void;
  accounts: string[];
}> = observer(({ onDataChange, accounts }) => {
  const [selectedAccount, setSelectedAccount] = useState(accounts[1]);
  const [selectedKind, setSelectedKind] = useState('long');

  onDataChange({
    selectedAccount,
    selectedKind,
  });

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setStateFunction: (value: string) => void,
  ) => {
    const { value } = e.target;
    setStateFunction(value);
  };

  return (
    <Box>
      <Flex gap={5}>
        <Box w="full">
          <SelectElement
            label="Account"
            value={selectedAccount}
            onChange={(e) => handleSelectChange(e, setSelectedAccount)}
            options={accounts}
          />
        </Box>
        <Box w="full">
          <SelectElement
            label="Kind"
            value={selectedKind}
            onChange={(e) => handleSelectChange(e, setSelectedKind)}
            options={['long', 'short']}
          />
        </Box>
      </Flex>
    </Box>
  );
});
