import { IAnyStateTreeNode, SnapshotIn, getEnv } from 'mobx-state-tree';
import { BotAdapterType } from '../../adapter';

export const withSetPropAction = <T extends IAnyStateTreeNode>(
  mstInstance: T,
) => ({
  setProp<K extends keyof SnapshotIn<T>, V extends SnapshotIn<T>[K]>(
    field: K,
    newValue: V,
  ) {
    mstInstance[field] = newValue;
  },
});


export const withWebSocket = <
  T extends IAnyStateTreeNode & {
    currentPrice: number;
    symbol: string;
  },
>(
  mstInstance: T,
) => {
  const { adapter } = getEnv<{ adapter: BotAdapterType }>(mstInstance);
  let socket: WebSocket;
  function destroySockets() {
    if (socket) {
      adapter.disconnectAllStreams();
    }
  }
  return {
    initializeSockets(callback?: any) {
      socket = adapter.initializeSockets(
        socket,
        [mstInstance.symbol],
        (p: number) => {
          this.updatePrice(p);
          if (callback) {
            callback(p);
          }
        },
      );
    },
    updatePrice(price: number) {
      mstInstance.currentPrice = price;
    },
    destroySockets,
  };
};
