import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { SelectElement } from '../../currentBot/components/FormElement';
export const TradeButton = ({
  label,
  onClick,
  loading,
  buttonProps,
  isDisabled = false,
}: {
  label: string;
  onClick: () => void;
  loading?: boolean;
  buttonProps?: Record<string, any>;
  isDisabled?: boolean;
}) => {
  return (
    <>
      <Button
        isDisabled={isDisabled}
        isLoading={loading ? loading : undefined}
        size="sm"
        height="48px"
        w={{ base: '100%', md: '210px' }}
        border="2px"
        onClick={onClick}
        {...buttonProps}
        _focus={{ boxShadow: 'none' }}
      >
        {label}
      </Button>
    </>
  );
};

export type TradingSectionPickerProps<T = any> = {
  options?: T[];
  defaultValue?: T;
  onChange?: (e: T) => void;
  onCreate?: () => void;
  defaultLabelText?: string;
  defaultCreateLabel?: string;
  as?: any;
  onEdit?: () => void;
};

export const TradingSectionPicker = observer(
  ({
    options = [],
    defaultValue,
    onChange,
    onCreate,
    defaultLabelText = 'Select Zone',
    defaultCreateLabel = 'Create Zone',
    as: _as,
    onEdit,
    valueLayout = (u) => `${u.entry} - ${u.stop}`,
    optionLayout = (u) => u.id,
  }: TradingSectionPickerProps) => {
    const defaultState = defaultValue;
    const tradingSections = options;
    return (
      <>
        {_as === 'select' ? (
          <SelectElement
            label=""
            value={defaultValue?.id}
            options={tradingSections}
            defaultText={defaultLabelText}
            optionLayout={optionLayout}
            valueLayout={valueLayout}
            onChange={(e) => {
              if (onChange) {
                let section = tradingSections.find(
                  (v) => v.id === e.target.value,
                );
                onChange(section);
              }
            }}
            labelProps={{
              fontWeight: 'bold',
              marginTop: '2',
              width: '370px',
            }}
            controlProps={{
              display: 'flex',
              py: 2,
              justifyContent: 'space-between',
            }}
          />
        ) : (
          <Menu w={{ base: 'full', md: 'initial' }}>
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              height="48px"
              border="2px"
              borderRaduis="15px"
              w={{ base: '100%', md: 'initial' }}
              _focus={{ boxShadow: 'none' }}
            >
              {<MenuText text={defaultState || defaultLabelText} />}
            </MenuButton>
            <MenuList>
              {tradingSections.map((section, index) => (
                <MenuItem
                  key={index}
                  // minH="48px"
                  justifyContent="center"
                  onClick={() => {
                    if (onChange) {
                      onChange(section);
                    }
                  }}
                >
                  <MenuText text={section} />
                </MenuItem>
              ))}
              {onCreate && (
                <MenuItem
                  minH="48px"
                  onClick={onCreate}
                  fontWeight="900"
                  mt={1}
                  bg={'gray.200'}
                >
                  <AddIcon boxSize={18} mr={2} />
                  <span>{defaultCreateLabel}</span>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
        {onEdit && (
          <Link
            display="flex"
            size="sm"
            justifyContent="flex-end"
            onClick={onEdit}
          >
            Edit
          </Link>
        )}
      </>
    );
  },
);

export const MenuText = ({
  text,
}: {
  text:
    | {
        support: number;
        resistance: number;
      }
    | string;
}) => (
  <Box
    as="span"
    display="inline-block"
    // maxW={{ base: '150px', md: '200px' }}
    fontSize={{ base: 'md', md: 'md' }}
    overflow="hidden"
    textOverflow="ellipsis"
    verticalAlign="middle"
  >
    <Text ml="3px" fontWeight="bold">
      {typeof text === 'object' ? `${text.support} - ${text.resistance}` : text}
    </Text>
  </Box>
);
