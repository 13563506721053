import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import MarketTransfer from '../../newBot/app-components/MarketTransfer';
import { Root } from '../stores';

export type TransferFundPageProps = {
  onGoBack: () => void;
  store: Root;
};

export const TransferFundPage = observer(
  ({ store, onGoBack }: TransferFundPageProps) => {
    const [loading, setLoading] = useState(false);

    async function onTransfer(p: any) {
      try {
        setLoading(true);
        await store.onTransfer(p);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('error', error);
      }
    }
    return (
      <MarketTransfer
        data={store.transferData}
        loading={loading}
        onTransfer={onTransfer}
      />
    );
  },
);

export default TransferFundPage;
