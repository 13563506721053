import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { to_f } from '../../../store/utils';
import { determine_pnl } from '../../../store/trade_signal';
import { observer } from 'mobx-react-lite';
import { IFutureInstance } from '../../../store/futureConfig';
import Config1Display from '../../../currentBot/components/GenericConfigDisplay';
import { determine_position_size } from '../../../store/hedge';
type RowEntryProps = {
  entry: number;
  stop: number;
  pnl: number;
  risk: number;
  kind: string;
  quantity: number;
  avg_entry: number;
  avg_size: number;
  sell_price: number;
  fee: number;
  incurred: number;
  net: number;
  risk_sell: number;
};

const useLoaderWithToast = () => {
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();
  function onSuccess() {
    setLoading(false);
    toast({
      title: 'Success',
      description: 'Request placed',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  }
  function onError(error: any) {
    setLoading(false);
    //console.log(error);
    toast({
      title: 'Error',
      description: 'Error placing order',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  }
  return {
    loading,
    setLoading,
    onSuccess,
    onError,
  };
};
type PopoverSummaryProps = {
  children: any;
  store: IFutureInstance;
  rowEntry?: RowEntryProps;
};

const CalculateLossDisplay = observer(
  ({
    store,
    rowEntry,
  }: {
    store: IFutureInstance;
    rowEntry: RowEntryProps;
  }) => {
    const { placeTrade: _placeTrade, kind } = store.config.re_entry_store;
    function calculatePnl() {
      const { entry, l_entry, size, take_profit } = store.config.re_entry_store;
      if (entry && size && take_profit) {
        const total_loss = determine_pnl(entry, take_profit, size, kind);
        const ratio = to_f(store.config.min_size / size, '%.3f');
        const loss = Math.abs(total_loss) * ratio;
        const newSize = determine_position_size(l_entry, take_profit, loss);
        return to_f(newSize, '%.3f');
      }
      return 0;
    }
    const avgValue = calculatePnl();
    return (
      <Box>
        <Config1Display
          props={{ columns: 2, spacing: 2 }}
          store={store.config.re_entry_store}
          hideFields={['l_size', 'kind', 'budget']}
        />
        {avgValue > 0 && (
          <Stack spacing={3} mt={3}>
            <Alert bg="blue.800" status="warning">
              <Flex justify={'space-between'} flex="1">
                <Flex direction={'column'} flex="1">
                  <Text>Size to loose: {avgValue}</Text>
                  <Text>min-size: {store.config.min_size}</Text>
                </Flex>
                <Flex direction={'column'}>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      store.config.updateFields({
                        first_order_size: avgValue,
                      });
                    }}
                  >
                    Update first trade size
                  </Button>
                </Flex>
              </Flex>
            </Alert>
          </Stack>
        )}
      </Box>
    );
  },
);

const TradeSummaryDisplay = observer(
  ({
    store,
    rowEntry,
  }: {
    store: IFutureInstance;
    rowEntry: RowEntryProps;
  }) => {
    const [toggle, setToggle] = React.useState(false);

    const {
      determineAvg,
      placeTrade: _placeTrade,
      calculateAvg,
      kind,
    } = store.config.re_entry_store;
    const avgValue = determineAvg(rowEntry);
    return (
      <Box>
        <Config1Display
          props={{ columns: 2, spacing: 2 }}
          store={store.config.re_entry_store}
          hideFields={toggle ? [] : ['l_entry', 'l_size', 'take_profit']}
        />
        <Box
          cursor={'pointer'}
          textDecoration={'underline'}
          fontSize={'12px'}
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {toggle ? `Hide ` : 'Show '} opp position
        </Box>
        {avgValue && (
          <Stack spacing={3} mt={3}>
            <Alert bg="blue.800" status="warning">
              <Flex justify={'space-between'} flex="1">
                <Flex direction={'column'} flex="1">
                  {avgValue.left.map((value: any, index: number) => (
                    <Text key={index}>{value}</Text>
                  ))}
                </Flex>
                <Flex direction={'column'}>
                  {avgValue.right.map((value: any, index: number) => (
                    <Text key={index}>{value}</Text>
                  ))}
                </Flex>
              </Flex>
            </Alert>
          </Stack>
        )}
      </Box>
    );
  },
);

const OppositeEntryPopoverElement = observer(
  ({ children, store, rowEntry, ...props }: PopoverSummaryProps) => {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const [swap, setSwap] = React.useState(false);
    const initialFocusRef = React.useRef();
    const { setLoading, loading, onSuccess, onError } = useLoaderWithToast();
    const { placeTrade: _placeTrade } = store.config.re_entry_store;

    const placeTrade = async () => {
      try {
        setLoading(true);
        await _placeTrade(rowEntry);
        onSuccess();
      } catch (error) {
        console.log('error', error);
        onError(error);
      }
    };
    return (
      <>
        <Popover
          isOpen={isOpen}
          onClose={onClose}
          initialFocusRef={initialFocusRef}
          placement="bottom"
          closeOnBlur={false}
        >
          <PopoverTrigger>
            <a
              onClick={() => {
                onToggle();
              }}
              style={{ cursor: 'pointer' }}
            >
              {children}
            </a>
          </PopoverTrigger>
          <PopoverContent color="white" bg="blue.800" borderColor={'blue.800'}>
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              {store.config.kind === 'short' ? `Long` : `Short`} Trade summary
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => {
                  setSwap(!swap);
                }}
                ml={3}
              >
                Swap
              </Button>
            </PopoverHeader>
            <PopoverArrow bg="blue.800" />
            <PopoverCloseButton />
            <PopoverBody>
              {isOpen && (
                <>
                  {swap ? (
                    <CalculateLossDisplay store={store} rowEntry={rowEntry} />
                  ) : (
                    <TradeSummaryDisplay store={store} rowEntry={rowEntry} />
                  )}
                </>
              )}
            </PopoverBody>
            <PopoverFooter
              border="0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb={4}
            >
              <Box fontSize="sm"></Box>
              <ButtonGroup size="sm">
                <Button
                  isLoading={loading}
                  colorScheme="blue"
                  isDisabled={
                    swap || store.config.re_entry_store.kind !== rowEntry?.kind
                  }
                  onClick={placeTrade}
                  ref={initialFocusRef}
                >
                  Place stop trade
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      </>
    );
  },
);

const ToolTipElement = React.forwardRef(({ children, ...props }: any, ref) => {
  return (
    <Box ref={ref} {...props}>
      {children}
    </Box>
  );
});

type RowWrapperProps = {
  children: any;
  store?: IFutureInstance;
  onClick?: (e: any) => void;
  wrapperStyle?: 'link' | 'tooltip' | 'popover';
  hoverText?: RowEntryProps;
};
function RowWrapperComponent({
  children,
  onClick,
  wrapperStyle,
  hoverText,
  store,
}: RowWrapperProps) {
  if (wrapperStyle === 'link') {
    return (
      <a href="#" style={{ cursor: 'pointer' }} onClick={onClick}>
        {children}
      </a>
    );
  }
  if (wrapperStyle === 'popover' && store) {
    return (
      <OppositeEntryPopoverElement store={store} rowEntry={hoverText}>
        {children}
      </OppositeEntryPopoverElement>
    );
  }
  if (wrapperStyle === 'tooltip') {
    return (
      <Tooltip
        placement="top"
        label={
          hoverText
            ? to_f(
                determine_pnl(
                  hoverText.avg_entry,
                  hoverText.stop,
                  hoverText.avg_size,
                  hoverText.kind,
                ),
                '%.2f',
              )
            : ''
        }
      >
        <ToolTipElement>{children}</ToolTipElement>
      </Tooltip>
    );
  }
  return <>{children}</>;
}

export const RowWrapper = observer(RowWrapperComponent);
