import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { withSetPropAction, withWebSocket } from './helpers';
import { Order, OrderControl, PositionTypes } from './utils';
import { FutureInstance } from '../../store/futureConfig';
import { to_f } from '../../store/utils';

type ClosedOrder = {
  symbol: string;
  id: number;
  orderId: number;
  side: 'SELL' | 'BUY';
  price: string;
  qty: string;
  realizedPnl: string;
  marginAsset: string;
  quoteQty: string;
  commision: string;
  commissionAsset: string;
  time: number;
  positionSide: 'BOTH' | 'LONG' | 'SHORT';
  maker: boolean;
  buyer: boolean;
};

type Balance = {
  asset: string;
  balance: number;
  type?: 'future' | 'spot';
};

export const MarketInstance = types
  .model('MarketInstance', {
    id: types.identifier,
    symbol: types.string,
    last_order: types.optional(types.number, 0),
    closed_order: types.frozen<{
      long: ClosedOrder;
      short: ClosedOrder;
    }>(),
    account_balance: types.frozen<Array<Balance>>(),
    open_orders: types.frozen<Array<Order>>(),
    position: types.frozen<PositionTypes>(),
    currentPrice: types.optional(types.number, 0),
    futureCTrader: types.optional(FutureInstance, {}),
  })
  .views(withSetPropAction)
  .actions(withWebSocket)
  .views((self) => {
    return {
      get owner() {
        return self.id.split(':')[1];
      },
      getPosition(kind: 'long' | 'short') {
        const options = {
          long: self.position.long,
          short: self.position.short,
        };
        return {
          price: to_f(
            options[kind].entryPrice,
            self.futureCTrader.price_places,
          ),
          quantity: to_f(
            Math.abs(options[kind].positionAmt),
            self.futureCTrader.decimal_places,
          ),
          addition: null,
        };
      },

      get liquidationPrice() {
        return to_f(
          self.position.long.liquidationPrice,
          self.futureCTrader.price_places,
        );
      },
      get dollarBalance() {
        const balance = self.account_balance
          .map((o) => ({
            ...o,
            type: o.type ?? 'future',
          }))
          .filter((o) => o.type === 'future')
          .find((b) =>
            self.symbol.endsWith('USDT')
              ? b.asset === 'USDT'
              : b.asset === 'USDC',
          );
        let bb = balance ? balance.balance : 0;
        return to_f(bb, self.futureCTrader.decimal_places);
      },
      get orders() {
        return new OrderControl({
          order_lists: self.open_orders,
          positions: self.position,
          price_places: self.futureCTrader?.price_places,
          decimal_places: self.futureCTrader?.decimal_places,
        });
      },
      summary(useStopLoss?: boolean) {
        const remainingLongSize = this.getOpenOrders({
          kind: 'long',
          stats: true,
          deduct_sl: useStopLoss,
        });
        const remainingShortSize = this.getOpenOrders({
          kind: 'short',
          stats: true,
          deduct_sl: useStopLoss,
        });
        const longStats = this.getOpenOrders({
          kind: 'long',
          stats: true,
          deduct_sl: useStopLoss,
        });
        const longStats2 = this.getOpenOrders({
          kind: 'long',
          stats: true,
          avg: {
            entry: remainingLongSize?.avg || 0,
            size: remainingLongSize?.size || 0,
          },
        });
        const shortStats = this.getOpenOrders({
          kind: 'short',
          stats: true,
          deduct_sl: useStopLoss,
        });
        const shortStats2 = this.getOpenOrders({
          kind: 'short',
          stats: true,
          avg: {
            entry: remainingShortSize?.avg || 0,
            size: remainingShortSize?.size || 0,
          },
        });
        const longSLStats = this.getStopLoss({ kind: 'long', stats: true });
        const shortSLStats = this.getStopLoss({ kind: 'short', stats: true });
        // let remainingLongSize =
        //   (longStats?.size || 0) - (longSLStats?.quantity || 0);
        // if (remainingLongSize < 0) {
        //   remainingLongSize = 0;
        // }
        // let remainingShortSize =
        //   (shortStats?.size || 0) - (shortSLStats?.quantity || 0);
        // if (remainingShortSize < 0) {
        //   remainingShortSize = 0;
        // }
        return [
          {
            title: 'Avg. Long Entry',
            value: longStats?.avg,
          },
          {
            title: 'Avg. Short Entry',
            value: shortStats?.avg,
          },
          {
            title: 'Avg. Long Size',
            value: longStats?.size,
            // value: remainingLongSize || longStats?.size,
          },
          {
            title: 'Avg. Short Size',
            value: shortStats?.size,
            // value: remainingShortSize || shortStats?.size,
          },
          {
            title: 'Long. Liq',
            value: longStats?.liquidation,
          },
          {
            title: 'Short. Liq',
            value: shortStats?.liquidation,
          },
          {
            title: 'Long. S/L size',
            value: longSLStats?.quantity,
          },
          {
            title: 'Short. S/L size',
            value: shortSLStats?.quantity,
          },
          {
            title: 'Long. loss pnl',
            value: longStats?.loss,
          },
          {
            title: 'Short. loss pnl',
            value: shortStats?.loss,
          },
          {
            title: 'Avg. Long Entry AS',
            value: longStats2?.avg,
          },
          {
            title: 'Avg. Short Entry AS',
            value: shortStats2?.avg,
          },
          {
            title: 'Avg. Long Size AS',
            value: longStats2?.size,
          },
          {
            title: 'Avg. Short Size AS',
            value: shortStats2?.size,
          },
          // {
          //   title: 'Current Price',
          //   value: self.currentPrice,
          // },
          // {
          //   title: 'Long Position',
          //   value: self.getPosition('long').quantity,
          // },
          // {
          //   title: 'Short Position',
          //   value: self.getPosition('short').quantity,
          // },
        ];
      },
      getStopLoss({
        kind,
        stats,
      }: {
        kind: 'long' | 'short';
        stats?: boolean;
      }) {
        return this.orders.get_sl_orders({
          kind,
          stats,
        });
      },
      getTakeProfit({
        kind,
        stats,
      }: {
        kind: 'long' | 'short';
        stats?: boolean;
      }) {
        return this.orders.get_tp_orders({
          kind,
          stats,
        });
      },
      getOpenOrders({
        kind,
        stats,
        deduct_sl,
        avg,
      }: {
        avg?: { entry: number; size: number };
        deduct_sl?: boolean;
        kind: 'long' | 'short';
        stats?: boolean;
      }) {
        return this.orders.get_open_orders({
          kind,
          stats,
          balance: this.dollarBalance,
          deduct_sl,
          avg,
        });
      },
    };
  })
  .actions((self) => {
    const onCancel = async (orders: Order[]) => {
      console.log('cancel orders', orders);
    };
    return {
      onCancel,
    };
  });

export interface MarketSnapshotIn extends SnapshotIn<typeof MarketInstance> {}

export interface MarketSymbol extends Instance<typeof MarketInstance> {}
