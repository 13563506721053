import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
  InputElement,
  SelectElement,
} from '../../currentBot/components/FormElement';
import { BaseModal } from '../../newBot/app-components/primitives/ModalComponent';
import {
  ButtonCollection,
  ConfirmationDetails,
  IncreasePostionFormField,
  OpenOrdersList,
  TradingSectionPicker,
} from '../components';
import { Exchange } from '../stores';

export type IncreasePositionSummayPageProps = {
  store: Exchange;
  onGoBack: () => void;
  navigate: (path: string) => void;
};

export const IncreasePositionSummayPage = observer(
  ({ store, onGoBack, navigate }: IncreasePositionSummayPageProps) => {
    const [loading, setLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [kind, setKind] = useState<'long' | 'short'>('long');
    const toast = useToast();
    const orders = store.activeTradeSection?.buildConfig(kind) || [];
    return (
      <>
        <IncreasePostionFormField
          actions={[
            {
              label: 'Trade section',
              renderItem: (
                <TradingSectionPicker
                  as="select"
                  options={store.zones}
                  defaultValue={store.activeZone}
                  onChange={(s) => {
                    store.setProp('activeTradeSection', s.id);
                  }}
                  onEdit={() => {
                    navigate('/trade-section');
                  }}
                />
              ),
            },
            {
              label: 'Select Postion Kind ',
              renderItem: (
                <SelectElement
                  label=""
                  value={kind}
                  options={['long', 'short']}
                  onChange={(e) => {
                    setKind(e.target.value);
                  }}
                  labelProps={{
                    fontWeight: 'bold',
                    marginTop: '2',
                    width: '370px',
                  }}
                  controlProps={{
                    display: 'flex',
                    py: 2,
                    justifyContent: 'space-between',
                  }}
                />
              ),
            },
          ]}
        />
        <OpenOrdersList<any>
          orders={orders.map((v) => ({
            orderId: v.entry,
            price: v.entry,
            stopPrice: '0',
            origQty: v.quantity,
            type: 'limit',
            positionSide: kind,
          }))}
        />
        <ButtonCollection
          actions={[{ label: 'Place Trades', action: onOpen }]}
          direction={'column'}
        />
        <BaseModal
          removeCloseButton
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
        >
          <ConfirmationDetails
            loading={loading}
            text="Please confirm you want to place this trades."
            buttonLabel="Place Trades"
            onClick={async () => {
              try {
                setLoading(true);
                await store.placeOrders(kind);
                setLoading(false);
                toast({
                  title: 'Place Trades successful',
                  description: 'Trades has been placed',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });

                onClose();
              } catch (error) {
                setLoading(false);
                toast({
                  title: 'Error',
                  description: error?.message,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
          />
        </BaseModal>
      </>
    );
  },
);

export default IncreasePositionSummayPage;
