import { observer } from 'mobx-react-lite';
import { ActionType, TableWithSearch } from './TableWithSearch';
import { ISolvedTraderConfig } from '../../../store/solvedConfig';
import React, { useEffect, useState } from 'react';
import { BaseDrawer } from '../primitives/ModalComponent';
import { Flex, useDisclosure } from '@chakra-ui/react';
import { ITradeZoneStore } from '../../../store/solvedConfig/trade-zone';
import TightFutureTraderAnalyzer from '../TightFutureTraderAnalyzer';

const NewFutureColums = [
  // {
  //   Header: 'Entry',
  //   accessor: 'entry',
  // },
  {
    Header: 'A.Entry',
    accessor: 'avg_entry',
  },
  {
    Header: 'Risk',
    accessor: 'risk',
  },
  {
    Header: 'size',
    accessor: 'avg_size',
  },
  {
    Header: 'Stop',
    accessor: 'stop',
  },
  {
    Header: 'TP',
    accessor: 'take_profit',
  },
  {
    Header: 'kind',
    accessor: 'kind',
  },
  // {
  //   Header: 'support',
  //   accessor: 'support',
  // },
  // {
  //   Header: 'resistance',
  //   accessor: 'resistance',
  // },
];

/**
 * Actions to be place
 * 1. Enter trade
 * 2. Enter Opposite trade
 */

const MarginOrderColumns = [
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Kind',
    accessor: 'kind',
  },
  {
    Header: 'Notion Value',
    accessor: 'notion_value',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
];
const FutureOrderColumns = [
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
  },
  {
    Header: 'Kind',
    accessor: 'kind',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Stop',
    accessor: 'stop',
  },
];

type DynamicTableProps = {
  store: ISolvedTraderConfig;
  market_type?: 'margin' | 'future' | 'zones';
  newData?: any;
  edit?: boolean;
};
export const DynamicTable: React.FC<DynamicTableProps> = observer(
  ({ store, market_type = 'margin', edit, newData }) => {
    const account =
      market_type === 'margin' ? store.marginAccount : store.futureAccount;
    const data = ['margin', 'future'].includes(market_type)
      ? account?.orders || []
      : store.tradeZones;

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [modalData, setModalData] = useState();
    const btnRef = React.useRef();

    const columnsOptions = {
      margin: MarginOrderColumns,
      future: FutureOrderColumns,
      zones: NewFutureColums,
    };

    const futureActions = [
      {
        label: (row: ITradeZoneStore) => `Place.entry @ ${row.entry}`,
        variant: 'outline',
        color: 'green',
        func: (row: ITradeZoneStore, index: number) => {
          setModalData(row);
          onOpen();
        },
      },
      {
        label: 'Cancel',
        func: (row: ITradeZoneStore, index: number) => {
          console.log(row);
        },
      },
    ];
    const marginActions: ActionType[] = [];

    return (
      <>
        <TableWithSearch
          title="Margin Orders"
          columns={columnsOptions[market_type]}
          data={data}
          as_select={['stop']}
          form={null}
          edit={edit}
          actions={market_type === 'zones' ? futureActions : marginActions}
        ></TableWithSearch>

        <BaseDrawer
          placement="top"
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          title={`Future Table`}
          buttonText="Cancel"
          onSave={onClose}
          footerProps={{ justifyContent: 'center' }}
          // displayCancelButton
          // buttonProps={{
          //   isLoading: store.loading || account.loading || store.account_loading,
          //   loadingText: 'Saving',
          // }}
          closeOnOverlayClick={true}
        >
          <Flex direction="column">
            <Flex justifyContent="space-around">
              <TightFutureTraderAnalyzer
                displayForm={false}
                store={store.futureCTrader}
                accounts={[]}
                // positionStore={positionStore}
                // callback={callback}
              />
            </Flex>
          </Flex>
        </BaseDrawer>
      </>
    );
  },
);
