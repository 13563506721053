import development from "./testing"
import production from "./production"

export type { TradeType } from './types'
function loadEnvironment(environment: "testing" | "production") {
  if (environment === "production") {
    return production
  }
  return development
}
export default loadEnvironment;
