export type TradeType = {
  symbol: string;
  entry: number;
  quantity: number;
  stop?: number | null;
  kind: string;
  isMarketTrade?: boolean;
};
export type StopLossType = {
  id?: number;
  entryPrice?: number;
  quantity?: number;
  symbol: string;
  kind: 'long' | 'short';
  pip: number;
  stopPrice?: number;
  takeProfitPrice?: number;
  percent?: number;
  risk?: number;
};
const sample_last_order = {
  symbol: 'BTCUSDT',
  id: 3177661754,
  orderId: 21859610647,
  price: '29970.41',
  qty: '0.009',
  quoteQty: '269.73369',
  commission: '0.000009',
  commissionAsset: 'BTC',
  time: 1690161332396,
  isBuyer: true,
  isMaker: false,
  isBestMatch: true,
  isIsolated: true,
};
const sampleBalance = {
  borrowed: 0.150001,
  free: 5e-6,
  locked: 0.10455,
  total: 0.10455500000000001,
  asset: 'BTC',
};
const sampleFutureOrder = {
  orderId: 175647083592,
  symbol: 'BTCUSDT',
  status: 'NEW',
  clientOrderId: 'XPGIDbHiIMdmw2WOpm7fpo',
  price: '29038.60',
  avgPrice: '0',
  origQty: '0.001',
  executedQty: '0',
  cumQuote: '0.00000',
  timeInForce: 'GTC',
  type: 'LIMIT',
  reduceOnly: false,
  closePosition: false,
  side: 'BUY',
  positionSide: 'LONG',
  stopPrice: '0',
  workingType: 'CONTRACT_PRICE',
  priceProtect: false,
  origType: 'LIMIT',
  time: 1690555240248,
  updateTime: 1690555240248,
};
const samplePosition = {
  symbol: 'BTCUSDT',
  positionAmt: 0.0,
  entryPrice: 0.0,
  markPrice: 29345.2,
  unRealizedProfit: 0.0,
  liquidationPrice: 0.0,
  leverage: 125,
  maxNotionalValue: 50000.0,
  marginType: 'cross',
  isolatedMargin: 0.0,
  isAutoAddMargin: 'false',
  positionSide: 'LONG',
  notional: '0',
  isolatedWallet: 0.0,
  updateTime: 1690554605310,
};

type IPosition = typeof samplePosition;
type BalanceType = typeof sampleBalance;
export class AccountBalanceParser {
  base_data: BalanceType;
  quote_data: BalanceType;
  constructor(base: BalanceType, quote: BalanceType) {
    this.base_data = base;
    this.quote_data = quote;
  }
  get symbol() {
    return {
      base: this.base_data.asset,
      quote: this.quote_data.asset,
    };
  }
  get assets() {
    return {
      base:
        this.base_data.total + this.base_data.free - this.base_data.borrowed,
      quote:
        this.quote_data.total + this.quote_data.free - this.quote_data.borrowed,
    };
  }
  get borrowed() {
    return {
      base: this.base_data.borrowed,
      quote: this.quote_data.borrowed,
    };
  }
}
export type MarginPayloadType = {
  last_orders: (typeof sample_last_order)[];
  account_balance: AccountBalanceParser;
  open_orders: (typeof sampleFutureOrder)[];
  owner: string;
};

export type FuturePayloadType = {
  last_order: number;
  account_balance: number;
  open_orders: (typeof sampleFutureOrder)[];
  owner: string;
  position: {
    long: IPosition;
    short: IPosition;
  };
};
