import {
  leverage_100,
  leverage_125,
  leverage_20,
  leverage_25,
  leverage_50,
  leverage_75,
  coin_leverage_100,
  coin_leverage_125,
  coin_leverage_75,
  coin_leverage_20,
  coin_leverage_50,
  coin_leverage_25,
} from './maintenance_margin';

function calc(
  floor: number,
  value: { m_rate: number },
  previous: { m_a: number; m_rate: number },
) {
  return floor * ((value.m_rate - previous.m_rate) / 100) + previous.m_a;
}

function get_maintenance_margin(
  position_size: number,
  maximum_leverage = 125,
  coin_type = false,
  ...rest: any
) {
  let options: any = {
    125: leverage_125,
    100: leverage_100,
    75: leverage_75,
    50: leverage_50,
    25: leverage_25,
    20: leverage_20,
    // "coin_125": coin_leverage_125,
    // "coin_100": coin_leverage_100,
  };
  if (coin_type) {
    options = {
      125: coin_leverage_125,
      100: coin_leverage_100,
      75: coin_leverage_75,
      50: coin_leverage_50,
      25: coin_leverage_25,
      20: coin_leverage_20,
    };
  }
  let func = options[maximum_leverage];
  if (!func) {
    //console.log(
    //   'Failed maximum_leverage',
    //   maximum_leverage,
    //   'with coin ',
    //   coin_type,
    // );
    func = coin_leverage_125;
  }

  let result = func(position_size, ...rest);
  let value = result['value'];
  let base_value = result['base_value'];
  let minimum = result['minimum'];

  if (position_size > minimum) {
    let previous = get_maintenance_margin(
      base_value,
      maximum_leverage,
      coin_type,
      ...rest,
    );
    value.m_a = Math.round(calc(base_value, value, previous));
  }
  value.m_t = (value.m_rate * position_size) / 100;
  return value;
}

function calculate_position_size(
  entry: number,
  size: number,
  contract_size?: number,
) {
  if (contract_size) {
    let new_size = size * contract_size;
    if (new_size) {
      let btc_size = new_size / entry;
      return btc_size;
    }
  }
  return entry * size;
}

function get_size(size: number) {
  return size;
}

function contract_balance(value: number, entry: number) {
  return value;
}
type Position = {
  entry: number;
  size: number;
};
type LongShort = {
  long: Position;
  short: Position;
};
function determine_liquidation(
  balance: number,
  long_position: Position,
  short_position: Position,
  both_position = { entry: 0, size: 0 },
  additional?: LongShort,
  contract_size?: number,
  maximum_leverage = 125,
  symbol?: string,
) {
  //console.log(maximum_leverage);
  let coin_type = Boolean(contract_size);
  let wallet_balance = balance;
  let unrealized_pnl = 0;
  let direction = -1;
  let entry_one_way = 0;
  let size_one_way = 0;
  let size_hedge_long = 0;
  let size_hedge_short = 0;
  let entry_hedge_long = 0;
  let entry_hedge_short = 0;
  if (both_position) {
    entry_one_way = both_position.entry;
    size_one_way = both_position.size;
  }
  if (long_position && short_position) {
    size_hedge_long = long_position.size;
    size_hedge_short = short_position.size;
    entry_hedge_long = long_position.entry;
    entry_hedge_short = short_position.entry;
  }
  if (additional) {
    let _long = additional.long;
    let short = additional.short;
    if (_long) {
      size_hedge_long = _long.size + size_hedge_long;
      entry_hedge_long =
        (entry_hedge_long * long_position.size + _long.entry * _long.size) /
        (size_hedge_long || 1);
    }
    if (short) {
      size_hedge_short = short.size + size_hedge_short;
      entry_hedge_short =
        (entry_hedge_short * short_position.size + short.entry * short.size) /
        (size_hedge_short || 1);
    }
  }
  let m_one_way = get_maintenance_margin(
    calculate_position_size(entry_one_way, size_one_way, contract_size),
    maximum_leverage,
    coin_type,
    symbol,
  );
  let m_long = get_maintenance_margin(
    calculate_position_size(entry_hedge_long, size_hedge_long, contract_size),
    maximum_leverage,
    coin_type,
    symbol,
  );
  let m_short = get_maintenance_margin(
    calculate_position_size(entry_hedge_short, size_hedge_short, contract_size),
    maximum_leverage,
    coin_type,
    symbol,
  );
  let maintenance_margin_one_way = m_one_way.m_rate / 100;
  let maintenance_margin_long = m_long.m_rate / 100;
  let maintenance_margin_short = m_short.m_rate / 100;

  let maintenance_amount_one_way = m_one_way.m_a;
  let maintenance_amount_hedge_long = m_long.m_a;
  let maintenance_amount_hedge_short = m_short.m_a;
  let maintenance_margin = 0;
  let liquidation = 0;
  if (contract_size) {
    let one_way_div = entry_one_way > 0 ? size_one_way / entry_one_way : 0;
    let hedge_long_div =
      entry_hedge_long > 0 ? size_hedge_long / entry_hedge_long : 0;
    let hedge_short_div =
      entry_hedge_short > 0 ? size_hedge_short / entry_hedge_short : 0;
    liquidation =
      (size_one_way * maintenance_margin_one_way +
        size_hedge_long * maintenance_margin_long +
        size_hedge_short * maintenance_margin_short +
        direction * size_one_way +
        size_hedge_long -
        size_hedge_short) /
      ((wallet_balance -
        maintenance_margin +
        unrealized_pnl +
        maintenance_amount_one_way +
        maintenance_amount_hedge_long +
        maintenance_amount_hedge_short) /
        contract_size +
        direction * one_way_div +
        hedge_long_div -
        hedge_short_div);
  } else {
    liquidation =
      (wallet_balance -
        maintenance_margin +
        unrealized_pnl +
        maintenance_amount_one_way +
        maintenance_amount_hedge_long +
        maintenance_amount_hedge_short -
        direction *
          calculate_position_size(entry_one_way, size_one_way, contract_size) -
        // get_size(size_hedge_long, kind) * entry_hedge_long +
        calculate_position_size(
          entry_hedge_long,
          size_hedge_long,
          contract_size,
        ) +
        // get_size(size_hedge_short, kind) * entry_hedge_short) /
        calculate_position_size(
          entry_hedge_short,
          size_hedge_short,
          contract_size,
        )) /
      (get_size(size_one_way) * maintenance_margin_one_way +
        get_size(size_hedge_long) * maintenance_margin_long +
        get_size(size_hedge_short) * maintenance_margin_short -
        direction * get_size(size_one_way) -
        get_size(size_hedge_long) +
        get_size(size_hedge_short));
  }
  let data = {
    liquidation,
    long: { entry: entry_hedge_long, size: size_hedge_long },
    short: { entry: entry_hedge_short, size: size_hedge_short },
  };
  return data;
}

function determine_pnl(
  entry: number,
  close_price: number,
  quantity: number,
  kind = 'long',
  contract_size?: number,
) {
  if (contract_size) {
    // position_size * contract_multiplier * direction of order * (1 / entry price - 1 / mark price)
    let direction = kind === 'long' ? 1 : -1;
    return quantity * contract_size * direction * (1 / entry - 1 / close_price);
    //
  }
  let difference: number;
  if (kind === 'long') {
    difference = close_price - entry;
  } else {
    difference = entry - close_price;
  }
  let q = quantity;

  let result = difference * q;
  // if (contract_size) {
  //   result = result * entry;
  // }
  return result;
}

export function calculate_liquidation(
  balance: number,
  long: Position,
  short: Position,
  additional?: LongShort,
  pnl?: LongShort,
  both?: Position,
  maximum_leverage = 125,
  contract_size?: number,
  symbol?: string,
) {
  console.log({
    balance,
    long,
    short,
  });
  // //console.log({maximum_leverage})
  // let result = determine_liquidation(balance, long, short, both, additional);
  let result: any = determine_liquidation(
    balance,
    long,
    short,
    both,
    undefined,
    contract_size,
    maximum_leverage,
    symbol,
  );
  if (pnl) {
    let _long = pnl.long;
    let _short = pnl.short;
    let result_long = result.long;
    let result_short = result.short;
    let profit = 0;
    if (_long) {
      let l_profit =
        determine_pnl(
          result.long.entry,
          _long.entry,
          _long.size,
          'long',
          contract_size,
        ) || 0;
      profit += l_profit;
      result_long = { ...result.long, size: result.long.size - _long.size };
    }
    if (_short) {
      let s_profit =
        determine_pnl(
          result.short.entry,
          _short.entry,
          _short.size,
          'short',
          contract_size,
        ) || 0;
      profit += s_profit;
      result_short = { ...result.short, size: result.short.size - _short.size };
    }
    if (profit > 0) {
      // if (contract_size) {
      //   let e = result_long.entry || result_short.entry;
      //   profit = profit / e;
      // }
      let new_liquidation = determine_liquidation(
        balance + profit,
        result_long,
        result_short,
        undefined,
        undefined,
        contract_size,
        maximum_leverage,
        symbol,
      );

      result = { ...new_liquidation, pnl: profit, balance: balance + profit };
    }
  }
  if (additional) {
    result = determine_liquidation(
      result.balance || balance,
      result.long,
      result.short,
      both,
      additional,
      contract_size,
      maximum_leverage,
      symbol,
    );
  }
  if (!result.liquidation) {
  }
  return result;
}

export function calculate_maximum_size(
  balance: number,
  long: Position,
  short: Position,
  current_price: number,
  leverage = 125,
  price_places = '%.2f',
  decimal_places = '%.3f',
  maximum_leverage = 125,
  ...rest: any
) {
  let p_places = parseInt(
    price_places.replace('%.', '').replace('f', '').trim(),
  );
  let d_places = parseInt(
    decimal_places.replace('%.', '').replace('f', '').trim(),
  );
  let long_pnl = determine_pnl(long.entry, current_price, long.size, 'long');
  let short_pnl = determine_pnl(
    short.entry,
    current_price,
    short.size,
    'short',
  );
  let pnl = long_pnl + short_pnl;
  let remaining = balance - pnl;
  let r_nominal = remaining * leverage;
  let left = r_nominal - long.entry * long.size - short.entry * short.size;
  let result = left / leverage;
  let liquidation = calculate_liquidation(balance, long, short);
  let m_long = get_maintenance_margin(
    calculate_position_size(current_price, long.size),
    maximum_leverage,
    ...rest,
  ).m_t;
  let m_short = get_maintenance_margin(
    calculate_position_size(current_price, short.size),
    maximum_leverage,
    ...rest,
  ).m_t;
  return {
    balance: result.toFixed(p_places),
    quantity: (result / current_price).toFixed(d_places),
    liquidation: liquidation.liquidation.toFixed(p_places),
    maintenance_margin: (m_long + m_short).toFixed(p_places),
    pnl: pnl.toFixed(p_places),
  };
}

function get_ideal_size(
  target_liquidation: number,
  wallet_balance: number,
  long: Position,
  short: Position,
  kind = 'short',
  places = 3,
  maximum_leverage = 125,
  ...rest: any
) {
  let short_entry = short.entry;
  let long_entry = long.entry;
  let m_long: any;
  if (kind === 'short') {
    m_long = get_maintenance_margin(
      calculate_position_size(long.entry, long.size),
      maximum_leverage,
      ...rest,
    );
  } else {
    m_long = get_maintenance_margin(
      calculate_position_size(short.entry, short.size),
      maximum_leverage,
      ...rest,
    );
  }
  let rate = m_long.m_rate / 100;
  let result: any;
  if (kind === 'short') {
    result =
      (wallet_balance / target_liquidation -
        (long.entry * long.size) / target_liquidation -
        long.size * (rate - 1)) /
      (rate + 1 - short_entry / target_liquidation);
  } else {
    result =
      ((short.entry * short.size) / target_liquidation +
        wallet_balance / target_liquidation -
        short.size * (rate + 1)) /
      (long_entry / target_liquidation + (rate - 1));
  }
  return parseFloat(result.toFixed(places));
}

function get_ideal_entry(
  target_liquidation: number,
  wallet_balance: number,
  long: Position,
  short: Position,
  kind = 'short',
  places = 2,
  maximum_leverage = 125,
  ...rest: any
) {
  let short_size = short.size;
  let long_size = long.size;
  let m_long: any;
  if (kind === 'short') {
    m_long = get_maintenance_margin(
      calculate_position_size(long.entry, long.size),
      maximum_leverage,
      ...rest,
    );
  } else {
    m_long = get_maintenance_margin(
      calculate_position_size(short.entry, short.size),
      maximum_leverage,
      ...rest,
    );
  }
  let rate = m_long.m_rate / 100;
  let result: any;
  if (kind === 'short') {
    result =
      (target_liquidation / short_size) *
      (short_size * (rate + 1) +
        long.size * (long.entry / target_liquidation + (rate - 1)) -
        wallet_balance / target_liquidation);
  } else {
    result =
      target_liquidation *
      (((short.entry * short.size) / target_liquidation +
        wallet_balance / target_liquidation -
        short.size * (rate + 1)) /
        long_size -
        (rate - 1));
  }
  return parseFloat(result.toFixed(places));
}

export function get_ideal_entry_and_size(
  target_liquidation: number,
  wallet_balance: number,
  long: Position,
  short: Position,
  kind = 'short',
  price_places = 2,
  decimal_places = 3,
  maximum_leverage = 125,
  ...rest: any
): { entry: number; size: number; additional?: { long?: any; short?: any } } {
  let size = get_ideal_size(
    target_liquidation,
    wallet_balance,
    long,
    short,
    kind,
    decimal_places,
    maximum_leverage,
    ...rest,
  );
  let _long = { ...long };
  let _short = { ...short };
  if (kind === 'short') {
    _short.size = size;
  } else {
    _long.size = size;
  }
  let entry = get_ideal_entry(
    target_liquidation,
    wallet_balance,
    _long,
    _short,
    kind,
    price_places,
    maximum_leverage,
    ...rest,
  );
  let buy_point: any;
  let sell_point: any;
  if (kind === 'long' && size > long.size) {
    buy_point = determine_new_entry(
      { entry, size },
      long,
      price_places,
      decimal_places,
    );
  }
  if (kind === 'short' && size > short.size) {
    sell_point = determine_new_entry(
      { entry, size },
      short,
      price_places,
      decimal_places,
    );
  }
  let additional: any = {};
  if (buy_point) {
    let ideal = null;
    if (buy_point.entry > long.entry) {
      let diff = Math.abs(buy_point.entry - long.entry);
      ideal = { entry: long.entry - diff, size: buy_point.size };
    }
    additional.long = { raw: buy_point, ideal };
  }
  if (sell_point) {
    let ideal = null;
    if (sell_point.entry < short.entry) {
      let diff = Math.abs(sell_point.entry - short.entry);
      ideal = { entry: short.entry + diff, size: sell_point.size };
    }
    additional.short = { raw: sell_point, ideal };
  }
  let result: any = { entry, size };
  if (additional.long || additional.short) {
    result.additional = additional;
  }
  return result;
}

function determine_new_entry(
  ideal_position: Position,
  previous_position: Position,
  price_places = 2,
  decimal_places = 3,
): Position {
  let new_size = ideal_position.size - previous_position.size;
  let result =
    (ideal_position.entry * ideal_position.size -
      previous_position.entry * previous_position.size) /
    new_size;
  return {
    entry: parseFloat(result.toFixed(price_places)),
    size: parseFloat(new_size.toFixed(decimal_places)),
  };
}

export function determine_close_price(
  entry: number,
  pnl: number,
  quantity: number,
  kind = 'long',
  contract_size?: number,
) {
  if (contract_size) {
    let direction = kind === 'long' ? 1 : -1;
    return (
      1 / (-1 * (pnl / (quantity * contract_size * direction) - 1 / entry))
    );
  }
  let q = quantity;
  // if (contract_size) {
  //   q = (quantity * contract_size) / entry;
  // }
  let dollar_value = entry;
  let position = dollar_value * q;
  let percent = pnl / position;
  let difference = (position * percent) / q;
  let result: number;
  if (kind === 'long') {
    result = difference + entry;
  } else {
    result = entry - difference;
  }
  return result;
}

export function determine_position_size(
  entry: number,
  stop: number,
  budget: number,
) {
  let stop_percent = Math.abs(entry - stop) / entry;
  let size = budget / stop_percent / entry;
  return size;
}
