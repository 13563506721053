import { Box, Heading, HStack } from '@chakra-ui/react';

export type IncreasePostionFormFieldProps = {
  actions: {
    label: string;
    renderItem: React.ReactNode;
  }[];
};

export const IncreasePostionFormField = ({
  actions,
}: IncreasePostionFormFieldProps) => {
  return (
    <>
      {actions.map((action, i) => (
        <HStack key={i} justifyContent="space-between">
          <Heading size="sm" my="6" textAlign="center">
            {action.label} :
          </Heading>
          <Box>{action.renderItem}</Box>
        </HStack>
      ))}
    </>
  );
};
