import { Button, HStack, Input, Stack, Text } from "@chakra-ui/react";

type FooterProps = {
    onPlaceTrade: () => void;
    onCancelTrade: () => void;
    onUpdateProfit: () => void;
  };
  export const Footer = ({
    onPlaceTrade,
    onCancelTrade,
    onUpdateProfit,
  }: FooterProps) => {
    return (
      <>
        <Text mr="70px" textAlign={'center'}>
          Take Profit :
        </Text>
        <HStack justifyContent="center">
          <Input
            borderColor={'black'}
            borderRadius={'md'}
            size="sm"
            width={['40%', '25%', '25%']}
          />
  
          <Button size="sm"> Enter </Button>
        </HStack>
        <Stack
          direction={['column', 'row']}
          spacing="10px"
          py={10}
          justifyContent="center"
          gap={5}
          mx={5}
        >
          <Button colorScheme="green" size="lg" onClick={onPlaceTrade}>
            Place Signal Trade
          </Button>
          <Button colorScheme="red" size="lg" onClick={onCancelTrade}>
            Cancel Signal Trade
          </Button>
          <Button colorScheme="purple" size="lg" onClick={onUpdateProfit}>
            Update Take Profit
          </Button>
        </Stack>
      </>
    );
  };