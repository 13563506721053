import * as React from 'react';
// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

const AppThemeProvider: React.FC<{}> = ({ children }) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default AppThemeProvider;
