import {
  applySnapshot,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import type { BotAdapterType } from '../adapter';
import { getParamForField } from './utils';
const configs = [
  {
    name: 'name',
    label: 'Profile name',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'kind',
    label: 'Trade direction (long,short)',
    kind: 'checkbox',
    options: ['long', 'short'],
  },
  {
    name: 'delete_existing',
    label: 'Delete existing trades',
    kind: 'checkbox',
  },
  {
    name: 'liquidation_close',
    label: 'Close all order on liquidation',
    kind: 'checkbox',
  },
  {
    name: 'one_way_trade',
    label: 'Use new config',
    kind: 'checkbox',
  },
  {
    name: 'use_entry',
    label: 'Use entry or last_order as focal_point',
    kind: 'checkbox',
  },
  {
    name: 'pause_jobs',
    label: 'Pause Jobs',
    kind: 'checkbox',
  },
  {
    name: 'one_off',
    label: 'Stop trade after take profit',
    kind: 'checkbox',
  },
  {
    name: 'old_mode',
    label: 'Use old mode',
    kind: 'checkbox',
  },
  {
    name: 'new_future',
    label: 'New future mode',
    kind: 'checkbox',
  },
  {
    name: 'margin_type',
    label: 'Margin Type',
    kind: 'radio',
    options: ['isolated', 'cross'],
  },
  { name: 'max_size', label: 'Maximum size', kind: 'special' },
  { name: 'min_size', label: 'Minimum size', kind: 'special' },
  { name: 'reduce_size', label: 'Reduce size', kind: 'special' },
  { name: 'spread', label: 'Spread', kind: 'special' },
  { name: 'budget', label: 'Budget', kind: 'special' },
  { name: 'budget_factor', label: 'Budget factor', kind: 'special' },
  { name: 'trade_factor', label: 'Trade factor', kind: 'special' },
  { name: 'min_profit', label: 'Min profit per trade', kind: 'special' },
  { name: 'loss', label: 'Amount to loose', kind: 'special' },
  {
    name: 'opposite_entry',
    label: 'Loss to trigger opposite entry',
    kind: 'special',
  },
  { name: 'liquidation_spread', label: 'liquidation spread', kind: 'special' },
];
const config_fields = {
  config_1: [
    'name',
    'budget',
    'kind',
    'margin_type',
    'delete_existing',
    'use_entry',
    'one_off',
    'max_size',
    'min_size',
    'reduce_size',
    'spread',
    'budget_factor',
    'trade_factor',
    'min_profit',
    'pause_jobs',
    'liquidation_close',
    'liquidation_spread',
    'loss',
    'opposite_entry',
    'old_mode',
    'new_future'
  ],
};
export const ProfileStore = types
  .model('ProfileStore', {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, 'Default profile'),
    kind: types.optional(types.array(types.string), ['long']),
    margin_type: types.optional(
      types.enumeration('MarginType', ['cross', 'isolated']),
      'cross',
    ),
    max_size: types.optional(types.number, 0),
    min_size: types.optional(types.number, 0),
    reduce_size: types.optional(types.number, 0),
    spread: types.optional(types.number, 0),
    budget: types.optional(types.number, 0),
    budget_factor: types.optional(types.number, 1),
    trade_factor: types.optional(types.number, 1),
    delete_existing: types.optional(types.boolean, true),
    use_entry: types.optional(types.boolean, false),
    one_off: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
    min_profit: types.maybeNull(types.number),
    loss: types.maybeNull(types.number),
    opposite_entry: types.maybeNull(types.number),
    liquidation_spread: types.maybeNull(types.number),
    pause_jobs: types.optional(types.boolean, false),
    liquidation_close: types.optional(types.boolean, true),
    old_mode: types.optional(types.boolean, false),
    new_future: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get data() {
        return getSnapshot(self);
      },
      get config_fields() {
        return config_fields;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
      },
      get tradeKind() {
        return getSnapshot(self.kind);
      },
      get oldModeTriggered() {
        let result: any = getSnapshot(self);
        let toRemove = ['loss', 'min_profit', 'opposite_entry'];
        let toReturn: any = {};
        if (self.old_mode) {
          Object.keys(result).forEach((x) => {
            if (!toRemove.includes(x)) {
              toReturn[x] = result[x];
            }
          });
        } else {
          toReturn = { ...result };
        }
        return toReturn;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);

    const runProfile = flow(function* runJobInstance(
      symbol,
      owner,
      resume = false,
    ) {
      const parent: any = getParent(self, 2);
      const appInstance: any = parent.appInstance;
      try {
        self.loading = true;
        yield adapter.runProfile({
          owner,
          symbol,
          profile_id: self.id,
          start: resume ? 'true' : '',
          old_mode: self.old_mode ? 'true' : '',
          new_future: self.new_future ? 'true' : '',
        });
        self.loading = false;
        yield appInstance.getBotAccounts();
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const checkForOldMode = () => {};
    return {
      runProfile,
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
      clear() {
        applySnapshot(self, {});
      },
    };
  });

export interface IProfileConfig extends Instance<typeof ProfileStore> {}
