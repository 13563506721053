import {
  Box,
  Badge,
  Circle,
  Flex,
  Heading,
  HStack,
  Progress,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi';
import Icon from '../../components/main/Icon';

interface IndicatorProps {
  type: 'up' | 'down';
}

const types = {
  up: { icon: FiArrowUpRight, color: 'green.500' },
  down: { icon: FiArrowDownLeft, color: 'red.500' },
};

const SymbolBadgeEnum: Record<string, string> = {
  long: 'green',
  short: 'red',
};

export const Indicator = (props: IndicatorProps) => {
  const { type } = props;
  const { color, icon } = types[type];
  return <Box as={icon} fontSize="lg" color={color} />;
};

export interface StatCardProps {
  data: {
    id?: string;
    symbol: string;
    label: string;
    currency: string;
    value: number;
    change: {
      value: number;
      percent: number;
    };
    swap?: boolean;
    info?: any;
    formattedData?: {
      limit: any;
      value: any;
    };
  };
}

function format(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
  }).format(value);
}

export const StatIconCard = (props: StatCardProps) => {
  console.log(JSON.stringify(props))
  const { data } = props;
  const {
    label,
    currency,
    value,
    change,
    symbol,
    swap = false,
    info,
    formattedData,
  } = data;

  const isNegative = change.percent < 0;
  let ss = swap ? label : symbol;
  let oo = swap ? symbol : label;
  const _value = formattedData?.value || 0;
  const _limit = formattedData?.limit || 0;

  return (
    <Box
      bg={mode('white', 'gray.700')}
      shadow="base"
      rounded="lg"
      overflow="hidden"
    >
      <Box px="6" py="4">
        <Flex
          justify="space-between"
          align="center"
          fontWeight="medium"
          fontSize="sm"
        >
          <HStack>
            <Circle color="white" rounded="full" size="6">
              <Icon name={ss.toLowerCase()} />
            </Circle>
            <Text fontWeight="medium" color={mode('gray.500', 'gray.400')}>
              {label}
            </Text>
          </HStack>
          {info ? <Text color="gray.400">{info}</Text> : null}
        </Flex>

        <Heading as="h4" size="lg" my="3" fontWeight="extrabold">
          {currency}
          {format(value)}
        </Heading>
        <Flex
          justify="space-between"
          align="center"
          fontWeight="medium"
          fontSize="sm"
        >
          <HStack spacing="0" color={mode('gray.500', 'gray.400')}>
            <Indicator type={isNegative ? 'down' : 'up'} />
            <Text>
              {currency}
              {format(change.value)} ({isNegative ? '' : '+'}
              {change.percent}%)
            </Text>
          </HStack>
          <HStack spacing="0" color={mode('gray.500', 'gray.400')}>
            <Indicator type={isNegative ? 'down' : 'up'} />
            <Text>
              {currency}
              {format(change.value)} ({isNegative ? '' : '+'}
              {change.percent}%)
            </Text>
          </HStack>
          <Badge colorScheme={SymbolBadgeEnum[symbol.toLowerCase()]}>
            {oo}
          </Badge>
        </Flex>
      </Box>
      {_value && _limit ? (
        <Progress
          aria-labelledby={props.data.id}
          value={_value}
          max={_limit}
          min={0}
          size="xs"
          colorScheme="blue"
        />
      ) : null}
    </Box>
  );
};
