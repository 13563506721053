import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import * as shared from '../../store/shared';
import { withSetPropAction } from './helpers';
import { fibonacci_analysis } from '../../store/utils';

type SimpleTradeSection = {
  entry: number;
  stop: number;
  size: number;
  risk_reward: number;
  risk_per_trade: number;
};

const defaultGlobalConfig = {
  price_places: '%.1f',
  decimal_places: '%.3f',
  percent_change: 0.04,
  tradeSplit: 10,
  budget: 5,
  increase_position: true,
  min_size: 0.003,
  minimum_size: 0.003,
  strategy: 'quantity' as any,
  currentQty: 0,
  fee: 0,
};

export const TradeSectionStore = types
  .model('TradeSectionStore', {
    id: types.identifier,
    kind: types.enumeration('kind', ['long', 'short']),
    config: types.frozen<SimpleTradeSection>(),
    take_profit: types.optional(types.number, 0),
  })
  .views(withSetPropAction)
  .views((self) => {
    let take_profit =
      self.kind == 'long'
        ? Math.max(self.config.entry, self.config.stop)
        : Math.min(self.config.entry, self.config.stop);
    return {
      get display() {
        const min = Math.min(self.config.entry, self.config.stop);
        const max = Math.max(self.config.entry, self.config.stop);
        return {
          id: self.id,
          support: min,
          resistance: max,
          entry: self.config.entry,
          stop: self.config.stop,
          kind: self.kind,
        };
      },
      buildConfig(props: {
        kind?: 'long' | 'short';
        raw_instance?: boolean;
        globalConfig?: {
          price_places?: string;
          decimal_places?: string;
          percent_change?: number;
          tradeSplit?: number;
          budget?: number;
          increase_position?: boolean;
          min_size?: number;
          minimum_size?: number;
          strategy?: string;
          currentQty?: number;
          fee?: number;
        };
      }) {
        const {
          kind: _kind,
          raw_instance,
          globalConfig: _globalConfig = {},
        } = props;
        const globalConfig = {
          price_places:
            _globalConfig.price_places || defaultGlobalConfig.price_places,
          decimal_places:
            _globalConfig.decimal_places || defaultGlobalConfig.decimal_places,
          percent_change:
            _globalConfig.percent_change || defaultGlobalConfig.percent_change,
          tradeSplit:
            _globalConfig.tradeSplit || defaultGlobalConfig.tradeSplit,
          budget: _globalConfig.budget || defaultGlobalConfig.budget,
          increase_position:
            _globalConfig.increase_position ||
            defaultGlobalConfig.increase_position,
          min_size: _globalConfig.min_size || defaultGlobalConfig.min_size,
          minimum_size:
            _globalConfig.minimum_size || defaultGlobalConfig.minimum_size,
          strategy: _globalConfig.strategy || defaultGlobalConfig.strategy,

          currentQty:
            _globalConfig.currentQty || defaultGlobalConfig.currentQty,
          fee: _globalConfig.fee || defaultGlobalConfig.fee,
        };
        const kind = _kind || self.kind;
        let support = Math.min(self.config.entry, self.config.stop);
        let resistance = Math.max(self.config.entry, self.config.stop);
        let entry = self.config.entry;
        let stop = self.config.stop;
        let take_profit = Math.max(self.config.entry, self.config.stop);
        if (kind == 'short') {
          entry = Math.min(self.config.entry, self.config.stop);
          stop = Math.max(self.config.entry, self.config.stop);
          take_profit = Math.min(self.config.entry, self.config.stop);
        }
        const config = {
          ...globalConfig,
          kind: kind as any,
          focus: self.config.entry,
          risk_reward: self.config.risk_reward,
          support,
          resistance,
          risk_per_trade: self.config.risk_per_trade,
          take_profit: self.take_profit || take_profit,
          entry,
          stop,
        };
        const trades = shared.buildConfig(config, {
          stop,
          entry,
          risk: config.risk_per_trade,
          raw_instance,
          increase: config.increase_position,
        } as shared.ExtendConfigType);
        return shared.computeTotalAverageForEachTrade(trades, {
          ...config,
          kind,
          take_profit,
          currentEntry: entry,
        });
        // return trades;
      },
      get trades() {
        return this.buildConfig({});
      },
      getTradeZones(params?: {
        kind?: 'long' | 'short';
        trend?: 'long' | 'short';
      }) {
        const { kind = self.kind, trend = 'long' } = params || {};
        let support = Math.min(self.config.entry, self.config.stop);
        let resistance = Math.max(self.config.entry, self.config.stop);
        let r = fibonacci_analysis({
          support,
          resistance,
          kind: kind,
          trend: trend,
        });
        return r
          .map((o, i) => {
            if (kind === 'long') {
              return {
                stop: o,
                entry: r[i + 1],
              };
            }
            return {
              entry: o,
              stop: r[i - 1],
            };
          })
          .filter((o) => o.entry && o.stop);
      },
    };
  })
  .actions((self) => {
    return {
      updateConfig(config: {
        support?: number;
        resistance?: number;
        risk?: number;
        kind?: 'long' | 'short';
        entry?: number;
        stop?: number;
      }) {
        let _config = { ...self.config };
        _config.entry =
          config.entry ||
          (self.kind === 'long' ? config.resistance : config.support) ||
          self.config.entry;
        _config.stop =
          config.stop ||
          (self.kind === 'long' ? config.support : config.resistance) ||
          self.config.stop;
        _config.risk_per_trade = config.risk || self.config.risk_per_trade;
        self.setProp('config', _config);
        self.setProp('kind', config.kind || self.kind);
      },
    };
  });

export interface TradeSection extends Instance<typeof TradeSectionStore> {}
export interface TradeSectionSnapshotOut
  extends SnapshotOut<typeof TradeSectionStore> {}
export interface TradeSectionSnapshotIn
  extends SnapshotIn<typeof TradeSectionStore> {}
