import { ComponentStyleConfig } from '@chakra-ui/theme';

export const MobileMebuButton: ComponentStyleConfig = {
  baseStyle: () => {
    return {
      _active: 'blue',

      // 'chakra-menu__menuitem ': {
      //   backgroundColor:'blue',
      // },
    };
  },
  defaultProps: {
    size: 'lg',
  },
};
