import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/react';

export const TradingCrumb = ({
  config,
  priceArray,
  back,

}) => {
  console.log('backButton', priceArray);
  const firstTrade = priceArray.at(-2);
  const lastTrade = priceArray.at(-1);

  return (
    <>
      <Breadcrumb m={4} fontWeight="bold">
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => config()}>Trade Config</BreadcrumbLink>
        </BreadcrumbItem>

        {priceArray.length > 1 ? (
          <BreadcrumbItem>
            <BreadcrumbLink
              isCurrentPage
              onClick={() => {
                back(firstTrade.price, firstTrade.kind);
              }}
            >{`${firstTrade.price} - ${firstTrade.kind}`}</BreadcrumbLink>
          </BreadcrumbItem>
        ) : null}
        <BreadcrumbItem>
          <BreadcrumbLink
            
          >{`${lastTrade.price} - ${lastTrade.kind}`}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </>
  );
};
