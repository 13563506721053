//@ts-nocheck
import {
  Box,
  Button,
  Flex,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import TightFutureTraderAnalyzer from '../newBot/app-components/TightFutureTraderAnalyzer';
import { TradingView } from '../newBot/app-components/tradingView/tradingView';
import { TightTradeAnalyzer } from '../newBot/app-components/tightTradeAnalyzer/TightTradeAnalyzer';
import type { IAccount, IPositionStore } from '../store/botConfig';

export const TraderOrderPage: React.FC<{
  account: IAccount;
  accounts?: string[];
  positionStore?: IPositionStore;
}> = observer(({ account, accounts = [], positionStore }) => {
  const store = account.futureCTrader;
  return (
    <Box bg="white" p="2">
      <Tabs defaultIndex={0}>
        <TabList bg="white">
          <Tab>Config</Tab>
          <Tab>Trade Analyzer</Tab>
          <Tab>Tight Trade Analyzer</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <TightFutureTraderAnalyzer
              account={account}
              accounts={accounts}
              store={store}
              positionStore={positionStore}
            />
          </TabPanel>

          <TabPanel>
            <TradingView store={store} />
          </TabPanel>
          <TabPanel>
            <TightTradeAnalyzer store={store} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
});
type NavigationMenuProps = {
  onBack: () => void;
  onEdit: () => void;
};
export const NavigationMenu = ({ onBack, onEdit }: NavigationMenuProps) => {
  return (
    <Flex p="2" alignItems="center">
      <Flex alignItems="center" cursor="pointer" onClick={onBack}>
        <HiArrowLeft />
        <Text ml="2">Back</Text>
      </Flex>
      <Spacer />
      <Button onClick={onEdit}>Edit</Button>
    </Flex>
  );
};
