import React, { useState, useEffect, FunctionComponent, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Spinner,
  Stack,
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
  toast,
  useDisclosure,
  useNumberInput,
  useRadio,
  useRadioGroup,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Store, { IStore } from '../store';
import { loadAdapter, setAdapter } from '../adapter';
import { getSnapshot } from 'mobx-state-tree';
import MarketsSidebar from './components/Sidebar';
import {
  InputElement,
  RadioElement,
  SelectElement,
} from './components/FormElement';
import { AddNewMarket } from './components/GenericConfigDisplay';

interface AppProps {}

const Incrementer: FunctionComponent<{
  value: number;
  maximum?: number;
  onChange: (pip: number) => void;
  allowNegative?: boolean;
  editable?: boolean;
  incrementer?: number;
}> = ({
  value = 1,
  onChange,
  allowNegative = false,
  editable = false,
  maximum,
  incrementer = 1,
}) => {
  const [defaultValue, setDefault] = useState(value);
  useEffect(() => {
    setDefault(value);
  }, [value]);
  function increment() {
    const newValue = defaultValue + incrementer;
    if (maximum) {
      if (newValue <= maximum) {
        logic('+');
      }
    } else {
      logic('+');
    }
  }
  const logic = (action: '-' | '+') => {
    const newValue =
      action === '-' ? defaultValue - incrementer : defaultValue + incrementer;
    setDefault(newValue);
    onChange(newValue);
  };
  function decrement() {
    if (allowNegative) {
      logic('-');
    } else {
      if (defaultValue > 0) {
        logic('-');
      }
    }
  }
  return (
    <Flex>
      <Button
        isDisabled={defaultValue === 0 && !allowNegative}
        onClick={decrement}
      >
        -
      </Button>
      <Box mx={5} />
      {editable ? (
        <Editable
          onChange={(e) => {
            let value = parseInt(e);
            setDefault(value);
            onChange(value);
          }}
          value={defaultValue.toString()}
          fontSize="1.5em"
        >
          <EditablePreview />
          <EditableInput />
        </Editable>
      ) : (
        <Text fontSize="1.5em">{defaultValue}</Text>
      )}

      <Box mx={5} />
      <Button
        isDisabled={Boolean(maximum && defaultValue >= maximum)}
        onClick={increment}
      >
        +
      </Button>
    </Flex>
  );
};
const EnhancedFormControl: FunctionComponent<{
  description?: string;
  [key: string]: any;
}> = ({ children, description, ...rest }) => {
  return (
    <FormControl>
      <Flex
        display="flex"
        justifyContent={['space-between', 'start']}
        {...rest}
      >
        {children}
      </Flex>
      {description && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
};
function RadioCard(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as="label" {...props.labelProps}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: 'teal.600',
          color: 'white',
          borderColor: 'teal.600',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
        fontSize={'0.75em'}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
}
const TradeKindSelector: FunctionComponent<{
  defaultValue: any;
  onChange: any;
  options: Array<string>;
}> = ({ defaultValue, onChange, options }) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'trade-selector',
    value: defaultValue,
    onChange,
  });
  const group = getRootProps();
  return (
    <HStack {...group}>
      {options.map((value) => {
        let p: any = { value };
        const radio = getRadioProps(p);
        return (
          <RadioCard
            labelProps={{ flex: 1, textAlign: 'center' }}
            key={value}
            {...radio}
          >
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
const RiskAllocator2: FunctionComponent<{
  description?: string;
  onChange?: (e: any) => any;
  defaultValue?: any;
  positionSize?: any;
  name?: string;
  labelText?: string;
  options: Array<any>;
  valueDisplay: (x: any) => any;
  valueKey?: (x: any) => any;
  wrapStyle?: any;
  render?: () => any;
  flex?: 'row' | 'column';
}> = ({
  description = '',
  positionSize,
  onChange,
  defaultValue,
  name = 'risk-allocator',
  labelText = 'Trading %',
  options,
  valueDisplay,
  valueKey = (x) => x,
  wrapStyle = {},
  render,
  flex = 'column',
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value: defaultValue,
    onChange,
  });

  const group = getRootProps();
  return (
    <EnhancedFormControl flexDirection={flex} description={description}>
      <Flex justifyContent="space-between">
        <FormLabel fontSize="1.5em">{labelText}</FormLabel>
        <Text>{positionSize}</Text>
      </Flex>
      <Spacer spacing={2} />
      <Flex>
        <Wrap {...wrapStyle} {...group}>
          {options.map((value) => {
            let p: any = { value: valueKey(value) };
            const radio = getRadioProps(p);
            return (
              <WrapItem>
                <RadioCard key={value} {...radio}>
                  {valueDisplay(value)}
                </RadioCard>
              </WrapItem>
            );
          })}
        </Wrap>
        {render && render()}
      </Flex>
      {/* <Select size="lg" onChange={onChange} defaultValue={defaultValue}>
        {tradingPercent.map((val) => (
          <option value={val}>{val} %</option>
        ))}
      </Select> */}
    </EnhancedFormControl>
  );
};
const SingleStopLimitComponent: FunctionComponent<{
  mode: string;
  store: IStore;
  toast: any;
}> = observer(({ mode, store, toast }) => {
  let keyValue: 'stop' | 'profit' = mode == 'Stop Loss' ? 'stop' : 'profit';
  const [loading, setLoading] = useState(false);
  function getDefault(v: any) {
    return v || 1;
  }
  useEffect(() => {
    setStopPip(store.openTrade?.stopPipUsed(store.market.pip));
    setTakeProfitPip(store.openTrade?.takeProfitPipUsed(store.market.pip));
  }, []);

  const [stopPip, setStopPip] = useState(
    getDefault(store.openTrade?.stopPipUsed(store.market.pip)),
  );
  const [takeProfitPip, setTakeProfitPip] = useState(
    getDefault(store.openTrade?.takeProfitPipUsed(store.market.pip)),
  );
  function getStopValue(kind: 'profit' | 'stop') {
    if (store.openTrade) {
      let x = kind === 'profit' ? takeProfitPip : stopPip;
      return store.openTrade.getStopValue(x, store.market, kind);
    }
    return undefined;
  }
  function stopDescription(kind: 'profit' | 'stop') {
    if (store.openTrade) {
      let x = kind === 'profit' ? takeProfitPip : stopPip;
      let value = store.openTrade.getStopValue(x, store.market, kind);
      // let value = getStopValue(kind) || 0;
      let feesClose = store.openTrade.idealClose(value, store.market.places);
      //console.log('places', store.market.places);
      return kind === 'profit'
        ? `The take profit value is ${value.toFixed(
            store.market.places,
          )} and pnl is ${store.openTrade.determinePnl(
            value,
          )}. Close with fees: ${feesClose} with pnl ${store.openTrade.determinePnl(
            parseFloat(feesClose),
          )}`
        : `The current value of the stop is ${value.toFixed(
            store.market.places,
          )} and pnl is ${store.openTrade.determinePnl(
            value,
          )}. Close with fees: ${feesClose} with pnl ${store.openTrade.determinePnl(
            parseFloat(feesClose),
          )}`;
    }
    return '';
  }
  let config = {
    stop: {
      increment: {
        allowNegative: true,
        editable: true,
        // maximum: store.openTrade
        //   ? store.openTrade.stopPipUsed(store.market.pip)
        //   : 1,
        value: stopPip,
        onChange: (pip: any) => {
          setStopPip(pip);
        },
      },
      toast: {
        success: {
          title: 'Stop loss updated',
          description: `The stop loss has been updated to ${store.openTrade?.stop}`,
        },
        error: {
          title: 'Error creating stop loss',
          description: 'Unable to create stop loss',
        },
      },
    },
    profit: {
      increment: {
        value: takeProfitPip,
        editable: true,
        onChange: (pip: any) => {
          setTakeProfitPip(pip);
        },
        incrementer: store.market.increment,
      },
      toast: {
        success: {
          title: 'Take Profit updated',
          description: `The take profit has been updated to ${store.openTrade?.takeProfit}`,
        },
        error: {
          title: 'Error creating take profit',
          description: 'Unable to create take profit',
        },
      },
    },
  };
  const tk = store.openTrade?.takeProfitPnl(
    keyValue,
    store.market,
    takeProfitPip,
  );
  return (
    <>
      {keyValue !== 'stop' && (
        <>
          <RiskAllocator2
            wrapStyle={{}}
            options={[25, 50, 75, 100]}
            name="profit-allocator"
            labelText="Size %"
            valueDisplay={(x: any) => `${x}%`}
            description={`The pnl is ${
              tk?.pnl
            } and size to be sold is ${store.openTrade?.sizeToSell.toFixed(
              4,
            )} and close price is ${tk?.close}`}
            onChange={(val: any) => {
              store.openTrade?.setSellPercent(parseFloat(val));
              // store.trader.setRiskPercent(parseFloat(val));
            }}
            positionSize={``}
            defaultValue={store.openTrade?.sellPercent}
          />
          <Box my={'1.5rem'} />
        </>
      )}
      <MobileFlex>
        <EnhancedFormControl description={stopDescription(keyValue)}>
          <Text fontSize="1.5em">Pips Used</Text>
          <Spacer spacing={2} />
          <Incrementer {...config[keyValue].increment} />
        </EnhancedFormControl>
        <Spacer spacing={2} />

        <Button
          onClick={() => {
            let item = keyValue === 'stop' ? stopPip : takeProfitPip;
            setLoading(true);
            let func =
              keyValue == 'stop'
                ? store.updateStopLoss
                : store.updateTakeProfit;
            let price = getStopValue(keyValue) || 0;
            let withFees = store.openTrade?.idealClose(price) || '0';
            func(item, parseFloat(withFees))
              .then(() => {
                setLoading(false);
                toast({
                  ...config[keyValue].toast.success,
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              })
              .catch((error) => {
                setLoading(false);
                toast({
                  ...config[keyValue].toast.error,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              });
          }}
          isLoading={loading}
          loadingText={`Update ${mode}`}
          size="lg"
          width={['100%', '50%']}
          alignSelf="end"
          colorScheme={keyValue == 'stop' ? 'red' : 'green'}
        >
          Update {mode}
        </Button>
      </MobileFlex>
    </>
  );
});
const EntryTradeComponent: FunctionComponent<{
  store: IStore;
  loading: boolean;
  onSubmit: (x?: any) => any;
  buttonText?: string;
  inputStyle?: any;
  buttonStyle?: any;
  loadingText?: string;
  labelText?: string | null;
  options?: any;
  description?: string;
  displayRisk?: any;
}> = observer(
  ({
    store,
    loading,
    onSubmit,
    buttonText,
    labelText = 'Entry Price',
    loadingText = 'Increasing position',
    inputStyle = { width: ['100%', '80%'] },
    buttonStyle = { width: ['100%', '80%'] },
    options = [],
    description = '',
    displayRisk = null,
    children,
  }) => {
    return (
      <>
        <Flex direction="column" width="100%" justifyContent="space-around">
          <Flex direction="row">
            {labelText && (
              <InputElement
                controlProps={{ margin: '0 auto', ...inputStyle }}
                label={labelText}
                type="number"
                mb={2}
                defaultValue={store.market.currentPrice}
                onChange={(e: any) => {
                  store.setEntryPrice(parseFloat(e.target.value));
                }}
              />
              /* {options.length > 0 ? (
                <Select
                  onChange={(e) => {
                    store.setEntryPrice(parseFloat(e.target.value));
                  }}
                  mb={2}
                  defaultValue={store.entryPrice}
                >
                  <option>Select Entry</option>
                  {options.map((o: any) => (
                    <option key={o.label} value={o.value}>
                      {o.label}
                      </option>
                  ))}
                </Select>
              ) : ( */
            )}
            {displayRisk}
          </Flex>
          {children}
          {description && (
            <FormControl>
              <FormHelperText mb={4}>{description}</FormHelperText>
            </FormControl>
          )}
          <Button
            size="lg"
            variant="outline"
            margin="0 auto"
            colorScheme="green"
            alignSelf="center"
            isLoading={loading}
            loadingText={loadingText}
            onClick={onSubmit}
            {...buttonStyle}
          >
            {buttonText}
          </Button>
        </Flex>
      </>
    );
  },
);
const MarginProfitCalculator: FunctionComponent<{ store: IStore }> = observer(
  ({ store }) => {
    let [entry, setEntry] = useState(0);
    let [size, setSize] = useState(0);
    let [pnl, setPnl] = useState(0);
    let [kind, setKind] = useState('long');
    function calculateFee(price: number) {
      let fee = 0.1 / 100;
      return price * fee;
    }
    function determineClosePrice(pnlValue = 0) {
      let total = entry * size;
      let sum = total + pnlValue;
      if (kind === 'short') {
        sum = total - pnlValue;
      }
      return sum / size;
    }
    let closePrice = determineClosePrice();
    let openFee = calculateFee(entry * size);
    let closeFee = calculateFee(closePrice * size);
    let pnlWithFee = pnl + openFee + closeFee;
    let idealClose = determineClosePrice(pnlWithFee);
    let description = `The close price is ${closePrice.toFixed(
      store.market.places,
    )} while the ideal close is ${idealClose.toFixed(
      store.market.places,
    )} with a total pnl of ${pnlWithFee.toFixed(store.market.places)}`;
    return (
      <>
        <Flex direction="column">
          <Flex direction="row" width="100%" justifyContent="space-between">
            <InputElement
              label="Margin Entry"
              type="number"
              mb={2}
              value={entry}
              onChange={(e: any) => {
                setEntry(parseFloat(e.target.value));
              }}
            />
            <Spacer spacing={2} />
            <InputElement
              label="Size"
              type="number"
              mb={2}
              value={size}
              onChange={(e: any) => {
                setSize(parseFloat(e.target.value));
              }}
            />
          </Flex>
          <Flex direction="row" width="100%" justifyContent="space-between">
            <InputElement
              label="Target Pnl"
              type="number"
              mb={2}
              value={pnl}
              onChange={(e: any) => {
                setPnl(parseFloat(e.target.value));
              }}
            />
            <Spacer spacing={2} />
            <SelectElement
              label="Kind"
              value={kind}
              onChange={(e) => {
                setKind(e.target.value);
              }}
              options={['long', 'short']}
            />
          </Flex>
          <Text color="#718096" fontSize="0.875rem" mb={4}>
            {description}
          </Text>
        </Flex>
      </>
    );
  },
);
const FibonacciCreateStrategy: FunctionComponent<{
  store: IStore;
  loading: boolean;
  onNewEntryCreate: (quantity?: number) => any;
}> = observer(({ store, loading, onNewEntryCreate }) => {
  let support =
    store.trader.tradeKind === 'long'
      ? store.fibTrader.selectedLow
      : store.fibTrader.selectedHigh;
  const { size, close }: any = store.trader.getFibSupport(
    store.entryPrice,
    support,
    store.trader.tradeKind,
    true,
  );
  useEffect(() => {
    store.trader.setRiskPercent(0.5);
  }, []);
  return (
    <>
      <RiskAllocator2
        options={[0.125, 0.25, 0.5, 1, 2, 3, 5, 7]}
        name="risk-allocator"
        valueDisplay={(x: any) => `${x}%`}
        description={`The current risk is ${store.trader.risk}`}
        onChange={(val: any) => {
          store.trader.setRiskPercent(parseFloat(val));
        }}
        positionSize={``}
        defaultValue={store.trader.riskPercent}
        render={() => <SupportResistance store={store} />}
      />{' '}
      <Box my={5} />
      <RadioElement
        controlProps={{ display: 'flex' }}
        label="Select Trade type"
        labelProps={{ fontSize: '1.5em' }}
        onChange={(val: 'long' | 'short') => {
          store.trader.setTradeKind(val);
        }}
        value={store.trader.tradeKind}
        radioProps={{ size: 'lg' }}
        options={[
          { label: 'Long', value: 'long' },
          { label: 'Short', value: 'short' },
        ]}
      />
      <Box my={5}></Box>
      <RiskAllocator2
        options={store.fibTrader.timeFrameOptions}
        name="risk-allocator"
        valueKey={(x: any) => x.value}
        valueDisplay={(x: any) => x.label}
        labelText="TimeFrame: "
        description={``}
        onChange={(val: any) => {
          store.fibTrader.setTimeFrame(val);
        }}
        flex="row"
        positionSize={``}
        defaultValue={store.fibTrader.timeFrame}
      />
      <FibonacciForm
        store={store}
        loading={loading}
        lowCurrent={store.market.currentPrice}
        action={{ low: '<', high: '>' }}
        summary={`The pnl to be lost at ${
          store.trader.tradeKind === 'long' ? 'support' : 'resistance'
        } ${support} is ${store.trader.determinePnl(
          support,
          store.entryPrice,
          size,
        )}. and the size is ${size}`}
        onSubmit={() => {
          onNewEntryCreate(size);
        }}
        kind={store.trader.tradeKind as any}
        options={store.fibTrader
          .getFibValues(store.trader.tradeKind as any)
          .filter((o) => {
            if (store.trader.tradeKind == 'long') {
              return o.value < store.market.currentPrice;
            }
            return o.value >= store.market.currentPrice;
          })}
        {...{
          buttonText: `New ${store.trader.tradeKind} position`,
          labelText: 'Entry Price',
        }}
      />
    </>
  );
});
const PipCreateStrategy: FunctionComponent<{
  store: IStore;
  loading: boolean;
  onNewEntryCreate: () => any;
}> = observer(({ store, loading, onNewEntryCreate }) => {
  const newTrade = store.getNewEntryAndSize;
  return (
    <>
      <MobileFlex>
        <EnhancedFormControl
          description={`The current value of the stop is ${store.trader
            .getStopLoss(store.market, newTrade.entryPrice)
            .toFixed(store.market.places)}`}
        >
          <Text fontSize="1.5em">Pips Used</Text>
          <Spacer spacing={2} />
          <Incrementer
            editable
            value={store.trader.pip}
            onChange={(pip) => {
              store.trader.setPip(pip);
            }}
          />
        </EnhancedFormControl>
        <Spacer spacing={2} />

        <RiskAllocator2
          options={[0.125, 0.25, 0.5, 1, 2, 3]}
          name="risk-allocator"
          valueDisplay={(x: any) => `${x}%`}
          description={`The current risk is ${
            store.trader.risk
          } and size is ${store.trader.getSize(store.market)}`}
          onChange={(val: any) => {
            store.trader.setRiskPercent(parseFloat(val));
          }}
          positionSize={`Position Size: ${
            store.market.currentPrice ? store.trader.getSize(store.market) : 0
          }`}
          defaultValue={store.trader.riskPercent}
        />
      </MobileFlex>

      <Box my={5} />

      <RadioElement
        controlProps={{ display: 'flex' }}
        label="Select Trade type"
        labelProps={{ fontSize: '1.5em' }}
        onChange={(val: 'long' | 'short') => {
          store.trader.setTradeKind(val);
        }}
        value={store.trader.tradeKind}
        options={['Long', 'Short'].map((o) => ({
          label: o,
          value: o.toLowerCase(),
        }))}
        radioProps={{ size: 'lg' }}
      />

      <Box my={10} />
      <EntryTradeComponent
        buttonText={`${store.trader.tradeKind} trade with size ${newTrade.quantity} at ${newTrade.entryPrice}`}
        loading={loading}
        store={store}
        onSubmit={onNewEntryCreate}
      />
      <Box my={3} />
    </>
  );
});
const CreateTrade: FunctionComponent<{
  store: IStore;
  onControlledOrder: (callback?: any, quantity?: number) => any;
  onOpenOrder: (
    price: string,
    isMarketTrade: boolean,
    useStop: boolean,
    callback?: (e: any) => any,
  ) => any;
}> = observer(({ onOpenOrder, store, onControlledOrder }) => {
  const [loading, setLoading] = useState(false);
  const [trackStopLoss, onTrackStopLoss] = useState(true);
  const [isMarketTrade, setMarketTrade] = useState(false);
  const toast = useToast();
  const priceRef = useRef<any>();
  const onNewEntryCreate = (quantity?: number) => {
    setLoading(true);
    onControlledOrder(() => {
      setLoading(false);
      toast({
        title: 'Error creating order',
        status: 'error',
        description: 'Unable to create trade',
        duration: 3000,
        isClosable: true,
      });
    }, quantity).then(() => {
      setLoading(false);
      toast({
        title: 'Create trade',
        status: 'success',
        description: 'Successfully created trade',
        duration: 3000,
        isClosable: true,
      });
    });
  };
  return (
    <>
      {store.market.pip === 0 ? (
        <Spinner />
      ) : (
        <StatGroup>
          <Stat>
            <StatLabel>Balance</StatLabel>
            <StatNumber>${store.trader.walletBalance}</StatNumber>
            <StatHelpText>
              Spread: {store.trader.getSpread(store.market.pip)} (1 pip ={' '}
              {store.market.pip.toFixed(store.market.places)})
            </StatHelpText>
          </Stat>
          <Stat>
            <StatLabel>Current Price</StatLabel>
            <StatNumber ref={priceRef}>{store.market.currentPrice}</StatNumber>
          </Stat>
        </StatGroup>
      )}
      <Box my={10} />
      <RadioElement
        controlProps={{ display: 'flex' }}
        label="Select Strategy"
        labelProps={{ fontSize: '1.5em' }}
        onChange={(val: 'pip' | 'fibonacci') => {
          store.switchStrategy(val);
        }}
        value={store.currentStrategy}
        options={store.strategies.map((o) => ({ label: o, value: o }))}
        radioProps={{ size: 'lg' }}
      />
      {store.currentStrategy === 'fibonacci' && (
        <FibonacciCreateStrategy
          store={store}
          onNewEntryCreate={onNewEntryCreate}
          loading={loading}
        />
      )}
      {store.currentStrategy === 'pip' && (
        <PipCreateStrategy
          store={store}
          loading={loading}
          onNewEntryCreate={onNewEntryCreate}
        />
      )}
      <Box my={3} />
      <Stack spacing={5} direction="column">
        <Checkbox
          defaultIsChecked={trackStopLoss}
          onChange={() => {
            onTrackStopLoss(!trackStopLoss);
          }}
          size="lg"
        >
          Set Stop loss based on pip
        </Checkbox>
        <Checkbox
          defaultIsChecked={isMarketTrade}
          onChange={() => setMarketTrade(!isMarketTrade)}
          size="lg"
        >
          Use market orders for stop loss
        </Checkbox>
      </Stack>

      <Box my={10} />
      <Flex justifyContent="space-around">
        <Button
          size="lg"
          variant="outline"
          width={['100%', '80%']}
          margin="0 auto"
          isLoading={loading}
          isDisabled={store.market.currentPrice === 0}
          loadingText={'Creating trade'}
          colorScheme={store.trader.tradeKind == 'long' ? 'blue' : 'red'}
          onClick={(e: any) => {
            let instance: any = priceRef.current;
            setLoading(true);
            onOpenOrder(
              instance?.innerText,
              isMarketTrade,
              trackStopLoss,
              () => {
                setLoading(false);
                toast({
                  title: 'Error creating new trade',
                  status: 'error',
                  description: 'Unable to create new trade',
                  duration: 3000,
                  isClosable: true,
                });
              },
            );
          }}
        >
          {`Place ${store.trader.tradeKind} trade at $${store.market.currentPrice}`}
        </Button>
      </Flex>
    </>
  );
});

const FibonacciStrategy: FunctionComponent<{
  store: IStore;
  loading: boolean;
  onNewEntry: (params: any) => void;
  onClose: () => any;
}> = observer(({ store, loading, onNewEntry, onClose }) => {
  const [mode, setMode] = useState('entry');
  let [swing, setSwing] = useState(true);
  const [takeProfitPip, setTakeProfitPip] = useState(
    getDefault(store.openTrade?.takeProfitPipUsed(store.market.pip)),
  );
  function getDefault(v: any) {
    return v || 1;
  }
  let feesClose = store.openTrade?.idealClose(store.entryPrice) || '0';
  let support =
    store.trader.tradeKind === 'long'
      ? store.fibTrader.selectedLow
      : store.fibTrader.selectedHigh;
  const { size, close, pnl }: any = store.trader.getFibSupport(
    store.entryPrice,
    support,
    store.openTrade.kind,
    true,
    store.openTrade,
  );
  useEffect(() => {
    setTakeProfitPip(store.openTrade?.takeProfitPipUsed(store.market.pip));
  }, []);
  // const size = store.trader.getSupportSize(
  //   store.entryPrice,
  //   store.openTrade.kind,
  // );
  function onSubmit() {
    onNewEntry({ size, close, pnl });
  }
  let config: any = {
    entry: {
      buttonText: `Increase ${store.openTrade?.kind} position`,
      labelText: 'Entry Price',
      kind: store.trader.tradeKind,
      action: {
        low: '<',
        high: '>',
      },
      summary: `The pnl to be lost at ${
        store.openTrade?.kind === 'long' ? 'support' : 'resistance'
      } ${support} is ${store.openTrade.determinePnl(
        support,
        store.entryPrice,
        size,
      )}. and the size is ${size} and the close price is ${close} with pnl ${pnl}`,
    },
    profit: {
      buttonText: `Increase ${store.openTrade?.kind} position`,
      labelText: 'Take Profit Price',
      kind: store.trader.tradeKind === 'long' ? 'short' : 'long',
      action: {
        low: '>=',
        high: '>',
      },
      summary: `The take profit value is ${store.entryPrice.toFixed(
        2,
      )} and pnl is ${store.openTrade.determinePnl(
        store.entryPrice,
      )}. Close with fees: ${feesClose} with pnl ${store.openTrade.determinePnl(
        parseFloat(feesClose),
      )}`,
    },
  };
  let kind: any = store.openTrade?.kind || 'long';
  const lowCurrent =
    mode == 'entry' ? store.market.currentPrice : store.openTrade?.entryPrice;
  const tk = store.openTrade?.takeProfitPnl(
    'profit',
    store.market,
    takeProfitPip,
  );
  function newClose() {
    store.setEntryPrice(parseFloat(tk.close));
    onClose();
  }
  return (
    <>
      <TradeKindSelector
        defaultValue={mode}
        onChange={setMode}
        options={['entry', 'profit']}
      />
      <Box my={5}></Box>

      {mode === 'entry' && (
        <>
          <Flex direction={['column', 'column', 'row']}>
            <RiskAllocator2
              options={store.fibTrader.timeFrameOptions}
              name="risk-allocator"
              valueKey={(x: any) => x.value}
              valueDisplay={(x: any) => x.label}
              labelText="TimeFrame: "
              description={``}
              onChange={(val: any) => {
                store.fibTrader.setTimeFrame(val);
              }}
              flex="row"
              positionSize={``}
              defaultValue={store.fibTrader.timeFrame}
            />
            <Spacer spacing={2} />
            <RadioElement
              controlProps={{ display: 'flex' }}
              label="Set stop type"
              labelProps={{ fontSize: '1em' }}
              onChange={() => {
                setSwing(!swing);
              }}
              value={swing.toString()}
              options={['true', 'false'].map((o) => ({ label: o, value: o }))}
              radioProps={{ size: 'lg' }}
            />
          </Flex>
          <Box my={5} />
          <FibonacciForm
            store={store}
            loading={loading}
            lowCurrent={lowCurrent}
            action={config[mode].action}
            swing={swing}
            summary={config[mode].summary}
            onSubmit={mode === 'entry' ? onSubmit : onClose}
            kind={config[mode].kind}
            {...config[mode]}
          />
        </>
      )}
      {mode === 'profit' && (
        <>
          <Box my={5}></Box>
          {store.kind === 'margin' ? (
            <MarginProfitCalculator store={store} />
          ) : (
            <>
              <EntryTradeComponent
                loading={loading}
                inputStyle={{ width: '100%' }}
                buttonStyle={{ width: '100%' }}
                description={``}
                store={store}
                onSubmit={newClose}
                {...{
                  buttonText: `Close ${kind} position`,
                  labelText: null,
                }}
              >
                <Box my={2}></Box>
                <Flex>
                  <InputElement
                    label="Pip Value"
                    type="number"
                    onChange={(e: any) => {
                      store.market.setPip(parseFloat(e.target.value) || 0);
                    }}
                    defaultValue={store.market.pip}
                  />
                  <Spacer spacing={2} />
                  <SelectElement
                    label="Sell Percent"
                    onChange={(e) => {
                      store.openTrade?.setSellPercent(
                        parseFloat(e.target.value),
                      );
                    }}
                    value={store.openTrade?.sellPercent}
                    options={[10, 12.5, 25, 30, 50, 75, 100]}
                    defaultText="Select Sell percent"
                    valueLayout={(u) => `${u}%`}
                  />
                </Flex>
                <Box my={2} />
                <EnhancedFormControl
                  description={`The pnl is ${
                    tk?.pnl
                  } and size to be sold is ${store.openTrade?.sizeToSell.toFixed(
                    4,
                  )} and close price is ${tk?.close}`}
                  // description={stopDescription('profit')}
                >
                  <Text fontSize="1.5em">Pips Used</Text>
                  <Spacer spacing={2} />
                  <Incrementer
                    {...{
                      value: takeProfitPip,
                      editable: true,
                      onChange: (pip: any) => {
                        setTakeProfitPip(pip);
                      },
                      incrementer: store.market.increment,
                    }}
                  />
                </EnhancedFormControl>
                <Box my={2} />
              </EntryTradeComponent>
            </>
          )}
        </>
      )}
    </>
  );
});

const PipStrategy: FunctionComponent<{
  store: IStore;
  toast: any;
  loading: boolean;
  onNewEntry: (params: any, callback?: any) => void;
  onClose: () => any;
}> = observer(({ store, toast, loading, onNewEntry, onClose }) => {
  const [mode, setMode] = useState('Stop Loss');

  const newTrade = store.getNewEntryAndSize;
  return (
    <>
      <TradeKindSelector
        defaultValue={mode}
        onChange={setMode}
        options={['Stop Loss', 'Take profit']}
      />
      <Box my={5}></Box>
      <SingleStopLimitComponent store={store} mode={mode} toast={toast} />
      <Box my="3em" />
      <Flex direction="row">
        <RiskAllocator2
          wrapStyle={{ display: 'hidden' }}
          options={[3]}
          name="increase-allocator"
          labelText="Increase Position"
          valueDisplay={(x: any) => `${x}%`}
          description={`The current risk is ${store.trader.newTradeRiskValue.toFixed(
            2,
          )} and new size would be ${newTrade.quantity.toFixed(
            3,
          )}(trade size: ${newTrade.currentSize.toFixed(
            3,
          )}) and new entry is ${newTrade.entryPrice.toFixed(
            2,
          )} and the pnl would be ${store.openTrade?.determinePnl(
            store.openTrade?.stop || store.market.currentPrice,
            newTrade.entryPrice,
            newTrade.quantity,
          )}`}
          onChange={(val: any) => {
            store.trader.prepareNewTrade(parseFloat(val));
          }}
          positionSize={``}
          defaultValue={store.trader.newTradeRisk}
        />
        <Box my={3} />
        <EntryTradeComponent
          buttonText={`Increase ${store.openTrade?.kind} position`}
          loading={loading}
          store={store}
          onSubmit={onNewEntry}
        />
      </Flex>

      <Box my="3em" />
      <Flex direction="row">
        <RiskAllocator2
          wrapStyle={{}}
          options={[12.5, 25, 50, 75, 100]}
          name="profit-allocator"
          labelText="Close Trade"
          valueDisplay={(x: any) => `${x}%`}
          description={`The current risk is ${store.trader.risk.toFixed(
            2,
          )} and size to be sold is ${store.openTrade?.sizeToSell.toFixed(4)}`}
          onChange={(val: any) => {
            store.openTrade?.setSellPercent(parseFloat(val));
            // store.trader.setRiskPercent(parseFloat(val));
          }}
          positionSize={``}
          defaultValue={store.openTrade?.sellPercent}
        />
        <Box my={3} />
        <Flex width="100%">
          <Button
            size="lg"
            variant="outline"
            width={['100%', '80%']}
            margin="0 auto"
            colorScheme="blue"
            alignSelf="center"
            isLoading={loading}
            loadingText="Closing Trade"
            onClick={onClose}
          >
            {`Close ${store.openTrade?.kind} trade`}
          </Button>
        </Flex>
      </Flex>
    </>
  );
});
const FibonacciForm: FunctionComponent<{
  store: IStore;
  action: { high: string; low: string };
  loading: boolean;
  summary?: string;
  kind: 'long' | 'short';
  lowCurrent: number;
  onSubmit: () => any;
  options?: any[];
  swing?: boolean;
}> = observer(
  ({
    loading,
    store,
    action,
    lowCurrent,
    onSubmit,
    summary = '',
    kind,
    options,
    swing = true,
    ...rest
  }) => {
    const _options = options || store.fibTrader.getFibValues(kind);
    return (
      <>
        <EntryTradeComponent
          loading={loading}
          inputStyle={{ width: '100%' }}
          buttonStyle={{ width: '100%' }}
          description={summary}
          store={store}
          options={_options}
          onSubmit={onSubmit}
          displayRisk={
            <>
              {<Spacer spacing={3} />}
              <InputElement
                label="Returns (R): "
                type="number"
                mb={2}
                defaultValue={store.trader.returns}
                onChange={(e: any) => {
                  store.trader.setReturns(parseFloat(e.target.value));
                }}
              />
            </>
          }
          {...rest}
        >
          <Box my={2}></Box>
          <Flex>
            {swing ? (
              <>
                {kind === 'long' ? (
                  <SelectElement
                    label="Swing Low"
                    onChange={(e) =>
                      store.fibTrader.setLow(parseFloat(e.target.value))
                    }
                    value={store.fibTrader.selectedLow}
                    defaultText="Select Swing Low"
                    options={store.fibTrader.getSwingValue(
                      'low',
                      lowCurrent,
                      action.low,
                    )}
                  />
                ) : (
                  <SelectElement
                    label="Swing High"
                    onChange={(e) =>
                      store.fibTrader.setHigh(parseFloat(e.target.value))
                    }
                    value={store.fibTrader.selectedHigh}
                    defaultText="Select Swing High"
                    options={store.fibTrader
                      .getSwingValue('high', lowCurrent, action.high)
                      .reverse()}
                  />
                )}
              </>
            ) : (
              <InputElement
                label="Spread"
                type="number"
                onChange={(e: any) => {
                  let func = {
                    long: store.fibTrader.setLow,
                    short: store.fibTrader.setHigh,
                  };
                  let v = parseFloat(e.target.value);
                  let spread =
                    kind == 'long'
                      ? store.entryPrice - v
                      : store.entryPrice + v;
                  if (spread) {
                    func[kind](spread);
                  }
                }}
                defaultValue={
                  kind === 'long'
                    ? store.fibTrader.selectedLow
                    : store.fibTrader.selectedHigh
                }
              />
            )}
            <Spacer spacing={2} />
            <SelectElement
              label="Sell Percent"
              onChange={(e) =>
                store.trader.setSellPercent(parseFloat(e.target.value))
              }
              value={store.trader.sellPercent}
              options={[25, 50, 75, 100]}
              valueLayout={(u) => `${u}%`}
              defaultText="Select Sell percent"
            />
          </Flex>
        </EntryTradeComponent>
      </>
    );
  },
);
const SupportResistance: FunctionComponent<{ store: IStore }> = observer(
  ({ store }) => {
    return (
      <InputElement
        controlProps={{ width: '65%' }}
        label={store.trader.tradeKind === 'long' ? 'Support' : 'Resistance'}
        placeholder={
          store.trader.tradeKind === 'long' ? 'Support' : 'Resistance'
        }
        key={store.trader.tradeKind}
        defaultValue={store.trader.significantArea}
        onChange={(e: any) => {
          let no = parseFloat(e.target.value);
          store.trader.setSignificantArea(no);
        }}
      />
    );
  },
);
const FollowTrade: FunctionComponent<{
  store: IStore;
  onTradeClosed: (callback?: any) => void;
  onPositionIncreased: (params: any, callback?: any) => any;
}> = observer(({ store, onTradeClosed, onPositionIncreased }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (store.openTrade) {
      store.openTrade.setClosed(store.market.currentPrice);
    }
  }, [store.market.currentPrice]);
  const toast = useToast();
  // //console.log('new Trade', newTrade);

  const onIncreasePosition = (params: any) => {
    setLoading(true);
    onPositionIncreased(params, () => {
      setLoading(false);
      toast({
        title: 'Error increasing position',
        status: 'error',
        description: 'Unable to create trade',
        duration: 3000,
        isClosable: true,
      });
    }).then(() => {
      setLoading(false);
      toast({
        title: 'Create trade',
        status: 'success',
        description: 'Successfully created trade',
        duration: 3000,
        isClosable: true,
      });
    });
  };
  return (
    <>
      <StatGroup justifyContent="space-around">
        {store.openTrade && (
          <Stat>
            <StatLabel>Entry Price: {store.openTrade.entryPrice}</StatLabel>
            <StatNumber>
              P/L: {store.openTrade.determinePnl(store.market.currentPrice)}
            </StatNumber>
            <StatLabel>Current Price: {store.market.currentPrice}</StatLabel>
            {/* <StatHelpText>
                Spread: {store.trader.getSpread(store.market.pip)} (1 pip ={' '}
                {store.market.pip})
              </StatHelpText> */}
            {/* <StatHelpText>
                Position Size: {store.openTrade.quantity}
              </StatHelpText> */}
            {/* <StatHelpText>Kind: {store.openTrade.kind}</StatHelpText> */}
          </Stat>
        )}
        <Stat>
          <StatLabel>Current Stop</StatLabel>
          <StatNumber>
            {store.openTrade?.stop?.toFixed(store.market.places) || 'null'}
          </StatNumber>
          {store.openTrade && (
            <StatHelpText>
              P/L:
              {store.openTrade.getStopPnl > 0 ? (
                <StatArrow ml={1} type="increase" />
              ) : (
                <StatArrow ml={1} type="decrease" />
              )}
              {store.openTrade.getStopPnl}
            </StatHelpText>
          )}
        </Stat>

        <Stat>
          <StatLabel>Take Profit</StatLabel>
          <StatNumber>
            {store.openTrade?.takeProfit?.toFixed(store.market.places) ||
              'null'}
          </StatNumber>
          {store.openTrade && (
            <StatHelpText>
              P/L:
              {store.openTrade.getTakeProfitPnl > 0 ? (
                <StatArrow ml={1} type="increase" />
              ) : (
                <StatArrow ml={1} type="decrease" />
              )}
              {store.openTrade.getTakeProfitPnl}
            </StatHelpText>
          )}
        </Stat>
      </StatGroup>
      <Box my={10}></Box>
      <RiskAllocator2
        options={[0.25, 0.5, 1, 2, 3, 5, 6.25, 10, 14, 100]}
        name="risk-allocator"
        valueDisplay={(x: any) => `${x}%`}
        description={`The current risk is ${
          store.trader.risk
        } and size is ${store.trader.getSize(store.market)}`}
        onChange={(val: any) => {
          store.trader.setRiskPercent(parseFloat(val));
        }}
        positionSize={`Current Size: ${store.openTrade?.quantity}`}
        defaultValue={store.trader.riskPercent}
        render={() => <SupportResistance store={store} />}
      />{' '}
      <Box my={10}></Box>
      <RadioElement
        controlProps={{ display: 'flex', justifyContent: 'space-between' }}
        label="Market Type"
        onChange={(val: 'future' | 'margin') => {
          store.setKind(val);
        }}
        value={store.kind}
        options={['future', 'margin'].map((o) => ({ label: o, value: o }))}
        radioProps={{ size: 'lg' }}
      />
      <Box my={5}></Box>
      <RadioElement
        controlProps={{ display: 'flex' }}
        label="Select Strategy"
        labelProps={{ fontSize: '1.5em' }}
        onChange={(val: 'pip' | 'fibonacci') => {
          store.switchStrategy(val);
        }}
        value={store.currentStrategy}
        radioProps={{ size: 'lg' }}
        options={store.strategies.map((o) => ({ label: o, value: o }))}
      />
      <Box my={3}></Box>
      {store.currentStrategy === 'fibonacci' && (
        <FibonacciStrategy
          store={store}
          loading={loading}
          onNewEntry={onIncreasePosition}
          onClose={() => {
            setLoading(true);
            let price = store.entryPrice;
            store
              .updateTakeProfit(1, price)
              .then(() => {
                setLoading(false);
                toast({
                  title: 'Take Profit updated',
                  description: `The take profit has been updated to ${store.entryPrice}`,
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              })
              .catch((error) => {
                setLoading(false);
                toast({
                  title: 'Error creating order',
                  description: 'Unable to create order',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              });
          }}
        />
      )}
      {store.currentStrategy === 'pip' && (
        <PipStrategy
          loading={loading}
          toast={toast}
          store={store}
          onNewEntry={onIncreasePosition}
          onClose={() => {
            setLoading(true);
            onTradeClosed(() => {
              setLoading(false);
              toast({
                title: 'Error closing all position',
                status: 'error',
                description: 'Unable to close position',
                duration: 3000,
                isClosable: true,
              });
            });
          }}
        />
      )}
    </>
  );
});
const MobileFlex: FunctionComponent = ({ children }) => {
  return <Flex flexDirection={['column', 'row']}>{children}</Flex>;
};
const Spacer = ({ spacing = 2 }) => <Box mx={spacing} my={[spacing, 0]} />;

interface Window {
  APP_DATA: any;
}
declare let window: Window;
let env: 'testing' | 'production' = 'production';
if (window.APP_DATA) {
  const environment: 'testing' | 'production' = window.APP_DATA.environment;
  env = environment || 'production';
}
//console.log(env);
const store = Store.create(
  {
    market: { symbol: 'BTCUSDT', currentPrice: 0 },
  },
  { adapter: setAdapter(loadAdapter(env)) },
);

const App = observer(({}: AppProps) => {
  // Create the count state.
  const create = Boolean(store.openTrade);
  const toast = useToast();
  function onTradeCreate(
    price: string,
    isMarketTrade: boolean,
    useStop: boolean,
    callback?: (e: any) => any,
  ) {
    store
      .createNewTrade(parseFloat(price), isMarketTrade, useStop)
      .then(() => {})
      .catch((error: any) => {
        if (callback) {
          callback(error);
        }
      });
  }
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef: any = React.useRef();

  return (
    <Box m={5}>
      {create ? (
        <FollowTrade
          store={store}
          onPositionIncreased={(params, callback?: () => void) => {
            return store
              .increasePosition(params, store.market.currentPrice)
              .catch((error) => {
                if (callback) {
                  callback();
                }
              });
          }}
          onTradeClosed={(callback?: () => void) => {
            store.marketCloseTrade(store.market.currentPrice).catch((error) => {
              if (callback) {
                callback();
              }
            });
          }}
        />
      ) : (
        <CreateTrade
          store={store}
          onControlledOrder={(callback?: () => void, quantity?: number) => {
            return store.controlledEntryTrade(quantity).catch((error) => {
              if (callback) {
                callback();
              }
            });
          }}
          onOpenOrder={onTradeCreate}
        />
      )}
      <Box my={5} />
      {store.orderOptions.length > 0 && (
        <RadioElement
          controlProps={{ display: 'flex' }}
          label="Select Trade type"
          labelProps={{ fontSize: '1.5em' }}
          onChange={(val: any) => {
            store.switchOpenOrder(parseInt(val));
            //console.log('currentIndex', store.currentTradeIndex);
          }}
          value={store.currentTradeIndex.toString()}
          options={store.orderOptions}
          radioProps={{ size: 'lg' }}
        />
      )}
      <Flex justifyContent="space-between">
        <Button
          onClick={() => {
            store.refreshSockets(
              () => {
                toast({
                  title: 'Deleting sockets connection',
                  status: 'warning',
                  description: 'Deleting stale connection',
                  duration: 3000,
                  isClosable: true,
                });
              },
              () => {
                toast({
                  title: 'Created new socket connection',
                  status: 'success',
                  description: 'created new socket connection',
                  duration: 3000,
                  isClosable: true,
                });
              },
            );
          }}
        >
          Update Websocket
        </Button>
        <MarketsSidebar
          isOpen={isOpen}
          onClose={onClose}
          title="Create your account"
          btnRef={btnRef}
          size="full"
          button={
            <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
              {`Switch from ${store.market.symbol}`}
            </Button>
          }
        >
          <AddNewMarket
            array={store.symbols}
            onAdd={store.addWatchSymbol}
            onSelect={(market) => {
              //console.log(market);
              store.changeMarket(market);
              onClose();
            }}
          />
        </MarketsSidebar>
      </Flex>
    </Box>
  );
});

export default App;
