import type { FlexProps } from '@chakra-ui/react';

import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import type { ITrader } from '../stores/app';
import Sidebar from '../app-components/Sidebar';
import type { IAccount } from '../../store/botConfig';

interface SidebarProps extends FlexProps {
  store: ITrader | IAccount;
  onSelectCoin: (coin: any) => any;
  onAccountSwitch: (account: string) => any;
}

const ApplicationSideBar = observer(
  ({ store, onSelectCoin, onAccountSwitch, ...props }: SidebarProps) => {
    let [currentTab, setCurrentTab] = useState('Favorites');
    let [filter, setFilter] = useState('');
    const favorites = store.coins.map((o) => o.toUpperCase());
    return (
      <Sidebar
        {...props}
        active={store.currentCoin?.toUpperCase()}
        onSelectCoin={onSelectCoin}
        currentPage={currentTab}
        onTabClick={(ct) => setCurrentTab(ct)}
        onCoinSearch={setFilter}
        coins={store.supportedCoins
          .filter((o) => {
            if (filter) {
              return o.toUpperCase().includes(filter.toUpperCase());
            }
            return true;
          })
          .filter((o) => {
            if (currentTab !== 'All') {
              return favorites.includes(o.toUpperCase());
            }
            return true;
          })}
        profile={store.accountInfo}
        onLogout={() => {}}
        addAccount={() => {}}
        onAccountSwitch={onAccountSwitch}
      />
    );
  },
);

export default ApplicationSideBar;
