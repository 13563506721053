//@ts-nocheck
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Text,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconButton,
  Select,
  useColorModeValue as mode,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect } from 'react';
import { HiRefresh } from 'react-icons/hi';
import { Redirect, Route, Switch } from 'react-router-dom';
import { BaseDrawer } from '../newBot/app-components/primitives/ModalComponent';
import ApplicationSideBar from '../newBot/app-components/ApplicationSideBar';
import { HomeSummarySection } from '../newBot/app-components/SolvedTrader/HomeSummarySection';
import { AppTabs } from '../newBot/app-components/AppTabs';
import { useMobileMenuState } from '../newBot/app-components/hooks';
import type { IAccount, IBotConfig, IBotStore } from '../store/botConfig';
import { DropdownMenu } from './components/DropdownMenu';
import {
  CreateAlertComponent,
  CreateJobComponent,
} from './components/NewJobComponent';
import TightFutureTraderAnalyzer from '../newBot/app-components/TightFutureTraderAnalyzer';
import { HiArrowLeft } from 'react-icons/hi';
import { useNavigationHook } from './hooks';
import JobsViews from './JobsView';
import MarketList, { SpotMarketList } from './MarketList';
import { GrResume } from 'react-icons/gr';
import { FaPauseCircle } from 'react-icons/fa';
import {
  RouteAwareTabbedTradeOrderPage,
  TabbedTradeOrderPage,
} from './TabbedTradeOrderPage';

interface Window {
  APP_DATA: any;
}
declare let window: Window;
let env: 'testing' | 'production' = 'production';
if (window.APP_DATA) {
  const environment: 'testing' | 'production' = window.APP_DATA.environment;
  env = environment || 'production';
}

const SharedPageView: React.FC<{
  store: IBotConfig;
  displaySort?: boolean;
  onAdd?: any;
  onPause?: any;
  markets: IBotConfig[];
  alert?: boolean;
  onResume?: any;
  lists: any[];
  [key: string]: any;
}> = observer(
  ({
    store,
    markets,
    displaySort,
    onAdd,
    onPause,
    onResume,
    alert = false,
    switchToJobsView,
    createComponent,
    switchToHomeView,
    modalTitle,
    lists,
  }) => {
    const title = alert ? 'alert' : 'job';
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (markets[0]) {
      //console.log(markets[0].toJSON());
    }

    return (
      <Flex direction="column" px={2}>
        <Flex direction={isMobile ? 'column' : 'row'}>
          <Select
            width={isMobile ? '90%' : '100%'}
            my={4}
            variant="outline"
            size="lg"
            mr={2}
            bg="white"
            placeholder="Select Market"
            defaultValue={store.account?.defaultConfig?.symbol}
            onChange={(e) => {
              store.account?.setDefaultConfig(e.target.value);
            }}
          >
            {markets.map((market) => {
              return (
                <option value={`${market.symbol}`}>{market.symbol}</option>
              );
            })}
          </Select>
          <HStack>
            {displaySort}
            <IconButton
              aria-label="Refresh markets"
              ml={2}
              isLoading={store.loading || store.account_loading}
              onClick={() => store.fetchAccounts()}
              icon={<HiRefresh />}
            />
            {onAdd ? (
              <IconButton
                aria-label={`Add ${title}`}
                ml={2}
                onClick={onOpen}
                icon={<AddIcon />}
              />
            ) : null}
            {onPause ? (
              <IconButton
                aria-label={`Pause all ${title}s`}
                ml={2}
                onClick={() =>
                  store.account
                    ?.bulkJobAction(
                      `Pause all ${title}`,
                      store.account.defaultConfig.symbol,
                    )
                    .then(() => store.fetchAccounts())
                }
                icon={<FaPauseCircle />}
              />
            ) : null}
            {onResume ? (
              <IconButton
                aria-label={`Resume all ${title}`}
                ml={2}
                onClick={() =>
                  store.account
                    ?.bulkJobAction(
                      `Resume all ${title}`,
                      store.account.defaultConfig.symbol,
                    )
                    .then(() => store.fetchAccounts())
                }
                icon={<GrResume />}
              />
            ) : null}
            <Button size={'sm'} onClick={switchToHomeView}>
              Home
            </Button>
          </HStack>
        </Flex>
        <JobsViews lists={lists} noAccordion store={store} alert={alert} />
        <BaseDrawer
          placement="top"
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          title={modalTitle}
          onSave={() => {
            onClose();
          }}
          footerProps={{ justifyContent: 'center' }}
          buttonText="Save"
          displayCancelButton
          // buttonText={`Add ${store.selectedMarkets.length} Selected Markets`}
          buttonProps={{
            isLoading: store.loading,
            loadingText: 'Saving',
          }}
          closeOnOverlayClick={true}
        >
          {createComponent}
        </BaseDrawer>
      </Flex>
    );
  },
);

export const JobsPage: FC<{
  store: IBotStore;
  markets: IBotConfig[];
  switchToJobsView?: any;
  switchToHomeView?: any;
}> = observer(
  ({ store, markets, switchToJobsView, switchToHomeView, alert }) => {
    function modalTitle() {
      let config: any = store.account?.defaultConfig || { symbol: '' };
      return `Create job for ${config.symbol}`;
    }
    return (
      <SharedPageView
        store={store}
        lists={store.account?.filteredJobs}
        markets={markets}
        displaySort={
          <DropdownMenu
            alignSelf="center"
            colorScheme="blue"
            variant="outline"
            onClick={(v) => {
              store.account?.setJobListFilter(v);
            }}
            label={store.account?.joblistFilter || `Sort jobs`}
            options={[`Long jobs`, `Short jobs`, `Other jobs`]}
          />
        }
        onAdd={true}
        onPause={true}
        onResume={true}
        createComponent={
          <CreateJobComponent
            toJobsPage={switchToJobsView}
            store={store}
            flex={[1, 1, 1, 0.9]}
            config={store.account?.defaultConfig}
          />
        }
        {...{ switchToJobsView, switchToHomeView, modalTitle: modalTitle() }}
      />
    );
  },
);
export const AlertsPage: FC<{
  store: IBotStore;
  markets: IBotConfig[];
  alert?: boolean;
  switchToJobsView?: any;
  switchToHomeView?: any;
}> = observer(
  ({ store, markets, switchToJobsView, switchToHomeView, alert }) => {
    function modalTitle() {
      let config: any = store.account?.defaultConfig || { symbol: '' };
      return `Create alert for ${config.symbol}`;
    }
    if (markets[0]) {
      //console.log(markets[0].toJSON());
    }

    return (
      <SharedPageView
        store={store}
        lists={store.account?.config?.alerts}
        displaySort={
          <DropdownMenu
            alignSelf="center"
            colorScheme="blue"
            variant="outline"
            onClick={(v) => {
              store.account?.setJobListFilter(v);
            }}
            label={`Sort alerts`}
            options={[`margin`, `future`]}
          />
        }
        markets={markets}
        alert
        onAdd={true}
        createComponent={
          <CreateAlertComponent
            toAlertPage={switchToJobsView}
            store={store}
            flex={[1, 1, 1, 0.9]}
            config={store.account?.defaultConfig}
          />
        }
        {...{ switchToHomeView, modalTitle: modalTitle() }}
      />
    );
  },
);

const DefaultContainer = ({ children }: { children: any }) => {
  return (
    <Box as="section" py={'12'} bg={mode('gray.100', 'gray.800')}>
      <Box maxW={{ base: 'xl', md: '7xl' }} mx="auto" px={{ md: '8' }}>
        {children}
      </Box>
    </Box>
  );
};

export const OldAppBody: FC<{
  isMarketListOpen?: boolean;
  isMenuOpen?: boolean;
  store: IBotStore;
  switchToHomeView: () => any;
  switchToHomeView: () => any;
  closeMobileMarketList: () => void;
}> = observer(
  ({
    store,
    isMenuOpen,
    switchToJobsView,
    switchToHomeView,
    isMarketListOpen,
    closeMobileMarketList,
  }) => {
    const { match, location } = useNavigationHook();

    function getMarkets(): any {
      let r = store.account?.markets || [];
      if (store.account) {
        if (match.url.includes('usd-future')) {
          r = store.account.usdMarkets;
        }
        if (match.url.includes('coin-future')) {
          r = store.account.coinMarkets;
        }
      }
      return r;
    }
    console.log(
      'accounts',
      store.accounts.map((o) => o.owner),
    );
    let owners = store.accounts.map((o) => o.owner).filter(Boolean);
    return (
      <>
        {store.account && owners.length > 0 ? (
          <Switch>
            <Route exact path={`${match.path}markets`}>
              <DefaultContainer>
                <MarketList
                  account={store.account}
                  store={store}
                  owners={owners}
                  {...{ switchToHomeView, switchToJobsView }}
                  markets={store.account.markets}
                />
              </DefaultContainer>
            </Route>
            <Route exact path={`${match.path}usd-future`}>
              <DefaultContainer>
                <MarketList
                  type="usd"
                  title={'USD-M Futures'}
                  account={store.account}
                  store={store}
                  {...{ switchToHomeView, switchToJobsView, owners }}
                  markets={store.account.usdMarkets}
                />
              </DefaultContainer>
            </Route>
            <Route exact path={`${match.path}coin-future`}>
              <DefaultContainer>
                <MarketList
                  type="coin"
                  title={'Coin-M Futures'}
                  account={store.account}
                  store={store}
                  {...{ switchToHomeView, switchToJobsView, owners }}
                  markets={store.account.coinMarkets}
                />
              </DefaultContainer>
            </Route>
            <Route exact path={`${match.path}spot`}>
              <DefaultContainer>
                <SpotMarketList
                  type="spot"
                  title={'Spot Markets'}
                  account={store.account}
                  store={store}
                  {...{ switchToHomeView, switchToJobsView }}
                  owners={owners}
                  markets={store.account.spot_markets}
                />
              </DefaultContainer>
            </Route>
            <Route exact path={`${match.path}home`}>
              <HomeSummarySection
                store={store}
                owners={owners}
                isMarketListOpen={isMarketListOpen}
                closeMobileMarketList={closeMobileMarketList}
              />
            </Route>
            <Route exact path={`${match.path}jobs`}>
              <DefaultContainer>
                <JobsPage
                  {...{ switchToHomeView, switchToJobsView }}
                  markets={getMarkets()}
                  store={store}
                />
              </DefaultContainer>
            </Route>
            <Route exact path={`${match.path}alerts`}>
              <DefaultContainer>
                <AlertsPage
                  {...{ switchToHomeView, switchToJobsView }}
                  markets={getMarkets()}
                  store={store}
                  alert={true}
                />
              </DefaultContainer>
            </Route>
            <Route path={`${match.path}spot/:symbol`}>
              <DefaultContainer>
                <RouteAwareTabbedTradeOrderPage
                  accounts={store.accounts.map((o) => o.owner)}
                  account={store.account}
                  positionStore={store.positionStore}
                />
              </DefaultContainer>
            </Route>
            <Route path={`${match.path}`}>
              {isMenuOpen ? null : <Redirect to={`${match.url}home`} />}
            </Route>
          </Switch>
        ) : null}
      </>
    );
  },
);
const BotConfig: FC<{ store: IBotStore; tab?: string }> = observer(
  ({ store, tab = 'Markets' }) => {
    const { isMenuOpen, toggle } = useMobileMenuState();
    const {
      isOpen: isMarketListOpen,
      onOpen: openMobileMarketList,
      onClose: closeMobileMarketList,
    } = useDisclosure();
    const { history, match, location } = useNavigationHook();
    const btnRef = React.useRef();

    function switchToJobsView() {
      history.push(`${match.url}jobs`);
      // setCurrentTab('Jobs');
    }
    function switchToHomeView() {
      history.push(`${match.url}home`);
    }
    function openSidebar() {
      if (!isMenuOpen) {
        history.push(`${match.url}side-bar`);
      } else {
        history.push(`${match.url}`);
      }
      toggle();
    }
    function currentTab() {
      let r = 'Home';
      if (location.pathname.includes('jobs')) {
        r = 'Jobs';
      }
      if (location.pathname.includes('alerts')) {
        r = 'Alerts';
      }
      if (location.pathname.includes('coin-future')) {
        r = 'Coin-Future';
      }
      if (location.pathname.includes('usd-future')) {
        r = 'USD-Future';
      }
      if (location.pathname.includes('spot')) {
        r = 'Spot';
      }
      if (location.pathname.includes('markets')) {
        r = 'Markets';
      }
      return r;
    }
    return (
      <Flex alignItems={'flex-start'}>
        <AppTabs
          flex={1}
          coin={store.account?.selectedCoin || ''}
          toggleSidebar={openSidebar}
          isSidebarOpen={isMenuOpen}
          currentPage={currentTab()}
          openMobileMarketList={openMobileMarketList}
          tabs={[
            // 'Coin-Future',
            'Home',
            'USD-Future',
            'Spot',
            'Markets',
            'Jobs',
            'Alerts',
          ]}
          onTabClick={(ct) => {
            //console.log(ct);
            if (ct === 'Markets') {
              history.push(`${match.url}markets`);
            } else if (ct === 'Jobs') {
              history.push(`${match.url}jobs`);
            } else if (ct === 'Alerts') {
              history.push(`${match.url}alerts`);
            } else if (ct === 'USD-Future') {
              history.push(`${match.url}usd-future`);
            } else if (ct === 'Coin-Future') {
              history.push(`${match.url}coin-future`);
            } else if (ct === 'Spot') {
              history.push(`${match.url}spot`);
            } else if (ct === 'Home') {
              history.push(`${match.url}home`);
            }
          }}
        >
          <Route path={match.path}>
            <OldAppBody
              {...{ switchToJobsView, switchToHomeView }}
              isMarketListOpen={isMarketListOpen}
              closeMobileMarketList={closeMobileMarketList}
              isMenuOpen={isMenuOpen}
              store={store}
            />
          </Route>
          <Route exact path={`${match.path}side-bar`}>
            <Drawer
              size="xs"
              placement="right"
              isOpen={isMenuOpen}
              blockScrollOnMount={false}
              onClose={toggle}
            >
              <DrawerOverlay />
              <DrawerContent
                bg={mode('white', 'gray.800')}
                shadow="none"
                position="relative"
                maxW="64"
                // maxW="200"
              >
                {store.account ? (
                  <ApplicationSideBar
                    onSelectCoin={(coin) => {
                      store.account?.setSelectedCoin(coin);
                      toggle();
                    }}
                    onAccountSwitch={(account: string) => {
                      //console.log(account);
                      store.switchAccount(account);
                    }}
                    store={store.account}
                  />
                ) : null}
              </DrawerContent>
            </Drawer>
          </Route>
        </AppTabs>
        {store.account ? (
          <>
            <ApplicationSideBar
              store={store.account}
              onAccountSwitch={(account: string) => {
                //console.log(account);
                store.switchAccount(account);
              }}
              onSelectCoin={(coin: string) => {
                store.account?.setSelectedCoin(coin);
                history.push(`${match.url}markets`);
              }}
              display={{ base: 'none', xl: 'flex' }}
            />
          </>
        ) : null}
      </Flex>
    );
  },
);

export default BotConfig;
