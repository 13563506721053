import {
  Button,
  Flex,
  Box,
  Heading,
  HStack,
  Text,
  useMediaQuery,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { AIChat } from './AIChat';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { FaCreditCard, FaPencilAlt, FaPlus, FaRedo } from 'react-icons/fa';
import { ISolvedTraderConfig } from '../../../store/solvedConfig';
import { AccountWithLoanIndicator } from './StatWithIndicator';
import { BaseDrawer } from '../primitives/ModalComponent';
import { TableWithSearch } from './TableWithSearch';
import { OrdersButton } from './MarginTradeSection';
import { AppContainer } from './TradeZoneControl';

const AccountBadgeEnum: Record<string, string> = {
  isolated_margin: 'green',
  future: 'orange',
  cross_margin: 'red',
};

const AccountColumns = [
  {
    Header: 'Owner',
    accessor: 'owner',
  },
  {
    Header: 'Long Position',
    accessor: 'long_position',
  },
  {
    Header: 'Short Position',
    accessor: 'short_position',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Liquidation',
    accessor: 'liquidation',
  },
];
export const FieldDisplay = ({
  label,
  value,
  color,
  additionProps = {},
}: {
  label: string;
  value?: {
    price: number;
    quantity?: number;
    addition?: any;
  };
  additionProps?: any;
  color?: string;
}) => {
  return (
    <>
      <Text display={'inline'} color={color}>
        {label}:
      </Text>
      {value ? (
        <>
          {' '}
          <strong> {value.price}</strong> {value.quantity ? `/ ` : null}
          {value.quantity}
          {value.addition ? (
            <Text display="inline" {...additionProps}>
              {value.addition}
            </Text>
          ) : null}
        </>
      ) : (
        <strong> N / A</strong>
      )}
    </>
  );
};
const LoanStatusSection: React.FC<{
  store: ISolvedTraderConfig;
  onEditConfig: any;
  onCreateAccount: any;
  onActionTrigger: any;
}> = observer(({ store, onEditConfig, onCreateAccount, onActionTrigger }) => {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalType, setModalType] = React.useState<'ai' | 'createAccount'>(
    'ai',
  );
  const btnRef = React.useRef();
  const actions = [
    {
      label: () => 'Action',
      func: (row: any, index: number) => {
        store.setCurrentAccount(index);
        onActionTrigger();
      },
    },
    {
      label: () => 'Refresh',
      variant: 'outline',
      color: 'green',
      func: (row: any, index: number) => {
        store.exchanges[index].refresh();
      },
      loading: (index: number) => store.exchanges[index].loading,
    },
  ];
  const handleOpenModal = (modal: 'ai' | 'createAccount') => {
    onOpen(modal);
    setModalType(modal);
  };
  return (
    <>
      <Box
        as="section"
        bg={useColorModeValue('gray.50', 'gray.800')}
        pt={isLargerThan600 ? '10' : '2'}
        mb="-5"
      >
        <Box maxW="7xl" mx="auto" px={{ base: '6', md: '8' }}>
          <HStack justify={'space-between'}>
            <Heading
              alignSelf={{ md: 'flex-start' }}
              fontSize={{ base: '1.25rem', md: '1.5rem' }}
              fontWeight="semibold"
              color={useColorModeValue('black', 'white')}
              alignSelf="center"
            >
              Trade Dashboard
            </Heading>
            <Flex justifyContent={'flex-end'} py="4">
              <HStack spacing="2" direction="row" align="center">
                <Button
                  leftIcon={<RiQuestionnaireFill />}
                  size={isLargerThan600 ? 'md' : 'xs'}
                  fontSize={{ base: '.8rem', md: '1rem' }}
                  width={isLargerThan600 ? 'initial' : '100%'}
                  colorScheme="blue"
                  onClick={() => handleOpenModal('ai')}
                >
                  Ask Trade AI
                </Button>
              </HStack>
            </Flex>
          </HStack>
        </Box>
      </Box>
      <AccountWithLoanIndicator store={store} multipleAccount={true} />
      <AppContainer>
        <Box
          pt="6"
          bg="white"
          display="flex"
          justifyContent={{ lg: 'flex-end' }}
        >
          <Flex
            width={{ base: '100%', lg: 'auto' }}
            direction={{ base: 'column', '2xl': 'row' }}
            justifyContent="space-between"
          >
            <OrdersButton
              marketType="future"
              onTransfer={() => {}}
              account={{}}
              onReset={() => newEntry?.onReset()}
              onCreateAccount={() => handleOpenModal('createAccount')}
            />
          </Flex>
        </Box>
      </AppContainer>
      <TableWithSearch
        title="Account List"
        columns={AccountColumns}
        data={store.exchanges.map((i, appIndex) => {
          return {
            owner: (
              <>
                {i.owner}
                <br />
                <Flex mt={3} gap={3}>
                  {actions?.map((action) => (
                    <Button
                      onClick={() => {
                        if (action.func) {
                          action.func(i, appIndex);
                        }
                      }}
                      size={action.size || 'sm'}
                      variant={action.variant || 'link'}
                      colorScheme={action.color || 'blue'}
                      isLoading={
                        action.loading ? action.loading(appIndex) : false
                      }
                    >
                      {typeof action.label === 'function'
                        ? action.label(i, appIndex)
                        : action.label}
                    </Button>
                  ))}
                </Flex>
              </>
            ),
            long_position: (
              <>
                <FieldDisplay
                  color="green"
                  label="Entry"
                  value={i.entry('long')}
                />
                <br />
                <FieldDisplay
                  color="purple"
                  label="TP"
                  additionProps={{ color: 'green' }}
                  value={i.take_profit('long')}
                />
                <br />
                <FieldDisplay
                  color="orange"
                  label="SL"
                  additionProps={{ color: 'red' }}
                  value={i.stop_loss('long')}
                />
              </>
            ),
            short_position: (
              <>
                <FieldDisplay
                  color="red"
                  label="Entry"
                  value={i.entry('short')}
                />
                <br />
                <FieldDisplay
                  color="purple"
                  label="TP"
                  additionProps={{ color: 'green' }}
                  value={i.take_profit('short')}
                />
                <br />
                <FieldDisplay
                  color="orange"
                  label="SL"
                  additionProps={{ color: 'red' }}
                  value={i.stop_loss('short')}
                />
              </>
            ),
            balance: (
              <>
                <FieldDisplay label="usdt" value={i.currency_display('usdt')} />
                <br />
                <FieldDisplay label="bnb" value={i.currency_display('bnb')} />
              </>
            ),
            liquidation: i.liquidation,
          };
        })}
        badgeEnum={AccountBadgeEnum}
        badgeKey={['market_type']}
        // actions={actions}
        form={
          <Heading
            fontSize={{ base: '1.25rem', md: '1.5rem' }}
            fontWeight="semibold"
            color={useColorModeValue('black', 'white')}
          >
            Account List
          </Heading>
        }
      >
        <Button
          isLoading={store.loading}
          onClick={() => store.onRefresh()}
          loadingText="Refreshing"
          colorScheme="yellow"
          leftIcon={<FaRedo />}
          size="sm"
        >
          Refresh
        </Button>
        <Button leftIcon={<FaCreditCard />} size="sm" colorScheme="blue">
          Withdraw
        </Button>
        <Button
          leftIcon={<FaPencilAlt />}
          onClick={onEditConfig}
          size="sm"
          colorScheme="teal"
        >
          Edit Config
        </Button>
        <Button
          leftIcon={<FaPlus />}
          onClick={onCreateAccount}
          size="sm"
          colorScheme="green"
        >
          Create Account
        </Button>
      </TableWithSearch>
      <BaseDrawer
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={btnRef}
        title={modalType == 'ai' ? `Ask Trade Question` : 'Create Account'}
        onSave={onClose}
        footerProps={{ justifyContent: 'center' }}
        displayTextButton={false}
        closeOnOverlayClick={true}
      >
        <Flex direction="column">
          <Flex justifyContent="space-around">
            {modalType == 'ai' ? (
              <AIChat store={store.ai} />
            ) : (
              <Box>Creation of account</Box>
            )}
          </Flex>
        </Flex>
      </BaseDrawer>
    </>
  );
});

export default LoanStatusSection;
