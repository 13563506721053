import { HedgeBot, Position } from './hedge_bot';
import { determine_pnl, Signal } from './trade_signal';
import { determine_position_size, to_f } from './utils';

type SimpleOrder = {
  price: number;
  quantity: number;
};

type Kind = 'long' | 'short';

export class ControlledStrategy {
  hedge: HedgeBot;
  signal?: Signal;
  size: number;
  override_liquidation?: boolean;
  trade_size: number = 0.04;

  constructor({
    hedge,
    signal,
    trade_size,
  }: {
    hedge: HedgeBot;
    signal?: Signal;
    trade_size?: number;
  }) {
    this.hedge = hedge;
    this.signal = signal;
    this.size = 4;
    this.override_liquidation = true;
    if (trade_size) {
      this.trade_size = trade_size;
    }
  }

  get zones() {
    const _zones = this.hedge.config.zones;
    const new_zones = [];
    for (let i = 0; i < _zones.length; i += this.size) {
      new_zones.push(_zones.slice(i, i + this.size));
    }
    return new_zones;
  }

  get long_position() {
    return this.hedge.avg_long_position;
  }

  get short_position() {
    return this.hedge.avg_short_position;
  }

  get maximum_loss() {
    const support = this.hedge.config.support;
    const resistance = this.hedge.config.resistance;
    const max_size = this.hedge.config.max_size || 0;
    const result = Math.abs(support - resistance) * max_size;
    return result;
  }

  get allowed_loss() {
    return to_f(this.maximum_loss / this.size, '%.2f');
  }

  get long_liquidation() {
    const long_position = this.hedge.batch.long_position;
    if (long_position) {
      return this.hedge.determine_liquidation_price(
        long_position,
        this.allowed_loss,
      );
    }
    return undefined;
  }

  get short_liquidation() {
    const short_position = this.hedge.batch.short_position;
    if (short_position) {
      return this.hedge.determine_liquidation_price(
        short_position,
        this.allowed_loss,
      );
    }
    return undefined;
  }

  determine_max_size_to_liquidation(
    current_price: number,
    kind: 'long' | 'short' = 'long',
  ) {
    const close_price =
      kind === 'long'
        ? this.hedge.config.support
        : this.hedge.config.resistance;
    const result = determine_position_size({
      entry: current_price,
      stop: close_price,
      budget: this.allowed_loss,
      as_coin: true,
    });
    return result;
  }

  zones_to_liquidation_price(kind: 'long' | 'short' = 'long') {
    const allowed_spread = 0.001 / 100;
    const position = kind === 'long' ? this.long_position : this.short_position;
    const liquidation_price =
      kind === 'long' ? this.long_liquidation : this.short_liquidation;
    let zones: number[] = [];

    if (liquidation_price && position) {
      let start = position.entry_price;
      if (kind === 'long') {
        if (this.last_buy) {
          start = this.last_buy.price;
        }
        zones = this.hedge.config.zones.filter(
          (x) =>
            start * (1 + allowed_spread) ** -1 >= x && x > liquidation_price,
        );
      } else {
        if (this.last_sell) {
          start = this.last_sell.price;
        }
        zones = this.hedge.config.zones.filter(
          (x) =>
            start * (1 + allowed_spread) ** 1 <= x && x < liquidation_price,
        );
      }
    }
    return zones;
  }

  get last_buy() {
    return this.hedge.batch.last_buy;
  }

  get last_sell() {
    return this.hedge.batch.last_sell;
  }

  get liquidation_on_avg_long(): number | undefined {
    return this.determine_liquidation_on_avg('long');
  }

  get liquidation_on_avg_short(): number | undefined {
    return this.determine_liquidation_on_avg('short');
  }

  determine_liquidation_on_avg(
    kind: 'long' | 'short',
    _position: {
      entry_price: number;
      quantity: number;
    } | null = null,
  ): number | undefined {
    let position =
      kind === 'long'
        ? this.hedge.avg_long_position
        : this.hedge.avg_short_position;
    if (_position) {
      position = _position;
    }
    if (position) {
      const positionData = {
        entryPrice: position.entry_price,
        positionAmt: position.quantity,
      };
      const positionObj = new Position({ raw_position: positionData, kind });
      return this.hedge.determine_liquidation_price(
        positionObj,
        this.allowed_loss,
      );
    }
    return undefined;
  }

  determine_new_liquidation_and_opposite_trade_protection(
    new_trade: SimpleOrder,
    kind: Kind = 'long',
  ) {
    const avg = this.hedge.batch.determine_average_entry(kind, [new_trade]);
    if (avg['entry_price']) {
      const new_liquidation = this.determine_liquidation_on_avg(kind, avg);
      if (new_liquidation) {
        let stop = new_liquidation;
        let budget = this.allowed_loss / 2;
        let ref =
          kind === 'long'
            ? this.hedge.config.support
            : this.hedge.config.resistance;
        if (this.override_liquidation) {
          ref = this.get_stop({ price: new_trade.price, kind });
        }
        if (kind === 'short' && new_liquidation > ref) {
          stop = ref;
        }
        if (kind === 'long' && new_liquidation < ref) {
          stop = ref;
        }
        if (new_trade['price'] !== stop) {
          const current_loss = determine_pnl(
            avg['entry_price'],
            stop,
            to_f(avg['quantity'], '%.3f'),
            kind,
          );
          if (Math.abs(current_loss) < budget) {
            budget = Math.abs(current_loss);
          }
          const opposite_size =
            determine_position_size({
              entry: new_trade['price'],
              stop,
              budget,
              as_coin: true,
            }) || 0;
          const opposition = kind === 'long' ? 'short' : 'long';
          return {
            avg: { ...avg, quantity: to_f(avg['quantity'], '%.3f') },
            pnl: to_f(current_loss, '%.2f'),
            [opposition]: {
              price: new_trade['price'],
              quantity: to_f(opposite_size, '%.3f'),
              take_profit: stop,
              kind: kind === 'short' ? 'long' : 'short',
            },
            [kind]: {
              // price: opposite_size,
              quantity: to_f(avg['quantity'] / 2, '%.3f'),
              stop: stop,
              price:stop,
            },
            current_price: new_trade['price'],
          };
        }
      }
    }
  }
  determine_reentry_after_liquidation(
    new_trade: SimpleOrder,
    kind: Kind = 'long',
  ) {
    const result = this.determine_new_liquidation_and_opposite_trade_protection(
      new_trade,
      kind,
    ) as any;
    if (result) {
      const new_quantity = result['avg']['quantity'] - result[kind]?.quantity;
      const position = {
        entryPrice: result['avg']['entry_price'],
        positionAmt: new_quantity,
      };
      const control = this.create_new_control(position, kind);
      const reverse_kind = kind === 'short' ? 'long' : 'short';
      return control.determine_new_liquidation_and_opposite_trade_protection(
        {
          price: result[reverse_kind]?.take_profit,
          quantity: new_trade['quantity'],
        },
        kind,
      );
    }
  }

  create_new_control(new_position: any, kind: string = 'long') {
    const reverse_kind = kind === 'short' ? 'long' : 'short';
    const hedge = new HedgeBot({
      config: this.hedge._config,
      positions: { [kind]: new_position, [reverse_kind]: {} } as any,
      orders: [],
      current_price: this.hedge.current_price,
    });
    const control = new ControlledStrategy({
      hedge,
      signal: this.signal,
      trade_size: this.trade_size,
    });
    return control;
  }

  build_reentries(prices: number[], quantity: number, kind: Kind = 'long') {
    const reentries = [];
    const reverse_kind = kind === 'short' ? 'long' : 'short';
    let current_controller = new ControlledStrategy({
      hedge: this.hedge,
      signal: this.signal,
      trade_size: this.trade_size,
    });
    for (const price of prices) {
      const reentry =
        current_controller.determine_new_liquidation_and_opposite_trade_protection(
          { price: price, quantity: quantity },
          kind,
        );
      if (reentry) {
        const new_position = {
          entryPrice: reentry['avg']['entry_price'],
          positionAmt: reentry['avg']['quantity'],
        };
        current_controller = this.create_new_control(new_position, kind);
        const sell = reentry[reverse_kind];
        // if (reentry["avg"]["quantity"] <= sell["quantity"]) {
        //     break;
        // }
        reentries.push(reentry);
      }
    }
    return reentries;
  }

  build_side_entries({
    quantity,
    kind = 'long',
    last_price,
  }: {
    quantity: number;
    kind: Kind;
    last_price?: number | null;
  }) {
    this.hedge.batch.orders = [];
    const position =
      kind === 'long'
        ? this.hedge.batch.long_position
        : this.hedge.batch.short_position;
    let current_price = this.hedge.current_price;
    if (position) {
      current_price = position.entry_price;
    }
    if (last_price) {
      current_price = last_price;
    }
    let prices = this.hedge.config.zones
      .filter((x) => x < current_price)
      .reverse();
    if (kind === 'short') {
      prices = this.hedge.config.zones.filter((x) => x > current_price);
    }
    return this.build_reentries(prices, quantity, kind);
  }
  get_stop({ price, kind = 'long' }: { price: number; kind: Kind }) {
    const { support, resistance, middle } = this.hedge.config;
    if (kind === 'long') {
      return price < middle ? support : middle;
    }
    return price < middle ? middle : resistance;
  }
  determine_trades_to_place({
    kind = 'long',
    lastPrice,
  }: {
    kind: Kind;
    lastPrice?: number;
  }): any {
    const position =
      kind === 'long'
        ? this.hedge.batch.long_position
        : this.hedge.batch.short_position;
    const _lastPrice = lastPrice ? lastPrice : position.entry_price;
    const orders = this.build_side_entries({
      quantity: this.trade_size,
      kind: position.kind,
      last_price: _lastPrice,
    });
    const reverseKind = position.kind === 'short' ? 'long' : 'short';
    const consideredOrders = [];
    let summation = 0;
    for (let j = 0; j < orders.length; j++) {
      const i = orders[j];
      const reverseOrder = orders[j][reverseKind] as SimpleOrder;
      if (reverseOrder.quantity > this.hedge.config.max_size) {
        break;
      }
      consideredOrders.push(i);
      const stopIndex = j;
    }
    const limitOrders = consideredOrders.map((x) => ({
      price: x.current_price,
      quantity: this.trade_size,
      kind: position.kind,
      side: position.kind === 'long' ? 'buy' : 'sell',
    }));

    const condition = (r: number) => {
      if (position.kind === 'long') {
        return r > position.entry_price;
      }
      return r < position.entry_price;
    };
    let sellZones = this.hedge.config.zones.filter(condition);
    const divideSize = Math.floor(position.quantity / this.trade_size);
    if (kind === 'short') {
      sellZones = sellZones.reverse();
    }
    sellZones = sellZones.slice(0, divideSize);

    let stopOrder;
    let riskOrders;
    let avg;
    let currentPrice;
    let reverseOrder;
    if (consideredOrders.length > 0) {
      const lastOrder = consideredOrders[consideredOrders.length - 1];
      stopOrder = {
        ...(lastOrder[position.kind] as SimpleOrder),
        kind: position.kind,
        side: position.kind === 'long' ? 'sell' : 'buy',
      };
      reverseOrder = lastOrder[reverseKind] as any;
      riskOrders = this.signal
        ?.build_entry({
          current_price: reverseOrder.price,
          stop_loss: reverseOrder.take_profit,
          risk: this.hedge.config.risk,
          kind: position.kind,
          no_of_trades: this.hedge.config.risk_reward,
        })
        .map((o) => ({ ...o, kind: position.kind }));
      avg = lastOrder.avg;
      currentPrice = lastOrder.current_price;
    } else {
      const price = this.get_stop({ price: _lastPrice, kind: position.kind });
      stopOrder = {
        price: price,
        quantity: to_f(position.quantity / 2, '%.3f'),
        stop: price,
        kind: position.kind,
        side: position.kind === 'long' ? 'sell' : 'buy',
      };
      riskOrders = [];
      avg = null;
      currentPrice = null;
    }

    return {
      orders: [...limitOrders, stopOrder],
      sell_orders: sellZones.map((x) => ({
        price: x,
        quantity: this.trade_size,
        kind: position.kind,
        side: position.kind === 'long' ? 'sell' : 'buy',
      })),
      risk: riskOrders,
      avg: avg,
      margin_size: reverseOrder?.quantity,
      current_price: currentPrice,
    };
  }
}
