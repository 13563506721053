import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useColorModeValue as mode,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  SelectElement,
  InputElementEnhanced,
} from '../../../currentBot/components/FormElement';
import Config1Display from '../../../currentBot/components/GenericConfigDisplay';
import {
  ISolvedTraderConfig,
  INewEntryZoneConfig,
} from '../../../store/solvedConfig';
import TightFutureTraderAnalyzer from '../TightFutureTraderAnalyzer';
import { BaseDrawer, ConfirmDialog } from '../primitives/ModalComponent';
import { IFutureInstance } from '../../../store/futureConfig';

export const AppContainer: React.FC<{ ignore?: boolean }> = ({
  children,
  ignore,
  ...rest
}) => {
  if (ignore) {
    return <Box width="full">{children}</Box>;
  }
  return (
    <Box as="section" py="1" bg="white">
      <Box
        maxW={{ base: '100%', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        width="full"
      >
        {children}
      </Box>
    </Box>
  );
};

export const AccountMenuText = ({ text }: { text: string }) => (
  <Box
    as="span"
    display="inline-block"
    maxW={{ base: '150px', md: '200px' }}
    fontSize={{ base: 'xs', md: 'md' }}
    overflow="hidden"
    textOverflow="ellipsis"
    verticalAlign="middle"
  >
    <span>{text}</span>
  </Box>
);

const TradeZoneConfigDisplay: React.FC<{
  props?: Record<string, any>;
  store: any;
  onChange?: (key: string, value: any) => void;
}> = observer(({ props = {}, store, onChange }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const defaultProps = {
    mt: 3,
    columns: { base: 2, lg: 3 },
    spacing: 5,
    flex: 1,
  };
  return (
    <Config1Display
      props={{ ...defaultProps, ...props }}
      store={store}
      Container={isMobile ? VStack : HStack}
      onChange={onChange}
    />
  );
});

export const AccountsMenu: React.FC<{
  activeAccount?: string;
  accounts?: string[];
  onAccountSwitch: (account: string) => void;
}> = observer(({ activeAccount, accounts = [], onAccountSwitch }) => {
  return (
    <Menu w={{ base: 'full', md: 'initial' }}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        w={{ base: 'full', md: 'initial' }}
      >
        <AccountMenuText text={activeAccount} />
      </MenuButton>
      <MenuList size="sm">
        {accounts.map((accountItem, index) => (
          <MenuItem
            minH="48px"
            key={index}
            onClick={() => onAccountSwitch(accountItem)}
          >
            <AccountMenuText text={accountItem} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
});

export const TradeZoneSelection: React.FC<{
  store: ISolvedTraderConfig;
  onSubmit: () => void;
}> = observer(({ onSubmit, store }) => {
  return (
    <Box>
      <Heading size="md" pb="4">
        Select Active Zone
      </Heading>
      <Flex
        w="full"
        bg="white"
        gap={{ base: '4', xl: '20' }}
        flexDirection={{ base: 'column', md: 'row' }}
        bg={mode('white', 'gray.700')}
        shadow="base"
        rounded="lg"
        px="3"
        py="6"
      >
        <Flex w="full" gap="4" flexDirection={{ base: 'column', md: 'row' }}>
          <TradeZoneConfigDisplay store={store.active_zone} />
        </Flex>
        <Flex flex="1" alignItems="end">
          <Button
            isDisabled={!store.active_zone.isValid}
            colorScheme="blue"
            w={{ base: 'full', md: 'initial' }}
            onClick={onSubmit}
          >
            Continue
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
});

export const StandaloneTradeZone: React.FC<{
  accounts?: string[];
  onViewOrders?: (account: string) => void;
  onPlaceOrder?: () => void;
  placeLoading?: boolean;
  entry_config: INewEntryZoneConfig;
  updateTradeNo?: (tradeNo: number) => void;
}> = observer(
  ({
    accounts = [],
    onViewOrders,
    onPlaceOrder,
    placeLoading,
    entry_config,
    updateTradeNo,
  }) => {
    return (
      <Box>
        <Heading size="md" pb="4">
          Enter Trade
        </Heading>
        <Box
          bg={mode('white', 'gray.700')}
          shadow="base"
          rounded="lg"
          px="3"
          py="6"
        >
          <Flex
            w="full"
            gap={{ base: '4', xl: '20' }}
            mb="6"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Flex w="full">
              <Flex w="full" gap="4" maxW={{ base: 'initial', md: '500px' }}>
                {/* <Box w="full">
                <SelectElement
                  label="Zone"
                  value={currentZone}
                  onChange={(e) => onUpdateZone(e.target.value)}
                  options={options}
                />
                {onZoneChange && (
                  <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    cursor="pointer"
                    mt="1"
                    onClick={onZoneChange}
                  >
                    Change Zone
                  </Text>
                )}
              </Box> */}
                <Box w="full">
                  <SelectElement
                    label="Kind"
                    value={entry_config.kind}
                    onChange={(e) => {
                      entry_config.updateFields({
                        kind: e.target.value,
                      });
                    }}
                    options={['long', 'short']}
                  />
                </Box>
                <Box w="full">
                  <InputElementEnhanced
                    label="Avg Entry"
                    type="number"
                    onChange={(e: any) => {
                      entry_config.updateFields({
                        average_entry: parseFloat(e.target.value) || 0,
                      });
                    }}
                    variant="unstyled"
                    isReadOnly
                    defaultValue={entry_config.averageEntryDefaultValue}
                  />
                </Box>
                <Box w="full">
                  <InputElementEnhanced
                    label="Start Entry"
                    type="number"
                    variant="unstyled"
                    onChange={(e: any) => {
                      entry_config.updateFields({
                        start_entry: parseFloat(e.target.value) || 0,
                      });
                    }}
                    isReadOnly
                    defaultValue={entry_config.startEntryDefaultValue}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex></Flex>
            <Flex alignSelf="start" w={{ base: 'full', md: 'initial' }}>
              <SelectElement
                label="Owner"
                value={entry_config.owner}
                onChange={(e) => {
                  entry_config.updateFields({
                    owner: e.target.value,
                  });
                }}
                options={accounts}
              />
            </Flex>
          </Flex>
          <Flex
            w="full"
            bg="white"
            gap={{ base: '4', xl: '20' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <TradeZoneConfigDisplay
              props={{
                flex: '100%',
              }}
              store={entry_config as any}
              onChange={(key, value) => {
                entry_config.determineOptimumReward();
              }}
            />
            <Flex flex="1" alignItems="end">
              <Button
                size="md"
                mr={2}
                onClick={() => onViewOrders(entry_config.owner)}
                alignSelf={{ md: 'flex-end' }}
                colorScheme="blue"
              >
                View orders
              </Button>
              <ConfirmDialog
                heading={`Creating the order confirmation`}
                confirmAction={onPlaceOrder}
                loading={placeLoading}
                actionText="Place order"
                colorScheme="green"
                button={
                  <Button
                    size="md"
                    alignSelf={{ md: 'flex-end' }}
                    colorScheme="green"
                  >
                    Place order
                  </Button>
                }
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    );
  },
);

export const EnterTradeZone: React.FC<{
  store: ISolvedTraderConfig;
  onZoneChange?: () => void;
  account?: any;
  placeLoading?: boolean;
  onViewOrders?: (account: string) => void;
  onPlaceOrder?: () => void;
}> = observer(
  ({
    onZoneChange,
    store,
    placeLoading,
    account,
    onPlaceOrder,
    onViewOrders,
  }) => {
    let {
      zoneOptions: options,
      onUpdateZone,
      currentZone,
      updateTradeNo,
      entry_config,
      determineOptimumReward,
    } = store.active_zone;

    const [selectedAccount, setSelectedAccount] = useState(
      account?.activeAccount,
    );
    return (
      <Box>
        <Heading size="md" pb="4">
          Enter Trade
        </Heading>
        <Box
          bg={mode('white', 'gray.700')}
          shadow="base"
          rounded="lg"
          px="3"
          py="6"
        >
          <Flex
            w="full"
            gap={{ base: '4', xl: '20' }}
            mb="6"
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <Flex w="full">
              <Flex w="full" gap="4" maxW={{ base: 'initial', md: '500px' }}>
                <Box w="full">
                  <SelectElement
                    label="Zone"
                    value={currentZone}
                    onChange={(e) => onUpdateZone(e.target.value)}
                    options={options}
                  />
                  {onZoneChange && (
                    <Text
                      fontSize="xs"
                      fontWeight="semibold"
                      cursor="pointer"
                      mt="1"
                      onClick={onZoneChange}
                    >
                      Change Zone
                    </Text>
                  )}
                </Box>
                <Box w="full">
                  <SelectElement
                    label="Kind"
                    value={entry_config.kind}
                    onChange={(e) => {
                      entry_config.updateFields({
                        kind: e.target.value,
                      });
                    }}
                    options={['long', 'short']}
                  />
                </Box>
                <Box w="full">
                  <InputElementEnhanced
                    label="Avg Entry"
                    type="number"
                    onChange={(e: any) => {
                      entry_config.updateFields({
                        average_entry: parseFloat(e.target.value) || 0,
                      });
                    }}
                    variant="unstyled"
                    isReadOnly
                    defaultValue={entry_config.averageEntryDefaultValue}
                  />
                </Box>
                <Box w="full">
                  <InputElementEnhanced
                    label="Start Entry"
                    type="number"
                    variant="unstyled"
                    onChange={(e: any) => {
                      entry_config.updateFields({
                        start_entry: parseFloat(e.target.value) || 0,
                      });
                    }}
                    isReadOnly
                    defaultValue={entry_config.startEntryDefaultValue}
                  />
                </Box>
              </Flex>
            </Flex>
            <Flex></Flex>
            <Flex alignSelf="start" w={{ base: 'full', md: 'initial' }}>
              {account && (
                <AccountsMenu
                  activeAccount={selectedAccount}
                  accounts={account.accounts}
                  onAccountSwitch={setSelectedAccount}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            w="full"
            bg="white"
            gap={{ base: '4', xl: '20' }}
            flexDirection={{ base: 'column', md: 'row' }}
          >
            <TradeZoneConfigDisplay
              props={{
                flex: '100%',
              }}
              store={entry_config as any}
              onChange={(key, value) => {
                determineOptimumReward();
              }}
            />
            <Flex flex="1" alignItems="end">
              <Button
                size="md"
                mr={2}
                onClick={() => onViewOrders(selectedAccount)}
                alignSelf={{ md: 'flex-end' }}
                colorScheme="blue"
              >
                View orders
              </Button>
              <ConfirmDialog
                heading={`Creating the order confirmation`}
                confirmAction={onPlaceOrder}
                loading={placeLoading}
                actionText="Place order"
                colorScheme="green"
                button={
                  <Button
                    size="md"
                    alignSelf={{ md: 'flex-end' }}
                    colorScheme="green"
                  >
                    Place order
                  </Button>
                }
              />
            </Flex>
          </Flex>
        </Box>
      </Box>
    );
  },
);

export const TradeSummary: React.FC<{
  store: IFutureInstance;
  isOpen?: boolean;
  onClose: () => void;
}> = observer(({ store, isOpen, onClose }) => {
  console.log('trades', store.config.toJSON());
  return (
    <BaseDrawer
      placement="top"
      isOpen={isOpen}
      onClose={onClose}
      title={`Future Table`}
      buttonText="Cancel"
      onSave={onClose}
      footerProps={{ justifyContent: 'center' }}
      closeOnOverlayClick={true}
    >
      <Flex direction="column">
        <Flex justifyContent="space-around">
          <TightFutureTraderAnalyzer
            displayForm={false}
            store={store}
            accounts={[]}
          />
        </Flex>
      </Flex>
    </BaseDrawer>
  );
});

export const TradeZoneControl: React.FC<{
  store: ISolvedTraderConfig;
  account?: any;
  ignoreContainer?: boolean;
  onAccountSwitch?: (account: string) => void;
  onPlaceOrder?: () => void;
  standalone?: boolean;
  accounts?: string[];
}> = observer(
  ({
    store,
    account,
    ignoreContainer,
    onPlaceOrder,
    standalone,
    accounts = [],
  }) => {
    const [zoneConfigured, setZoneConfigured] = useState(
      store.active_zone.isValid,
    );
    const modalProps = useDisclosure();
    return (
      <AppContainer ignore={ignoreContainer}>
        {standalone ? (
          <StandaloneTradeZone
            entry_config={store.entry_config}
            onViewOrders={(account: string) => {
              store.entry_config.viewTrades(account);
              console.log('settings', store.clonedStore.toJSON());
              modalProps.onOpen();
            }}
            updateTradeNo={store.updateTradeNo}
            onPlaceOrder={onPlaceOrder}
            accounts={accounts}
          />
        ) : (
          <>
            {zoneConfigured ? (
              <EnterTradeZone
                store={store}
                onZoneChange={() => {
                  store.active_zone.updateSupportAndResistance();
                  setZoneConfigured(false);
                }}
                onViewOrders={(account: string) => {
                  store.active_zone.viewTrades(account);
                  console.log('settings', store.clonedStore.toJSON());
                  modalProps.onOpen();
                }}
                onPlaceOrder={onPlaceOrder}
                account={account}
              />
            ) : (
              <TradeZoneSelection
                store={store}
                onSubmit={() => {
                  setZoneConfigured(true);
                }}
              />
            )}
          </>
        )}
        <TradeSummary
          isOpen={modalProps.isOpen}
          onClose={modalProps.onClose}
          store={store.clonedStore}
        />
      </AppContainer>
    );
  },
);
