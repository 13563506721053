import { extendTheme } from '@chakra-ui/react';
import styles from './styles';
import foundations from './foundations';
import components from './components';

const overrides = {
  styles,
  // Other foundational style overrides go here
  ...foundations,
  components,
  colors: {
    paleturquoise: {
      100: 'paleturquoise',
      500: 'paleturquoise',
      800: '#2d9c9c',
    },
    peachpuff: {
      100: 'peachpuff',
      500: 'peachpuff',
      800: '#c77832',
    },
  },
};

export default extendTheme(overrides);
