import {
  Badge,
  Box,
  Button,
  NumberInput,
  NumberInputField,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Checkbox,
  useColorModeValue as mode,
  Flex,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Indicator } from './StatWithIndicator';
import { ITradeZoneStore } from '../../../store/solvedConfig/trade-zone';

const NumberComponent: React.FC<{
  defaultValue: number;
  onChange: (v: number) => void;
}> = ({ defaultValue, onChange }) => {
  return (
    <NumberInput
      defaultValue={defaultValue}
      onChange={(o) => onChange(parseFloat(o))}
    >
      <NumberInputField paddingRight={0} />
    </NumberInput>
  );
};

export type ActionType = {
  label: string | ((value?: any, index?: any) => any);
  variant?: string;
  color?: string;
  size?: string;
  func: (value?: any, index?: any) => any;
  loading?: (index: number) => boolean;
};

type TableProps = {
  columns: any[];
  data: any[];
  badgeEnum?: any;
  children?: any;
  badgeKey?: string[];
  title?: string;
  form?: any;
  actions?: Array<ActionType>;
  edit?: boolean;
  hasCheckbox?: boolean;
  as_select?: string[];
  onRowChecked?: (index: number) => void;
  onCheckAll?: (e: any) => void;
  selectedRows?: number[];
  selectAllChecked?: boolean;
  onCancelSelected?: () => void;
};

const OrderBadgeEnum: Record<string, string> = {
  long: 'green',
  short: 'orange',
  buy: 'blue',
  sell: 'yellow',
};

const DefaultRow: React.FC<{
  row: any;
  appIndex: number;
  newColumns: any[];
  actions?: ActionType[];
  hasCheckbox?: boolean;
  onRowChecked: (e?: any) => void;
  isChecked?: boolean;
}> = observer(
  ({
    row,
    newColumns,
    actions,
    appIndex,
    hasCheckbox,
    isChecked = false,
    onRowChecked,
  }) => {
    return (
      <Tr>
        {hasCheckbox && (
          <Td whiteSpace="nowrap">
            <Checkbox
              _focus={{ boxShadow: 'none' }}
              isChecked={isChecked}
              onChange={onRowChecked}
            />
          </Td>
        )}
        {newColumns.map((column: any, index: number) => {
          const cell = row[column.accessor as keyof typeof row];
          let element = column.Cell?.(cell) ?? cell;

          if (column.accessor === 'pnl' && row.pnl && 'ratio' in row) {
            const changeText = row.ratio * 100;
            const isNegative = !(changeText > 0);
            element = (
              <Box>
                {row.pnl}
                <Badge>
                  <Indicator
                    type={isNegative ? 'down' : 'up'}
                    value={changeText.toFixed(2)}
                  />
                </Badge>
              </Box>
            );
          }

          return (
            <>
              <Td whiteSpace="nowrap" key={index}>
                {element}
              </Td>
            </>
          );
        })}
        {actions?.map((action) => (
          <Td textAlign="right">
            <Button
              onClick={() => {
                if (action.func) {
                  action.func(row, appIndex);
                }
              }}
              size={action.size || 'sm'}
              variant={action.variant || 'link'}
              colorScheme={action.color || 'blue'}
              isLoading={action.loading ? action.loading(appIndex) : false}
            >
              {typeof action.label === 'function'
                ? action.label(row, appIndex)
                : action.label}
            </Button>
          </Td>
        ))}
      </Tr>
    );
  },
);

const DynamicRow: React.FC<{
  row: ITradeZoneStore;
  appIndex: number;
  newColumns: any[];
  as_select: string[];
  actions?: ActionType[];
  hasCheckbox?: boolean;
  onRowChecked?: (index: number) => void;
}> = observer(
  ({ row, newColumns, as_select, actions, appIndex, hasCheckbox }) => {
    return (
      <Tr>
        {hasCheckbox && (
          <Td>
            {/* <Checkbox
              _focus={{ boxShadow: 'none' }}
              onChange={(e) => {
                // row.handleCheckboxChange(parseFloat(e.target.value));
                console.log(e)
              }}
              // isChecked={isSelected}
            /> */}
          </Td>
        )}
        {newColumns.map((column: any, index: any) => {
          const cell = row[column.accessor as keyof typeof row];
          let element = column.Cell?.(cell) ?? cell;

          if (column.accessor === 'risk') {
            element = (
              <Box>
                <NumberComponent
                  defaultValue={(row as ITradeZoneStore).risk_per_trade}
                  onChange={(v) => {
                    row.setRisk(v);
                  }}
                />
              </Box>
            );
          }

          if (column.accessor === 'avg_entry') {
            element = (
              <Box>
                <Box>
                  <Badge>
                    {row.support} - {row.resistance}
                  </Badge>
                </Box>
                {row.avg_entry}
              </Box>
            );
          }
          if (as_select.includes(column.accessor)) {
            element = (
              <Select
                onChange={(e) => {
                  row.updateStop(parseFloat(e.target.value));
                }}
                value={row[column.accessor]}
              >
                {(row as ITradeZoneStore)
                  .getOptions()
                  .map((i: number, index: number) => {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
              </Select>
            );
          }
          return (
            <Td
              whiteSpace="nowrap"
              {...(column.accessor === 'risk'
                ? {
                    width: { base: '100%', lg: '10%' },
                    px: { md: '2' },
                  }
                : {})}
              key={index}
            >
              {element}
            </Td>
          );
        })}
        {actions?.map((action) => (
          <Td textAlign="right">
            <Button
              onClick={() => {
                if (action.func) {
                  action.func(row, appIndex);
                }
              }}
              size={action.size || 'sm'}
              variant={action.variant || 'link'}
              colorScheme={action.color || 'blue'}
            >
              {typeof action.label === 'function'
                ? action.label(row, appIndex)
                : action.label}
            </Button>
          </Td>
        ))}
      </Tr>
    );
  },
);

const TableContent: React.FC<TableProps> = observer(
  ({
    columns,
    data,
    badgeEnum = OrderBadgeEnum,
    badgeKey = ['kind', 'side'],
    actions = [],
    as_select = [],
    edit = false,
    hasCheckbox = false,
    onRowChecked = (e) => {},
    onCheckAll = (e) => {},
    selectAllChecked,
    selectedRows = [],
  }) => {
    const newColumns: any = columns.map((o) => {
      let d: any = {
        Header: o.Header,
        accessor: o.accessor,
      };
      if (badgeEnum && badgeKey.includes(d.accessor)) {
        d.Cell = function StatusCell(dx: any) {
          return (
            <Badge fontSize="xs" colorScheme={badgeEnum[dx]}>
              {dx}
            </Badge>
          );
        };
      }
      return d;
    });

    return (
      <Table my="2" borderWidth="1px" fontSize="sm">
        <Thead bg={mode('gray.50', 'gray.800')}>
          <Tr>
            {hasCheckbox && (
              <Th>
                <Checkbox isChecked={selectAllChecked} onChange={onCheckAll} />
              </Th>
            )}
            {newColumns.map((column: any, index: any) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
            {actions?.length > 0 &&
              actions?.map((action, index) => <Th key={index} />)}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row, index) =>
            edit ? (
              <DynamicRow
                appIndex={index}
                row={row}
                newColumns={newColumns}
                as_select={as_select}
                actions={actions}
                hasCheckbox={hasCheckbox}
              />
            ) : (
              <DefaultRow
                appIndex={index}
                row={row}
                newColumns={newColumns}
                actions={actions}
                hasCheckbox={hasCheckbox}
                onRowChecked={() => onRowChecked(index)}
                isChecked={selectedRows.includes(index)}
              />
            ),
          )}
        </Tbody>
      </Table>
    );
  },
);

export const TableWithSearch: React.FC<TableProps> = observer((props) => {
  return (
    <Box as="section" py="1" bg="white">
      {/* <Box
        maxW={{ base: '100%', md: '7xl' }}
        mx="auto"
        px={{ base: '6', md: '8' }}
        width="full"
      > */}
      <Box overflowX="auto" overflowY={'auto'} maxH="400px">
        {(props.selectedRows?.length ?? 0) > 0 && (
          <Flex justifyContent={'flex-end'} mt={1}>
            <Button onClick={props.onCancelSelected}>cancel selected</Button>
          </Flex>
        )}

        <TableContent {...props} />
        {/* <TablePagination /> */}
      </Box>
      {/* </Box> */}
    </Box>
  );
});
