import { Box, Center, Stack } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { TradeButton } from './TradeActions';

type TradingSectionAction = {
  label?: string;
  action?: () => void;
  render?: () => React.ReactNode;
  isDisabled?: boolean;
  buttonProps?: Record<string, any>;
  loading?: boolean;
};

export type ButtonCollectionProps = {
  actions?: TradingSectionAction[];
  hasBorder?: boolean;
  direction?: 'row' | 'column';
};

export const ButtonCollection = observer(
  ({ actions, hasBorder, direction }: ButtonCollectionProps) => {
    return (
      <Box
        border={hasBorder ? '1px' : undefined}
        borderRadius="lg"
        py={3}
        px={2}
      >
        <Stack
          justifyContent={'center'}
          direction={{ base: direction ?? 'column', md: direction ?? 'row' }}
          gap={direction === 'column' ? 5 : 1}
          alignItems="center"
        >
          {actions?.map((action, i) => {
            return (
              <Fragment key={i}>
                {action?.render && action.render()}
                {action.label && action.action && (
                  <TradeButton
                    isDisabled={action.isDisabled}
                    loading={action.loading}
                    label={action.label}
                    onClick={action.action}
                    buttonProps={action.buttonProps || {}}
                  />
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Box>
    );
  },
);
