import {
  Box,
  BoxProps,
  HStack,
  SimpleGrid,
  Text,
  useCheckbox,
  useCheckboxGroup,
  UseCheckboxProps,
  useColorModeValue as mode,
  useRadio,
  useRadioGroup,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

export const CheckboxBox = (props: BoxProps) => (
  <Box
    borderWidth="2px"
    px="4"
    py="3"
    borderRadius="md"
    cursor="pointer"
    transition="all 0.2s"
    _focus={{ shadow: 'outline' }}
    _checked={{
      bg: mode('gray.50', 'whiteAlpha.100'),
      borderColor: mode('blue.500', 'blue.300'),
    }}
    {...props}
  />
);

interface ButtonCheckboxProps extends UseCheckboxProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const ButtonRadio = (props: ButtonCheckboxProps | any) => {
  const { title, description, ...rest } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(
    rest,
  );
  //console.log(getCheckboxProps());
  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} />
      <CheckboxBox {...getCheckboxProps()}>
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? '' : undefined}
            fontSize="2xl"
            _checked={{
              color: mode('blue.500', 'blue.300'),
            }}
            color={mode('gray.300', 'whiteAlpha.400')}
          >
            {state.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="sm">{description}</Text>
          </Box>
          {/* <Box fontWeight="bold" color={mode('blue.600', 'blue.400')}>
            {price}
          </Box> */}
        </HStack>
      </CheckboxBox>
    </label>
  );
};

export const ButtonCheckbox = (props: ButtonCheckboxProps) => {
  const { title, description, ...rest } = props;
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useCheckbox(
    rest,
  );

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} />
      <CheckboxBox {...getCheckboxProps()}>
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? '' : undefined}
            fontSize="2xl"
            _checked={{
              color: mode('blue.500', 'blue.300'),
            }}
            color={mode('gray.300', 'whiteAlpha.400')}
          >
            {state.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            <Text fontSize="sm">{description}</Text>
          </Box>
          {/* <Box fontWeight="bold" color={mode('blue.600', 'blue.400')}>
            {price}
          </Box> */}
        </HStack>
      </CheckboxBox>
    </label>
  );
};
type IMarket = {
  symbol: string;
  name: string;
};
const StyledCheckboxGroup: React.FC<{
  markets: string[] | any[];
  onItemChecked: (props?: any) => void;
  defaultValue?: string[];
}> = ({ markets, onItemChecked, defaultValue = [] }) => {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue,

    onChange: onItemChecked,
  });

  return (
    <Box>
      <SimpleGrid columns={[2, 3]} spacing={10}>
        {markets.map((market: any) => {
          return (
            <ButtonCheckbox
              {...getCheckboxProps({ value: market })}
              title={market}
              description={market}
            >
              {market}
            </ButtonCheckbox>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export const StyledRadiobuttonGroup: React.FC<{
  options: any[];
  defaultValue: string;
  onItemChecked: (props?: any) => void;
}> = ({ options, defaultValue, onItemChecked, ...rest }) => {
  const { getRadioProps } = useRadioGroup({
    defaultValue,
    onChange: onItemChecked,
  });

  return (
    <Box {...rest}>
      <SimpleGrid columns={2} spacing={3}>
        {options.map((market) => {
          return (
            <ButtonRadio
              {...getRadioProps({ value: market.name })}
              // onChange={() => onItemChecked(market.name)}
              title={market.name}
              description={''}
            >
              {market.name}
            </ButtonRadio>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default observer(StyledCheckboxGroup);
