import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { FieldDisplay } from '../../newBot/app-components/SolvedTrader/LoanStatusSection';
import { PositionProps, SingleExchange, ZoneProps } from './types';
import { SelectElement } from '../../currentBot/components/FormElement';
import { observer } from 'mobx-react-lite';
type ZoneType = {
  id: string;
  entry: number;
  stop: number;
  kind: string;
  support: number;
  resistance: number;
};
export const ExchangeSummaryView = observer(
  (
    props: SingleExchange & {
      owner: string;
      symbol?: string;
      zones?: ZoneType[];
      activeZone?: ZoneType;
      onZoneChange: (zone?: ZoneType) => void;
    },
  ) => {
    return (
      <>
        <Box
          px={{ base: '2', md: '4' }}
          border="1px"
          borderRadius="lg"
          mx="auto"
          minH={200}
          fontSize={{ base: '15px' }}
        >
          {' '}
          <HStack justifyContent="space-between">
            <Text fontWeight="bold">{props.symbol}</Text>
            <Text fontWeight="bold">{props.owner}</Text>
          </HStack>
          <HStack justifyContent="space-between" py={1} mb={2}>
            {/* <ZoneDisplay {...props.zone} /> */}
            <ZoneDisplaySelection
              options={props.zones ?? []}
              defaultValue={props.activeZone}
              onZoneChange={props.onZoneChange}
            />
            <TextValueDisplay
              text={`current price`}
              value={props.currentPrice}
            />
          </HStack>
          <HStack justifyContent="space-between">
            <Position kind="long" {...props.positions.long_position} />
            <Position kind="short" {...props.positions.short_position} />
          </HStack>
          <HStack justifyContent="space-between" mt={'20px'}>
            <TextValueDisplay text={'Balance'} value={props.balance} />
            <TextValueDisplay text={'Liquidation'} value={props.liquidation} />
          </HStack>
        </Box>
      </>
    );
  },
);

export const TextValueDisplay = ({
  text,
  value,
}: {
  text: string;
  value: number;
}) => {
  return (
    <Flex>
      <Text>{text}:</Text>
      <Text ml="3px">{value}</Text>
    </Flex>
  );
};

const ZoneDisplay = (props: ZoneProps & { label?: string; kind?: any }) => {
  const label = props.label || 'zone';
  return (
    <Flex direction={{ base: 'column', md: 'row' }}>
      <Text>{label}:</Text>
      <Text ml="3px" fontWeight="bold">
        {props.entry} - {props.stop}
      </Text>
      <Text>({props.kind})</Text>
    </Flex>
  );
};

type ZoneSelectionProps<T = ZoneType> = {
  options: T[];
  defaultValue?: T;
  onZoneChange: (zone?: T) => void;
};
const ZoneDisplaySelection = observer(
  ({ defaultValue, options, onZoneChange }: ZoneSelectionProps) => {
    return (
      <Flex direction={{ base: 'column', md: 'row' }}>
        <SelectElement
          controlProps={{
            direction: {
              base: 'column',
              md: 'row',
            },
            display: 'flex',
          }}
          label={'Zone'}
          defaultText={`Select zone`}
          value={defaultValue?.id}
          labelProps={{
            size: 'xs',
            fontWeight: 'bold',
            alignSelf: 'center',
            mb: 0,
          }}
          selectProps={{ size: 'sm' }}
          options={options}
          optionLayout={(u) => u.id}
          valueLayout={(u) => `${u.entry} - ${u.stop}`}
          onChange={(e: any) => {
            const item = options.find((o) => o.id === e.target.value);
            onZoneChange(item);
          }}
        />
      </Flex>
    );
  },
);

export const Position = (props: PositionProps & { kind: 'long' | 'short' }) => {
  return (
    <>
      <Box>
        <Text> {props.kind} </Text>
        <FieldDisplay
          color={props.kind === 'long' ? 'green' : 'red'}
          label="Entry"
          value={props.entry}
        />
        <br />
        <FieldDisplay
          color="purple"
          label="TP"
          additionProps={{ color: 'green' }}
          value={props.take_profit}
        />
        <br />
        <FieldDisplay
          color="orange"
          label="SL"
          additionProps={{ color: 'red' }}
          value={props.stop_loss}
        />
      </Box>
    </>
  );
};
