import { flow } from 'mobx';
import { applySnapshot, getSnapshot, Instance, types } from 'mobx-state-tree';
import { getParamForField } from './utils';

const configs = [
  {
    name: 'symbol',
    label: 'Symbol',
    kind: 'input',
    type: 'string',
  },
  { name: 'price', label: 'Alert Price', kind: 'special' },
  {
    name: 'market_type',
    label: 'Market type',
    kind: 'select',
    options: ['future', 'margin'],
  },
];

const config_fields = {
  config_1: ['symbol', 'price', 'market_type'],
};

export const AlertStore = types
  .model('Alert', {
    symbol: types.optional(types.string, ''),
    price: types.optional(types.number, 0),
    market_type: types.optional(
      types.enumeration(['margin', 'future']),
      'future',
    ),
  })
  .views((self) => {
    return {
      get summary() {
        return `${self.symbol} ${self.market_type} alert at ${self.price}`;
      },
      get config_fields() {
        return config_fields;
      },
      get alert() {
        return true;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
      },
      get displayName() {
        return '';
      },
    };
  })
  .views((self) => ({
    get name() {
      return self.summary;
    },
  }))
  .actions((self) => {
    const removeAction = flow(function* removeAction() {
      //console.log('job executed');
      yield;
    });
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
      removeAction,
      updatePrice(price: number) {
        self.price = price;
      },
      updateMarketType(market: 'margin' | 'future') {
        self.market_type = market;
      },
    };
  });

export interface IAlertStore extends Instance<typeof AlertStore> {}
