import {
  Avatar,
  Button,
  Divider as ChakraDivider,
  Flex,
  Text,
  Textarea,
  Spinner,
  HStack,
  ButtonGroup,
} from '@chakra-ui/react';
import { BeatLoader } from 'react-spinners';

import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { IAIStore } from '../../../store/solvedConfig/trade-zone';
import { AiOutlineSend } from 'react-icons/ai';
export const AIChat: React.FC<{
  store: IAIStore;
}> = observer(({ store }) => {
  const messages = store.allMessages;
  const [inputMessage, setInputMessage] = useState('');
  const handleSendMessage = (e) => {
    e.preventDefault();
    if (!inputMessage.trim().length) {
      return;
    }
    store.processMessage(inputMessage);
    setInputMessage(' ');
  };

  return (
    <>
      <Flex w="100%" h="90vh" justify="center" align="center">
        <Flex w="100%" h="90%" flexDir="column">
          <Flex
            w="100%"
            h="93%"
            overflowY="scroll"
            flexDirection="column"
            p="3"
          >
            {messages.map((item, index) => {
              return <Message key={index} {...item} />;
            })}
            <AlwaysScrollToBottom />
          </Flex>
          <ChakraDivider
            w="100%"
            borderBottomWidth="3px"
            color="black"
            mt="5"
          />
          <Flex w="90%" position={'absolute'} bottom="20px" gap={2}>
            <Textarea
              resize="none"
              overflowY={'hidden'}
              placeholder="Type Something..."
              border="none"
              borderRadius="none"
              _focus={{
                border: 'none',
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage(e);
                }
              }}
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
            />

            <Button
              bg="transparent"
              color="black"
              _hover={{
                bg: 'black',
                color: 'white',
                border: '1px solid black',
                borderRadius: '5px',
              }}
              disabled={inputMessage.trim().length <= 0}
              onClick={(e) => handleSendMessage(e)}
            >
              <AiOutlineSend size={30} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
});

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current?.scrollIntoView());
  return <div ref={elementRef} />;
};

export const Message: React.FC<{
  type: string;
  content: string;
  loading?: boolean;
}> = observer((item) => {
  const isMe = item.type === 'human';
  const avatarImg =
    'https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light';

  return (
    <Flex w="100%" justify={isMe ? 'flex-end' : 'flex-start'}>
      {!isMe && <Avatar name="Computer" src={avatarImg} bg="blue.300" mr="2" />}
      <Flex
        bg={isMe ? 'black' : 'gray.100'}
        color={isMe ? 'white' : 'black'}
        minW="100px"
        maxW="350px"
        my="1"
        p="3"
      >
        <Text overflowWrap="anywhere">
          {item.loading ? <BeatLoader size={8} color="black" /> : item.content}
        </Text>
      </Flex>
    </Flex>
  );
});

