import {
  Box,
  Divider,
  Flex,
  Text,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LuDiamond } from 'react-icons/lu';
import { ButtonCollection } from '../components';
import { Exchange, Root } from '../stores';
import { AppPage } from '../components/Page';

export type AccountsListPageProps = {
  store: Root;
  navigate: (path: string) => void;
};

export const AccountsListPage = observer(
  ({ store, navigate }: AccountsListPageProps) => {
    const toast = useToast();

    useEffect(() => {}, []);

    console.log(store.activeAccount?.owner)

    return (
      <AppPage>
        <Box
          maxW="3xl"
          mx="auto"
          rounded={{ md: 'lg' }}
          bg={mode('white', 'gray.700')}
          shadow="base"
          overflow="hidden"
          cursor="pointer"
        >
          <Flex
            align="center"
            justify="space-between"
            px="6"
            py="4"
            backgroundColor={'gray.100'}
          >
            <Box flexBasis="5%"></Box>
            <Text as="h3" fontWeight="bold" fontSize="lg" flexBasis={'70%'}>
              Name
            </Text>
            <Text as="h3" fontWeight="bold" fontSize="lg" flex={1}>
              Balance
            </Text>
          </Flex>
          <Divider />
          <Box>
            {store.accounts.map((account, i) => (
              <AccountCard
                key={account.owner}
                account={account}
                isDefault={account.owner === store.activeAccount?.owner}
                onClick={async () => {
                  store.setProp('activeAccount', account.owner);
                  toast({
                    title: `${account.name} has been set as default`,
                    // description: `${account.name} has been set as default`,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                  navigate('/home');
                }}
              />
            ))}
          </Box>
        </Box>
        <ButtonCollection
          actions={[
            {
              label: 'Create Account',
              action: () => {
                store.setProp('activeAccount', undefined);
                navigate('/create-account');
              },
              loading: false,
            },
          ]}
          direction={'column'}
        />
      </AppPage>
    );
  },
);

interface AccountCardProps {
  account: Exchange;
  isDefault?: boolean;
  onClick: () => void;
}

export const AccountCard = ({
  account,
  isDefault,
  onClick,
}: AccountCardProps) => {
  return (
    <Flex
      as="dl"
      px="2"
      py="4"
      _even={{ bg: mode('gray.50', 'gray.600') }}
      onClick={onClick}
    >
      <Box position="relative">
        {isDefault && (
          <LuDiamond
            size={20}
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          />
        )}
      </Box>
      <Flex flex="1" justifyContent="space-between" ml={8}>
        <Box flexBasis={'75%'}>{account.name}</Box>
        <Box flex="1" fontWeight="semibold">
          {account.balance}
        </Box>
      </Flex>
    </Flex>
  );
};

export default AccountsListPage;
