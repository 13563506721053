import {
  applySnapshot,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import pnl, { determine_position_size } from './pnl';
import * as shared from './shared';
import {
  computeTotalAverageForEachTrade,
  determine_average_entry_and_size,
  fibonacci_analysis,
  getParamForField,
} from './utils';

import { flow } from 'mobx';
import { BotAdapterType } from '../adapter';
import { BarePositionStore } from './barePosition';
import { ControlledStrategy } from './controlled';
import { calculate_liquidation } from './hedge';
import { FinalOrderType, HedgeBot } from './hedge_bot';
import { determine_pnl } from './trade_signal';
import { determine_amount_to_sell, to_f } from './utils';
import { withSetPropAction } from '../mobileAppView/stores/helpers';

export type ITrade = {
  entry: number;
  fee: number;
  margin: number;
  quantity: number;
  risk: number;
  rr: number;
  stop: number;
  take_profit: number;
};
type IIncreaseTrade = {
  entry: number;
  stop: number;
  pnl: number;
  risk: number;
  kind: string;
  quantity: number;
  avg_entry: number;
  avg_size: number;
  sell_price: number;
  fee: number;
  incurred: number;
  net: number;
  risk_sell: number;
};
const ACTION_OPTIONS = ['Determine size', 'Update Take-profit'];
const existingTradeConfig = [
  {
    name: 'entry',
    label: 'E. entry',
    kind: 'special',
  },
  {
    name: 'size',
    label: 'E. size',
    kind: 'special',
  },
  {
    name: 'l_entry',
    label: 'Opp. entry',
    kind: 'special',
  },
  {
    name: 'l_size',
    label: 'Opp. size',
    kind: 'special',
  },
  {
    name: 'take_profit',
    label: 'Take profit',
    kind: 'special',
  },
  {
    name: 'kind',
    label: 'Trade Kind',
    kind: 'radio',
    options: ['long', 'short'],
  },
  {
    name: 'action',
    label: 'Action',
    kind: 'select',
    options: ACTION_OPTIONS,
  },
  { name: 'budget', label: 'Budget', kind: 'special' },
];
const configs = [
  {
    name: 'kind',
    label: 'Trade Kind',
    kind: 'radio',
    options: ['long', 'short'],
  },
  {
    name: 'trend',
    label: 'Trend',
    kind: 'radio',
    options: ['long', 'short'],
  },
  { name: 'multiplier', label: 'multiplier', kind: 'special' },
  { name: 'gap', label: 'gap', kind: 'special' },
  { name: 'budget', label: 'Budget', kind: 'special' },
  { name: 'support', label: 'Support', kind: 'special' },
  { name: 'resistance', label: 'Resistance', kind: 'special' },
  {
    name: 'entry',
    label: 'Entry',
    kind: 'special',
  },
  { name: 'stop', label: 'Stop Price', kind: 'special' },
  { name: 'take_profit', label: 'Take Profit', kind: 'special' },
  { name: 'leverage', label: 'Leverage', kind: 'special' },
  { name: 'percent_change', label: 'Percent change', kind: 'special' },
  { name: 'min_size', label: 'Min size', kind: 'special' },
  { name: 'account_length', label: 'Act length', kind: 'special' },
  { name: 'risk_reward', label: 'Risk reward', kind: 'special' },
  { name: 'modulo', label: 'Trade index', kind: 'select', options: [0, 1] },
  {
    name: 'market_type',
    label: 'Trade Type',
    kind: 'select',
    options: [
      'take_profit',
      'take_profit_limit',
      'stop_loss',
      'aggressive',
      'margin_trade',
      'controlled',
      'margin_protect',
      'future_protect',
      'future_spread_protect',
      'cut_loss',
      'no_profit',
      'buy_more',
      'buy_early',
    ],
  },
  {
    name: 'mode',
    label: 'Mode',
    kind: 'select',
    options: ['main', 'default', 're_entry', 'entries', 'controlled'],
  },
  {
    name: 'type',
    label: 'Job Type',
    kind: 'select',
    options: [
      'new_order',
      'purchase_after_profit',
      'increase_position_with_borrow',
    ],
  },
  { name: 'zone_risk', label: 'Zone risk', kind: 'special' },
  { name: 'risk_per_trade', label: 'Risk per trade', kind: 'special' },
  { name: 'take_profit', label: 'Take Profit', kind: 'special' },
  { name: 'trade_place_index', label: 'Index to place trade', kind: 'special' },
  { name: 'multiplier', label: 'Multiplier', kind: 'special' },
  { name: 'sub_accounts', label: 'Sub accounts', kind: 'list' },
  {
    name: 'ranges',
    label: 'Include Range',
    kind: 'checkbox',
    options: ['high', 'low'],
  },
  {
    name: 'increase_position',
    label: 'Increase position',
    kind: 'checkbox',
  },
  {
    name: 'use_default',
    label: 'Use default',
    kind: 'checkbox',
  },
  {
    name: 'use_fibonacci',
    label: 'Use Fibonacci',
    kind: 'checkbox',
  },
  {
    name: 'as_select',
    label: 'As select',
    kind: 'checkbox',
  },
  {
    name: 'append',
    label: 'Cancel existing',
    kind: 'checkbox',
  },
  {
    name: 'use_special',
    label: 'Use special',
    kind: 'check_box',
  },
  {
    name: 'reduce',
    label: 'Reduce tp',
    kind: 'checkbox',
  },
  {
    name: 'leverage',
    label: 'Leverage',
    kind: 'special',
  },
  {
    name: 'min_index',
    label: 'Min index',
    kind: 'special',
  },
  {
    name: 'max_size',
    label: 'Max size',
    kind: 'special',
  },
  {
    name: 'max_index',
    label: 'Max index',
    kind: 'special',
  },
  {
    name: 'fee',
    label: 'Fee',
    kind: 'special',
  },
  {
    name: 'price_places',
    label: 'Price places',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'stop_percent',
    label: 'Stop percent',
    kind: 'special',
  },
  {
    name: 'tradeSplit',
    label: 'Trade split',
    kind: 'special',
  },
  {
    name: 'currentEntry',
    label: 'Current Entry',
    kind: 'special',
  },
  {
    name: 'currentQty',
    label: 'Current Qty',
    kind: 'special',
  },
  {
    name: 'qtyPerTrade',
    label: 'Qty per trade',
    kind: 'special',
  },
  {
    name: 'min_profit',
    label: 'Min profit',
    kind: 'special',
  },
  {
    name: 'first_order_size',
    label: 'First order',
    kind: 'special',
  },
  {
    name: 'long_zones',
    label: 'First entry',
    kind: 'special',
    valueLayout: (v: any) => v.label,
    optionLayout: (v: any) => v.value,
  },
  {
    name: 'short_zones',
    label: 'Second stop',
    kind: 'special',
    valueLayout: (v: any) => v.label,
    optionLayout: (v: any) => v.value,
  },
  {
    name: 'use_min_size',
    label: 'Use min size',
    kind: 'checkbox',
  },
  {
    name: 'increase_position',
    label: 'Increase',
    kind: 'checkbox',
  },
  {
    name: 'rr',
    label: 'R/R',
    kind: 'special',
  }
];
const config_fields: any = {
  config_1: [
    'multiplier',
    // 'budget',
    'entry',
    // 'account_length',
    'take_profit',
    'kind',
    'stop',
    'risk_reward',
    // 'modulo',
    'risk_per_trade',
    // 'percent_change',
    // 'take_profit',
    // 'leverage',
    // 'min_size',
    // 'tradeSplit',
    // 'market_type',
    'max_size',
    'zone_risk',
    'support',
    'resistance',
    'sub_accounts',
    // 'trade_place_index',
    // 'use_special',
    // 'stop_percent',
    // 'leverage',
    'trend',
    'price_places',
    // 'mode',
    // 'min_index',
    // 'max_index',
    // 'ranges',
    // 'multiplier',
    'currentEntry',
    // 'currentQty',
    'fee',
    // 'qtyPerTrade',
    'long_zones',
    'short_zones',
    // 'first_order_size',
    'min_size',
    'gap',
    // 'increase_position',
    // 'append',
    'min_profit',
    'rr',
    'use_default',
    // 'cancel',
    'use_fibonacci',
    'as_select',
    'reduce',
    'use_min_size',
    'increase_position',
  ],
};

const StopEntryStore = types
  .model('StopEntryStore', {
    entry: types.optional(types.number, 0),
    size: types.optional(types.number, 0),
    l_entry: types.optional(types.number, 0),
    l_size: types.optional(types.number, 0),
    kind: types.optional(types.string, 'long'),
    // action: types.maybe(types.enumeration(ACTION_OPTIONS)),
    take_profit: types.optional(types.number, 0),
    budget: types.optional(types.number, 300),
  })
  .views((self) => {
    return {
      liquidationAnalysis(entry?: IIncreaseTrade) {
        let empty = {
          entry: self.l_entry,
          size: self.l_size,
        };
        let non_empty = {
          entry: self.entry,
          size: self.size,
        };
        return calculate_liquidation(
          self.budget,
          self.kind === 'long' ? non_empty : empty,
          self.kind === 'short' ? non_empty : empty,
          {
            long: {
              entry: 0,
              size: 0,
            },
            short: {
              entry: entry?.avg_entry || 0,
              size: entry?.avg_size || 0,
            },
          },
        );
      },
      calculateAvg(entry?: IIncreaseTrade) {
        if (entry && self.entry && self.size) {
          let result: any = determine_average_entry_and_size([
            {
              price: self.entry,
              quantity: self.size,
            },
            {
              price: entry.avg_entry,
              quantity: entry.avg_size - self.size,
            },
          ]);

          result.pnl = to_f(
            determine_pnl(
              result.price,
              entry.stop,
              result.quantity,
              entry.kind,
            ),
            '%.2f',
          );
          return result;
        }
      },
      determineAvg(entry?: IIncreaseTrade) {
        if (entry && self.entry && self.size) {
          let _self = self as any;
          const liquidation = _self.liquidationAnalysis(entry);
          let result: any = determine_average_entry_and_size([
            {
              price: self.entry,
              quantity: self.size,
            },
            {
              price: entry.avg_entry,
              quantity: entry.avg_size - self.size,
            },
          ]);
          let pp =
            self.kind === 'long'
              ? result.price
              : to_f(liquidation[self.kind].entry, '%.2f');

          let qq =
            self.kind === 'long'
              ? result.quantity
              : to_f(liquidation[self.kind].size, '%.3f');
          result.pnl = to_f(
            determine_pnl(pp, entry.stop, qq, self.kind),
            '%.2f',
          );
          result.take_profit = entry.stop;
          let display = {
            left: [
              `Entry: ${entry?.avg_entry}`,
              `Avg. entry: ${
                self.kind === 'long'
                  ? result.price
                  : to_f(liquidation[self.kind].entry)
              }`,
              `Avg. size: ${
                self.kind === 'long'
                  ? result.quantity
                  : to_f(liquidation[self.kind].size, '%.3f')
              }`,
              `Liquidation: ${to_f(liquidation.liquidation, '%.2f')}`,
            ],
            right: [
              `Size: ${entry.avg_size}`,
              `qt: ${result.quantity}`,
              `PnL: ${result.pnl}`,
              `Take profit: ${result.take_profit}`,
            ],
          };
          return display;
        }
      },
      get config_fields() {
        return {
          config_1: [
            'entry',
            'size',
            'l_entry',
            'l_size',
            'kind',
            'budget',
            'take_profit',
          ],
        };
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let result = getParamForField(
          self,
          existingTradeConfig,
          field,
          isGroup,
        );
        return result;
      },
    };
  })
  .actions((self) => {
    function placeTrade(rowEntry: any) {
      let parent: any = getParent(self, 2);
      return parent.placeStopOrders(
        [
          {
            avg_entry: rowEntry.avg_entry,
            avg_size: rowEntry?.avg_size - self.size,
          },
        ],
        rowEntry?.kind,
        true,
      );
    }
    return {
      placeTrade,
      updateFields(obj: {
        entry?: number;
        size?: number;
        l_entry?: number;
        l_size?: number;
      }) {
        if (obj.entry) {
          if (typeof obj.entry === 'string') {
            obj.entry = parseFloat(obj.entry);
          }
        }
        if (obj.size) {
          if (typeof obj.size === 'string') {
            obj.size = parseFloat(obj.size);
          }
        }
        if (obj.l_entry) {
          if (typeof obj.l_entry === 'string') {
            obj.l_entry = parseFloat(obj.l_entry);
          }
        }
        if (obj.l_size) {
          if (typeof obj.l_size === 'string') {
            obj.l_size = parseFloat(obj.l_size);
          }
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export const FutureFormConfig = types
  .model('FutureFormConfig', {
    use_min_size: types.optional(types.boolean, false),
    strategy: types.optional(types.enumeration(['quantity', 'entry']), 'entry'),
    kind: types.optional(types.string, 'short'),
    trend: types.optional(types.string, 'long'),
    entry: types.optional(types.number, 0),
    stop: types.optional(types.number, 0),
    budget: types.optional(types.number, 5),
    modulo: types.optional(types.number, -1),
    risk_reward: types.optional(types.number, 10),
    account_length: types.optional(types.number, 10),
    take_profit: types.optional(types.number, 0),
    leverage: types.optional(types.number, 125),
    percent_change: types.optional(types.number, 0),
    re_entry: types.optional(types.string, 'entry'),
    market_type: types.optional(types.string, ''),
    mode: types.optional(types.string, 'default'),
    min_size: types.optional(types.number, 0.003),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    zone_risk: types.optional(types.number, 4),
    fee: types.optional(types.number, 0.0),
    price_places: types.optional(types.string, '%.1f'),
    // fee: types.optional(types.number, 0.06),
    focus: types.optional(types.number, 0),
    sub_accounts: types.optional(types.array(types.string), []),
    margin_sub_account: types.optional(types.string, ''),
    risk_per_trade: types.optional(types.number, 0),
    trade_place_index: types.optional(types.number, 0),
    ranges: types.optional(types.array(types.string), []),
    multiplier: types.optional(types.number, 10),
    first_order_size: types.optional(types.number, 0),
    increase_position: types.optional(types.boolean, true),
    use_default: types.optional(types.boolean, false),
    min_index: types.optional(types.number, 0),
    max_index: types.optional(types.number, 1),
    stop_percent: types.optional(types.number, 0.01),
    use_special: types.optional(types.boolean, true),
    tradeSplit: types.optional(types.number, 1),
    currentEntry: types.optional(types.number, 0),
    currentQty: types.optional(types.number, 0),
    qtyPerTrade: types.optional(types.number, 0),
    long_zones: types.optional(types.number, 0),
    cancel: types.optional(types.boolean, true),
    append: types.optional(types.boolean, false),
    short_zones: types.optional(types.number, 0),
    use_fibonacci: types.optional(types.boolean, true),
    as_select: types.optional(types.boolean, true),
    reduce: types.optional(types.boolean, true),
    accounts: types.optional(types.array(types.string), []),
    re_entry_store: types.optional(StopEntryStore, {}),
    max_size: types.optional(types.number, 0.01),
    gap: types.optional(types.number, 1),
    min_profit: types.optional(types.number, 0),
    rr: types.optional(types.number, 1),
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      get tradeValues(): number[] {
        let parent: IFutureInstance = getParent(self, 1);
        let value = parent.getTradingZones({
          trend: self.trend as any,
          kind: self.kind as any,
          use_fibonacci: self.zone_risk > 3 ? self.use_fibonacci : false,
          no_of_trades: self.zone_risk,
        });
        return value
          .map((o: any) => {
            return o.stop;
          })
          .filter((o: any) => o > self.short_zones);
      },

      getParamForField(field: string, isGroup?: string): any | any[] {
        let parent: IFutureInstance = getParent(self, 1);
        let result = getParamForField(self, configs, field, isGroup);
        if (field === 'sub_accounts') {
          result = {
            ...result,
            kind: 'select',
            options: self.accounts.map((o) => o),
          };
        }
        if (['long_zones', 'short_zones'].includes(field) && self.as_select) {
          let value = parent.getTradingZones({
            trend: self.trend as any,
            kind: self.kind as any,
            use_fibonacci: self.zone_risk > 3 ? self.use_fibonacci : false,
            no_of_trades: self.zone_risk,
          });
          result = {
            ...result,
            kind: 'select',
            options: value.map((o: any) => {
              return {
                label: field === 'long_zones' ? o.entry : o.stop,
                value: field === 'long_zones' ? o.entry : o.stop,
              };
            }),
          };
        }
        if (parent.zones.length > 0 && parent.as_select) {
          if (['entry', 'stop'].includes(field)) {
            result = { ...result, kind: 'select', options: parent.zones };
          }
          if (['resistance', 'support'].includes(field)) {
            result = {
              ...result,
              kind: 'select',
              options: parent.resistanceHigh(field as 'support' | 'resistance'),
            };
          }
        }
        return result;
      },
    };
  })
  .views(withSetPropAction)
  .actions((self) => {
    let parent: any = getParent(self, 1);
    return {
      updateFields(obj: any) {
        let zones = parent.zones;
        if (obj.entry) {
          if (typeof obj.entry === 'string') {
            obj.entry = parseFloat(obj.entry);
          }
          // let longProfit = zones.filter((o: any) => o > obj.entry);
          // if (self.kind === 'long' && longProfit.length > 0) {
          //   self.take_profit = parseFloat(longProfit[0]);
          // }
          // if (self.percent_change) {
          //   self.stop = obj.entry;
          // }
        }
        if (obj.sub_accounts) {
          if (!Array.isArray(obj.sub_accounts)) {
            obj.sub_accounts = obj.sub_accounts.split(',');
          }
        }
        if (obj.stop) {
          // obj.stop = parseFloat(obj.stop);
          // if (self.kind === 'short') {
          //   self.take_profit = obj.stop;
          // }
          // if (self.percent_change) {
          //   self.entry = obj.stop;
          // }
        }
        if (obj.modulo) {
          obj.modulo = parseInt(obj.modulo);
        }
        if (obj.support) {
          if (typeof obj.support === 'string') {
            obj.support = parseFloat(obj.support);
          }
        }
        if (obj.resistance) {
          if (typeof obj.resistance === 'string') {
            obj.resistance = parseFloat(obj.resistance);
          }
        }
        if (obj.long_zones) {
          if (typeof obj.long_zones === 'string') {
            obj.long_zones = parseFloat(obj.long_zones);
          }
          obj.entry = obj.long_zones;
          if (!obj.take_profit) {
            obj.take_profit = obj.long_zones;
          }
          if (!obj.currentEntry) {
            obj.currentEntry = obj.long_zones;
          }
        }
        if (obj.short_zones) {
          if (typeof obj.short_zones === 'string') {
            obj.short_zones = parseFloat(obj.short_zones);
          }
          obj.stop = obj.short_zones;
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export const FutureInstance = types
  .model('JobInstance', {
    config: types.optional(FutureFormConfig, {}),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    split: types.optional(types.number, 5),
    symbol: types.optional(types.string, ''),
    price_places: types.optional(types.string, '%.2f'),
    decimal_places: types.optional(types.string, '%.3f'),
    loading: types.optional(types.boolean, false),
    account: types.optional(types.string, ''),
    minimum: types.optional(types.number, 0),
    as_select: types.optional(types.boolean, false),
    positionStore: types.maybe(BarePositionStore),
    max_size: types.optional(types.number, 0),
    zone_split: types.optional(types.number, 4),
    profit_quote_price: types.optional(types.number, 1000),
    profit_base_price: types.optional(types.number, 100000),
    // accounts: types.optional(types.array(types.string), []),
  })
  .views((self) => ({
    resistanceHigh(field: 'support' | 'resistance') {
      const resistance = new Array(self.split).fill(1).map((j, i) => {
        return (
          self.resistance * Math.pow(1 + self.config.percent_change, i * 1)
        );
      });
      const support = new Array(self.split).fill(1).map((j, i) => {
        return (
          self.resistance * Math.pow(1 + self.config.percent_change, i * -1)
        );
      });
      support.sort((a, b) => a - b);
      return field === 'resistance' ? [...support, ...resistance] : support;
    },
    get zones() {
      const support = self.config.support;
      const resistance = self.config.resistance;
      if (support && resistance && self.config.percent_change) {
        let lower = new Array(self.split).fill(1).map((j, i) => {
          return support * Math.pow(1 + self.config.percent_change, -i);
        });
        let higher = new Array(self.split).fill(1).map((_, i) => {
          return support * Math.pow(1 + self.config.percent_change, i);
        });
        let result = [...new Set([...lower, ...higher])].sort();
        return result.filter((u) => u < resistance);
      }
      return [];
    },
    determineMaximumSize(price: number) {
      return pnl.determine_position_size(
        price,
        self.config.kind === 'short'
          ? self.config.resistance
          : self.config.support,
        self.config.budget,
      );
    },
    buildHedgeOrders({
      current_price,
      kind,
      orders = [],
      positions = {
        long: {},
        short: {},
      },
      raw_instance = false,
    }: {
      raw_instance?: boolean;
      current_price: number;
      kind: 'long' | 'short';
      orders?: any[];
      positions?: {
        long: any;
        short: any;
      };
    }) {
      const sample_config = {
        support: self.config.support,
        resistance: self.config.resistance,
        budget: self.config.budget,
        split: self.config.tradeSplit,
        risk: self.config.risk_per_trade,
        max_size: self.max_size,
        position: {
          entry: self.config.currentEntry,
          quantity: self.config.currentQty,
        },
      };
      const instance = new HedgeBot({
        config: sample_config,
        orders,
        positions,
        current_price,
      });
      if (raw_instance) {
        return instance;
      }
      const last_price = current_price;
      if (last_price) {
        const _orders = instance.cleaned_orders({
          last_price,
          as_list: true,
        }) as FinalOrderType[];
        return _orders.filter((o) => o.kind === kind);
      }
      return [];
    },
    buildConfig(params: shared.ExtendConfigType) {
      console.log('params', params);
      return shared.buildConfig(self.config as shared.AppConfig, {
        ...params,
        price_places: params.price_places || self.price_places,
        gap: params.gap || self.config.gap,
        decimal_places: self.decimal_places,
        rr: self.config.rr,
      });
    },
    getTradingZones({
      kind,
      trend,
      entry,
      stop,
      no_of_trades,
      use_fibonacci,
    }: {
      use_fibonacci?: boolean;
      kind: 'long' | 'short';
      trend?: 'long' | 'short';
      entry?: number;
      stop?: number;
      no_of_trades?: number;
    }) {
      let _entry =
        trend === 'long' ? self.config.resistance : self.config.support;
      let _stop =
        trend === 'long' ? self.config.support : self.config.resistance;
      if (self.config.use_fibonacci && self.config.zone_risk > 3) {
        let r = fibonacci_analysis({
          support: self.config.support,
          resistance: self.config.resistance,
          kind: kind,
          trend: trend,
          places: self.price_places,
        });
        return r
          .map((o, i) => {
            if (kind === 'long') {
              return {
                stop: o,
                entry: r[i + 1],
              };
            }
            return {
              entry: o,
              stop: r[i - 1],
            };
          })
          .filter((o) => o.entry && o.stop);
      }
      const result = this.buildConfig({
        entry: entry || _entry,
        stop: stop || _stop,
        kind: trend,
        no_of_trades: no_of_trades || self.config.zone_risk,
      });
      return result.map((o: any) => {
        if (kind === 'long') {
          return {
            entry: o.entry,
            stop: o.stop,
          };
        }
        return {
          entry: o.stop,
          stop: o.entry,
        };
      });
      // console.log("results",result)
    },

    activeTradingZone(currentPrice: number) {
      let kind = self.config.kind as 'long' | 'short';
      const zones = this.getTradingZones({ kind });
      const ranges = zones.filter((r: any) => {
        if (kind === 'long') {
          return r.stop < currentPrice;
        }
        return r.stop > currentPrice;
      });
      if (ranges[0]) {
        const maxEntry =
          kind === 'long'
            ? Math.max(...ranges.map((o: any) => o.entry))
            : Math.min(...ranges.map((o: any) => o.entry));
        const minStop =
          kind === 'long'
            ? Math.min(...ranges.map((o: any) => o.stop))
            : Math.max(...ranges.map((o: any) => o.stop));
        return {
          entry: ranges[0].entry,
          stop: ranges[0].stop,
          maxEntry,
          minStop,
        };
      }
      return null;
    },
    buildKindOrders({ kind }: { kind: 'long' | 'short' }) {
      if (!self.config.risk_per_trade) {
        return [];
      }
      let entry =
        kind === 'long' ? self.config.resistance : self.config.support;
      let stop = kind === 'long' ? self.config.support : self.config.resistance;
      const result = this.buildConfig({
        entry,
        stop,
        kind,
        increase: self.config.increase_position,
      });
      return result;
      const _self = this as any;
      return _self.update_open_prices({ trades: result, kind });
    },
    buildFullOrders({
      entry,
      kind,
      opposite,
      take_profit,
      raw_instance,
      newStop,
      risk_reward,
    }: {
      take_profit?: number;
      entry: number;
      kind?: 'long' | 'short';
      opposite?: boolean;
      raw_instance?: boolean;
      risk_reward?: number;
      newStop?: number;
    }) {
      const stop = newStop ? newStop : self.config.stop;
      if (!raw_instance) {
        const condition = entry && (stop || self.config.stop_percent);
        if (!condition) {
          return [];
        }
        if (stop) {
          if (entry.toString().length !== stop.toString().length) {
            if (
              entry === self.config.entry &&
              !['main'].includes(self.config.mode)
            ) {
            }
            // if(entry.toString().length)
            // return [];
          }
        }
      }
      const result = this.buildConfig({
        take_profit,
        entry,
        kind,
        stop: stop,
        raw_instance,
        risk_reward,
        gap: self.config.gap,
        increase: self.config.increase_position,
      });
      return result;
    },
    get hedge_full_orders() {
      let { entry, mode, kind, currentEntry, currentQty } = self.config;
      let positions: any = { long: {}, short: {} };
      if (currentEntry && currentQty) {
        positions[kind] = {
          entryPrice: currentEntry,
          positionAmt: currentQty,
        };
      }
      let result = this.buildHedgeOrders({
        current_price: entry,
        kind: kind as any,
        positions,
        raw_instance: true,
      }) as HedgeBot;
      let values = result.cleaned_orders({
        last_price: entry,
        as_list: true,
      }) as FinalOrderType[];
      if (mode === 'controlled') {
        const signal = this.buildFullOrders({
          raw_instance: true,
          entry,
        });
        const control = new ControlledStrategy({
          hedge: result,
          signal,
          trade_size: self.config.qtyPerTrade,
        });
        const allOrders = control.build_side_entries({
          quantity: self.config.qtyPerTrade,
          kind: kind as any,
          last_price: entry,
        });
        let reverse = kind === 'long' ? 'short' : 'long';
        return allOrders.map((r) => {
          let reverse_trade = r[reverse] as any;
          let main = r[kind] as any;
          return {
            entry: r.current_price,
            avg_entry: r.avg.entry_price,
            avg_qty: r.avg.quantity,
            liquidation: reverse_trade.take_profit,
            opposite_qty: reverse_trade.quantity,
            loss_pnl: r.pnl,
            sell_amount: main.quantity,
          };
        });
      }
      if (mode === 're_entry') {
        let _value = result.build_entry_for_all_zones();
        values =
          kind === 'long'
            ? _value.long
            : // .filter(
              //     (r) => r.price <= entry
              //     // && r.price > result.long_stop,
              //   )
              _value.short;
        // .filter(
        //     (r) => r.price >= entry
        //     // && r.price < result.short_stop,
        //   );
      }
      const _values = values
        .filter((r) => r.kind === kind)
        .map((o) => {
          const _result = {
            ...o,
            entry: o.price,
            stop:
              self.config.kind === 'long'
                ? o.price < result.config.middle
                  ? result.config.support
                  : result.config.middle
                : o.price < result.config.middle
                ? result.config.middle
                : result.config.resistance,
          };

          return _result;
        });
      const risk_longs = _values.filter(
        (r) => r.kind === 'long' && r.stop === result.config.support,
      );
      const other_longs = _values.filter(
        (r) => r.kind === 'long' && r.stop === result.config.middle,
      );
      const risk_shorts = _values.filter(
        (r) => r.kind === 'short' && r.stop === result.config.resistance,
      );
      const other_shorts = _values.filter(
        (r) => r.kind === 'short' && r.stop === result.config.middle,
      );

      return _values
        .filter((r) => r.kind === kind)
        .map((r) => ({
          ...r,
          incurred: to_f(
            determine_pnl(r.entry, r.stop, r.quantity, self.config.kind),
            '%.2f',
          ),
        }));
    },
    get full_orders() {
      let entry = self.config.entry;
      if (self.config.kind === 'short') {
        // entry =
        //   self.config.entry * Math.pow(1 + self.config.percent_change, -1);
      }
      let risk_reward = undefined;
      if (self.config.use_default) {
        const first_pass = this.buildFullOrders({
          entry,
          kind: self.config.kind as any,
        });
        if (first_pass.length > 0) {
          entry = first_pass.at(-1).entry;
          risk_reward = first_pass.length;
          console.log('length', first_pass.length, 'entry', entry);
        }
      }
      const result = this.buildFullOrders({
        entry,
        kind: self.config.kind as any,
        risk_reward,
      });
      return result;
    },
    get full_opposite_orders() {
      let entry = self.config.entry;
      if (self.config.kind === 'short') {
        entry =
          self.config.entry * Math.pow(1 + self.config.percent_change, -1);
      }
      return this.buildFullOrders({ entry, opposite: true });
    },
    get trades_with_avg() {
      let _self = this as any;
      return _self.trades
        // .update_open_prices({
        //   trades: _self.trades,
        //   kind: self.config.kind,
        // })
        .filter((o: any) => (self.config.currentEntry ? o.avg_size : true));
    },
    buildTrades({
      currentPrice,
      _kind,
      take_profit,
      newStop,
    }: {
      currentPrice: number;
      _kind?: 'long' | 'short';
      take_profit?: number;
      newStop?: number;
    }) {
      const kind = _kind || (self.config.kind === 'long' ? 'short' : 'long');
      return (
        this.buildFullOrders({
          entry: currentPrice,
          kind,
          take_profit,
          newStop,
        }) || []
      );
    },
    get range_orders() {
      const high = self.config.entry * (1 + self.config.percent_change);
      const low =
        self.config.entry * Math.pow(1 + self.config.percent_change, -1);
      return {
        high: this.buildFullOrders({ entry: high }),
        low: this.buildFullOrders({ entry: low }),
      };
    },
  }))
  .views((self) => {
    return {
      get signal_orders() {
        const result = self.full_orders;
        // if (self.config.trade_place_index !== 0 && result) {
        //   let index = result.length - Math.abs(self.config.trade_place_index);
        //   let middle = result?.slice(0, index);
        //   if (self.config.trade_place_index < 0) {
        //     middle = result?.slice(-index);
        //   }
        //   let high: any = [];
        //   let low: any = [];
        //   if (self.config.ranges.includes('high')) {
        //     high =
        //       self.range_orders.high?.slice(
        //         -middle.length * self.config.multiplier,
        //       ) || [];
        //   }
        //   if (self.config.ranges.includes('low')) {
        //     // low = self.range_orders.low
        //     low = self.range_orders.low?.slice(
        //       -middle.length * self.config.multiplier,
        //     );
        //   }
        //   if (self.config.kind === 'long') {
        //     return low.concat(middle);
        //   }
        //   return high.concat(middle);
        // }
        return result;
      },

      submitValues() {
        const result: any = {
          accounts: this.accounts,
          budget: self.config.budget,
          take_profit: self.config.take_profit,
          stop: self.config.stop,
          entry: self.config.entry,
          risk_reward: self.config.risk_reward,
          kind: self.config.kind,
          symbol: self.symbol,
          modulo: self.config.modulo,
          owner: self.account,
          min_size: self.config.min_size,
          quantity: parseFloat(this.positionSize()),
          percent_change: self.config.percent_change,
          re_entry: self.config.re_entry,
          market_type: self.config.market_type,
          support: self.config.support,
          resistance: self.config.resistance,
        };
        return result;
      },
      canPlaceTrade(text: 'margin' | 'future' | 'spot') {
        if (['margin', 'spot'].includes(text)) {
          return Boolean(
            self.account &&
              self.config.budget &&
              self.config.stop &&
              self.config.entry &&
              self.symbol,
          );
        }
        return Boolean(
          self.account &&
            self.config.budget &&
            self.config.stop &&
            self.config.entry &&
            self.config.kind &&
            self.config.percent_change,
          // self.config.risk_reward &&
          // self.config.modulo > -1,
        );
      },
      get accounts() {
        return new Array(self.config.account_length).fill(0).map((u, i) => ({
          owner: i,
          percent: 1 / self.config.account_length,
        }));
      },
      get p_places() {
        return to_f(self.price_places);
      },
      get d_places() {
        return to_f(self.decimal_places);
      },
      get fixed() {
        let pp = self.price_places.replace('%.', '').replace('f', '');
        return parseInt(pp, 10);
      },
      get total_size_from_entry() {
        const longs = (this.signal_orders || [])
          .filter((r: any) => r.entry >= self.config.currentEntry)
          .map((o: any) => o.quantity)
          .reduce((a: any, b: any) => a + b, 0);
        const shorts = (this.signal_orders || [])
          .filter((r: any) => r.entry <= self.config.currentEntry)
          .map((o: any) => o.quantity)
          .reduce((a: any, b: any) => a + b, 0);
        return self.config.kind === 'long'
          ? to_f(longs, '%.3f')
          : to_f(shorts, '%.3f');
      },
      get quantity_to_sell() {
        let kind = self.config.kind;
        let entry = self.config.currentEntry;
        let pnl = self.config.budget;
        let quantity = self.config.currentQty;
        let sell_price = self.config.take_profit;

        return (
          determine_amount_to_sell(
            entry,
            quantity,
            sell_price,
            pnl,
            kind as any,
          ) || 0
        );
      },

      get trades() {
        return this.signal_orders || [];
        return (this.signal_orders || []).map((x: any) => {
          return {
            risk: x.risk,
            fee: x.fee,
            sell_price: x.sell_price,
            net: x.net,
            incurred: x.incurred,
            entry: x.entry,
            stop: x.stop,
            pnl: x.pnl,
            new_stop: x.new_stop,
            entry_loss: x.entry_loss,
            take_profit: x.sell_price,
            quantity: self.config.use_min_size
              ? self.config.min_size
              : x.quantity,
            risk_sell: x.risk_sell,
          };
        });
      },
      positionSize(_trades?: any) {
        const _self = this;
        let kind = self.config.kind;
        let percent_change = self.config.percent_change;
        let entry = self.config.entry;
        let stop_loss =
          kind === 'long'
            ? entry * (1 + percent_change)
            : entry * Math.pow(1 + percent_change, -1);
        const trades = _trades || self.full_orders;
        let risk = trades
          ?.map((o: any) => o.risk)
          .reduce((a: any, b: any) => a + b, 0);
        let total_fees = trades
          ?.map((o: any) => o.fee)
          .reduce((a: any, b: any) => a + b, 0);
        let size = pnl.determine_position_size(
          entry,
          stop_loss,
          risk + total_fees,
        );
        return size.toFixed(_self.d_places);
      },
      buildMarginPositionSize(trades?: any) {
        const _self = this;
        const _trades = trades || _self.trades;
        let total_fees = _trades
          .map((o: any) => o.fee)
          .reduce((a: number, b: number) => a + b, 0);

        let total_risk =
          _trades
            .map((o: any) => o.risk)
            .reduce((a: number, b: number) => a + b, 0) + total_fees;
        total_risk = total_risk * (1 + 0.2 / 100);
        let margin_fee = total_risk * (0.2 / 100); // spot fees addition
        const _entry = _trades[_trades.length - 1]?.entry || 0;
        const _close = _trades[0]?.stop || 0;
        const margin_size = _self?.marginPositionSize({
          risk: total_risk + margin_fee,
          entry: _entry,
          stop_loss: _close,
          as_number: true,
        }) as any;
        return margin_size;
      },
      marginPositionSize({
        risk,
        entry,
        stop_loss,
        as_number,
      }: {
        entry: number;
        stop_loss: number;
        risk: number;
        as_number?: boolean;
      }) {
        const _self = this;
        if (entry && stop_loss && risk) {
          let size = determine_position_size(entry, stop_loss, risk);
          if (as_number) {
            return size;
          }
          return size.toFixed(_self.d_places);
        }
      },
      update_open_prices({
        trades,
        kind,
        currentEntry,
        take_profit,
      }: {
        take_profit?: number;
        currentEntry?: number;
        trades: any[];
        kind: any;
      }) {
        return computeTotalAverageForEachTrade(trades, {
          ...(self.config as any).toJSON(),
          kind,
          take_profit: take_profit || self.config.take_profit,
          currentEntry: currentEntry || self.config.currentEntry,
        });
      },
      buildAvg({ _trades, kind }: { _trades: any[]; kind: 'long' | 'short' }) {
        let avg: any = determine_average_entry_and_size(
          _trades?.map((r: any) => ({
            price: r.entry,
            quantity: r.quantity,
          })) || [],
        );
        const stop_prices = _trades.map((o) => o.stop);
        const stop_loss =
          kind === 'long' ? Math.min(...stop_prices) : Math.max(...stop_prices);
        avg.pnl = to_f(
          pnl.determine_pnl(avg.price, stop_loss, avg.quantity, kind),
          '%.2f',
        );
        avg.entry_loss = to_f(
          _trades?.reduce((a: any, b: any) => a + b.entry_loss, 0),
          '%.2f',
        );
        avg.x_fee = _trades?.reduce((a: any, b: any) => a + b.x_fee, 0);
        return avg;
      },
      determine_avg(kind: 'long' | 'short') {
        const _trades = self.buildKindOrders({ kind });
        let avg = this.buildAvg({ _trades, kind });
        // let avg: any = determine_average_entry_and_size(
        //   _trades?.map((r: any) => ({
        //     price: r.entry,
        //     quantity: r.quantity,
        //   })) || [],
        // );
        // const stop =
        //   kind === 'long' ? self.config.support : self.config.resistance;
        // const stop_prices = _trades.map((o) => o.stop);
        // const stop_loss =
        //   kind === 'long' ? Math.min(...stop_prices) : Math.max(...stop_prices);
        // avg.pnl = pnl.determine_pnl(avg.price, stop_loss, avg.quantity, kind);
        const avgEntryAndSize = self.config.increase_position ? avg : null;
        return {
          heading: 'Avg entry',
          value: `Entry: ${avgEntryAndSize?.price} Size: ${avgEntryAndSize?.quantity} Loss: ${avg.pnl}`,
        };
      },
      labels(trades?: any[], bare?: boolean, _kind?: string) {
        const _self = this;
        const _trades = trades || _self.trades;
        let total_fees = _trades
          .map((o: any) => o.fee)
          .reduce((a: number, b: number) => a + b, 0);

        let total_risk =
          _trades
            .map((o: any) => o.risk)
            .reduce((a: number, b: number) => a + b, 0) + total_fees;
        total_risk = total_risk * (1 + 0.2 / 100);
        let margin_fee = total_risk * (0.2 / 100); // spot fees addition
        const _entry = _trades[_trades.length - 1]?.entry || 0;
        const _close = _trades[0]?.stop || 0;
        const margin_size = _self.buildMarginPositionSize(_trades) as any;
        let kind = self.config.kind === 'long' ? 'short' : 'long';
        const entry_loss = to_f(
          _trades?.reduce((a: any, b: any) => a + b.entry_loss, 0),
          '%.2f',
        );
        const x_fee = _trades?.reduce((a: any, b: any) => a + b.x_fee, 0);

        if (trades?.length) {
          kind = self.config.kind;
        }
        if (_kind) {
          kind = _kind === 'long' ? 'short' : 'long';
        }
        const my_pnl = determine_pnl(_entry, _close, margin_size, kind) || 0;
        //console.log('my_pnl', my_pnl, _entry, _close, margin_size, kind);
        if (bare) {
          return [
            {
              heading: 'Total Risk',
              value: {
                risk: total_risk + margin_fee,
                size: parseFloat(margin_size.toFixed(_self.d_places)),
                entry: parseFloat(_entry.toFixed(_self.p_places)),
                take_profit: parseFloat(_close.toFixed(_self.p_places)),
              },
            },
          ];
        }
        let avg: any = determine_average_entry_and_size(
          _trades.map((r: any) => ({
            price: r.entry,
            quantity: r.quantity,
          })),
        );
        const stop_prices = _trades.map((o: any) => o.stop);
        const stop_loss =
          self.config.kind === 'long'
            ? Math.min(...stop_prices)
            : Math.max(...stop_prices);
        if (stop_loss) {
          avg.pnl = determine_pnl(
            avg.price,
            stop_loss,
            avg.quantity,
            self.config.kind as any,
          );
        }
        const avgEntryAndSize = self.config.increase_position ? avg : null;

        function determine_hedge_trade(value: string) {
          const getEntry = self.config.stop;
          const previousStop = self.config.stop;
          const getBudget = self.config.risk_per_trade * (self.config.fee + 1);
          const getStop = _entry;
          const resistance = self.config.resistance;
          console.log('entry', getEntry);
          console.log('stop', getStop);
          console.log('budget', getBudget);
          console.log('entry to use', self.config.entry);
          console.log('previous Stop', self.config.stop);
          let quantity = pnl.determine_position_size(
            getEntry,
            30680,
            getBudget,
          );
          let risk = pnl.determine_risk(
            getEntry,
            resistance,
            pnl.to_f(quantity, '%.3f'),
          );
          if (value === 'quantity') {
            return quantity.toFixed(3);
          }
          if (value === 'risk') {
            return risk.toFixed(3);
          }
          if (value === 'profit') {
            return getBudget;
          }
        }

        let values = [
          {
            heading: 'Total Risk',
            value: `Risk: ${total_risk.toFixed(3)} Fee: ${margin_fee.toFixed(
              3,
            )} Total: ${(margin_fee + total_risk).toFixed(3)}`,
          },
          // {
          //   heading: 'Hedge Trade',
          //   value: `Entry: ${self.config.stop.toFixed(2)}
          //   \nQuantity: ${determine_hedge_trade('quantity')}
          //   \nRisk: ${determine_hedge_trade('risk')},
          //   \nProfit: ${determine_hedge_trade('profit')},
          //   \nnewValue:${_newValues}`,
          // },
          {
            heading: 'Margin sell',
            value: `Entry: ${_entry.toFixed(
              _self.p_places,
            )}\nKind: ${kind}\nClose: ${_close.toFixed(_self.p_places)}`,
            // value: _trades
            //   .map((o: any) => o.margin)
            //   .reduce((a: number, b: number) => a + b, 0)
            //   .toFixed(_self.p_places),
          },
          {
            heading: 'kind',
            value: kind,
            // value: _self.positionSize(_trades),
          },
          {
            heading: 'M. position size',
            value: margin_size?.toFixed(_self.d_places),
            isClickable: true,
          },
          {
            heading: 'F margin',
            value: (
              _trades
                .map((o: any) => o.entry * o.quantity)
                .reduce((a: number, b: number) => a + b, 0) /
              self.config.leverage
            ).toFixed(_self.p_places),
          },
        ];
        if (avgEntryAndSize) {
          values.push({
            heading: 'Avg entry',
            value: `Entry: ${avgEntryAndSize.price} Size: ${
              avgEntryAndSize.quantity
            } Loss: ${to_f(avg.pnl, '%.2f')} 
            \nFees: ${to_f(total_fees, '%.2f')}\n
            \nE.loss: ${entry_loss}\n
            X.fees: ${to_f(x_fee, '%.2f')}`,
          });
        }
        return values;
      },
    };
  })
  .views(withSetPropAction)
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const updateRiskValue = function (margin_size: number, _trades: any) {
      // self.config.risk = value;
      const trades = _trades ? self.trades : _trades;
      const _entry = trades[trades.length - 1]?.entry || 0;
      const _close = trades[0]?.stop || 0;
      const mainRisk = self.buildMarginPositionSize();
      const currentRisk = _trades
        ? self.buildMarginPositionSize(_trades)
        : mainRisk;
      let risk = pnl.determine_risk(_entry, _close, currentRisk);
      const total_fee = trades.reduce((a: any, b: any) => a + b.fee, 0);
      risk = risk - total_fee;
      self.config.updateFields({
        risk_per_trade: risk / trades.length,
      });
    };
    // const changeConfig({
    //   stop,
    //   _kind = self.config.kind, // Use the current value of kind if _kind is not provided
    //   entry = self.config.entry, // Use the current value of entry if entry is not provided
    // }: {
    //   spot: number;
    //   _kind?: 'long' | 'short';
    //   entry?: number;
    // }) {
    //   self.config.stop = stop;
    //   self.config.kind = _kind;
    //   self.config.entry = entry;
    // },

    const changeConfig = flow(function* changeConfig(stop, _kind?, entry?) {
      self.config.stop = stop;
      self.config.stop = stop;
      self.config.kind = _kind;
      self.config.entry = entry;
    });
    const placeStopOrders = flow(function* placeStopOrders(
      trades?: any,
      _kind?: any,
      ignore?: boolean,
      minimum?: boolean,
    ) {
      let orders = trades || self.signal_orders || [];
      let kind = self.config.kind as 'long' | 'short';
      if (trades) {
        kind = kind === 'long' ? 'short' : 'long';
      }
      if (_kind) {
        kind = _kind;
      }
      if (self.config.currentEntry && !ignore) {
        orders = self
          .update_open_prices({
            trades: orders,
            kind,
          })
          .filter((o) => o.avg_size);
      }
      try {
        let close_price = orders[0].avg_entry;
        let spread = 1.00001;
        if (kind === 'long') {
          spread = Math.pow(spread, -1);
        }
        yield adapter.placeStopOrders({
          orders: [
            {
              price: close_price,
              quantity: minimum ? self.config.min_size : orders[0].avg_size,
              kind: kind,
              side: kind === 'long' ? 'buy' : 'sell',
              stop: pnl.to_f(close_price * spread, self.config.price_places),
            },
          ],
          symbol: self.symbol,
          owner: self.config.sub_accounts[0],
        });
      } catch (error) {
        throw error;
      }
    });
    const placeSignalOrder = flow(function* placeSignalOrder(
      trades?: any,
      _kind?: any,
    ) {
      let orders = trades || self.signal_orders || [];
      let kind = self.config.kind as 'long' | 'short';
      if (trades) {
        kind = kind === 'long' ? 'short' : 'long';
      }
      if (_kind) {
        kind = _kind;
      }
      if (self.config.currentEntry) {
        orders = self
          .update_open_prices({
            trades: orders,
            kind,
          })
          .filter((o) => o.avg_size);
        if (orders.length > 0) {
          if (!self.config.currentQty) {
            orders[orders.length - 1].quantity =
              orders[orders.length - 1].avg_size;
          }
        }
      }
      console.log('orders', orders);
      try {
        yield adapter.placeSignalTrade({
          orders,
          symbol: self.symbol,
          kind,
          cancel: self.config.append,
          // append: self.config.append,
          sub_accounts: [...self.config.sub_accounts],
        });
        console.log('placed signal order');
      } catch (error) {
        throw error;
      }
    });
    const cancelSignalOrder = flow(function* cancelSignalOrder(
      trades?: any,
      _kind?: any,
    ) {
      const orders = trades || self.signal_orders || [];
      let kind = self.config.kind as 'long' | 'short';
      if (trades) {
        kind = kind === 'long' ? 'short' : 'long';
      }
      if (_kind) {
        kind = _kind;
      }
      try {
        yield adapter.placeSignalTrade({
          orders,
          symbol: self.symbol,
          kind,
          cancel: true,
          sub_accounts: [...self.config.sub_accounts],
        });
      } catch (error) {
        throw error;
      }
    });
    const updateTakeProfit = flow(function* updateTakeProfit(
      trades?: any,
      _kind?: any,
    ) {
      const orders = trades || self.signal_orders || [];
      let kind = self.config.kind as 'long' | 'short';
      if (trades) {
        kind = kind === 'long' ? 'short' : 'long';
      }
      if (_kind) {
        kind = _kind;
      }

      try {
        yield adapter.updateClosePrices({
          symbol: self.symbol,
          kind,
          orders,
          sub_accounts: [...self.config.sub_accounts],
          reduce: self.config.reduce,
        });
      } catch (error) {
        throw error;
      }
    });
    const placeMarginSignalOrder = flow(function* placeMarginSignalOrder(
      trades?: any,
      _kind?: any,
    ) {
      const { entry, stop, qtyPerTrade, kind, margin_sub_account } =
        self.config;
      try {
        yield adapter.placeMarginSignalTrade({
          size: qtyPerTrade,
          symbol: self.symbol,
          entry: entry,
          stop,
          kind: kind as any,
          cancel: true,
          owner: margin_sub_account,
        });
      } catch (error) {
        throw error;
      }
    });
    const reducePositionTrade = flow(function* reducePositionTrade(
      _kind?: any,
    ) {
      const { kind } = self.config;

      try {
        yield adapter.reducePositionTrade({
          symbol: self.symbol,
          kind: kind as any,
          owner: self.config.sub_accounts[0],
        });
      } catch (error) {
        throw error;
      }
    });
    // const placeMarginSignalOrder = flow(function* placeMarginSignalOrder(
    //   trades?: any,
    //   _kind?: any,
    // ) {
    //   const value = self.labels(trades, true);
    //   if (value.length > 0) {
    //     const { risk, size, entry, take_profit } = value[0].value;
    //     let kind = (self.config.kind === 'long' ? 'short' : 'long') as any;
    //     let _entry = entry;
    //     if (trades) {
    //       kind = self.config.kind === 'long' ? 'long' : 'short';
    //     }
    //     // let stop = self.signal_orders[0].sell_price;
    //     const _trades = self.full_opposite_orders;
    //     let stop = _trades[0].stop;
    //     if (trades) {
    //       stop = trades[0].sell_price;
    //     }
    //     if (_kind) {
    //       kind = _kind === 'long' ? 'short' : 'long';
    //     }
    //     if (!trades) {
    //       _entry = stop;
    //       stop = entry;
    //     } else {
    //       _entry = stop;
    //       stop = null;
    //     }
    //     try {
    //       yield adapter.placeMarginSignalTrade({
    //         size,
    //         symbol: self.symbol,
    //         entry: _entry,
    //         stop,
    //         kind,
    //         cancel: false,
    //       });
    //     } catch (error) {
    //       throw error;
    //     }
    //   }
    // });

    const updateMarginTakeProfit = flow(function* updateMarginTakeProfit(
      trades?: any,
      _kind?: any,
    ) {
      const value = self.labels(trades, true);
      const orders = trades || self.signal_orders || [];
      if (value.length > 0) {
        const { risk, size, entry, take_profit } = value[0].value;
        let kind = self.config.kind as any;
        if (trades) {
          kind = self.config.kind === 'long' ? 'short' : 'long';
        }
        if (_kind) {
          kind = _kind;
        }
        const positionMargin = entry * size;
        const lossMargin = size * orders[0].stop;
        try {
          yield adapter.updateMarginClosePrices({
            size,
            symbol: self.symbol,
            take_profit,
            kind,
          });
        } catch (error) {
          throw error;
        }
      }
    });

    const placeSingleOrder = flow(function* placeSingleOrder(
      trade: any,
      kind: any,
    ) {
      try {
        yield placeSignalOrder([trade], kind);
      } catch (error) {
        throw error;
      }
    });

    const placeOrder = flow(function* placeOrder(
      kind: any = 'future',
      trades?: any,
      _kind?: any,
    ) {
      try {
        if (kind === 'place_stop_order') {
          yield placeStopOrders(trades, _kind);
          return;
        }
        if (kind === 'place_minimum_stop_order') {
          yield placeStopOrders(trades, _kind, false, true);
          return;
        }
        if (kind === 'place_signal') {
          yield placeSignalOrder(trades, _kind);
          return;
        } else if (kind === 'cancel_signal') {
          yield cancelSignalOrder(trades, _kind);
          return;
        } else if (kind === 'update_signal') {
          yield updateTakeProfit(trades, _kind);
          return;
        } else if (kind === 'place_margin_signal') {
          yield placeMarginSignalOrder(trades, _kind);
          return;
        } else if (kind === 'reduce_position') {
          yield reducePositionTrade(_kind);
          return;
        } else if (kind === 'cancel_margin_signal') {
        } else if (kind === 'update_margin_signal') {
          yield updateMarginTakeProfit(trades, _kind);
          return;
        } else {
          yield adapter.placeFutureOrder(self.submitValues(), kind);
        }
      } catch (error) {
        throw error;
      }
    });
    const createSingleOrder = flow(function* createSingleOrder(trade: ITrade) {
      try {
        yield adapter.createSingleControlledOrder({
          ...trade,
          symbol: self.symbol,
          kind: self.config.kind,
          owner: self.account,
        });
      } catch (error) {
        throw error;
      }
    });
    const analyzePosition = flow(function* analyzePosition() {
      try {
        let result = yield adapter.analyzePosition({
          owner: self.account,
          symbol: self.symbol,
          kind: self.config.kind,
        });
        return result;
      } catch (error) {
        throw error;
      }
    });
    const switchModeToDefault = function (trade: any) {
      self.config.updateFields({
        mode: 'default',
        entry: trade.entry,
        stop: trade.liquidation,
        take_profit: trade.avg_entry,
      });
    };
    const switchModeToStrategy = function (trade: any) {
      self.config.updateFields({
        mode: 'strategy',
        entry:
          self.config.kind === 'long'
            ? self.config.resistance
            : self.config.support,
        stop:
          self.config.kind === 'long'
            ? self.config.support
            : self.config.resistance,
        take_profit: trade.avg_entry,
      });
    };
    const determineOptimumRiskReward = flow(function* (
      criterion = 'quantity',
      update = false,
    ) {
      if (criterion === 'stop' && self.config.kind === 'short') {
        const result = yield adapter.getOptimumStop(
          {
            ...(self.config as any).toJSON(),
            strategy: 'entry',
            // as_array: true,
          },
          self.config.tradeValues.at(-1) as any,
          25,
        );
        if (update && result) {
          self.config.updateFields({
            stop: result.value,
            risk_reward: result.risk_reward,
            short_zones: result.value,
            as_select: false,
          });
        }
      } else {
        const { result } = yield adapter.getOptimumReward({
          ...(self.config as any).toJSON(),
          strategy: criterion,
          increase: self.config.increase_position,
          gap: self.config.gap,
          decimal_places: self.decimal_places,
          // as_array: true,
        });
        if (update) {
          self.config.updateFields({
            risk_reward: result,
          });
        }
        return result;
      }
    });
    const deleteTradingSection = flow(function* deleteConfig(payload: {
      entry: number;
      stop: number;
      kind: string;
    }) {
      try {
        const result = yield adapter.businessLogicActions({
          action: 'b_delete_trade',
          owner: self.config.sub_accounts[0],
          symbol: self.symbol,
          data: {
            entry: payload.entry,
            stop: payload.stop,
            kind: payload.kind,
          },
        });
        return result;
      } catch (error) {
        throw error;
      }
    });
    const saveTradesSection = flow(function* saveConfig() {
      const trades = self.trades_with_avg;
      console.log('trades', trades);
      try {
        const result = yield adapter.businessLogicActions({
          action: 'b_save_trades',
          owner: self.config.sub_accounts[0],
          symbol: self.symbol,
          data: {
            entry: self.config.entry,
            stop: self.config.stop,
            kind: self.config.kind,
            size: self.config.max_size,
            risk_reward: self.config.risk_reward,
            risk_per_trade: self.config.risk_per_trade,
            trades: [],
            // trades: self.trades_with_avg,
          },
        });
        return result;
      } catch (error) {
        throw error;
      }
    });
    const generateRemoteConfig = flow(function* generateRemoteConfig() {
      try {
        const result = yield adapter.businessLogicActions({
          action: 'b_get_trades',
          owner: self.config.sub_accounts[0],
          symbol: self.symbol,
          config_owner: self.account,
        });
        const combined = result.long.concat(result.short);
        const trades = combined || [];
        const found = trades
          .filter((o: any) => {
            const entry = Math.max(o.entry, o.stop);
            const stop = Math.min(o.entry, o.stop);
            if (self.config.kind === 'long') {
              return entry <= self.config.entry;
            }
            // return entry >= self.config.entry;
            return stop - 0.00098 <= self.config.entry;
          })
          .sort((a: any, b: any) => {
            return a.entry - b.entry;
            // return b.entry - a.entry
          });
        console.log('found', found);
        if (found.length > 0) {
          const item = found.at(-1);
          // const item = self.config.kind === 'long' ? found.at(-1) : found[0];
          // let support = self.config.support;
          // let _min = Math.min(item.entry, item.stop);
          // if (support > _min) {
          //   support = _min;
          // }
          const entry = Math.max(item.entry, item.stop);
          const stop = Math.min(item.entry, item.stop);
          const r_entry = self.config.kind === 'long' ? entry : stop;
          const r_stop = self.config.kind === 'short' ? entry : stop;
          self.config.updateFields({
            entry: r_entry,
            stop: r_stop,
            risk_reward: item.risk_reward,
            take_profit: r_entry,
            // support,
            risk_per_trade: item.risk_per_trade,
          });
        } else {
          throw new Error('No trades found');
        }
      } catch (error) {
        console.log('error', error);
        throw error;
      }
    });
    const onUpdateMaximumSize = flow(function* onUpdateMaximumSize() {
      try {
        const result = yield adapter.getOrdersForAccount({
          kind: self.config.kind,
          owner: self.config.sub_accounts[0],
          symbol: self.symbol,
        });
        if (result) {
          const { long, short } = result;
          self.config.updateFields({
            max_size: to_f(
              Math.abs(
                Math.abs(long.positionAmt) - Math.abs(short.positionAmt),
              ),
              '%.3f',
            ),
          });
        }
      } catch (error) {
        throw error;
      }
    });
    const determineClosestRisk = flow(function* determineClosestRisk(
      defaultValue?: any,
    ) {
      if (defaultValue) {
        self.config.updateFields(defaultValue);
      }
      const result = yield adapter.getOptimumRisk(
        {
          ...(self.config as any).toJSON(),
          as_array: false,
          strategy: 'entry',
          // criterion: 'entry'
        },
        self.config.max_size,
        self.config.multiplier,
        self.config.gap,
      );
      if (result) {
        self.config.updateFields({
          risk_per_trade: result.value,
          risk_reward: result.risk_reward,
        });
      }
      console.log('result', result);
      return result;
    });
    const updateSupportResistance = () => {
      if (self.config.long_zones && self.config.short_zones) {
        self.config.updateFields({
          zone_risk: 3,
          support: Math.min(self.config.long_zones, self.config.short_zones),
          resistance: Math.max(self.config.long_zones, self.config.short_zones),
        });
      }
    };
    const resetSupportResistance = () => {
      self.config.updateFields({
        support: self.support,
        zone_risk: 5,
        resistance: self.resistance,
      });
    };
    const updateConfig = (
      config: {
        entry: number;
        stop: number;
        take_profit?: number;
        kind: 'long' | 'short';
        currentEntry?: number;
        support?: number;
        resistance?: number;
        increase_position?: boolean;
        use_fibonacci?: boolean;
        sub_accounts?: string[];
        risk_reward?: number;
        risk_per_trade?: number;
      },
      addition?: any,
    ) => {
      if (addition) {
        self.support = addition.support;
        self.resistance = addition.resistance;
      }
      self.config.updateFields(config);
    };
    const updateState = (v: any) => {
      applySnapshot(self, { ...getSnapshot(self), ...v });
    };
    return {
      setAccounts(accounts: string[]) {
        self.config.updateFields({ accounts });
      },
      deleteTradingSection,
      generateRemoteConfig,
      onUpdateMaximumSize,
      saveTradesSection,
      updateState,
      updateConfig,
      updateSupportResistance,
      resetSupportResistance,
      determineClosestRisk,
      determineOptimumRiskReward,
      switchModeToStrategy,
      switchModeToDefault,
      placeSingleOrder,
      updateRiskValue,
      analyzePosition,
      createSingleOrder,
      placeStopOrders,
      placeOrder,
      setSymbol(symbol: string) {
        self.symbol = symbol;
      },
      setAccount(e: string) {
        self.account = e;
      },
      setMinimum(v: number) {
        self.minimum = v;
      },
      toggleField() {
        self.as_select = !self.as_select;
      },
    };
  });

export interface IFutureInstance extends Instance<typeof FutureInstance> {}
export interface IFutureFormConfig extends Instance<typeof FutureFormConfig> {}
export interface IStopEntryStore extends Instance<typeof StopEntryStore> {}
