import { Box, Grid, Link } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter as Router,
  Link as RouterLink,
  Route,
  Switch,
} from 'react-router-dom';
import { loadAdapter, setAdapter } from './adapter';
import AppThemeProvider from './bootstrap';
import App from './currentBot/App';
import BotConfig from './currentBot/BotConfig';
import NewBotConfig from './currentBot/NewBotConfig';
import Calculator from './currentBot/Calculator';
import MarginCalculator from './currentBot/MarginCalculator';
import MultipleMarkets from './currentBot/MultipleMarket';
import './index.css';
import { BotStore, CalculatorStore } from './store/botConfig';
import {
  bootStrapApp,
  SamplePageContainer,
  ApplicationWrapperProps,
} from './mobileApp_with_ionic/pageSetup';

const BareBonesWrapper = ({
  children,
  tabsLocation = 'bottom',
  tabs,
}: ApplicationWrapperProps) => {
  return <>{children}</>;
};

export const NonRouterWrapper = bootStrapApp(
  {
    routeMode: 'hash',
    tabsLocation: 'bottom',
    tabs: [
      {
        tabName: 'home',
        href: '/home',
        label: 'Home',
      },
      {
        tabName: 'accounts',
        href: '/accounts',
        label: 'Accounts',
      },
    ],
  },
  BareBonesWrapper,
  SamplePageContainer,
  () => {},
  '/mobile-app',
);

interface Window {
  APP_DATA: any;
  rootStore: any;
}

declare let window: Window;
let env: 'testing' | 'production' = 'production';
const store = CalculatorStore.create(
  {
    symbol: 'BTCUSDT',
    configs: [{ symbol: 'BTCUSDT' }],
  },
  {
    adapter: setAdapter(loadAdapter(env)),
  },
);
const rootStore = BotStore.create(
  {},
  {
    adapter: setAdapter(loadAdapter(env)),
  },
);
window.rootStore = rootStore;
function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <AppThemeProvider>
        <Router>
          <div>
            <Grid
              mt={3}
              mx={3}
              fontSize="1.5em"
              templateColumns={{ base: 'repeat(3, 1fr)', md: 'repeat(4,1fr)' }}
              gap={6}
            >
              <Box>
                <Link as={RouterLink} to="/">
                  Home
                </Link>
              </Box>
              <Box>
                <Link as={RouterLink} to="/mobile-app/home">
                  Mobile
                </Link>
              </Box>
              <Box>
                <Link as={RouterLink} to="/margin-calculator">
                  Margin Calculator
                </Link>
              </Box>
              <Box>
                <Link as={RouterLink} to="/calculator">
                  Calculator
                </Link>
              </Box>
              {/* <Box>
                <Link as={RouterLink} to="/old-config">
                  Old Config
                </Link>
              </Box> */}
            </Grid>
            <Switch>
              <Route path="/old-config">
                <App />
              </Route>
              <Route path="/advanced">
                <MultipleMarkets />
              </Route>
              <Route path="/calculator">
                <Calculator
                  store={rootStore.calculator}
                  onClick={(p: any) => {
                    //console.log('Switch');
                  }}
                />
              </Route>
              <Route path="/margin-calculator">
                <BotConfig />
              </Route>
              <Route path="/mobile-app">
                <NonRouterWrapper store={rootStore.mobile} />
              </Route>
              <Route path="/">
                {/* <BotConfig /> */}
                <NewBotConfig store={rootStore} />
              </Route>
            </Switch>
          </div>
        </Router>
      </AppThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  );
}
renderApp();

// Hot Module Replacement (HMR) - Remove this snippet to remove HMR.
// Learn more: https://www.snowpack.dev/#hot-module-replacement
// if (import.meta.hot) {
//   import.meta.hot.accept();
// }
