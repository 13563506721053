import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { TradeProps } from '../types';

type MenuProps = {
  currentTrade: TradeProps;
  options: number[];
  onClick: (entry: number) => void;
};

export const MobileMenu = ({ currentTrade, options, onClick }: MenuProps) => {
  const style = useMultiStyleConfig('MobileMebuButton', {});
  return (
    <Flex justifyContent={'flex-end'}>
      <Menu>
        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
          {currentTrade.entry}
        </MenuButton>
        <MenuList width="500px">
          {options.map((entry: number, index: number) => (
            <MenuItem
              sx={style}
              _hover={{ color: 'white', bg: 'blue.800' }}
              backgroundColor={
                entry === currentTrade.entry ? 'blue.700' : 'gray.200'
              }
              color={entry === currentTrade.entry ? 'gray.200' : 'black'}
              onClick={() => onClick(entry)}
            >
              {entry}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};
