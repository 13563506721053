import {
  Flex,
  useDisclosure,
  useToast,
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import NewJobComponent, {
  CreateJobComponent,
  NewAlertComponent,
} from './components/NewJobComponent';
import Sidebar from './components/Sidebar';
import { JobListView } from './EditJob';
import type { IBotStore } from '../store/botConfig';
import type { IJobStore } from '../store/jobConfig';
import { ConfirmDialog } from '../newBot/app-components/primitives/ModalComponent';
import { IAlertStore } from '../store/alertConfig';

export const JobListGroup: React.FC<{
  jobGroup: {
    title: string;
    jobs: IJobStore[] | IAlertStore | undefined;
  }[];
  modalDisclosure: any;
  store: IBotStore;
}> = observer(({ jobGroup, store, modalDisclosure }) => {
  // const modalDisclosure = useDisclosure();
  const btnRef: any = React.useRef();
  const toast = useToast();
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }

  return (
    <>
      <Accordion
        allowMultiple
        defaultIndex={jobGroup?.map((jobDetail, index) => index)}
      >
        {jobGroup?.map((jobDetail, index) =>
          jobDetail.jobs &&
          Array.isArray(jobDetail.jobs) &&
          jobDetail.jobs.length > 0 ? (
            <AccordionItem key={index} id={`${index}`}>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {jobDetail.title}
                  </Box>
                  <ConfirmDialog
                    heading={`Delete ${jobDetail.title}`}
                    confirmAction={() =>
                      store.account?.bulkDeleteJobs(jobDetail.jobs)
                    }
                    button={
                      <Button
                        as="span"
                        // mb={[4, 0, 0, 0]}
                        isLoading={store.account?.loading}
                        ml={4}
                        colorScheme="red"
                        loadingText="deleting"
                      >
                        Delete Jobs
                      </Button>
                    }
                  />
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                {Array.isArray(jobDetail.jobs)
                  ? jobDetail.jobs.map((job: any, index: number) => {
                      return (
                        <JobListView
                          key={index}
                          job={job}
                          store={store}
                          onEdit={() => {
                            store.setEditJob(job, index);
                            modalDisclosure.onOpen();
                          }}
                          onRun={() => {
                            // store
                            //   .runJobInstance(job.name)
                            //   .then(() => {
                            //     toastHelper('success', 'Job ran successfully');
                            //   })
                            //   .catch((error) => {
                            //     toastHelper('error', 'Error running job');
                            //   });
                          }}
                          onStatus={(func) => {
                            store.getJobStatus(job.job_id).then((data) => {
                              func(JSON.stringify(data));
                              // alert(JSON.stringify(data));
                            });
                          }}
                          onPause={() => {
                            // store
                            //   .pauseJobInstance(job.name)
                            //   .then(() => {
                            //     toastHelper('success', 'Job Status updated');
                            //   })
                            //   .catch((error: any) => {
                            //     toastHelper('error', 'Error updating config');
                            //   });
                          }}
                          onDelete={() => {
                            store
                              .deleteJobInstance(job.name)
                              .then(() => {
                                toastHelper('success', 'Job Deleted');
                              })
                              .catch((error) => {
                                toastHelper('error', 'Error updating config');
                              });
                          }}
                        />
                      );
                    })
                  : null}
              </AccordionPanel>
            </AccordionItem>
          ) : null,
        )}
      </Accordion>
    </>
  );
});

const JobsViews: React.FC<{
  store: IBotStore;
  alert?: boolean;
  noAccordion?: boolean;
  lists?: any[];
}> = observer(({ store, noAccordion = false, alert = false, lists = [] }) => {
  const btnRef: any = React.useRef();
  const toast = useToast();
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  const modalDisclosure = useDisclosure();
  const title: string = alert ? 'Alert' : 'Job';
  const titleLowerCase = title.toLowerCase();
  const editJob: any = alert ? store.editAlert : store.editJob;

  return (
    <Flex direction="column">
      {noAccordion ? (
        <>
          {lists.map((job: any, index: number) => {
            return (
              <JobListView
                key={index}
                job={job}
                store={store.account}
                onEdit={() => {
                  store.setEditJob(job, index);
                  modalDisclosure.onOpen();
                }}
                onRun={() => {
                  store.account
                    ?.runAction({ type: titleLowerCase, name: job.name })
                    .then(() => {
                      toastHelper('success', `${title} ran successfully`);
                    })
                    .catch((error) => {
                      toastHelper('error', `Error running ${titleLowerCase}`);
                    });
                }}
                onStatus={(func) => {
                  store.account?.getJobStatus(job.job_id).then((data: any) => {
                    func(JSON.stringify(data));
                    // alert(JSON.stringify(data));
                  });
                }}
                onPause={() => {
                  store.account
                    ?.pauseAction({ type: titleLowerCase, name: job.name })
                    .then(() => {
                      toastHelper('success', `${title} Status updated`);
                      return store.fetchAccounts();
                    })
                    .catch((error) => {
                      toastHelper('error', 'Error updating config');
                    });
                }}
                onDelete={() => {
                  job
                    .removeAction(titleLowerCase)
                    // store
                    //   .deleteJobInstance(job.name)
                    .then(() => {
                      toastHelper('success', `${title} Deleted`);
                    })
                    .catch((error: any) => {
                      toastHelper('error', 'Error updating config');
                    });
                }}
              />
            );
          })}
        </>
      ) : (
        <JobListGroup
          modalDisclosure={modalDisclosure}
          jobGroup={[
            {
              title: `Long ${title}`,
              jobs: store.account?.defaultConfig.longJobs,
            },
            {
              title: `Short ${title} `,
              jobs: store.account?.defaultConfig.shortJobs,
            },
            {
              title: `Other ${title} `,
              jobs: store.account?.defaultConfig.otherJobs || [],
            },
          ]}
          store={store}
        />
      )}
      <Sidebar
        placement="right"
        button={null}
        title={`Edit ${editJob?.name}`}
        {...{ ...modalDisclosure, btnRef }}
      >
        {editJob ? (
          editJob.alert ? (
            <NewAlertComponent
              loading={store.loading}
              alert={editJob}
              onClose={() => {
                store
                  .editAlertInstance()
                  .then(() => {
                    toastHelper('success', `Alert updated`);
                    modalDisclosure.onClose();
                  })
                  .catch((error: any) => {
                    toastHelper('error', 'Error updating alert');
                  });
              }}
            />
          ) : (
            <NewJobComponent
              loading={store.loading}
              onClose={() => {
                store
                  .editJobInstance()
                  .then(() => {
                    toastHelper('success', `${editJob?.name} updated`);
                    modalDisclosure.onClose();
                  })
                  .catch((error) => {
                    toastHelper('error', 'Error updating config');
                  });
              }}
              job={editJob}
            />
          )
        ) : null}
      </Sidebar>
    </Flex>
  );
});

export default JobsViews;
