import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { ButtonCollection, SectionConfig, SectionSummary } from '../components';
import { Exchange } from '../stores';
import { TradeSummary } from '../../newBot/app-components/SolvedTrader/TradeZoneControl';
import { useDisclosure } from '@chakra-ui/react';

type SectionconfigPageProps = {
  store: Exchange;
  onGoBack: () => void;
};

export const SectionConfigPage = observer(
  ({ store, onGoBack }: SectionconfigPageProps) => {
    const [loading, setLoading] = useState(false);
    const [isEntryLoading, setIsEntryLoading] = useState(false);
    const modalProps = useDisclosure();
    const actions = [
      {
        label: 'View orders',
        action: () => {
          store.updateFutureConfig();
          modalProps.onOpen();
        },
      },
      {
        label: 'Optimum risk',
        loading: isEntryLoading,
        isDisabled: !(
          store.newTradeSection.entry && store.newTradeSection.stop
        ),
        action: () => {
          // update the internal entry and stop for the futrueTrader
          store.updateFutureConfig();
          setIsEntryLoading(true);
          store.futureCTrader
            ?.determineClosestRisk({
              risk_per_trade: 10,
            })
            .then((result) => {
              setIsEntryLoading(false);
              store.newTradeSection.updateFields({
                risk: result.value,
                risk_reward: result.risk_reward,
              });
            })
            .catch((error) => {
              setIsEntryLoading(false);
              console.log('error', error);
            });
        },
      },
      {
        label: store.activeTradeSection
          ? `Edit trade section`
          : 'Create new trading section',
        action: async () => {
          try {
            setLoading(true);
            await store.createOrEditTradeSection();
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log('error', error);
          }
        },
      },
    ];
    if (store.activeTradeSection) {
      actions.push({
        label: 'Update S/R',
        action: () => {
          store.createTradeConfigForm();
        },
      });
      actions.push({
        label: 'Delete trade section',
        action: async () => {
          try {
            setLoading(true);
            await store.deleteTradingSection();
            setLoading(false);
            onGoBack();
          } catch (error) {
            setLoading(false);
            console.log('error', error);
          }
        },
      });
    }
    return (
      <>
        <SectionConfig
          onGetLatestResistance={async () => {
            try {
              setLoading(true);
              await store.onGetLatestResistance();
              setLoading(false);
            } catch (error) {
              setLoading(false);
              console.log('error', error);
            }
          }}
          config={store.newTradeSection}
          loading={loading}
        />
        <SectionSummary labels={store.tradeSummary()} />
        <ButtonCollection actions={actions} />
        {store.futureCTrader && (
          <TradeSummary
            isOpen={modalProps.isOpen}
            onClose={modalProps.onClose}
            store={store.futureCTrader}
          />
        )}
      </>
    );
  },
);

export default SectionConfigPage;
