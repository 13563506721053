export const allMarkets = {
  usdt: [
    'MATICUSDT',
    'CHRUSDT',
    'IOSTUSDT',
    'ONTUSDT',
    'SRMUSDT',
    'OGNUSDT',
    'BCHUSDT',
    'NKNUSDT',
    'SNXUSDT',
    'ENJUSDT',
    'EGLDUSDT',
    'GRTUSDT',
    'BTCBUSD',
    'ONEUSDT',
    'DASHUSDT',
    'OCEANUSDT',
    'FTMUSDT',
    'BZRXUSDT',
    'SUSHIUSDT',
    'TRXUSDT',
    'CHZUSDT',
    'MANAUSDT',
    'XLMUSDT',
    'TRBUSDT',
    'NEARUSDT',
    'IOTAUSDT',
    'YFIIUSDT',
    'BTCUSDT',
    'AXSUSDT',
    'VETUSDT',
    'KSMUSDT',
    'ALGOUSDT',
    'DODOUSDT',
    'ALICEUSDT',
    'ZECUSDT',
    'KNCUSDT',
    'DENTUSDT',
    'MTLUSDT',
    'FILUSDT',
    'SOLUSDT',
    'THETAUSDT',
    'ADAUSDT',
    'BELUSDT',
    'BANDUSDT',
    'STMXUSDT',
    'BALUSDT',
    'SFPUSDT',
    'LITUSDT',
    'TOMOUSDT',
    'BLZUSDT',
    'DOTUSDT',
    'RUNEUSDT',
    'ICXUSDT',
    'LTCUSDT',
    'BTCSTUSDT',
    'HOTUSDT',
    'XMRUSDT',
    'ALPHAUSDT',
    'XEMUSDT',
    'ZRXUSDT',
    'SANDUSDT',
    'RENUSDT',
    'SKLUSDT',
    'RVNUSDT',
    'NEOUSDT',
    'LINAUSDT',
    'BNBUSDT',
    'SCUSDT',
    'CRVUSDT',
    'DEFIUSDT',
    'REEFUSDT',
    'ETHUSDT',
    'HNTUSDT',
    'MKRUSDT',
    'CTKUSDT',
    'QTUMUSDT',
    'AVAXUSDT',
    'LUNAUSDT',
    'AKROUSDT',
    'CELRUSDT',
    'WAVESUSDT',
    'BATUSDT',
    'BTSUSDT',
    'LRCUSDT',
    'ANKRUSDT',
    '1INCHUSDT',
    'ZENUSDT',
    'ZILUSDT',
    'KAVAUSDT',
    'BTTUSDT',
    'RLCUSDT',
    'RSRUSDT',
    'ETHUSDT_210625',
    'FLMUSDT',
    'STORJUSDT',
    'AAVEUSDT',
    'EOSUSDT',
    'BTCUSDT_210625',
    'ATOMUSDT',
    'SXPUSDT',
    'DOGEUSDT',
    'COMPUSDT',
    'XRPUSDT',
    'HBARUSDT',
    'YFIUSDT',
    'UNIUSDT',
    'LINKUSDT',
    'DGBUSDT',
    'OMGUSDT',
    'ETCUSDT',
    'XTZUSDT',
    'CVCUSDT',
    'UNFIUSDT',
    'COTIUSDT',
  ],
  coin: [
    'BTCUSD_PERP',
    'BTCUSD_210625',
    'BTCUSD_210924',
    'ETHUSD_PERP',
    'ETHUSD_210625',
    'ETHUSD_210924',
    'LINKUSD_PERP',
    'BNBUSD_PERP',
    'TRXUSD_PERP',
    'DOTUSD_PERP',
    'ADAUSD_PERP',
    'EOSUSD_PERP',
    'LTCUSD_PERP',
    'BCHUSD_PERP',
    'XRPUSD_PERP',
    'ETCUSD_PERP',
    'FILUSD_PERP',
    'EGLDUSD_PERP',
    'ADAUSD_210625',
    'LINKUSD_210625',
    'BCHUSD_210625',
    'DOTUSD_210625',
    'XRPUSD_210625',
    'LTCUSD_210625',
    'BNBUSD_210625',
    'DOGEUSD_PERP',
    'ADAUSD_210924',
    'LINKUSD_210924',
    'BCHUSD_210924',
    'DOTUSD_210924',
    'XRPUSD_210924',
    'LTCUSD_210924',
    'BNBUSD_210924',
    'UNIUSD_PERP',
    'THETAUSD_PERP',
    'XLMUSD_PERP',
  ],
};
// this is where to change the futureInstance params.
// the change happens in the spot_market section
export const allAccounts = [
  {
    owner: 'sub_account',
    config: [
      {
        symbol: 'BTCUSDT',
        budget: 25,
        spread: 1600,
        resistance: 25500,
        profit: 25,
        split: 4,
        r: 4.1,
        bias: 'long',
        price_places: '%.2f',
        maximum: 0.05,
        decimal_places: '%.3f',
        interval: 25,
        swap_factor: 0,
        onswap: 'profit',
        delay: false,
        profit_style: null,
        minimum_balance: 25,
        chart_type: 'hours_1',
        other_profit: 1.22,
        support: 30000,
        bias_r: 0.6,
        bias_ratio: 0.04,
        other_r: 0.05,
        use_maximum: false,
        source: 'close',
        strategy: 'profit_maximizer',
        start_ratio: 1,
        ideal_stop: 31000,
        risk: 40,
        acceptable_loss: false,
        sell_spread: 30,
        bias_only: true,
        jobs: [],
        spot_markets: [
          {
            symbol: 'TRXUSDT',
            profile: {
              symbol: 'TRXUSDT',
              id: 'e41a43dd-a571-4f0a-a30d-181b53ea44fc',
              not_bull: true,
              is_bear: false,
            },
          },
          {
            symbol: 'BTCUSDT',
            profile: {
              symbol: 'BTCUSDT',
              id: '798d46cd-34d5-4f7a-81b2-abc03822e48f',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 2000,
              expected_loss: 5,
              risk_reward: 150,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 0.025,
              bear_factor: 0.025,
              loss_factor: 1,
              support: 41230.7,
              resistance: 42065.73,
              max_size: 0.1,
              tradeSplit: 24,
              futureBudget: 60,
              x_places: '%.1f',
              zone_split: 5,
              min_size: 0.003,
              transfer_funds: false,
              decimal_places: '%.3f',
              focus: 42065.73,
              // focus: 31000,
              price_places: '%.1f',
              sub_accounts: ['tayo_sub_account'],
              margin_sub_account: 'sub_account',
              risk_per_trade: 23,
            },
          },
        ],
        margin_snapshot: {
          long: {
            borrowed: 205.24524,
            orders: [
              {
                take_profit: 45675.37,
                quantity: 0.00947,
                stop: 47520.66,
                min_total: 432.55,
                max_total: 450.02,
              },
              {
                price: 45675.37,
                quantity: 0.00038,
                total: 17.36,
              },
            ],
            asset: 'USDT',
          },
          short: {
            borrowed: 0.011338,
            orders: [
              {
                price: 47520.66,
                quantity: 0.00947,
                total: 450.02,
              },
            ],
            asset: 'BTC',
          },
        },
        profiles: [
          {
            id: '4d298f55-cf48-4c79-867f-2d71cb11f652',
            name: 'Default profile',
            kind: ['long'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 90,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
            symbol: 'BTCUSDT',
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
      {
        symbol: 'SOLBUSD',
        profiles: [
          {
            id: '238d77f9-613e-493b-888f-f57d6e57fa3c',
            name: 'Default profile',
            kind: ['long'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 100,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
          },
        ],
        spot_markets: [],
        alerts: [],
        baseAsset: 'SOLBUSD',
      },
      {
        symbol: 'SOLUSDT',
        price_places: '%.2f',
        decimal_places: '%.0f',
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 0.1,
        acceptable_loss: false,
        sell_spread: 10,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        minimum_balance: 25,
        other_profit: 1,
        leverage: 50,
        spot_markets: [
          {
            symbol: 'BNBUSDT',
            profile: {
              symbol: 'BNBUSDT',
              id: '59bb5fc0-2b58-497a-a811-798e27d744c4',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 200,
              expected_loss: 4,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 362,
              resistance: 700,
              tradeSplit: 15,
              futureBudget: 300,
              x_places: '%.2f',
              min_size: 0.02,
              transfer_funds: false,
            },
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
      {
        symbol: 'ETHUSDT',
        price_places: '%.2f',
        decimal_places: '%.3f',
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 0.1,
        acceptable_loss: false,
        sell_spread: 10,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        minimum_balance: 25,
        other_profit: 1,
        leverage: 100,
        spot_markets: [
          {
            symbol: 'ETHUSDT',
            profile: {
              symbol: 'ETHUSDT',
              id: '442c6564-a828-4c51-a87c-768780e0606c',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 200,
              expected_loss: 4,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 2747.89,
              resistance: 4800,
              tradeSplit: 10,
              futureBudget: 300,
              x_places: '%.2f',
              min_size: 0.002,
              transfer_funds: false,
            },
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
    ],
    exchanges: [],
  },
  {
    owner: 'main_account',
    config: [
      {
        symbol: 'BTCUSDT',
        spot_markets: [
          {
            symbol: 'BTCUSDC',
            profile: {
              symbol: 'BTCUSDC',
              id: '24b795d8-7912-4c09-b2e9-7dfd754377f1',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 900,
              expected_loss: 18,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 48470.85,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 900,
              x_places: '%.2f',
            },
          },
          {
            symbol: 'BNBUSDT',
            profile: {
              symbol: 'BNBUSDT',
              id: 'ec30dd72-1ac8-43d6-a1e6-9962db27c7de',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 500,
              expected_loss: 10,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 0.25,
              bear_factor: 1,
              loss_factor: 0.25,
              support: 386.3,
              resistance: 700,
              tradeSplit: 10,
              futureBudget: 500,
              x_places: '%.2f',
              min_size: 0.02,
              transfer_funds: false,
            },
          },
          {
            symbol: 'BTCBUSD',
            profile: {
              symbol: 'BTCBUSD',
              id: 'b1297d37-af78-4f8e-b6ce-57f1e30bf262',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 900,
              expected_loss: 18,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 1,
              bull_factor: 0.25,
              bear_factor: 0.25,
              loss_factor: 3,
              support: 40094.33,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 750,
              x_places: '%.2f',
              min_size: 0.003,
              transfer_funds: true,
            },
          },
          {
            symbol: 'BTCUSDT',
            profile: {
              symbol: 'BTCUSDT',
              id: '3d3c3744-c252-4f0e-88d5-b73efda901f7',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 3750,
              expected_loss: 75,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 0.25,
              bear_factor: 0.25,
              loss_factor: 3,
              support: 35535.1,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 3750,
              x_places: '%.1f',
              min_size: 0.003,
              transfer_funds: false,
            },
          },
        ],
        jobs: [
          {
            seconds: 25,
            name: 'Remove BTC profits in BTCBUSD account',
            conditions: [
              {
                variable: 'skip',
              },
            ],
            deleteAction: {
              immediate: false,
            },
            actions: [
              {
                type: 'margin_profit',
                params: {
                  asset: 'BTC',
                  isolatedSymbol: 'BTCBUSD',
                },
              },
            ],
            job_id: 'b2832b98a11d43d6b3bd67901965b0b1',
            symbol: 'BTCUSDT',
            job_status: 'pause',
          },
          {
            seconds: 25,
            name: 'Remove USDT profits in BTCUSDT account',
            conditions: [
              {
                variable: 'skip',
              },
            ],
            deleteAction: {
              immediate: false,
            },
            actions: [
              {
                type: 'margin_profit',
                params: {
                  asset: 'USDT',
                  isolatedSymbol: 'BTCUSDT',
                },
              },
            ],
            job_id: 'ff42bb425d55459184f083ee4ffa954b',
            symbol: 'BTCUSDT',
            job_status: 'pause',
          },
          {
            seconds: 25,
            name: 'Remove BTC profits in BTCUSDT account',
            conditions: [
              {
                variable: 'skip',
              },
            ],
            deleteAction: {
              immediate: false,
            },
            actions: [
              {
                type: 'margin_profit',
                params: {
                  asset: 'BTC',
                  isolatedSymbol: 'BTCUSDT',
                },
              },
            ],
            job_id: '6c59768ccd1249a1811ed515b69fc472',
            symbol: 'BTCUSDT',
            job_status: 'running',
          },
        ],
        margin_snapshot: {
          long: {
            borrowed: 479.964838,
            orders: [
              {
                take_profit: 45675.37,
                quantity: 0.01894,
                stop: 47520.66,
                min_total: 865.09,
                max_total: 900.04,
              },
              {
                price: 45675.37,
                quantity: 0.00077,
                total: 35.17,
              },
            ],
            asset: 'USDT',
          },
          short: {
            borrowed: 0.010398,
            orders: [
              {
                price: 47520.66,
                quantity: 0.01894,
                total: 900.04,
              },
            ],
            asset: 'BTC',
          },
        },
        profiles: [
          {
            id: '30efd9ac-da81-4147-a535-4195caaf2f6d',
            name: 'Default profile',
            kind: ['long'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 800,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
            symbol: 'BTCUSDT',
          },
        ],
        trade_info: {
          position: {
            long: {
              symbol: 'BTCUSDT',
              positionAmt: 0.053,
              entryPrice: 36611.9,
              markPrice: 36485.652143,
              unRealizedProfit: -6.691136,
              liquidationPrice: 37180.248195,
              leverage: 125,
              maxNotionalValue: 50000.0,
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'LONG',
              notional: '1933.73956359',
              isolatedWallet: 0.0,
              updateTime: 1651795537123,
            },
            short: {
              symbol: 'BTCUSDT',
              positionAmt: -0.123,
              entryPrice: 35927.550406,
              markPrice: 36485.652143,
              unRealizedProfit: -68.646514,
              liquidationPrice: 37180.248195,
              leverage: 125,
              maxNotionalValue: 50000.0,
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'SHORT',
              notional: '-4487.73521362',
              isolatedWallet: 0.0,
              updateTime: 1651785602046,
            },
            both: {
              symbol: 'BTCUSDT',
              positionAmt: 0.0,
              entryPrice: 0.0,
              markPrice: 36485.652143,
              unRealizedProfit: 0.0,
              liquidationPrice: 0.0,
              leverage: 125,
              maxNotionalValue: 50000.0,
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'BOTH',
              notional: '0',
              isolatedWallet: 0.0,
              updateTime: 0,
            },
          },
          orders: [
            {
              orderId: 52706420615,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'eIx7pSdxSsUlB70852jzP3',
              price: 36970.7,
              avgPrice: '0',
              origQty: 0.053,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'LIMIT',
              reduceOnly: true,
              closePosition: false,
              side: 'SELL',
              positionSide: 'LONG',
              stopPrice: 0.0,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'LIMIT',
              time: 1651795720295,
              updateTime: 1651795720295,
            },
            {
              orderId: 52700130359,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'cTrAti6owKvvxd1WYKfLKI',
              price: 35886.9,
              avgPrice: '0',
              origQty: 0.123,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'LIMIT',
              reduceOnly: true,
              closePosition: false,
              side: 'BUY',
              positionSide: 'SHORT',
              stopPrice: 0.0,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'LIMIT',
              time: 1651789428905,
              updateTime: 1651789428905,
            },
            {
              orderId: 52706420649,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'QFPKns2wzesN143AEo84VY',
              price: 36974.4,
              avgPrice: '0',
              origQty: 0.026,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'STOP',
              reduceOnly: false,
              closePosition: false,
              side: 'BUY',
              positionSide: 'LONG',
              stopPrice: 36972.6,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'STOP',
              time: 1651795720311,
              updateTime: 1651795720313,
            },
            {
              orderId: 52706464705,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'ynBpoymh7j2NtLzniB7ygh',
              price: 36970.7,
              avgPrice: '0',
              origQty: 0.028,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'STOP',
              reduceOnly: false,
              closePosition: false,
              side: 'BUY',
              positionSide: 'LONG',
              stopPrice: 36970.7,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'STOP',
              time: 1651795751365,
              updateTime: 1651795751365,
            },
            {
              orderId: 52700130666,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: '1bxfqUJwa1L2ziiQzTUnwQ',
              price: 35883.3,
              avgPrice: '0',
              origQty: 0.098,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'STOP',
              reduceOnly: false,
              closePosition: false,
              side: 'SELL',
              positionSide: 'SHORT',
              stopPrice: 35885.1,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'STOP',
              time: 1651789429589,
              updateTime: 1651789429589,
            },
            {
              orderId: 52706634978,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'FBqOi4DtBGugmx0CPECaoZ',
              price: 36245.8,
              avgPrice: '0',
              origQty: 0.029,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'LIMIT',
              reduceOnly: false,
              closePosition: false,
              side: 'BUY',
              positionSide: 'LONG',
              stopPrice: 0.0,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'LIMIT',
              time: 1651795864491,
              updateTime: 1651795864491,
            },
            {
              orderId: 52691167177,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'cRUpbX9zaH66D66tnjVi8m',
              price: 36970.7,
              avgPrice: '0',
              origQty: 0.023,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'LIMIT',
              reduceOnly: false,
              closePosition: false,
              side: 'SELL',
              positionSide: 'SHORT',
              stopPrice: 0.0,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'LIMIT',
              time: 1651781467514,
              updateTime: 1651781467515,
            },
            {
              orderId: 52691167176,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'XqcTLqkI2uHUmPoZPt29QH',
              price: 36972.6,
              avgPrice: '0',
              origQty: 0.023,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'STOP',
              reduceOnly: true,
              closePosition: false,
              side: 'BUY',
              positionSide: 'SHORT',
              stopPrice: 36972.6,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'STOP',
              time: 1651781467514,
              updateTime: 1651781467515,
            },
            {
              orderId: 52706452345,
              symbol: 'BTCUSDT',
              status: 'NEW',
              clientOrderId: 'w5uOpfb67aAPedxola2ZBj',
              price: 36970.7,
              avgPrice: '0',
              origQty: 0.028,
              executedQty: '0',
              cumQuote: '0',
              timeInForce: 'GTC',
              type: 'STOP',
              reduceOnly: false,
              closePosition: false,
              side: 'BUY',
              positionSide: 'LONG',
              stopPrice: 36970.7,
              workingType: 'CONTRACT_PRICE',
              priceProtect: false,
              origType: 'STOP',
              time: 1651795740639,
              updateTime: 1651795740639,
            },
          ],
          job_id: '816be59c802e47008f46fcf1e296e9e2',
        },
        alerts: [
          {
            name: 'BTCUSDT-future-1',
            symbol: 'BTCUSDT',
            price: 40896.2,
            market_type: 'future',
          },
          {
            name: 'BTCUSDT-future-2',
            symbol: 'BTCUSDT',
            price: 40493.2,
            market_type: 'future',
          },
          {
            name: 'BTCUSDT-future-3',
            symbol: 'BTCUSDT',
            price: 40094.3,
            market_type: 'future',
          },
          {
            name: 'BTCUSDT-margin-4',
            symbol: 'BTCUSDT',
            price: 39701.0,
            market_type: 'margin',
          },
        ],
        baseAsset: 'USDT',
      },
      {
        symbol: 'BTCBUSD',
        price_places: '%.1f',
        decimal_places: '%.3f',
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 0.1,
        acceptable_loss: false,
        sell_spread: 10,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        minimum_balance: 25,
        other_profit: 1,
        leverage: 50,
        alerts: [],
        baseAsset: 'BUSD',
      },
      {
        symbol: 'SOLUSDT',
        price_places: '%.2f',
        decimal_places: '%.0f',
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 0.1,
        acceptable_loss: false,
        sell_spread: 10,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        minimum_balance: 25,
        other_profit: 1,
        leverage: 50,
        spot_markets: [],
        alerts: [],
        baseAsset: 'USDT',
      },
      {
        symbol: 'SOLBUSD',
        alerts: [],
        baseAsset: 'SOLBUSD',
      },
      {
        symbol: 'ETHUSDT',
        price_places: '%.2f',
        decimal_places: '%.3f',
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 0.1,
        acceptable_loss: false,
        sell_spread: 10,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        minimum_balance: 25,
        other_profit: 1,
        leverage: 100,
        spot_markets: [
          {
            symbol: 'ETHUSDT',
            profile: {
              symbol: 'ETHUSDT',
              id: '0c586636-f674-4784-ae24-4e8e6cf61008',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 1000,
              expected_loss: 20,
              risk_reward: 1,
              spread: 50,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 0.25,
              bear_factor: 0.25,
              loss_factor: 3,
              support: 3148,
              resistance: 4200,
              tradeSplit: 10,
              futureBudget: 1000,
              x_places: '%.2f',
              min_size: 0.005,
              transfer_funds: true,
            },
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
      {
        spot_markets: [],
        symbol: 'BTCUSDT_220624',
        alerts: [],
        baseAsset: 'USDT',
      },
      {
        spot_markets: [
          {
            symbol: 'BTCUSD_PERP',
            profile: {
              symbol: 'BTCUSD_PERP',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 1500,
              expected_loss: 30,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 0.25,
              bear_factor: 0.25,
              loss_factor: 3,
              support: 43399.4,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 5000,
              x_places: '%.1f',
              min_size: 0.005,
              transfer_funds: false,
              id: '013b4208-dee7-4773-9c2e-7dc0086ee461',
            },
          },
        ],
        symbol: 'BTCUSD_PERP',
        trade_info: {
          position: {
            long: {
              symbol: 'BTCUSD_PERP',
              positionAmt: 0.0,
              entryPrice: 0.0,
              markPrice: 36484.933237,
              unRealizedProfit: 0.0,
              liquidationPrice: 0.0,
              leverage: 125,
              maxQty: '5',
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'LONG',
              notionalValue: '0',
              isolatedWallet: 0.0,
              updateTime: 1650139761650,
              maxNotionalValue: null,
            },
            short: {
              symbol: 'BTCUSD_PERP',
              positionAmt: 0.0,
              entryPrice: 0.0,
              markPrice: 36484.933237,
              unRealizedProfit: 0.0,
              liquidationPrice: 0.0,
              leverage: 125,
              maxQty: '5',
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'SHORT',
              notionalValue: '0',
              isolatedWallet: 0.0,
              updateTime: 1650139765877,
              maxNotionalValue: null,
            },
            both: {
              symbol: 'BTCUSD_PERP',
              positionAmt: 0.0,
              entryPrice: 0.0,
              markPrice: 36484.933237,
              unRealizedProfit: 0.0,
              liquidationPrice: 0.0,
              leverage: 125,
              maxQty: '5',
              marginType: 'cross',
              isolatedMargin: 0.0,
              isAutoAddMargin: 'false',
              positionSide: 'BOTH',
              notionalValue: '0',
              isolatedWallet: 0.0,
              updateTime: 0,
              maxNotionalValue: null,
            },
          },
          orders: [],
          job_id: 'ff0637c255de4a5ba63e2d47fc28ec48',
        },
        alerts: [],
        baseAsset: 'BTC',
      },
    ],
    exchanges: ['okex', 'okcoin'],
  },
  {
    owner: 'peter_bot',
    config: [
      {
        symbol: 'BTCUSDT',
        budget: 10,
        spread: 20,
        support: 1300,
        resistance: 3000,
        profit: 25,
        split: 1,
        r: 4.1,
        bias: 'long',
        price_places: '%.3f',
        maximum: 0.5,
        decimal_places: '%.0f',
        interval: 25,
        swap_factor: 0,
        onswap: 'profit',
        delay: false,
        minimum_balance: 0.003,
        chart_type: 'hours_1',
        other_profit: 1.22,
        ideal_stop: 1400,
        bias_r: 1,
        bias_ratio: 0.04,
        other_r: 1,
        use_maximum: true,
        source: 'close',
        strategy: 'loss_minimizer',
        start_ratio: 1,
        bias_only: true,
        follow_trend: false,
        starter: 0,
        risk: 40,
        acceptable_loss: false,
        sell_spread: 10,
        ema: 0,
        ideal_ema: 0,
        sell_at_entry: false,
        fee: 0,
        long_p: 0,
        short_p: 0,
        long_e: 0,
        short_e: 0,
        long_s: 0,
        short_s: 0,
        along_e: 0,
        ashort_e: 0,
        along_s: 0,
        ashort_s: 0,
        minimum_size: 1,
        start_size: 1,
        reduce: false,
        additional_budget: 0,
        contract_size: 100,
        leverage: 125,
        job_id: '',
        jobs: [],
        profiles: [
          {
            id: '8cf5cdb8-30ac-49d9-94a0-1837601f916f',
            name: 'Default profile',
            kind: ['long', 'short'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 400,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
          },
        ],
        spot_markets: [
          {
            symbol: 'BTCUSDT',
            profile: {
              symbol: 'BTCUSDT',
              id: 'bd010325-530f-41c4-9ea5-4b122cb949f9',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 500,
              expected_loss: 10,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 39000,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 500,
              x_places: '%.1f',
              min_size: 0.003,
              transfer_funds: false,
            },
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
    ],
    exchanges: [],
  },
  {
    owner: 'tola_sub_account',
    config: [
      {
        symbol: 'BTCUSDT',
        budget: 25,
        spread: 1600,
        resistance: 25500,
        profit: 25,
        split: 4,
        r: 4.1,
        bias: 'long',
        price_places: '%.2f',
        maximum: 0.05,
        decimal_places: '%.3f',
        interval: 25,
        swap_factor: 0,
        onswap: 'profit',
        delay: false,
        profit_style: null,
        minimum_balance: 25,
        chart_type: 'hours_1',
        other_profit: 1.22,
        support: 30000,
        bias_r: 0.6,
        bias_ratio: 0.04,
        other_r: 0.05,
        use_maximum: false,
        source: 'close',
        strategy: 'profit_maximizer',
        start_ratio: 1,
        ideal_stop: 31000,
        risk: 40,
        acceptable_loss: false,
        sell_spread: 30,
        bias_only: true,
        jobs: [],
        margin_snapshot: {
          long: {
            borrowed: 49.853925,
            orders: [
              {
                take_profit: 40000.0,
                quantity: 0.001,
                stop: 50000.0,
                min_total: 40.0,
                max_total: 50.0,
              },
            ],
            asset: 'USDT',
          },
          short: {
            borrowed: 0.0,
            orders: [],
            asset: 'BTC',
          },
        },
        profiles: [
          {
            id: '9ae67a4b-7592-451c-a46a-9bde70f0827a',
            name: 'Default profile',
            kind: ['long'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 300,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
            symbol: 'BTCUSDT',
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
    ],
    exchanges: [],
  },
  {
    owner: 'tayo_sub_account',
    config: [
      {
        symbol: 'BTCUSDT',
        budget: 25,
        spread: 1600,
        resistance: 25500,
        profit: 25,
        split: 4,
        r: 4.1,
        bias: 'long',
        price_places: '%.2f',
        maximum: 0.05,
        decimal_places: '%.3f',
        interval: 25,
        swap_factor: 0,
        onswap: 'profit',
        delay: false,
        profit_style: null,
        minimum_balance: 25,
        chart_type: 'hours_1',
        other_profit: 1.22,
        support: 30000,
        bias_r: 0.6,
        bias_ratio: 0.04,
        other_r: 0.05,
        use_maximum: false,
        source: 'close',
        strategy: 'profit_maximizer',
        start_ratio: 1,
        ideal_stop: 31000,
        risk: 40,
        acceptable_loss: false,
        sell_spread: 30,
        bias_only: true,
        jobs: [],
        profiles: [
          {
            id: 'fa34df71-786a-4c04-a48f-d62d90e79133',
            name: 'Default profile',
            kind: ['long'],
            margin_type: 'cross',
            max_size: 0,
            min_size: 0,
            reduce_size: 0,
            spread: 0,
            budget: 550,
            budget_factor: 1,
            trade_factor: 1,
            delete_existing: true,
            use_entry: false,
            one_off: false,
            loading: false,
            min_profit: 0,
            loss: 0,
            opposite_entry: 0,
            liquidation_spread: 0,
            pause_jobs: false,
            liquidation_close: true,
            old_mode: false,
            new_future: false,
            symbol: 'BTCUSDT',
          },
        ],
        spot_markets: [
          {
            symbol: 'BTCBUSD',
            profile: {
              symbol: 'BTCBUSD',
              id: 'a23b8fd8-bfcc-42d9-85aa-35daddcb1b0c',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 900,
              expected_loss: 18,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 42815.05,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 42815.05,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 900,
              x_places: '%.2f',
            },
          },
          {
            symbol: 'BTCUSDT',
            profile: {
              symbol: 'BTCUSDT',
              id: 'c488036e-44de-42d8-b75f-98af0c2eb74a',
              not_bull: true,
              is_bear: false,
              buy_more: false,
              is_margin: false,
              budget: 900,
              expected_loss: 18,
              risk_reward: 1,
              spread: 0,
              follow_profit: false,
              follow_stop: false,
              entry_price: 0,
              dollar_price: 0,
              bull_factor: 3,
              bear_factor: 1,
              loss_factor: 3,
              support: 42815.05,
              resistance: 70000,
              tradeSplit: 10,
              futureBudget: 900,
              x_places: '%.2f',
              min_size: 0.003,
              transfer_funds: false,
            },
          },
        ],
        alerts: [],
        baseAsset: 'USDT',
      },
    ],
    exchanges: [],
  },
];

export const SPOT_SYMBOLS = [
  'ETHBTC',
  'LTCBTC',
  'BNBBTC',
  'NEOBTC',
  'BCCBTC',
  'GASBTC',
  'BTCUSDT',
  'HSRBTC',
  'MCOBTC',
  'WTCBTC',
  'LRCBTC',
  'QTUMBTC',
  'YOYOBTC',
  'OMGBTC',
  'ZRXBTC',
  'STRATBTC',
  'SNGLSBTC',
  'BQXBTC',
  'KNCBTC',
  'FUNBTC',
  'SNMBTC',
  'IOTABTC',
  'LINKBTC',
  'XVGBTC',
  'SALTBTC',
  'MDABTC',
  'MTLBTC',
  'SUBBTC',
  'EOSBTC',
  'SNTBTC',
  'ETCBTC',
  'MTHBTC',
  'ENGBTC',
  'DNTBTC',
  'ZECBTC',
  'BNTBTC',
  'ASTBTC',
  'DASHBTC',
  'OAXBTC',
  'ICNBTC',
  'BTGBTC',
  'EVXBTC',
  'REQBTC',
  'VIBBTC',
  'TRXBTC',
  'POWRBTC',
  'ARKBTC',
  'XRPBTC',
  'MODBTC',
  'ENJBTC',
  'STORJBTC',
  'VENBTC',
  'KMDBTC',
  'RCNBTC',
  'NULSBTC',
  'RDNBTC',
  'XMRBTC',
  'DLTBTC',
  'AMBBTC',
  'BATBTC',
  'BCPTBTC',
  'ARNBTC',
  'GVTBTC',
  'CDTBTC',
  'GXSBTC',
  'POEBTC',
  'QSPBTC',
  'BTSBTC',
  'XZCBTC',
  'LSKBTC',
  'TNTBTC',
  'FUELBTC',
  'MANABTC',
  'BCDBTC',
  'DGDBTC',
  'ADXBTC',
  'ADABTC',
  'PPTBTC',
  'CMTBTC',
  'XLMBTC',
  'CNDBTC',
  'LENDBTC',
  'WABIBTC',
  'TNBBTC',
  'WAVESBTC',
  'GTOBTC',
  'ICXBTC',
  'OSTBTC',
  'ELFBTC',
  'AIONBTC',
  'NEBLBTC',
  'BRDBTC',
  'EDOBTC',
  'WINGSBTC',
  'NAVBTC',
  'LUNBTC',
  'TRIGBTC',
  'APPCBTC',
  'VIBEBTC',
  'RLCBTC',
  'INSBTC',
  'PIVXBTC',
  'IOSTBTC',
  'CHATBTC',
  'STEEMBTC',
  'NANOBTC',
  'VIABTC',
  'BLZBTC',
  'AEBTC',
  'RPXBTC',
  'NCASHBTC',
  'POABTC',
  'ZILBTC',
  'ONTBTC',
  'STORMBTC',
  'XEMBTC',
  'WANBTC',
  'WPRBTC',
  'QLCBTC',
  'SYSBTC',
  'GRSBTC',
  'CLOAKBTC',
  'GNTBTC',
  'LOOMBTC',
  'BCNBTC',
  'REPBTC',
  'BTCTUSD',
  'TUSDBTC',
  'ZENBTC',
  'SKYBTC',
  'CVCBTC',
  'THETABTC',
  'IOTXBTC',
  'QKCBTC',
  'AGIBTC',
  'NXSBTC',
  'DATABTC',
  'SCBTC',
  'NPXSBTC',
  'KEYBTC',
  'NASBTC',
  'MFTBTC',
  'DENTBTC',
  'ARDRBTC',
  'HOTBTC',
  'VETBTC',
  'DOCKBTC',
  'POLYBTC',
  'PHXBTC',
  'HCBTC',
  'GOBTC',
  'PAXBTC',
  'RVNBTC',
  'DCRBTC',
  'MITHBTC',
  'BCHABCBTC',
  'BCHSVBTC',
  'BTCPAX',
  'RENBTC',
  'BTCUSDC',
  'BTTBTC',
  'BTCUSDS',
  'ONGBTC',
  'FETBTC',
  'CELRBTC',
  'MATICBTC',
  'ATOMBTC',
  'PHBBTC',
  'TFUELBTC',
  'ONEBTC',
  'FTMBTC',
  'BTCBBTC',
  'ALGOBTC',
  'ERDBTC',
  'DOGEBTC',
  'DUSKBTC',
  'ANKRBTC',
  'WINBTC',
  'COSBTC',
  'COCOSBTC',
  'TOMOBTC',
  'PERLBTC',
  'CHZBTC',
  'BANDBTC',
  'BTCBUSD',
  'BEAMBTC',
  'XTZBTC',
  'HBARBTC',
  'NKNBTC',
  'STXBTC',
  'KAVABTC',
  'BTCNGN',
  'ARPABTC',
  'CTXCBTC',
  'BCHBTC',
  'BTCRUB',
  'TROYBTC',
  'VITEBTC',
  'FTTBTC',
  'BTCTRY',
  'BTCEUR',
  'OGNBTC',
  'DREPBTC',
  'TCTBTC',
  'WRXBTC',
  'LTOBTC',
  'MBLBTC',
  'COTIBTC',
  'STPTBTC',
  'BTCZAR',
  'BTCBKRW',
  'SOLBTC',
  'BTCIDRT',
  'CTSIBTC',
  'HIVEBTC',
  'CHRBTC',
  'MDTBTC',
  'STMXBTC',
  'PNTBTC',
  'BTCGBP',
  'DGBBTC',
  'BTCUAH',
  'COMPBTC',
  'BTCBIDR',
  'SXPBTC',
  'SNXBTC',
  'IRISBTC',
  'MKRBTC',
  'DAIBTC',
  'RUNEBTC',
  'BTCAUD',
  'FIOBTC',
  'AVABTC',
  'BALBTC',
  'YFIBTC',
  'BTCDAI',
  'JSTBTC',
  'SRMBTC',
  'ANTBTC',
  'CRVBTC',
  'SANDBTC',
  'OCEANBTC',
  'NMRBTC',
  'DOTBTC',
  'LUNABTC',
  'IDEXBTC',
  'RSRBTC',
  'PAXGBTC',
  'WNXMBTC',
  'TRBBTC',
  'BZRXBTC',
  'WBTCBTC',
  'SUSHIBTC',
  'YFIIBTC',
  'KSMBTC',
  'EGLDBTC',
  'DIABTC',
  'UMABTC',
  'BELBTC',
  'WINGBTC',
  'UNIBTC',
  'NBSBTC',
  'OXTBTC',
  'SUNBTC',
  'AVAXBTC',
  'HNTBTC',
  'FLMBTC',
  'SCRTBTC',
  'ORNBTC',
  'UTKBTC',
  'XVSBTC',
  'ALPHABTC',
  'VIDTBTC',
  'BTCBRL',
  'AAVEBTC',
  'NEARBTC',
  'FILBTC',
  'INJBTC',
  'AERGOBTC',
  'AUDIOBTC',
  'CTKBTC',
  'BOTBTC',
  'AKROBTC',
  'AXSBTC',
  'HARDBTC',
  'RENBTCBTC',
  'STRAXBTC',
  'FORBTC',
  'UNFIBTC',
  'ROSEBTC',
  'SKLBTC',
  'SUSDBTC',
  'GLMBTC',
  'GRTBTC',
  'JUVBTC',
  'PSGBTC',
  '1INCHBTC',
  'REEFBTC',
  'OGBTC',
  'ATMBTC',
  'ASRBTC',
  'CELOBTC',
  'RIFBTC',
  'BTCSTBTC',
  'TRUBTC',
  'CKBBTC',
  'TWTBTC',
  'FIROBTC',
  'LITBTC',
  'BTCVAI',
  'SFPBTC',
  'FXSBTC',
  'DODOBTC',
  'FRONTBTC',
  'EASYBTC',
  'CAKEBTC',
  'ACMBTC',
  'AUCTIONBTC',
  'PHABTC',
  'TVKBTC',
  'BADGERBTC',
  'FISBTC',
  'OMBTC',
  'PONDBTC',
  'DEGOBTC',
  'ALICEBTC',
  'LINABTC',
  'PERPBTC',
  'RAMPBTC',
  'SUPERBTC',
  'CFXBTC',
  'EPSBTC',
  'AUTOBTC',
  'TKOBTC',
  'TLMBTC',
  'MIRBTC',
  'BARBTC',
  'FORTHBTC',
  'EZBTC',
  'BTCUSDT',
  'ETHUSDT',
  'BNBUSDT',
  'BCCUSDT',
  'NEOUSDT',
  'LTCUSDT',
  'QTUMUSDT',
  'ADAUSDT',
  'XRPUSDT',
  'EOSUSDT',
  'TUSDUSDT',
  'IOTAUSDT',
  'XLMUSDT',
  'ONTUSDT',
  'TRXUSDT',
  'ETCUSDT',
  'ICXUSDT',
  'VENUSDT',
  'NULSUSDT',
  'VETUSDT',
  'PAXUSDT',
  'BCHABCUSDT',
  'BCHSVUSDT',
  'USDCUSDT',
  'LINKUSDT',
  'WAVESUSDT',
  'BTTUSDT',
  'USDSUSDT',
  'ONGUSDT',
  'HOTUSDT',
  'ZILUSDT',
  'ZRXUSDT',
  'FETUSDT',
  'BATUSDT',
  'XMRUSDT',
  'ZECUSDT',
  'IOSTUSDT',
  'CELRUSDT',
  'DASHUSDT',
  'NANOUSDT',
  'OMGUSDT',
  'THETAUSDT',
  'ENJUSDT',
  'MITHUSDT',
  'MATICUSDT',
  'ATOMUSDT',
  'TFUELUSDT',
  'ONEUSDT',
  'FTMUSDT',
  'ALGOUSDT',
  'USDSBUSDT',
  'GTOUSDT',
  'ERDUSDT',
  'DOGEUSDT',
  'DUSKUSDT',
  'ANKRUSDT',
  'WINUSDT',
  'COSUSDT',
  'NPXSUSDT',
  'COCOSUSDT',
  'MTLUSDT',
  'TOMOUSDT',
  'PERLUSDT',
  'DENTUSDT',
  'MFTUSDT',
  'KEYUSDT',
  'STORMUSDT',
  'DOCKUSDT',
  'WANUSDT',
  'FUNUSDT',
  'CVCUSDT',
  'CHZUSDT',
  'BANDUSDT',
  'BUSDUSDT',
  'BEAMUSDT',
  'XTZUSDT',
  'RENUSDT',
  'RVNUSDT',
  'HCUSDT',
  'HBARUSDT',
  'NKNUSDT',
  'STXUSDT',
  'KAVAUSDT',
  'ARPAUSDT',
  'IOTXUSDT',
  'RLCUSDT',
  'MCOUSDT',
  'CTXCUSDT',
  'BCHUSDT',
  'TROYUSDT',
  'VITEUSDT',
  'FTTUSDT',
  'USDTTRY',
  'USDTRUB',
  'EURUSDT',
  'OGNUSDT',
  'DREPUSDT',
  'BULLUSDT',
  'BEARUSDT',
  'ETHBULLUSDT',
  'ETHBEARUSDT',
  'TCTUSDT',
  'WRXUSDT',
  'BTSUSDT',
  'LSKUSDT',
  'BNTUSDT',
  'LTOUSDT',
  'EOSBULLUSDT',
  'EOSBEARUSDT',
  'XRPBULLUSDT',
  'XRPBEARUSDT',
  'STRATUSDT',
  'AIONUSDT',
  'MBLUSDT',
  'COTIUSDT',
  'BNBBULLUSDT',
  'BNBBEARUSDT',
  'STPTUSDT',
  'USDTZAR',
  'WTCUSDT',
  'DATAUSDT',
  'XZCUSDT',
  'SOLUSDT',
  'USDTIDRT',
  'CTSIUSDT',
  'HIVEUSDT',
  'CHRUSDT',
  'BTCUPUSDT',
  'BTCDOWNUSDT',
  'GXSUSDT',
  'ARDRUSDT',
  'LENDUSDT',
  'MDTUSDT',
  'STMXUSDT',
  'KNCUSDT',
  'REPUSDT',
  'LRCUSDT',
  'PNTUSDT',
  'USDTUAH',
  'COMPUSDT',
  'USDTBIDR',
  'BKRWUSDT',
  'SCUSDT',
  'ZENUSDT',
  'SNXUSDT',
  'ETHUPUSDT',
  'ETHDOWNUSDT',
  'ADAUPUSDT',
  'ADADOWNUSDT',
  'LINKUPUSDT',
  'LINKDOWNUSDT',
  'VTHOUSDT',
  'DGBUSDT',
  'GBPUSDT',
  'SXPUSDT',
  'MKRUSDT',
  'DAIUSDT',
  'DCRUSDT',
  'STORJUSDT',
  'BNBUPUSDT',
  'BNBDOWNUSDT',
  'XTZUPUSDT',
  'XTZDOWNUSDT',
  'USDTBKRW',
  'MANAUSDT',
  'AUDUSDT',
  'YFIUSDT',
  'BALUSDT',
  'BLZUSDT',
  'IRISUSDT',
  'KMDUSDT',
  'USDTDAI',
  'JSTUSDT',
  'SRMUSDT',
  'ANTUSDT',
  'CRVUSDT',
  'SANDUSDT',
  'OCEANUSDT',
  'NMRUSDT',
  'DOTUSDT',
  'LUNAUSDT',
  'RSRUSDT',
  'PAXGUSDT',
  'WNXMUSDT',
  'TRBUSDT',
  'BZRXUSDT',
  'SUSHIUSDT',
  'YFIIUSDT',
  'KSMUSDT',
  'EGLDUSDT',
  'DIAUSDT',
  'RUNEUSDT',
  'FIOUSDT',
  'UMAUSDT',
  'EOSUPUSDT',
  'EOSDOWNUSDT',
  'TRXUPUSDT',
  'TRXDOWNUSDT',
  'XRPUPUSDT',
  'XRPDOWNUSDT',
  'DOTUPUSDT',
  'DOTDOWNUSDT',
  'USDTNGN',
  'BELUSDT',
  'WINGUSDT',
  'LTCUPUSDT',
  'LTCDOWNUSDT',
  'UNIUSDT',
  'NBSUSDT',
  'OXTUSDT',
  'SUNUSDT',
  'AVAXUSDT',
  'HNTUSDT',
  'FLMUSDT',
  'UNIUPUSDT',
  'UNIDOWNUSDT',
  'ORNUSDT',
  'UTKUSDT',
  'XVSUSDT',
  'ALPHAUSDT',
  'USDTBRL',
  'AAVEUSDT',
  'NEARUSDT',
  'SXPUPUSDT',
  'SXPDOWNUSDT',
  'FILUSDT',
  'FILUPUSDT',
  'FILDOWNUSDT',
  'YFIUPUSDT',
  'YFIDOWNUSDT',
  'INJUSDT',
  'AUDIOUSDT',
  'CTKUSDT',
  'BCHUPUSDT',
  'BCHDOWNUSDT',
  'AKROUSDT',
  'AXSUSDT',
  'HARDUSDT',
  'DNTUSDT',
  'STRAXUSDT',
  'UNFIUSDT',
  'ROSEUSDT',
  'AVAUSDT',
  'XEMUSDT',
  'AAVEUPUSDT',
  'AAVEDOWNUSDT',
  'SKLUSDT',
  'SUSDUSDT',
  'SUSHIUPUSDT',
  'SUSHIDOWNUSDT',
  'XLMUPUSDT',
  'XLMDOWNUSDT',
  'GRTUSDT',
  'JUVUSDT',
  'PSGUSDT',
  'USDTBVND',
  '1INCHUSDT',
  'REEFUSDT',
  'OGUSDT',
  'ATMUSDT',
  'ASRUSDT',
  'CELOUSDT',
  'RIFUSDT',
  'BTCSTUSDT',
  'TRUUSDT',
  'CKBUSDT',
  'TWTUSDT',
  'FIROUSDT',
  'LITUSDT',
  'SFPUSDT',
  'DODOUSDT',
  'CAKEUSDT',
  'ACMUSDT',
  'BADGERUSDT',
  'FISUSDT',
  'OMUSDT',
  'PONDUSDT',
  'DEGOUSDT',
  'ALICEUSDT',
  'LINAUSDT',
  'PERPUSDT',
  'RAMPUSDT',
  'SUPERUSDT',
  'CFXUSDT',
  'EPSUSDT',
  'AUTOUSDT',
  'TKOUSDT',
  'PUNDIXUSDT',
  'TLMUSDT',
  '1INCHUPUSDT',
  '1INCHDOWNUSDT',
  'BTGUSDT',
  'MIRUSDT',
  'BARUSDT',
  'FORTHUSDT',
  'BAKEUSDT',
  'BURGERUSDT',
  'SLPUSDT',
  'BNBBTC',
  'BNBETH',
  'BNBUSDT',
  'VENBNB',
  'YOYOBNB',
  'POWRBNB',
  'NULSBNB',
  'RCNBNB',
  'RDNBNB',
  'DLTBNB',
  'WTCBNB',
  'AMBBNB',
  'BCCBNB',
  'BATBNB',
  'BCPTBNB',
  'NEOBNB',
  'QSPBNB',
  'BTSBNB',
  'XZCBNB',
  'LSKBNB',
  'IOTABNB',
  'ADXBNB',
  'CMTBNB',
  'XLMBNB',
  'CNDBNB',
  'WABIBNB',
  'LTCBNB',
  'WAVESBNB',
  'GTOBNB',
  'ICXBNB',
  'OSTBNB',
  'AIONBNB',
  'NEBLBNB',
  'BRDBNB',
  'MCOBNB',
  'NAVBNB',
  'TRIGBNB',
  'APPCBNB',
  'RLCBNB',
  'PIVXBNB',
  'STEEMBNB',
  'NANOBNB',
  'VIABNB',
  'BLZBNB',
  'AEBNB',
  'RPXBNB',
  'NCASHBNB',
  'POABNB',
  'ZILBNB',
  'ONTBNB',
  'STORMBNB',
  'QTUMBNB',
  'XEMBNB',
  'WANBNB',
  'SYSBNB',
  'QLCBNB',
  'ADABNB',
  'GNTBNB',
  'LOOMBNB',
  'BCNBNB',
  'REPBNB',
  'TUSDBNB',
  'ZENBNB',
  'SKYBNB',
  'EOSBNB',
  'CVCBNB',
  'THETABNB',
  'XRPBNB',
  'AGIBNB',
  'NXSBNB',
  'ENJBNB',
  'TRXBNB',
  'ETCBNB',
  'SCBNB',
  'NASBNB',
  'MFTBNB',
  'ARDRBNB',
  'VETBNB',
  'POLYBNB',
  'PHXBNB',
  'GOBNB',
  'PAXBNB',
  'RVNBNB',
  'DCRBNB',
  'USDCBNB',
  'MITHBNB',
  'BNBPAX',
  'RENBNB',
  'BNBTUSD',
  'BNBUSDC',
  'BTTBNB',
  'BNBUSDS',
  'ONGBNB',
  'HOTBNB',
  'ZRXBNB',
  'FETBNB',
  'XMRBNB',
  'ZECBNB',
  'IOSTBNB',
  'CELRBNB',
  'DASHBNB',
  'OMGBNB',
  'MATICBNB',
  'ATOMBNB',
  'PHBBNB',
  'TFUELBNB',
  'ONEBNB',
  'FTMBNB',
  'ALGOBNB',
  'ERDBNB',
  'DOGEBNB',
  'DUSKBNB',
  'ANKRBNB',
  'WINBNB',
  'COSBNB',
  'COCOSBNB',
  'TOMOBNB',
  'PERLBNB',
  'CHZBNB',
  'BANDBNB',
  'BNBBUSD',
  'BEAMBNB',
  'XTZBNB',
  'HBARBNB',
  'NKNBNB',
  'STXBNB',
  'KAVABNB',
  'BNBNGN',
  'ARPABNB',
  'CTXCBNB',
  'BCHBNB',
  'BNBRUB',
  'TROYBNB',
  'VITEBNB',
  'FTTBNB',
  'BNBTRY',
  'BNBEUR',
  'OGNBNB',
  'DREPBNB',
  'TCTBNB',
  'WRXBNB',
  'LTOBNB',
  'STRATBNB',
  'MBLBNB',
  'COTIBNB',
  'STPTBNB',
  'BNBZAR',
  'BNBBKRW',
  'SOLBNB',
  'BNBIDRT',
  'CTSIBNB',
  'HIVEBNB',
  'CHRBNB',
  'MDTBNB',
  'STMXBNB',
  'IQBNB',
  'BNBGBP',
  'DGBBNB',
  'COMPBNB',
  'BNBBIDR',
  'SXPBNB',
  'SNXBNB',
  'VTHOBNB',
  'IRISBNB',
  'MKRBNB',
  'DAIBNB',
  'RUNEBNB',
  'FIOBNB',
  'AVABNB',
  'BNBAUD',
  'BALBNB',
  'YFIBNB',
  'BNBDAI',
  'JSTBNB',
  'SRMBNB',
  'ANTBNB',
  'CRVBNB',
  'SANDBNB',
  'OCEANBNB',
  'NMRBNB',
  'DOTBNB',
  'LUNABNB',
  'RSRBNB',
  'PAXGBNB',
  'WNXMBNB',
  'TRBBNB',
  'BZRXBNB',
  'SUSHIBNB',
  'YFIIBNB',
  'KSMBNB',
  'EGLDBNB',
  'DIABNB',
  'BELBNB',
  'WINGBNB',
  'SWRVBNB',
  'CREAMBNB',
  'UNIBNB',
  'AVAXBNB',
  'BAKEBNB',
  'BURGERBNB',
  'FLMBNB',
  'CAKEBNB',
  'SPARTABNB',
  'XVSBNB',
  'ALPHABNB',
  'AAVEBNB',
  'NEARBNB',
  'FILBNB',
  'INJBNB',
  'CTKBNB',
  'KP3RBNB',
  'AXSBNB',
  'HARDBNB',
  'BNBBRL',
  'UNFIBNB',
  'PROMBNB',
  'BIFIBNB',
  'BNBUAH',
  'ETHBTC',
  'QTUMETH',
  'EOSETH',
  'SNTETH',
  'BNTETH',
  'BNBETH',
  'ETHUSDT',
  'OAXETH',
  'DNTETH',
  'MCOETH',
  'ICNETH',
  'WTCETH',
  'LRCETH',
  'OMGETH',
  'ZRXETH',
  'STRATETH',
  'SNGLSETH',
  'BQXETH',
  'KNCETH',
  'FUNETH',
  'SNMETH',
  'NEOETH',
  'IOTAETH',
  'LINKETH',
  'XVGETH',
  'SALTETH',
  'MDAETH',
  'MTLETH',
  'SUBETH',
  'ETCETH',
  'MTHETH',
  'ENGETH',
  'ZECETH',
  'ASTETH',
  'DASHETH',
  'BTGETH',
  'EVXETH',
  'REQETH',
  'VIBETH',
  'HSRETH',
  'TRXETH',
  'POWRETH',
  'ARKETH',
  'YOYOETH',
  'XRPETH',
  'MODETH',
  'ENJETH',
  'STORJETH',
  'VENETH',
  'KMDETH',
  'RCNETH',
  'NULSETH',
  'RDNETH',
  'XMRETH',
  'DLTETH',
  'AMBETH',
  'BCCETH',
  'BATETH',
  'BCPTETH',
  'ARNETH',
  'GVTETH',
  'CDTETH',
  'GXSETH',
  'POEETH',
  'QSPETH',
  'BTSETH',
  'XZCETH',
  'LSKETH',
  'TNTETH',
  'FUELETH',
  'MANAETH',
  'BCDETH',
  'DGDETH',
  'ADXETH',
  'ADAETH',
  'PPTETH',
  'CMTETH',
  'XLMETH',
  'CNDETH',
  'LENDETH',
  'WABIETH',
  'LTCETH',
  'TNBETH',
  'WAVESETH',
  'GTOETH',
  'ICXETH',
  'OSTETH',
  'ELFETH',
  'AIONETH',
  'NEBLETH',
  'BRDETH',
  'EDOETH',
  'WINGSETH',
  'NAVETH',
  'LUNETH',
  'TRIGETH',
  'APPCETH',
  'VIBEETH',
  'RLCETH',
  'INSETH',
  'PIVXETH',
  'IOSTETH',
  'CHATETH',
  'STEEMETH',
  'NANOETH',
  'VIAETH',
  'BLZETH',
  'AEETH',
  'RPXETH',
  'NCASHETH',
  'POAETH',
  'ZILETH',
  'ONTETH',
  'STORMETH',
  'XEMETH',
  'WANETH',
  'WPRETH',
  'QLCETH',
  'SYSETH',
  'GRSETH',
  'CLOAKETH',
  'GNTETH',
  'LOOMETH',
  'BCNETH',
  'REPETH',
  'ETHTUSD',
  'TUSDETH',
  'ZENETH',
  'SKYETH',
  'CVCETH',
  'THETAETH',
  'IOTXETH',
  'QKCETH',
  'AGIETH',
  'NXSETH',
  'DATAETH',
  'SCETH',
  'NPXSETH',
  'KEYETH',
  'NASETH',
  'MFTETH',
  'DENTETH',
  'ARDRETH',
  'HOTETH',
  'VETETH',
  'DOCKETH',
  'PHXETH',
  'HCETH',
  'PAXETH',
  'ETHPAX',
  'ETHUSDC',
  'ETHBUSD',
  'ETHRUB',
  'ETHTRY',
  'ETHEUR',
  'ETHZAR',
  'ETHBKRW',
  'STMXETH',
  'ETHGBP',
  'ETHBIDR',
  'ETHAUD',
  'ETHDAI',
  'ETHNGN',
  'WBTCETH',
  'SCRTETH',
  'AAVEETH',
  'EASYETH',
  'ETHBRL',
  'RENBTCETH',
  'SLPETH',
  'CVPETH',
  'STRAXETH',
  'FRONTETH',
  'HEGICETH',
  'SUSDETH',
  'COVERETH',
  'GLMETH',
  'GHSTETH',
  'DFETH',
  'GRTETH',
  'DEXEETH',
  'FIROETH',
  'BETHETH',
  'PROSETH',
  'UFTETH',
  'PUNDIXETH',
  'EZETH',
];

export const sampleProfileOrders = {
  margin: {
    btcusdt: {
      owner: 'sub_account',
      last_orders: [
        {
          symbol: 'BTCUSDT',
          id: 3177661754,
          orderId: 21859610647,
          price: '29970.41',
          qty: '0.009',
          quoteQty: '269.73369',
          commission: '0.000009',
          commissionAsset: 'BTC',
          time: 1690161332396,
          isBuyer: true,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177662357,
          orderId: 21859622337,
          price: '29953.5',
          qty: '0.003',
          quoteQty: '89.8605',
          commission: '0.000003',
          commissionAsset: 'BTC',
          time: 1690161409122,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177669446,
          orderId: 21859627264,
          price: '29933',
          qty: '0.003',
          quoteQty: '89.799',
          commission: '0.000003',
          commissionAsset: 'BTC',
          time: 1690162641239,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177670522,
          orderId: 21859631757,
          price: '29912.5',
          qty: '0.004',
          quoteQty: '119.65',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690162744943,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177671839,
          orderId: 21859643270,
          price: '29892',
          qty: '0.004',
          quoteQty: '119.568',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690162746324,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177674956,
          orderId: 21859648681,
          price: '29871.5',
          qty: '0.004',
          quoteQty: '119.486',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690162880197,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177690887,
          orderId: 21859649626,
          price: '29851',
          qty: '0.004',
          quoteQty: '119.404',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690164436039,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177694975,
          orderId: 21859650942,
          price: '29830.5',
          qty: '0.004',
          quoteQty: '119.322',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690164626772,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177699530,
          orderId: 21859657485,
          price: '29810',
          qty: '0.004',
          quoteQty: '119.24',
          commission: '0.000004',
          commissionAsset: 'BTC',
          time: 1690164900045,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177701800,
          orderId: 21859663850,
          price: '29789.5',
          qty: '0.005',
          quoteQty: '148.9475',
          commission: '0.000005',
          commissionAsset: 'BTC',
          time: 1690164927724,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177714592,
          orderId: 21859666010,
          price: '29769',
          qty: '0.005',
          quoteQty: '148.845',
          commission: '0.000005',
          commissionAsset: 'BTC',
          time: 1690165986153,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177728850,
          orderId: 21859667029,
          price: '29748.5',
          qty: '0.005',
          quoteQty: '148.7425',
          commission: '0.000005',
          commissionAsset: 'BTC',
          time: 1690167994066,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177730397,
          orderId: 21859668646,
          price: '29728',
          qty: '0.006',
          quoteQty: '178.368',
          commission: '0.000006',
          commissionAsset: 'BTC',
          time: 1690168059340,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177733094,
          orderId: 21859670972,
          price: '29707.5',
          qty: '0.006',
          quoteQty: '178.245',
          commission: '0.000006',
          commissionAsset: 'BTC',
          time: 1690168203181,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177747774,
          orderId: 21859672396,
          price: '29687',
          qty: '0.006',
          quoteQty: '178.122',
          commission: '0.000006',
          commissionAsset: 'BTC',
          time: 1690170262473,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177759663,
          orderId: 21859674066,
          price: '29666.5',
          qty: '0.007',
          quoteQty: '207.6655',
          commission: '0.000007',
          commissionAsset: 'BTC',
          time: 1690171597988,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177896580,
          orderId: 21859674949,
          price: '29646',
          qty: '0.007',
          quoteQty: '207.522',
          commission: '0.000007',
          commissionAsset: 'BTC',
          time: 1690192198687,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177897387,
          orderId: 21859679772,
          price: '29625.5',
          qty: '0.008',
          quoteQty: '237.004',
          commission: '0.000008',
          commissionAsset: 'BTC',
          time: 1690192199040,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177898165,
          orderId: 21859685053,
          price: '29605',
          qty: '0.009',
          quoteQty: '266.445',
          commission: '0.000009',
          commissionAsset: 'BTC',
          time: 1690192199042,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177900940,
          orderId: 21859685767,
          price: '29584.5',
          qty: '0.01',
          quoteQty: '295.845',
          commission: '0.00001',
          commissionAsset: 'BTC',
          time: 1690192200381,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177902324,
          orderId: 21859686892,
          price: '29564',
          qty: '0.011',
          quoteQty: '325.204',
          commission: '0.000011',
          commissionAsset: 'BTC',
          time: 1690192201462,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177904010,
          orderId: 21859688377,
          price: '29543.5',
          qty: '0.013',
          quoteQty: '384.0655',
          commission: '0.000013',
          commissionAsset: 'BTC',
          time: 1690192202461,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177904774,
          orderId: 21859689018,
          price: '29523',
          qty: '0.015',
          quoteQty: '442.845',
          commission: '0.000015',
          commissionAsset: 'BTC',
          time: 1690192202649,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177905804,
          orderId: 21859691848,
          price: '29502.5',
          qty: '0.018',
          quoteQty: '531.045',
          commission: '0.000018',
          commissionAsset: 'BTC',
          time: 1690192202651,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177911547,
          orderId: 21859694196,
          price: '29482',
          qty: '0.022',
          quoteQty: '648.604',
          commission: '0.000022',
          commissionAsset: 'BTC',
          time: 1690192204179,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177912216,
          orderId: 21859695673,
          price: '29461.5',
          qty: '0.03',
          quoteQty: '883.845',
          commission: '0.00003',
          commissionAsset: 'BTC',
          time: 1690192204179,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177913046,
          orderId: 21859697783,
          price: '29441',
          qty: '0.0137',
          quoteQty: '403.3417',
          commission: '0.0000137',
          commissionAsset: 'BTC',
          time: 1690192204179,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177913047,
          orderId: 21859697783,
          price: '29441',
          qty: '0.0313',
          quoteQty: '921.5033',
          commission: '0.0000313',
          commissionAsset: 'BTC',
          time: 1690192204179,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177913662,
          orderId: 21859704821,
          price: '29420.5',
          qty: '0.089',
          quoteQty: '2618.4245',
          commission: '0.000089',
          commissionAsset: 'BTC',
          time: 1690192204179,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177914740,
          orderId: 21861409180,
          price: '29400',
          qty: '0.11892',
          quoteQty: '3496.248',
          commission: '3.496248',
          commissionAsset: 'USDT',
          time: 1690192204179,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3177956343,
          orderId: 21862962923,
          price: '29078.42',
          qty: '0.27672',
          quoteQty: '8046.5803824',
          commission: '8.04658038',
          commissionAsset: 'USDT',
          time: 1690192316510,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3178033199,
          orderId: 21863713890,
          price: '29276.02',
          qty: '0.01024',
          quoteQty: '299.7864448',
          commission: '0.00001024',
          commissionAsset: 'BTC',
          time: 1690194393313,
          isBuyer: true,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3179566493,
          orderId: 21886085636,
          price: '29380.01',
          qty: '0.00459',
          quoteQty: '134.8542459',
          commission: '0.00000459',
          commissionAsset: 'BTC',
          time: 1690398076521,
          isBuyer: true,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3179593872,
          orderId: 21886183742,
          price: '29316',
          qty: '0.05',
          quoteQty: '1465.8',
          commission: '1.4658',
          commissionAsset: 'USDT',
          time: 1690399413751,
          isBuyer: false,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223652,
          orderId: 21894873942,
          price: '29224.91',
          qty: '0.0009',
          quoteQty: '26.302419',
          commission: '0.02630242',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223653,
          orderId: 21894873942,
          price: '29224.91',
          qty: '0.0008',
          quoteQty: '23.379928',
          commission: '0.02337993',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223654,
          orderId: 21894873942,
          price: '29224.61',
          qty: '0.00035',
          quoteQty: '10.2286135',
          commission: '0.01022861',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223655,
          orderId: 21894873942,
          price: '29224.11',
          qty: '0.0009',
          quoteQty: '26.301699',
          commission: '0.0263017',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223656,
          orderId: 21894873942,
          price: '29224.1',
          qty: '0.0008',
          quoteQty: '23.37928',
          commission: '0.02337928',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223657,
          orderId: 21894873942,
          price: '29224.1',
          qty: '0.0009',
          quoteQty: '26.30169',
          commission: '0.02630169',
          commissionAsset: 'USDT',
          time: 1690479689105,
          isBuyer: false,
          isMaker: false,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180223854,
          orderId: 21894873942,
          price: '29224.1',
          qty: '0.04535',
          quoteQty: '1325.312935',
          commission: '1.32531294',
          commissionAsset: 'USDT',
          time: 1690479713404,
          isBuyer: false,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
        {
          symbol: 'BTCUSDT',
          id: 3180229476,
          orderId: 21894929697,
          price: '29224.1',
          qty: '0.05002',
          quoteQty: '1461.789482',
          commission: '0.00005002',
          commissionAsset: 'BTC',
          time: 1690480580618,
          isBuyer: true,
          isMaker: true,
          isBestMatch: true,
          isIsolated: true,
        },
      ],
      account_balance: {
        BTC: {
          borrowed: 0.150001,
          free: 5e-6,
          locked: 0.10455,
          total: 0.10455500000000001,
          asset: 'BTC',
        },
        USDT: {
          borrowed: 800,
          free: 0.09212,
          locked: 3146.944714,
          total: 3147.036834,
          asset: 'USDT',
        },
      },
      open_orders: [
        {
          symbol: 'BTCUSDT',
          orderId: 21886095259,
          clientOrderId: 'and_5a224c034b3f4844b1ffc00f17d9477c',
          price: '70000',
          origQty: '0.00458',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'SELL',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690398122117,
          updateTime: 1690398122117,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21894933459,
          clientOrderId: 'and_f5291fc450f046c1ad64a565b74def29',
          price: '28829.6',
          origQty: '0.05454',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'BUY',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690480209014,
          updateTime: 1690480209014,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21894993096,
          clientOrderId: 'and_151143c675f54cda91a787c548fe9282',
          price: '29640.6',
          origQty: '0.04998',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'SELL',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690480788929,
          updateTime: 1690480788929,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21894998329,
          clientOrderId: 'and_68e75b7822ce42ffa4347ea87ed41ed0',
          price: '30046.2',
          origQty: '0.04999',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'SELL',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690480846634,
          updateTime: 1690480846634,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21895024068,
          clientOrderId: 'and_3fbccdc79b234bb697639298a4279973',
          price: '29641',
          origQty: '0.00135',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'STOP_LOSS_LIMIT',
          side: 'BUY',
          stopPrice: '29640.6',
          icebergQty: '0',
          time: 1690480994590,
          updateTime: 1690480994590,
          isWorking: false,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21895035376,
          clientOrderId: 'and_0380ca49e37a4036bfd870f3c80f3dd2',
          price: '30047',
          origQty: '0.00134',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'STOP_LOSS_LIMIT',
          side: 'BUY',
          stopPrice: '30046.2',
          icebergQty: '0',
          time: 1690481043961,
          updateTime: 1690481043961,
          isWorking: false,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21901676341,
          clientOrderId: 'and_8e5df9c5664d4c74a727aae085e3b604',
          price: '10000',
          origQty: '0.02636',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'BUY',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690554804508,
          updateTime: 1690554804508,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
        {
          symbol: 'BTCUSDT',
          orderId: 21902428792,
          clientOrderId: 'and_7e02b787118542bb82c77fa2f5e95aa9',
          price: '15000',
          origQty: '0.12307',
          executedQty: '0',
          cummulativeQuoteQty: '0',
          status: 'NEW',
          timeInForce: 'GTC',
          type: 'LIMIT',
          side: 'BUY',
          stopPrice: '0',
          icebergQty: '0',
          time: 1690558901305,
          updateTime: 1690558901305,
          isWorking: true,
          accountId: 30200902,
          isIsolated: true,
          selfTradePreventionMode: 'NONE',
        },
      ],
    },
  },
  future: {
    btcusdt: {
      owner: 'main_account',
      last_order: 29534.6,
      account_balance: 500.5,
      open_orders: [
        {
          orderId: 175647083592,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'XPGIDbHiIMdmw2WOpm7fpo',
          price: '29038.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240248,
          updateTime: 1690555240248,
        },
        {
          orderId: 175647082314,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '4xPzZD6KbnOPD8wOsfxxNC',
          price: '29012.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239877,
          updateTime: 1690555239877,
        },
        {
          orderId: 175647104329,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'JHXiJNejR3knLEw3zy0HTV',
          price: '29203.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242838,
          updateTime: 1690555242838,
        },
        {
          orderId: 175379040326,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'LnmKZMqjj2hKPxoIdMzCxd',
          price: '29616.10',
          avgPrice: '0',
          origQty: '0.016',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477245734,
          updateTime: 1690477245734,
        },
        {
          orderId: 175647073344,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Ppb1qPwYsAW5eWTE3EYysa',
          price: '28906.60',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238472,
          updateTime: 1690555238472,
        },
        {
          orderId: 175381792691,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'android_c4rqCRlYhj1jJA9B4rzN',
          price: '0',
          avgPrice: '0',
          origQty: '0',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTE_GTC',
          type: 'STOP_MARKET',
          reduceOnly: true,
          closePosition: true,
          side: 'BUY',
          positionSide: 'SHORT',
          stopPrice: '29624.10',
          workingType: 'CONTRACT_PRICE',
          priceProtect: true,
          origType: 'STOP_MARKET',
          time: 1690477819415,
          updateTime: 1690477819415,
        },
        {
          orderId: 175647085634,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'FzN7z5fUupdVSdgcWFrujI',
          price: '29058.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240640,
          updateTime: 1690555240640,
        },
        {
          orderId: 175647081029,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'KfuJPNseD3PkzMpN09fV8C',
          price: '28880.20',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239459,
          updateTime: 1690555239459,
        },
        {
          orderId: 175647096902,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'zsZfGdkEHRZbFJmniXpNKJ',
          price: '29157.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241812,
          updateTime: 1690555241812,
        },
        {
          orderId: 175379040322,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '6oDwVLf4hkaovSyPlkr3Gw',
          price: '29607.90',
          avgPrice: '0',
          origQty: '0.012',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477245734,
          updateTime: 1690477245734,
        },
        {
          orderId: 175647104583,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'UIcKdA9YUyUOgphDtVOyQ6',
          price: '29111.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242920,
          updateTime: 1690555242920,
        },
        {
          orderId: 175647053127,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'LWCutakhrOh5ILex6HiU77',
          price: '28781.20',
          avgPrice: '0',
          origQty: '0.006',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236559,
          updateTime: 1690555236559,
        },
        {
          orderId: 175647091782,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Uf6biSaDgTPcQMTpcSNZYg',
          price: '29117.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241288,
          updateTime: 1690555241288,
        },
        {
          orderId: 175647096671,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'T02dtazBNTkyt4R1ROKeAj',
          price: '29131',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241763,
          updateTime: 1690555241763,
        },
        {
          orderId: 175647104340,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'NfPWqTDIsMyCBRBjmezump',
          price: '29210.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242845,
          updateTime: 1690555242845,
        },
        {
          orderId: 175647053928,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '8DMUvW1NFgvnbuDwppTn4J',
          price: '28794.40',
          avgPrice: '0',
          origQty: '0.005',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236681,
          updateTime: 1690555236681,
        },
        {
          orderId: 175647070059,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'lGIlsjkfgikgIz4TV7X8ti',
          price: '28748.20',
          avgPrice: '0',
          origQty: '0.016',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237296,
          updateTime: 1690555237296,
        },
        {
          orderId: 175647086699,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'ShwxKZ2ViZag3BuAAKmZfE',
          price: '29091.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240918,
          updateTime: 1690555240918,
        },
        {
          orderId: 175647073390,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'DDmUZovtliu764N13rNON8',
          price: '28913.20',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238501,
          updateTime: 1690555238501,
        },
        {
          orderId: 175662749023,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'android_AjbJb1o8JUGkK7ZS4rj8',
          price: '29120.70',
          avgPrice: '0',
          origQty: '0.117',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: true,
          closePosition: false,
          side: 'BUY',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690558131227,
          updateTime: 1690558131227,
        },
        {
          orderId: 175379040610,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'CQQBFCx2RDHkKzfzCWiBvU',
          price: '29599.80',
          avgPrice: '0',
          origQty: '0.010',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477245804,
          updateTime: 1690477245804,
        },
        {
          orderId: 175647047271,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '99YP6sYNwBmcHt28imlf6s',
          price: '28735',
          avgPrice: '0',
          origQty: '0.045',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236127,
          updateTime: 1690555236127,
        },
        {
          orderId: 175647086695,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Ijc1GmJudyb2hBW9U8Kuii',
          price: '29104.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240916,
          updateTime: 1690555240916,
        },
        {
          orderId: 175379054176,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'V87MZs4Y2OBm0XtKynlQWd',
          price: '29575.30',
          avgPrice: '0',
          origQty: '0.006',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477247464,
          updateTime: 1690477247464,
        },
        {
          orderId: 175647071609,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'qUee1bEffuaenqRLfjT5FT',
          price: '28860.40',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237708,
          updateTime: 1690555237708,
        },
        {
          orderId: 175647078267,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'L22sBxsZCFz9XHoquYtFf0',
          price: '28959.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238945,
          updateTime: 1690555238945,
        },
        {
          orderId: 175647049850,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'R9Krcqxew9ih9nCl38wdw7',
          price: '28741.60',
          avgPrice: '0',
          origQty: '0.024',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236237,
          updateTime: 1690555236237,
        },
        {
          orderId: 175379056501,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '4auma34SVWZ74e68BSiAUh',
          price: '29567.20',
          avgPrice: '0',
          origQty: '0.005',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477247549,
          updateTime: 1690477247549,
        },
        {
          orderId: 175647047283,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'mwYRGUGtEeoGd5gQu2FerF',
          price: '28728.40',
          avgPrice: '0',
          origQty: '0.417',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236127,
          updateTime: 1690555236127,
        },
        {
          orderId: 175647104119,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '60RUY2OsrJSTJLfoRAYbk4',
          price: '29190.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242794,
          updateTime: 1690555242794,
        },
        {
          orderId: 175647068173,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'RpKb0eBvPSDDh4ONeTelW3',
          price: '28820.80',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237190,
          updateTime: 1690555237190,
        },
        {
          orderId: 175379056902,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'eq6e3h9wQzfohLieWUMrOg',
          price: '29559',
          avgPrice: '0',
          origQty: '0.005',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477247599,
          updateTime: 1690477247599,
        },
        {
          orderId: 175647097868,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'pMnZap2E1E8l4jY8RXNvHn',
          price: '29164',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242029,
          updateTime: 1690555242029,
        },
        {
          orderId: 175647071490,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '41yHO51RjMnQZm8FRhWC4X',
          price: '28853.80',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237663,
          updateTime: 1690555237663,
        },
        {
          orderId: 175647053573,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'aoSttX8z064Mz3VsZWcrDb',
          price: '28787.80',
          avgPrice: '0',
          origQty: '0.006',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236616,
          updateTime: 1690555236616,
        },
        {
          orderId: 175647068164,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Xi3oCTySbsoCMAaIeRllks',
          price: '28807.60',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237189,
          updateTime: 1690555237189,
        },
        {
          orderId: 175379043871,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '8dblGNu2a6W8h4yDdpCVpN',
          price: '29583.50',
          avgPrice: '0',
          origQty: '0.007',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477246464,
          updateTime: 1690477246464,
        },
        {
          orderId: 175647085340,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'aerWKfxWui8pIj3Cj2E4ld',
          price: '29051.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240582,
          updateTime: 1690555240582,
        },
        {
          orderId: 175379056918,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'IqpRVQ7DG0RXBPtVunYfTm',
          price: '0',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'STOP_MARKET',
          reduceOnly: true,
          closePosition: false,
          side: 'BUY',
          positionSide: 'SHORT',
          stopPrice: '29640.60',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'STOP_MARKET',
          time: 1690477247602,
          updateTime: 1690477247602,
        },
        {
          orderId: 175647081745,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'IL2n4zpX9GjCMSGvqMjOxx',
          price: '28992.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239663,
          updateTime: 1690555239663,
        },
        {
          orderId: 175379056156,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'QnCNfo0Mu6U6lSlzIadpCO',
          price: '0',
          avgPrice: '0',
          origQty: '0.005',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'STOP_MARKET',
          reduceOnly: true,
          closePosition: false,
          side: 'BUY',
          positionSide: 'SHORT',
          stopPrice: '29640.60',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'STOP_MARKET',
          time: 1690477247519,
          updateTime: 1690477247519,
        },
        {
          orderId: 175647049257,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Np80nRrMLWvI7NsAe6kMVQ',
          price: '28754.80',
          avgPrice: '0',
          origQty: '0.012',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236223,
          updateTime: 1690555236223,
        },
        {
          orderId: 175647070504,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'tPNRoaAd3BtBID0tvFxv8V',
          price: '28840.60',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237340,
          updateTime: 1690555237340,
        },
        {
          orderId: 175647096360,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'rWVJButTzj2KI9jOrwyJE5',
          price: '29124.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241705,
          updateTime: 1690555241705,
        },
        {
          orderId: 175647082284,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'GfwYhL8gBVFyBjMbcH6ZUT',
          price: '28900',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239859,
          updateTime: 1690555239859,
        },
        {
          orderId: 175647085612,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'cOpyi37twHrE5BM2M97ygO',
          price: '29045.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240635,
          updateTime: 1690555240635,
        },
        {
          orderId: 175647069984,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'TLvgdw0kmx4s94kgRNCetI',
          price: '28834',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237292,
          updateTime: 1690555237292,
        },
        {
          orderId: 175647087651,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'iFL20nxaSFhKgfRKWgqa0k',
          price: '29098',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241088,
          updateTime: 1690555241088,
        },
        {
          orderId: 175647104827,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'qMLeKi2gVZAQs7bKm1pVZ8',
          price: '29150.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242989,
          updateTime: 1690555242989,
        },
        {
          orderId: 175647081531,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '7CG6IWhjU1ADWcMBthFvXL',
          price: '28972.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239568,
          updateTime: 1690555239568,
        },
        {
          orderId: 175647085882,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'sME9LflHzq8bDnORaMAaJL',
          price: '28939.60',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240711,
          updateTime: 1690555240711,
        },
        {
          orderId: 175647102271,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'udwi2AFEos0j0z8KE33tsK',
          price: '29197',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242405,
          updateTime: 1690555242405,
        },
        {
          orderId: 175647104575,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'C37tstqQRNAVqJT8snF5jC',
          price: '29223.40',
          avgPrice: '0',
          origQty: '0.026',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242915,
          updateTime: 1690555242915,
        },
        {
          orderId: 175647082303,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'FKQltknEoExumPhxfsvEYS',
          price: '29005.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239871,
          updateTime: 1690555239871,
        },
        {
          orderId: 175647096638,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'PDSbhC0OyWSVGnwFxCIub2',
          price: '29137.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241756,
          updateTime: 1690555241756,
        },
        {
          orderId: 175647073585,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '1736ZSerPuUmFibddcrRNp',
          price: '28933',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238587,
          updateTime: 1690555238587,
        },
        {
          orderId: 175647070514,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'tFfLieSY8zrfJyBtKfMEaJ',
          price: '28847.20',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237344,
          updateTime: 1690555237344,
        },
        {
          orderId: 175647086132,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'YfR3j4JVWzh4KSr25RIIDH',
          price: '29078.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240775,
          updateTime: 1690555240775,
        },
        {
          orderId: 175647047882,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'g9Ph6hGheB8vI49snvLMGV',
          price: '28768',
          avgPrice: '0',
          origQty: '0.008',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236160,
          updateTime: 1690555236160,
        },
        {
          orderId: 175647097806,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '9o7QFn6bIovlXC37R9oVJw',
          price: '29177.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242021,
          updateTime: 1690555242021,
        },
        {
          orderId: 175379057609,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'JONPsQjR6RA2hjd0YdtNrX',
          price: '29542.70',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477247682,
          updateTime: 1690477247682,
        },
        {
          orderId: 175647096772,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'MNdPZ6pW7kYHhZmEOB7Mhv',
          price: '29144.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241786,
          updateTime: 1690555241786,
        },
        {
          orderId: 175647049926,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'tZfA5IKwTvc3T3NESM0aE1',
          price: '28774.60',
          avgPrice: '0',
          origQty: '0.007',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236243,
          updateTime: 1690555236243,
        },
        {
          orderId: 175647081691,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'agKBpVIqmNuOa1aVOZu859',
          price: '28985.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239641,
          updateTime: 1690555239641,
        },
        {
          orderId: 175647071711,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'SUTtV6ceie0aDU5d9DFvKZ',
          price: '28873.60',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237751,
          updateTime: 1690555237751,
        },
        {
          orderId: 175647086814,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'rqz5wDaTCKuMS77Z4AUtno',
          price: '29084.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240931,
          updateTime: 1690555240931,
        },
        {
          orderId: 175647069392,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'LWa0Bz6bie53jb5fGaD6yC',
          price: '28827.40',
          avgPrice: '0',
          origQty: '0.003',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237252,
          updateTime: 1690555237252,
        },
        {
          orderId: 175647086036,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'prbrJAy2pwueoMB2B8hKAy',
          price: '29071.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240750,
          updateTime: 1690555240750,
        },
        {
          orderId: 175647086057,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'LQUWqeh797EpVwoXUHZ6Yy',
          price: '29065',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240753,
          updateTime: 1690555240753,
        },
        {
          orderId: 175647081185,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'K1vNZqh1TwKU9C6QddV15i',
          price: '28966',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239498,
          updateTime: 1690555239498,
        },
        {
          orderId: 175647099130,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'aWSSoP3TkopZUgbu01cNBF',
          price: '29183.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242195,
          updateTime: 1690555242195,
        },
        {
          orderId: 175647050747,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'FMp7FLOVH8sO50uIs28Gnr',
          price: '28761.40',
          avgPrice: '0',
          origQty: '0.010',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555236295,
          updateTime: 1690555236295,
        },
        {
          orderId: 175647081723,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '137aj60hhb7Gc5Ly8hcn0U',
          price: '28999',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239653,
          updateTime: 1690555239653,
        },
        {
          orderId: 175647074044,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '0MvcgBDbqV1axAzUQMXBOG',
          price: '28952.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238818,
          updateTime: 1690555238818,
        },
        {
          orderId: 175647073520,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '6gmeI0w8wwB56zD0e3pOtO',
          price: '28919.80',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238566,
          updateTime: 1690555238566,
        },
        {
          orderId: 175647073268,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'eDUjImUbeI2u3EXL41OROC',
          price: '28893.40',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238418,
          updateTime: 1690555238418,
        },
        {
          orderId: 175647074039,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Vr3yX5TQjvsQRRaFv9MgYT',
          price: '28867',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238815,
          updateTime: 1690555238816,
        },
        {
          orderId: 175379038604,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'jPXWaCKpaDcxaZFU0jqDA0',
          price: '29632.40',
          avgPrice: '0',
          origQty: '0.049',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477245352,
          updateTime: 1690477245352,
        },
        {
          orderId: 175379038602,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'Fv4FxxMzanLYzjAY0CYpGf',
          price: '29624.20',
          avgPrice: '0',
          origQty: '0.024',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477245350,
          updateTime: 1690477245350,
        },
        {
          orderId: 175647097475,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'nrieemnh4A4vXRD0Euem0S',
          price: '29170.60',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241956,
          updateTime: 1690555241956,
        },
        {
          orderId: 175647066777,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'p3IzlZY6yO74uETB4oiL1H',
          price: '28801',
          avgPrice: '0',
          origQty: '0.005',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237134,
          updateTime: 1690555237134,
        },
        {
          orderId: 175647071899,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'RyfxTAtfLrK07gZ63me98V',
          price: '28886.80',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555237858,
          updateTime: 1690555237858,
        },
        {
          orderId: 175647081628,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'yqN7DW4p32sKJb2OuSnGmM',
          price: '28979.20',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555239616,
          updateTime: 1690555239616,
        },
        {
          orderId: 175647073170,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'LcO1XPurgHMzbkTVMMh0aB',
          price: '28814.20',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238386,
          updateTime: 1690555238386,
        },
        {
          orderId: 175647096982,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'CDjLCEbE4Vhu1FvQLZQdgu',
          price: '29025.40',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241830,
          updateTime: 1690555241830,
        },
        {
          orderId: 175647082664,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '0cuQaGYvtARjJBbWdDu0tA',
          price: '29018.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555240020,
          updateTime: 1690555240020,
        },
        {
          orderId: 175647073441,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: '7teUdtoVVACRT56K9HscQR',
          price: '28926.40',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238538,
          updateTime: 1690555238538,
        },
        {
          orderId: 175379057071,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'daxm5DoSr6egZgnT4R85Rs',
          price: '29550.90',
          avgPrice: '0',
          origQty: '0.004',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477247612,
          updateTime: 1690477247612,
        },
        {
          orderId: 175647073976,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'X5rApdJF45A1Tye4Qm4vWf',
          price: '28946.20',
          avgPrice: '0',
          origQty: '0.002',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555238769,
          updateTime: 1690555238769,
        },
        {
          orderId: 175647097019,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'PyJn4BmBZSZXwAWGQwvRe9',
          price: '29032',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555241839,
          updateTime: 1690555241839,
        },
        {
          orderId: 175647104440,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'GiTREIQNbGBEI8f0z3pTFL',
          price: '29216.80',
          avgPrice: '0',
          origQty: '0.001',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'BUY',
          positionSide: 'LONG',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690555242856,
          updateTime: 1690555242856,
        },
        {
          orderId: 175379043763,
          symbol: 'BTCUSDT',
          status: 'NEW',
          clientOrderId: 'QDykUIyciwJkospZAKfhXa',
          price: '29591.60',
          avgPrice: '0',
          origQty: '0.008',
          executedQty: '0',
          cumQuote: '0.00000',
          timeInForce: 'GTC',
          type: 'LIMIT',
          reduceOnly: false,
          closePosition: false,
          side: 'SELL',
          positionSide: 'SHORT',
          stopPrice: '0',
          workingType: 'CONTRACT_PRICE',
          priceProtect: false,
          origType: 'LIMIT',
          time: 1690477246444,
          updateTime: 1690477246444,
        },
      ],
      position: {
        long: {
          symbol: 'BTCUSDT',
          positionAmt: 0.0,
          entryPrice: 0.0,
          markPrice: 29345.2,
          unRealizedProfit: 0.0,
          liquidationPrice: 0.0,
          leverage: 125,
          maxNotionalValue: 50000.0,
          marginType: 'cross',
          isolatedMargin: 0.0,
          isAutoAddMargin: 'false',
          positionSide: 'LONG',
          notional: '0',
          isolatedWallet: 0.0,
          updateTime: 1690554605310,
        },
        short: {
          symbol: 'BTCUSDT',
          positionAmt: -0.117,
          entryPrice: 29355.438462,
          markPrice: 29345.2,
          unRealizedProfit: 1.1979,
          liquidationPrice: 33498.802534,
          leverage: 125,
          maxNotionalValue: 50000.0,
          marginType: 'cross',
          isolatedMargin: 0.0,
          isAutoAddMargin: 'false',
          positionSide: 'SHORT',
          notional: '-3433.38840000',
          isolatedWallet: 0.0,
          updateTime: 1690557083356,
        },
        both: {
          symbol: 'BTCUSDT',
          positionAmt: 0.0,
          entryPrice: 0.0,
          markPrice: 29345.2,
          unRealizedProfit: 0.0,
          liquidationPrice: 0.0,
          leverage: 125,
          maxNotionalValue: 50000.0,
          marginType: 'cross',
          isolatedMargin: 0.0,
          isAutoAddMargin: 'false',
          positionSide: 'BOTH',
          notional: '0',
          isolatedWallet: 0.0,
          updateTime: 1685781018986,
        },
      },
    },
  },
};
