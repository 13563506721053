import { Box, Flex, useDisclosure } from '@chakra-ui/react';

import React from 'react';
import { BaseDrawer } from '../newBot/app-components/primitives/ModalComponent';
import type { IAccount, IPositionStore } from '../store/botConfig';
import { NavigationMenu, TraderOrderPage } from './SpotTradeDetailPage';
import Config1Display from './components/GenericConfigDisplay';
import { useNavigationHook } from './hooks';
import { observer } from 'mobx-react-lite';

interface Props {
  account: IAccount;
  accounts?: string[];
  positionStore?: IPositionStore;
  goBack?: () => void;
  routeSymbol?: string;
}

export const TabbedTradeOrderPage = observer(
  ({ account, accounts, positionStore, goBack, routeSymbol }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    const store = account.futureCTrader;
    const currentMarket = account.spot_markets.find(
      (o) => o.symbol === routeSymbol,
    );
    const symbol = currentMarket?.symbol;
    const loaded = account.futureCTrader.symbol;
    React.useEffect(() => {
      if (!loaded && symbol) {
        //console.log('loaded', loaded);
        account.initializeFuture(symbol);
      }
    }, [loaded, symbol, account]);

    function onSave() {
      account.editSpotProfile(symbol).then(() => {
        account.initializeFuture(symbol);
        onClose();
      });
    }

    return loaded === symbol ? (
      <Box>
        <Box bg={'white'} p="1" mb="2">
          <NavigationMenu onBack={goBack} onEdit={onOpen} />
        </Box>
        <TraderOrderPage
          accounts={accounts}
          account={account}
          positionStore={positionStore}
        />

        <BaseDrawer
          placement="top"
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          title={`Edit spot profile for ${symbol}`}
          onSave={onSave}
          footerProps={{ justifyContent: 'center' }}
          buttonText="Save"
          displayCancelButton
          buttonProps={{
            isLoading:
              store.loading || account.loading || store.account_loading,
            loadingText: 'Saving',
          }}
          closeOnOverlayClick={true}
        >
          <Flex direction="column">
            <Flex justifyContent="space-around">
              <Config1Display
                override="config_1"
                store={account.spotInstance(symbol)?.profile}
              />
            </Flex>
          </Flex>
        </BaseDrawer>
      </Box>
    ) : null;
  },
);

export const RouteAwareTabbedTradeOrderPage = observer((props: Props) => {
  const { history, match } = useNavigationHook();
  const routeSymbol = match.params.symbol;
  return (
    <TabbedTradeOrderPage
      {...props}
      goBack={history.goBack}
      routeSymbol={routeSymbol}
    />
  );
});
