import {
  Box,
  Flex,
  Button,
  Select,
  FormControl,
  FormLabel,
  Icon,
  FormHelperText,
  Input,
} from '@chakra-ui/react';
import * as React from 'react';

import { FaExchangeAlt } from 'react-icons/fa';
import { TransferData, Root } from '../../mobileAppView/stores';

type FromToComponentProps = {
  options?: string[];
  from?: string[];
  to?: string[];
  canSwitch?: boolean;
  onChange?: (e: { from: string; to: string }) => void;
  placeholder?: string;
};
const FromToComponent = ({
  options,
  from = [],
  to = [],
  canSwitch,
  placeholder = 'Select wallet',
  onChange,
}: FromToComponentProps) => {
  let [transferTo, setTransferTo] = React.useState('');
  let [transferFrom, setTransferFrom] = React.useState('');
  return (
    <Flex>
      <Box flex="1" p="3">
        <FormControl id="from" position="relative">
          <FormLabel fontSize="sm">From</FormLabel>
          <Select
            value={transferFrom}
            onChange={(event) => {
              setTransferFrom(event.target.value);
              onChange &&
                onChange({ from: event.target.value, to: transferTo });
            }}
            fontSize="sm"
            placeholder={placeholder}
          >
            {(options || from).map((wallet, index) => (
              <option key={index} value={wallet}>
                {wallet}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box width="80px" textAlign="center" alignSelf="center">
        <Box
          margin="auto"
          borderRadius="50%"
          p="10px"
          width="50px"
          height="50px"
          bg={canSwitch ? 'white' : 'gray.500'}
          border="2px solid #000"
          onClick={() => {
            if (!canSwitch) return;
            let switchWallet = transferFrom;
            onChange && onChange({ from: transferTo, to: switchWallet });
            setTransferFrom(transferTo);
            setTransferTo(switchWallet);
            switchWallet = '';
          }}
        >
          <Icon as={FaExchangeAlt} />
        </Box>
      </Box>
      <Box flex="1" p="3">
        <FormControl id="to">
          <FormLabel fontSize="sm">To</FormLabel>
          <Select
            value={transferTo}
            onChange={(event) => {
              //console.log(event.target.value);
              setTransferTo(event.target.value);
              onChange &&
                onChange({ from: transferFrom, to: event.target.value });
            }}
            fontSize="sm"
            placeholder={placeholder}
          >
            {(options || to).map((wallet, index) => (
              <option key={index} value={wallet}>
                {wallet}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Flex>
  );
};

export type MarketTransferProps = {
  data?: Array<{
    owner: string;
    wallets: Array<{
      type: string;
      asset: string;
      balance: number;
    }>;
  }>;
  loading?: boolean;
  onTransfer: (data: TransferData) => Promise<void>;
  defaultValue?: TransferData;
};

const DefaultValue = {
  from: {
    owner: '',
    wallet: '',
  },
  to: {
    owner: '',
    wallet: '',
  },
  asset: '',
  amount: 0,
};
const useTransferData = ({
  data = [],
  defaultValue = DefaultValue,
  onTransfer,
}: MarketTransferProps) => {
  const accounts = data.map((account) => account.owner);
  const [accountSelection, setAccountSelection] =
    React.useState<TransferData>(defaultValue);
  const fromSelection =
    data.find((account) => account.owner === accountSelection.from.owner)
      ?.wallets || [];
  const toSelection =
    data.find((account) => account.owner === accountSelection.to.owner)
      ?.wallets || [];
  const fromWallets = fromSelection.map((o) => o.type) || [];

  const toWallets = toSelection.map((o) => o.type) || [];
  let coinList: { [key: string]: number } = {};
  fromSelection
    .filter((o) => o.type === accountSelection.from.wallet)
    .forEach((wallet) => {
      coinList[wallet.asset] = wallet.balance;
    });
  const selectedCoin = accountSelection.asset;
  const walletAmount = accountSelection.amount;

  function onSelectionUpdate(
    e: { from: string; to: string },
    key: 'owner' | 'wallet',
  ) {
    setAccountSelection({
      from: {
        ...accountSelection.from,
        [key]: e.from,
      },
      to: {
        ...accountSelection.to,
        [key]: e.to,
      },
      asset: '',
      amount: 0,
    });
  }
  function onCoinOrAmountUpdate(e: any, key: 'asset' | 'amount') {
    setAccountSelection({
      ...accountSelection,
      [key]: e,
    });
  }
  function onTransferAllFunds() {
    setAccountSelection({
      ...accountSelection,
      amount: coinList[selectedCoin],
    });
  }
  async function transferFunds() {
    await onTransfer(accountSelection);
  }
  return {
    accounts,
    accountSelection,
    fromWallets: Array.from(new Set(fromWallets)),
    toWallets: Array.from(new Set(toWallets)),
    walletAmount,
    onCoinOrAmountUpdate,
    onSelectionUpdate,
    onTransferAllFunds,
    transferFunds,
    totalAvailable: `${coinList[selectedCoin] || 0} ${selectedCoin}`,
    coinOptions: Object.keys(coinList),
  };
};

export const MarketTransfer = ({
  data = [],
  onTransfer,
  loading,
  defaultValue = DefaultValue,
}: MarketTransferProps) => {
  const {
    accounts,
    onCoinOrAmountUpdate,
    fromWallets,
    toWallets,
    walletAmount,
    onSelectionUpdate,
    onTransferAllFunds,
    transferFunds,
    totalAvailable,
    coinOptions,
  } = useTransferData({ data, defaultValue, onTransfer });
  return (
    <>
      <FromToComponent
        options={accounts}
        canSwitch
        placeholder={'Select account'}
        onChange={(e) => {
          console.log(e);
          onSelectionUpdate(e, 'owner');
        }}
      />
      <FromToComponent
        placeholder={'Select wallet'}
        from={fromWallets}
        to={toWallets}
        onChange={(e) => {
          console.log(e);
          onSelectionUpdate(e, 'wallet');
        }}
      />
      <Box p="3">
        <FormControl id="coin">
          <FormLabel fontSize="sm">Coin</FormLabel>
          <Select
            fontSize="sm"
            onChange={(event) => {
              onCoinOrAmountUpdate(event.target.value, 'asset');
            }}
            placeholder="Select coin"
          >
            {coinOptions.map((coin, index) => (
              <option key={index} value={coin}>
                {coin}
              </option>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box p="3">
        <FormControl id="amount">
          <FormLabel fontSize="sm">Amount</FormLabel>
          <Input
            onChange={(event) => {
              onCoinOrAmountUpdate(parseFloat(event.target.value), 'amount');
            }}
            value={walletAmount}
            fontSize="sm"
            type="number"
          />
          <FormHelperText fontSize="xs">
            Available: {totalAvailable}{' '}
            <Button
              ml="2"
              onClick={onTransferAllFunds}
              color="#000"
              variant="link"
            >
              use max
            </Button>
          </FormHelperText>
        </FormControl>
      </Box>
      <Box p="3">
        <Button
          onClick={transferFunds}
          isLoading={loading}
          colorScheme="blue"
          size="md"
          width="100%"
        >
          Transfer
        </Button>
      </Box>
    </>
  );
};

export default MarketTransfer;
