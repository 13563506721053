import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  chakra,
  Divider,
  Flex,
  FlexProps,
  HStack,
  HTMLChakraProps,
  Icon,
  Img,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  Link,
  LinkProps,
  Menu,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Stack,
  Text,
  useColorModeValue as mode,
  useMenuButton,
  useToken,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { HiSelector } from 'react-icons/hi';
import CIcon from '../components/main/Icon';
import { TabLink } from './primitives/TabLink';

type ProfileType = {
  id: string;
  accounts: string[];
  email: string;
  logo: string;
  activeAccount: string;
};
interface ACType extends FlexProps {
  profile: ProfileType;
}

const AccountSwitcherButton = ({ profile, ...props }: ACType) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      as="button"
      {...buttonProps}
      w={'full'}
      display={'flex'}
      alignItems={'center'}
      rounded={'lg'}
      bg={'blue.700'}
      //   bg={'gray.700'}
      px={'3'}
      py={'2'}
      fontSize={'sm'}
      userSelect={'none'}
      cursor={'pointer'}
      outline={'0'}
      transition={'all 0.2s'}
      _active={{ bg: 'blue.800' }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Img
          w="8"
          h="8"
          rounded="md"
          objectFit="cover"
          src={profile.logo}
          alt={profile.activeAccount}
        />
        <Box textAlign="start">
          <Box isTruncated fontWeight="semibold">
            {profile.activeAccount}
          </Box>
          <Box fontSize="xs" color="gray.400">
            {profile.id}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color="gray.400">
        <HiSelector />
      </Box>
    </Flex>
  );
};

export const AccountSwitcher: React.FC<{
  profile: ProfileType;
  onLogout: () => any;
  addAccount?: () => any;
  onAccountSwitch: (account: string) => any;
  [key: string]: any;
}> = ({ profile, onLogout, addAccount, onAccountSwitch, ...rest }) => {
  return (
    <Menu>
      <AccountSwitcherButton profile={profile} {...rest} />
      <MenuList shadow="lg" py="4" color="gray.600" px="3">
        <Text fontWeight="medium" mb="2">
          {profile.email}
        </Text>
        <MenuOptionGroup defaultValue="chakra-ui">
          {profile.accounts.map((account, index) => {
            return (
              <MenuItemOption
                key={account.toLowerCase()}
                value={account.toLowerCase()}
                fontWeight="semibold"
                rounded="md"
                onClick={() => onAccountSwitch(account)}
              >
                {account}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem onClick={addAccount} rounded="md">
          Add an account
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={onLogout} rounded="md">
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const Logo = (props: HTMLChakraProps<'svg'> & { iconColor?: string }) => {
  const { iconColor = 'currentColor', ...rest } = props;
  const color = useToken('colors', iconColor);
  return (
    <chakra.svg viewBox="0 0 123 24" fill="none" height="6" {...rest}>
      <path
        d="M23 0H7a1 1 0 00-1 1v16H3c-1.654 0-3 1.346-3 3v4h14v-3.583c0-.808.645-1.417 1.5-1.417.723 0 1.5.47 1.5 1.5 0 1.93 1.57 3.5 3.5 3.5s3.5-1.57 3.5-3.5V1a1 1 0 00-1-1zM12 20.417V22H2v-2a1 1 0 011-1h9.304c-.196.43-.304.909-.304 1.417zM20 13H10v-2h10v2zm0-6H10V5h10v2z"
        fill={color}
      />
      <path
        d="M36.102 19h10.142v-2.855h-6.627v-2.99h6.108v-2.862h-6.108V7.31h6.627V4.455H36.102V19zM51.518 12.778c.007-1.242.732-1.981 1.833-1.981 1.1 0 1.754.724 1.747 1.932V19h3.473v-6.953c.007-2.457-1.492-4.098-3.786-4.098-1.612 0-2.833.817-3.316 2.145h-.121V8.09h-3.303V19h3.473v-6.222zM70.513 8.09h-3.658l-2.017 7.515h-.113l-2.01-7.514h-3.658L62.793 19h3.977l3.743-10.91zM76.192 19.206c2.863 0 4.745-1.385 5.142-3.53l-3.189-.092c-.27.724-.98 1.115-1.889 1.115-1.335 0-2.159-.888-2.159-2.223v-.092h7.273v-.867c0-3.594-2.188-5.568-5.284-5.568-3.296 0-5.412 2.258-5.412 5.64 0 3.493 2.088 5.617 5.518 5.617zm-2.095-6.84c.05-1.086.91-1.91 2.06-1.91 1.143 0 1.967.796 1.981 1.91h-4.04zM86.217 4.455h-3.473V19h3.473V4.455zM93.12 19.206c3.43 0 5.511-2.266 5.511-5.625 0-3.367-2.08-5.632-5.511-5.632-3.43 0-5.512 2.265-5.512 5.632 0 3.36 2.081 5.625 5.512 5.625zm.021-2.62c-1.285 0-1.996-1.222-1.996-3.026 0-1.811.71-3.04 1.996-3.04 1.243 0 1.953 1.229 1.953 3.04 0 1.804-.71 3.025-1.953 3.025zM100.01 23.091h3.473v-5.86h.071c.441 1.01 1.421 1.925 3.068 1.925 2.415 0 4.361-1.889 4.361-5.603 0-3.85-2.059-5.604-4.339-5.604-1.726 0-2.671 1.008-3.09 2.01h-.106V8.09h-3.438v15zm3.402-9.546c0-1.782.739-2.883 2.01-2.883 1.286 0 1.996 1.13 1.996 2.883 0 1.762-.71 2.905-1.996 2.905-1.271 0-2.01-1.136-2.01-2.904zM117.503 19.206c2.862 0 4.744-1.385 5.142-3.53l-3.189-.092c-.27.724-.98 1.115-1.89 1.115-1.335 0-2.159-.888-2.159-2.223v-.092h7.273v-.867c0-3.594-2.187-5.568-5.284-5.568-3.295 0-5.412 2.258-5.412 5.64 0 3.493 2.088 5.617 5.519 5.617zm-2.096-6.84c.05-1.086.909-1.91 2.06-1.91 1.144 0 1.967.796 1.982 1.91h-4.042z"
        fill="currentColor"
      />
    </chakra.svg>
  );
};

interface NavLinkProps extends LinkProps {
  isActive?: boolean;
  label: string;
  icon: any;
  [key: string]: any;
}

const CoinLink = observer((props: NavLinkProps) => {
  const { icon, isActive, label, name, ...rest } = props;
  return (
    <Link
      display="block"
      py={2}
      px={3}
      borderRadius="md"
      transition="all 0.3s"
      fontWeight="medium"
      lineHeight="1.5rem"
      aria-current={isActive ? 'page' : undefined}
      color="whiteAlpha.900"
      _hover={{
        bg: 'blue.500',
        color: 'white',
      }}
      _activeLink={{
        bg: 'blue.700',
        color: 'white',
      }}
      // _active={{
      //   bg: 'blue.700',
      //   color: 'white',
      // }}
      {...rest}
      // backgroundColor={isActive ? 'blue.700' : undefined}
    >
      <HStack spacing={4}>
        <Icon as={icon} boxSize="20px" name={name} />
        <Text as="span">{label}</Text>
      </HStack>
    </Link>
  );
});

const SearchField: React.FC<InputGroupProps> = (props) => {
  return (
    <InputGroup {...props}>
      <InputLeftElement pointerEvents="none">
        <SearchIcon opacity={0.82} />
      </InputLeftElement>
      <Input
        placeholder="Search"
        bg="whiteAlpha.400"
        border={0}
        // onChange={}
        focusBorderColor="whiteAlpha.800"
        _placeholder={{ color: 'whiteAlpha.600' }}
      />
    </InputGroup>
  );
};

const ScrollArea = (props: BoxProps) => (
  <Box
    overflowY="auto"
    minH="px"
    maxH="full"
    {...props}
    sx={{
      '&::-webkit-scrollbar-track': {
        bg: 'transparent',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        bg: mode('blue.600', 'gray.700'),
        borderRadius: '20px',
      },
    }}
  />
);

const Sidebar: React.FC<{
  coins: Array<string>;
  active?: string;
  currentPage: string;
  onTabClick: (tab: string) => any;
  onSelectCoin: (coin: string) => any;
  onLogout: () => any;
  addAccount: () => any;
  onAccountSwitch: (account: string) => any;
  onCoinSearch: (val: string) => any;
  profile: ProfileType;
}> = ({
  coins,
  onSelectCoin,
  active,
  currentPage,
  onTabClick,
  addAccount,
  onLogout,
  onCoinSearch,
  onAccountSwitch,
  profile,
  ...rest
}) => {
  return (
    <Flex
      height="100vh"
      width={{ base: 'full', sm: 'xs' }}
      direction="column"
      bg="blue.600"
      color="white"
      px={6}
      py={8}
      {...rest}
    >
      <Logo mb={6} />
      <SearchField onChange={(e: any) => onCoinSearch(e.target.value)} />
      <Stack direction="row">
        {['Favorites', 'All'].map((page, i) => {
          return (
            <TabLink
              key={`${page}-${i}`}
              onClick={() => onTabClick(page)}
              aria-current={page === currentPage ? 'page' : undefined}
            >
              {page}
            </TabLink>
          );
        })}
      </Stack>
      <Divider mb={3} borderColor="whiteAlpha.400" />

      <ScrollArea height="50vh">
        <Stack spacing={6}>
          <Stack>
            {coins.map((coin, index) => {
              return (
                <CoinLink
                  key={coin}
                  label={coin.toUpperCase()}
                  icon={CIcon}
                  isActive={coin.toLowerCase() === active?.toLowerCase()}
                  onClick={() => onSelectCoin(coin)}
                  name={coin.toLowerCase()}
                />
              );
            })}
          </Stack>
        </Stack>
      </ScrollArea>
      <Divider borderColor="whiteAlpha.400" />

      <Spacer />
      <AccountSwitcher
        addAccount={addAccount}
        onLogout={onLogout}
        onAccountSwitch={onAccountSwitch}
        profile={profile}
        mb={6}
      />
    </Flex>
  );
};

export default observer(Sidebar);
