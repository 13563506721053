import { AddIcon, DeleteIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  StackDivider,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FaBitcoin, FaDollarSign, FaShieldAlt } from 'react-icons/fa';
import { HiRefresh } from 'react-icons/hi';
import { AlertDialogComponent } from '../../currentBot/components/Sidebar';
import { DropdownMenu } from '../../currentBot/components/DropdownMenu';
import type { IBotConfig, ISpotMarketConfig } from '../../store/botConfig';
import type { IExchange, IMarket } from '../stores/app';
import ProfileList, { ProfileItem } from './ProfileList';
// import type { ISpotProfileConfig } from 'src/store/spotConfig';

interface DescriptionProps {
  market?: IBotConfig;
  exchangeData: IExchange | any;
  children?: React.ReactNode;
  onSelectedAction: (param?: any) => any;
  onViewJob: (params?: any) => any;
  onCreateJob?: () => any;
  onCreateProfile: () => any;
  type: FullDescriptionType;
  profiles?: any;
  switchProfile?: any;
  onEditProfile?: any;
  onDeleteProfile?: any;
  onRunProfile?: any;
  onDuplicateProfile?: any;
  loading?: boolean;
  refresh_loading?: boolean;
  account_loading?: boolean;
  app_loading?: boolean;
  config_loading?: boolean;
  defaultProfile?: any;
  onViewStatus?: (params?: any) => any;
  onClosePosition?: (params?: any) => any;
  onPauseJob?: (params?: any) => any;
  toProfile?: (params?: any) => any;
  onProtectProfit?: (params?: any) => any;
}

export const Card = (props: BoxProps) => (
  <Box
    bg={mode('white', 'gray.700')}
    shadow="base"
    maxW="2xl"
    mx="auto"
    px={{ base: 4, md: 8 }}
    pt="6"
    pb="8"
    borderTopWidth="2px"
    borderColor={mode('gray.300', 'gray.500')}
    borderTopColor={mode('blue.500', 'blue.300')}
    borderBottomRadius={{ base: 'none', sm: 'base' }}
    {...props}
  />
);
const ActionType: React.FC<DescriptionProps> = observer(
  ({
    onCreateJob = () => {},
    onCreateProfile,
    onSelectedAction,
    onViewJob,
    exchangeData,
    type,
    profiles,
    account_loading,
    switchProfile,
    onPauseJob = () => {},
    onProtectProfit = () => {},
  }) => {
    return (
      <HStack
        // flex={1}
        // position={{  lg: 'relative' }}
        top={{ sm: '0' }}
        insetEnd={{ sm: '0' }}
        // flexDirection={{ lg: 'column', md: 'column' }}
        justifyContent={'center'}
        alignSelf="center"
        // justifyContent={{ lg: 'flex-end', md: 'flex-end' }}
      >
        {type == 'regular' ? (
          <>
            <Box textAlign="center">
              <DropdownMenu
                component={IconButton}
                rightIcon={false}
                onClick={(action) => {
                  if (action === 'Create Job') {
                    onCreateJob();
                  }
                  if (action === 'Create Profile') {
                    onCreateProfile();
                  }
                }}
                icon={<HamburgerIcon />}
                colorScheme="green"
                options={['Create Job', 'Create Profile']}
                label="Create"
              />
              <Text fontSize="xs" display={['none', 'block', 'block']} mt="1">
                Create
              </Text>
            </Box>
            <Box textAlign="center">
              <AlertDialogComponent
                onActionClick={(value) => onSelectedAction(value)}
                confirmSummary={(value: string) => value}
                confirmText={(value: string) => value}
                heading={(value: string) => value}
                renderCallback={(openFunc: any) => (
                  <DropdownMenu
                    rightIcon={false}
                    component={IconButton}
                    icon={<HamburgerIcon />}
                    onClick={(action) => {
                      openFunc(action.value);
                    }}
                    isLoading={account_loading}
                    options={exchangeData.actions}
                    label="Job Actions"
                    transform={(o) => o.label}
                  />
                )}
              />
              <Text fontSize="xs" display={['none', 'block', 'block']} mt="1">
                Actions
              </Text>
            </Box>
            <Box textAlign="center">
              <IconButton
                aria-label="View Jobs"
                // size="sm"
                icon={<HamburgerIcon />}
                px={4}
                colorScheme="blue"
                onClick={onViewJob}
              >
                {/* View Jobs */}
              </IconButton>
              <Text fontSize="xs" display={['none', 'block', 'block']} mt="1">
                Jobs
              </Text>
            </Box>
          </>
        ) : (
          <>
            <DropdownMenu
              size="sm"
              onClick={switchProfile}
              colorScheme="green"
              options={profiles}
              transform={(p: any) => p.name}
              label="Switch Profile"
            />
            <Button size="sm" onClick={onCreateJob}>
              Create Job{' '}
            </Button>
            <Button size="sm" colorScheme="yellow" onClick={onPauseJob}>
              Pause Jobs
            </Button>
            <IconButton
              onClick={onProtectProfit}
              colorScheme="orange"
              aria-label="dollar button"
              icon={<FaBitcoin />}
            />
          </>
        )}
      </HStack>
    );
  },
);
export const Description = observer((props: DescriptionProps) => {
  const {
    exchangeData,
    children,
    onSelectedAction,
    onViewJob,
    onCreateJob = () => {},
    onCreateProfile,
    market,
    type,
    onEditProfile,
    onDeleteProfile,
    onRunProfile,
    onDuplicateProfile,
    loading,
    refresh_loading,
    account_loading,
    app_loading,
    config_loading,
    onViewStatus,
    onClosePosition,
    onPauseJob = () => {},
    toProfile = () => {},
    onProtectProfit = () => {},
  } = props;
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection={{
        base: 'column',
        lg: 'row',
        sm: 'column',
        md: 'row',
        xl: 'row',
      }}
    >
      <Box flex={1}>
        <Flex px={1} justifyContent={['space-between']}>
          <Box
            onClick={toProfile}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
            alignSelf="center"
            maxW="xl"
          >
            {exchangeData.symbol}
          </Box>
          <HStack
            fontSize="sm"
            fontWeight="medium"
            color={mode('gray.500', 'white')}
            ml="3"
          >
            <span>{exchangeData.summary}</span>
          </HStack>
          <ActionType
            type={type}
            {...{
              exchangeData,
              onSelectedAction,
              onViewJob,
              onCreateJob,
              onCreateProfile,
              account_loading,
              onPauseJob,
              profiles: market?.profiles || [],
              switchProfile: market?.setEditProfile,
              onProtectProfit,
            }}
          />
        </Flex>
        <Box
          mt="3"
          // maxW="xl"
          color={mode('gray.600', 'gray.200')}
        >
          <ProfileList
            type={type}
            onEdit={onEditProfile}
            onDelete={onDeleteProfile}
            onRun={onRunProfile}
            onDuplicate={onDuplicateProfile}
            loading={loading}
            {...{
              refresh_loading,
              account_loading,
              app_loading,
              config_loading,
            }}
            profiles={market?.profiles || []}
            defaultProfile={market?.editProfile}
            onStatus={onViewStatus}
            onViewJobs={onViewJob}
            onClose={onClosePosition}
          />
          {children}
        </Box>
      </Box>
    </Box>
  );
});
const MarketLabel: React.FC = ({ children }) => {
  return (
    <Box
      as="button"
      borderRadius="md"
      bg="tomato"
      color="white"
      px={4}
      //   py={4}
      h={8}
    >
      {children}
    </Box>
  );
};

type MarketDisplayProps = {
  label: string;
  coin: string;
  market_type?: 'spot' | 'future';
  markets?: Array<IMarket | IBotConfig>;
  onAddNewMarket?: () => any;
  onRefresh: () => any;
  onProtectProfit?: (params?: any) => any;
  onMarketActionSelected: (market: string, action: string) => any;
  onViewMarketJobs: (market: string) => any;
  onCreateJob: (symbol: string) => any;
  onCreateProfile: (symbol: string) => any;
  onDuplicateProfile: (profile: any, symbol?: any) => any;
  onRunProfile: any;
  canAdd?: boolean;
  loading?: boolean;
  [key: string]: any;
};
export type FullDescriptionType = 'regular' | 'usd' | 'coin';
export const SpotDescription: React.FC<{
  func: any;
  market: ISpotMarketConfig;
  actions: any[];
  profile: any;
}> = observer(
  ({
    actions,
    profile,
    market,
    func = {
      edit: () => {},
      transfer: () => {},
      view: () => {},
      create: () => {},
    },
  }) => {
    return (
      <ProfileItem
        actions={actions.map((o: any) => {
          if (o.type === 'run') {
            return { ...o, loading: market.loading };
          }
          return o;
        })}
        profile={profile}
        func={func}
      />
    );
  },
);
export const FullDescription: React.FC<{
  market: IBotConfig | any;
  onMarketActionSelected?: any;
  onViewMarketJobs?: any;
  onCreateJob?: () => any;
  onCreateProfile: () => any;
  onProtectProfit?: (params?: any) => any;
  onDuplicateProfile?: (profile: any) => any;
  onRunProfile: (profile: any, resume?: boolean) => any;
  onEditProfile: (profile: any) => any;
  onDeleteProfile?: (profile: any) => any;
  toProfile?: any;
  onPauseJob?: any;
  onViewStatus?: any;
  onClosePosition?: any;
  loading?: boolean;
  type?: FullDescriptionType;
  refresh_loading?: boolean;
  account_loading?: boolean;
  app_loading?: boolean;
  config_loading?: boolean;
  market_type?: 'spot' | 'future';
  spotActions?: (market: ISpotMarketConfig) => any;
  spotActionList?: (market: ISpotMarketConfig) => any[];
}> = ({
  market,
  onMarketActionSelected,
  onViewMarketJobs,
  onCreateJob,
  onCreateProfile,
  onDuplicateProfile,
  onRunProfile,
  onEditProfile,
  onDeleteProfile,
  onProtectProfit,
  toProfile,
  onPauseJob,
  onClosePosition,
  onViewStatus,
  loading,
  refresh_loading,
  account_loading,
  app_loading,
  config_loading,
  type = 'regular',
  market_type = 'future',
  spotActions = () => ({}),
  spotActionList = () => [],
}) => {
  return market_type == 'spot' ? (
    <SpotDescription
      profile={{
        name: market.symbol,
        type: 'spot',
        style: { fontWeight: 'bold' },
      }}
      market={market}
      func={spotActions(market)}
      actions={spotActionList(market)}
    />
  ) : (
    <Description
      exchangeData={market.exchangeData}
      onSelectedAction={(value) => {
        onMarketActionSelected(market.symbol, value);
      }}
      onCreateJob={onCreateJob}
      onCreateProfile={onCreateProfile}
      onViewJob={() => {
        onViewMarketJobs(market.symbol);
      }}
      type={type}
      market={market}
      {...{
        onEditProfile,
        onRunProfile,
        onProtectProfit,
        onDeleteProfile,
        onDuplicateProfile,
        loading,
        refresh_loading,
        account_loading,
        app_loading,
        config_loading,
        onClosePosition: (params?: any) => {
          onClosePosition && onClosePosition(market.symbol, params);
        },
        onViewStatus: (params?: any) => {
          onViewStatus && onViewStatus(market.symbol, params);
        },
        toProfile: (params?: any) => {
          toProfile && toProfile(market.symbol, params);
        },
        onPauseJob: (params?: any) => {
          onPauseJob && onPauseJob(market.symbol, params);
        },
      }}
    >
      <SimpleGrid maxW="30rem" columns={3} spacing={3}>
        {(market.exchangeData?.items || []).map((item: any, index: number) => {
          return <MarketLabel key={item}>{item}</MarketLabel>;
        })}
      </SimpleGrid>
    </Description>
  );
};

const MarketDisplay = ({
  label,
  coin,
  markets = [],
  onAddNewMarket,
  onMarketActionSelected,
  onViewMarketJobs,
  onRefresh,
  onCreateJob,
  onCreateProfile,
  onDuplicateProfile,
  onRunProfile,
  onPauseJob,
  toProfile,
  onViewStatus,
  onClosePosition,
  onProtectProfit,
  loading = false,
  canAdd,
  onEditProfile,
  onDeleteProfile,
  onDeleteSymbol,
  type,
  refresh_loading = false,
  account_loading = false,
  config_loading = false,
  app_loading = false,
  market_type,
  spotActions,
  spotActionList,
  ...rest
}: MarketDisplayProps) => {
  return (
    <Card
      rounded={{ lg: 'lg' }}
      bg={mode('white', 'gray.700')}
      maxW="3xl"
      mx="auto"
      px={0}
      shadow="base"
      overflow="hidden"
      {...rest}
    >
      <Flex align="center" justify="space-between" px="0" py="4">
        <Flex>
          <Box aria-hidden fontSize="2xl" pt="1" color="gray.500">
            <FaShieldAlt />
          </Box>
          <Text ml={2} as="h3" fontWeight="bold" fontSize="lg">
            {label}
          </Text>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          {canAdd ? (
            <IconButton
              aria-label="Add new market"
              icon={<AddIcon />}
              onClick={onAddNewMarket}
            />
          ) : null}
          <IconButton
            aria-label="Refresh markets"
            ml={2}
            isLoading={app_loading}
            onClick={onRefresh}
            icon={<HiRefresh />}
          />
          <AlertDialogComponent
            onActionClick={(value) => onDeleteSymbol(value)}
            confirmSummary={(value: string) =>
              `Are you sure you want to delete ${value}`
            }
            confirmText={(value: string) => `Delete ${value}`}
            heading={(value: string) => value}
            renderCallback={(openFunc: any) => (
              <DropdownMenu
                ml={2}
                component={IconButton}
                rightIcon={false}
                icon={<DeleteIcon />}
                // size="sm"
                onClick={(action) => {
                  openFunc(action.value);
                }}
                options={markets.map((o) => ({
                  label: o.symbol,
                  value: o.symbol,
                }))}
                label="Delete symbol"
                transform={(o) => o.label}
              />
            )}
          />
        </Flex>
      </Flex>
      <Divider />
      {markets.length > 0 ? (
        <Stack spacing="6" py="5" px="1" divider={<StackDivider />}>
          {markets.map((market, index) => {
            return market.exchangeData ? (
              <FullDescription
                key={`${market.symbol}-${index}`}
                market={market}
                {...{
                  type,
                  market_type,
                  onMarketActionSelected,
                  onViewMarketJobs,
                  onProtectProfit: () =>
                    onProtectProfit && onProtectProfit(market.symbol),
                  onCreateJob: () => onCreateJob(market.symbol),
                  onCreateProfile: () => onCreateProfile(market.symbol),
                  onDuplicateProfile: (profile: any) =>
                    onDuplicateProfile(profile, market.symbol),
                  onRunProfile: (...rest) =>
                    onRunProfile(market.symbol, ...rest),

                  onViewStatus,
                  onClosePosition,
                  onEditProfile: (profile: any) =>
                    onEditProfile(profile, market.symbol),
                  onDeleteProfile: (profile: any) =>
                    onDeleteProfile(profile, market.symbol),
                  onPauseJob,
                  toProfile,
                  loading,
                  refresh_loading,
                  account_loading,
                  app_loading,
                  config_loading,
                }}
              />
            ) : market_type == 'spot' ? (
              <FullDescription
                key={`${market.symbol}-${index}`}
                market={market}
                {...{
                  type,
                  market_type,
                  onMarketActionSelected,
                  onViewMarketJobs,
                  onCreateJob: () => onCreateJob(market.symbol),
                  onCreateProfile: () => onCreateProfile(market.symbol),
                  onDuplicateProfile: (profile: any) =>
                    onDuplicateProfile(profile, market.symbol),
                  onRunProfile: (...rest) =>
                    onRunProfile(market.symbol, ...rest),
                  onViewStatus,
                  onClosePosition,
                  onEditProfile: (profile: any) =>
                    onEditProfile(profile, market.symbol),
                  onDeleteProfile: (profile: any) =>
                    onDeleteProfile(profile, market.symbol),
                  loading,
                  refresh_loading,
                  account_loading,
                  app_loading,
                  config_loading,
                  spotActionList,
                  spotActions,
                }}
              />
            ) : null;
          })}
        </Stack>
      ) : (
        <Box bg={mode('white', 'gray.700')} p="6" rounded="lg" shadow="base">
          <Box
            border="3px dashed currentColor"
            color={mode('gray.200', 'gray.600')}
            h="36"
            rounded="lg"
            textAlign="center"
            pt={5}
          >
            <Text>You are not in any trading market at the moment</Text>
            <Button
              leftIcon={<AddIcon />}
              onClick={onAddNewMarket}
              colorScheme="pink"
              variant="solid"
            >
              Add {coin} markets
            </Button>
          </Box>
        </Box>
      )}
    </Card>
  );
};

export default observer(MarketDisplay);
