import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  IconButtonProps,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

import { TOptionsEvents } from 'keen-slider';
import { useKeenSlider } from 'keen-slider/react';

import { Circle, HStack, Stack, StackProps } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { SingleExchange } from '../types';

export type ElementType<T extends ReadonlyArray<unknown>> =
  T extends ReadonlyArray<infer ElementType> ? ElementType : never;

export const Carousel = React.forwardRef<HTMLDivElement, FlexProps>(
  function Carousel(props, ref) {
    return (
      <Flex
        ref={ref}
        className="chakra-carousel"
        overflow="hidden"
        position="relative"
        userSelect="none"
        {...props}
      />
    );
  },
);

export const CarouselSlide = (props: BoxProps) => (
  <Box
    className="chakra-carousel__slide"
    position="relative"
    overflow="hidden"
    width="100%"
    minH="100%"
    {...props}
  />
);

export const useCarousel = (options?: TOptionsEvents) => {
  const defaultOptions = { slides: '.chakra-carousel__slide' };
  return useKeenSlider<HTMLDivElement>({ ...defaultOptions, ...options });
};

export interface CarouselViewProps<T = any> {
  items?: T[];
  rootProps?: StackProps;
  renderItem?: (item: T, index: number) => React.ReactNode;
  onItemChange?: (index: number) => void;
  defaultIndex?: number;
}

export const CarouselView = observer((props: CarouselViewProps) => {
  const {
    items = [],
    rootProps,
    onItemChange = (_x) => {},
    renderItem = (x) => <></>,
    defaultIndex = 0,
  } = props;

  const [currentSlide, setCurrentSlide] = React.useState(defaultIndex ?? 0);
  console.log({ defaultIndex, currentSlide });

  const [ref, slider] = useCarousel({
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  React.useEffect(() => {
    onItemChange(currentSlide);
  }, [currentSlide]);

  return (
    <>
      <Stack spacing="4" {...rootProps}>
        <Box
          position="relative"
          sx={{
            _hover: {
              '> button': {
                display: 'inline-flex',
              },
            },
          }}
        >
          <Carousel ref={ref}>
            {items.map((item, i) => (
              <CarouselSlide key={i}>{renderItem(item, i)}</CarouselSlide>
            ))}
          </Carousel>

          {currentSlide > 0 && (
            <CarouselIconButton
              pos="absolute"
              left="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider?.prev()}
              icon={<IoChevronBackOutline />}
              aria-label="Previous Slide"
            />
          )}

          {currentSlide < items.length - 1 && (
            <CarouselIconButton
              pos="absolute"
              right="3"
              top="50%"
              transform="translateY(-50%)"
              onClick={() => slider?.next()}
              icon={<IoChevronForwardOutline />}
              aria-label="Next Slide"
            />
          )}
        </Box>
      </Stack>
      {items.length > 1 && (
        <HStack width="full" justify="center" bottom="0" py="4">
          {items.map((_, index) => (
            <Circle
              key={index}
              size="2"
              bg={currentSlide === index ? 'black' : 'gray.400'}
            />
          ))}
        </HStack>
      )}
    </>
  );
});

const CarouselIconButton = (props: IconButtonProps) => (
  <IconButton
    display="none"
    fontSize="lg"
    isRound
    boxShadow="base"
    bg={useColorModeValue('white', 'gray.800')}
    _hover={{
      bg: useColorModeValue('gray.100', 'gray.700'),
    }}
    _active={{
      bg: useColorModeValue('gray.200', 'gray.600'),
    }}
    _focus={{ boxShadow: 'inerhit' }}
    _focusVisible={{ boxShadow: 'outline' }}
    {...props}
  />
);
