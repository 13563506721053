import { calculate_liquidation } from './hedge';
import { v4 as uuidv4 } from 'uuid';
import { determine_pnl } from './trade_signal';
import AppPnl from './pnl';

export function profitHelper(
  longPosition: any,
  shortPosition: any,
  config?: any,
  contract_size?: number,
  balance = 0,
) {
  let long: any = { takeProfit: 0, quantity: 0, pnl: 0 };
  let short: any = { takeProfit: 0, quantity: 0, pnl: 0 };
  if (longPosition) {
    long = config?.getSize2(longPosition, contract_size, balance) || null;
  }
  if (shortPosition) {
    short = config?.getSize2(shortPosition, contract_size, balance) || null;
  }
  return { long, short };
}

export function liquidationAnalysis(
  kind: 'pnl' | 'additional',
  longPosition: any,
  shortPosition: any,
  config: any,
  balance: number,
  leverage = 125,
  additionalFunc = (config: any) => ({
    long: { entry: config.long_e, size: config.long_s },
    short: { entry: config.short_e, size: config.short_s },
  }),
  contract_size?: number,
) {
  let long: any;
  let short: any;
  // if (longPosition) {
  //   long = config.getSize2(longPosition, contract_size, balance) || null;
  // }
  // if (shortPosition) {
  //   short = config.getSize2(shortPosition, contract_size, balance) || null;
  // }
  if (config) {
    let profit = profitHelper(
      longPosition,
      shortPosition,
      config,
      contract_size,
      balance,
    );
    let longSize = longPosition ? longPosition.quantity * config.long_p : 0;
    let shortSize = shortPosition ? shortPosition.quantity * config.short_p : 0;
    let pnlLong = {
      long: { entry: profit.long.takeProfit || 0, size: longSize },
      short: {
        entry: profit.short.takeProfit || 0,
        size: shortSize,
      },
    };
    let kindProps = kind == 'additional' ? additionalFunc(config) : undefined;
    let result = calculate_liquidation(
      balance,
      longPosition
        ? {
            entry: longPosition.entryPrice,
            size: longPosition.quantity,
          }
        : { entry: 0, size: 0 },
      shortPosition
        ? {
            entry: shortPosition.entryPrice,
            size: shortPosition.quantity,
          }
        : { entry: 0, size: 0 },
      kindProps,
      pnlLong,
      undefined,
      leverage,
      contract_size,
      config.symbol,
    );
    if (!result.balance) {
      result.balance = balance;
    }
    return result;
  }
  return {};
}

export function getParamForField(
  self: any,
  configs: any[],
  field: string,
  isGroup?: string,
): any | any[] {
  if (isGroup === 'group' && field === 'checkbox') {
    return configs
      .filter((o) => o.kind === field && o.group === true)
      .map((o) => {
        let _self: any = self;
        let value: any = _self[o.name];
        return { ...o, value };
      });
  }
  let r: any = configs.find((o) => o.name == field);
  if (r) {
    let oo: any = self;
    let tt: any = oo[r.name] || '';
    r.value = tt;
  }
  return r;
}

export function getTradeEntries(
  entry: number,
  min_size: number,
  kind: 'long' | 'short',
  size: number,
  spread = 0,
) {
  let result = [];
  let index = 0;
  let no_of_trades = size > min_size ? Math.round(size / min_size) : 1;
  while (index < no_of_trades) {
    if (kind === 'long') {
      result.push({ entry: entry - index * spread, size: min_size });
    } else {
      result.push({ entry: entry + index * spread, size: min_size });
    }
    index = index + 1;
  }
  return result;
}

export function extractValue(_param: any, condition: boolean) {
  let param;
  if (condition) {
    try {
      let value = JSON.parse(_param || '[]');
      param = value.map((o: any) => parseFloat(o));
    } catch (error) {
      //console.log(error);
    }
  } else {
    param = parseFloat(_param);
  }
  return param;
}

export function asCoins(symbol: string) {
  let _type = symbol.toLowerCase().includes('usdt') ? 'usdt' : 'coin';
  if (symbol.toLowerCase() == 'btcusdt') {
    _type = 'usdt';
  }
  let result =
    _type === 'usdt'
      ? symbol.toLowerCase().includes('usdt')
        ? 'USDT'
        : 'BUSD'
      : symbol.toUpperCase().split('USD_')[0];
  if (symbol.toLowerCase().includes('-')) {
    result = result.split('-')[0];
  }
  if (symbol.toLowerCase() == 'usdt-usd') {
    //console.log(result);
  }
  let result2 = _type == 'usdt' ? symbol.split(result)[0] : result;
  if (result.includes('-')) {
    result2 = result;
  }
  return result2;
}
export const SpecialCoins = ['NGN', 'USDT', 'BUSD', 'PAX', 'USDC', 'EUR'];
export function allCoins(symbols: string[]) {
  let r = symbols.map((o, i) => asCoins(o));
  return [...new Set(r), ...SpecialCoins];
}

export function formatPrice(
  value: number,
  opts: { locale?: string; currency?: string } = {},
) {
  const { locale = 'en-US', currency = 'USD' } = opts;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
}

export function to_f(value: string | number, places: string = '%.1f'): number {
  let v: number = typeof value === 'string' ? parseFloat(value) : value;
  const formattedValue: string = places.replace('%.', '').replace('f', '');

  return parseFloat(v.toFixed(parseInt(formattedValue)));
}

export function determine_stop_and_size(
  entry: number,
  pnl: number,
  take_profit: number,
  kind: string = 'long',
) {
  const difference =
    kind === 'long' ? take_profit - entry : entry - take_profit;
  const quantity = pnl / difference;
  return Math.abs(quantity);
}

export const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function determine_amount_to_sell(
  entry: number,
  quantity: number,
  sell_price: number,
  pnl: number,
  kind: 'long' | 'short',
  places: string = '%.3f',
) {
  const _pnl = determine_pnl(entry, sell_price, quantity, kind);
  const ratio = pnl / to_f(Math.abs(_pnl), places);
  quantity = quantity * ratio;
  return to_f(quantity, places);
}

export function determine_position_size({
  entry,
  stop,
  budget,
  percent,
  min_size,
  notional_value,
  as_coin = true,
  places = '%.3f',
}: {
  entry: number;
  stop?: number;
  budget?: number;
  percent?: number;
  notional_value?: number;
  min_size?: number;
  as_coin?: boolean;
  places?: string;
}) {
  let stop_percent = stop ? Math.abs(entry - stop) / entry : percent;
  let min_value = notional_value ? to_f(notional_value / entry, places) : 0;

  if (stop_percent && budget) {
    let size = budget / stop_percent;
    let notion_value = size * entry;
    if (notional_value && notional_value > notion_value) {
      size = notional_value / entry;
    }
    if (as_coin) {
      size = size / entry;
      if (min_size && min_size === 1) {
        return to_f(Math.round(size), places);
      }
    }
    return to_f(size, places);
  }

  return undefined;
}

export function determine_remaining_entry({
  risk,
  max_size,
  stop_loss,
  kind,
  position,
}: {
  risk: number;
  max_size: number;
  stop_loss: number;
  kind: string;
  position: {
    quantity: number;
    entry: number;
  };
}) {
  const avg_entry = determine_avg_entry_based_on_max_size({
    risk,
    max_size,
    stop_loss,
    kind,
  });
  console.log({
    risk,
    max_size,
    stop_loss,
    kind,
    avg_entry,
  });
  const result = avg_entry * max_size - position.quantity * position.entry;
  return result / (max_size - position.quantity);
}

function determine_avg_entry_based_on_max_size({
  risk,
  max_size,
  stop_loss,
  kind = 'long',
}: {
  risk: number;
  max_size: number;
  stop_loss: number;
  kind: string;
}) {
  const diff = max_size * stop_loss;
  if (kind === 'long') {
    return (risk + diff) / max_size;
  }
  return (risk - diff) / max_size;
}

export function determine_average_entry_and_size(
  orders: Array<{ price: number; quantity: number }>,
  places = '%.3f',
  price_places = '%.1f',
) {
  const sum_values = orders.reduce(
    (sum, order) => sum + order.price * order.quantity,
    0,
  );
  const total_quantity = orders.reduce((sum, order) => sum + order.quantity, 0);
  const avg_price = total_quantity
    ? to_f(sum_values / total_quantity, price_places)
    : 0;
  return {
    price: avg_price,
    quantity: to_f(total_quantity, places),
  };
}

export const createArray = (start: number, stop: number, step: number) => {
  const result = [];
  let current = start;

  while (current <= stop) {
    result.push(current);
    current += step;
  }

  return result;
};

export const groupBy = (xs: any[], key: string) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const generateUniqueString = () => {
  const uuid = uuidv4();
  const truncatedString = uuid.replace(/-/g, '').substring(0, 7);
  return truncatedString;
};

export function fibonacci_analysis({
  support,
  resistance,
  kind = 'long',
  trend = 'long',
  places = '%.1f',
}: {
  support: number;
  resistance: number;
  kind?: string;
  trend?: string;
  places?: string;
}) {
  const swing_high = trend === 'long' ? resistance : support;
  const swing_low = trend === 'long' ? support : resistance;
  const ranges = [0, 0.236, 0.382, 0.5, 0.618, 0.789, 1, 1.272, 1.414, 1.618];
  const fib_calc = (p: number, h: number, l: number) => p * (h - l) + l;
  const fib_values = ranges
    .map((x) => fib_calc(x, swing_high, swing_low))
    .map((x) => to_f(x, places));
  if (kind === 'short') {
    // reverse the array
    return trend === 'long' ? fib_values.reverse() : fib_values;
  } else {
    return trend === 'short' ? fib_values.reverse() : fib_values;
  }
  return fib_values;
}

export const groupIntoPairs = (arr: any[], size: number) => {
  const result = [];
  for (let i = 0; i < arr.length; i += size) {
    result.push(arr.slice(i, i + size));
  }
  return result;
};

export const groupIntoPairsWithSumLessThan = (
  arr: any[],
  targetSum: number,
  key = 'quantity',
  firstSize = 0,
) => {
  if (firstSize) {
    // lets determine the total size by summing all the quantities
    const totalSize = arr.reduce((sum, order) => sum + order[key], 0);
    // deduct the first size from the total size
    const remainingSize = totalSize - firstSize;
    // run through the original array and create a new array with the total quantity being the remaining size
    let newSum = 0;
    let newArray = [];
    let lastIndex = 0;
    for (let i = 0; i < arr.length; i++) {
      if (newSum < remainingSize) {
        newSum += arr[i][key];
        newArray.push(arr[i]);
        lastIndex = i;
      }
    }
    const lastGroup = arr.slice(lastIndex + 1);
    const previousPair = groupInPairs(newArray, key, targetSum);
    if (lastGroup.length > 0) {
      previousPair.push(lastGroup);
    }
    return previousPair;
  }
  return groupInPairs(arr, key, targetSum);
};
function groupInPairs(_arr: any[], key: string, targetSum: number) {
  const result = [];
  let currentSum = 0;
  let currentGroup = [];
  for (let i = 0; i < _arr.length; i++) {
    currentSum += _arr[i][key];
    currentGroup.push(_arr[i]);
    if (currentSum >= targetSum) {
      result.push(currentGroup);
      currentGroup = [];
      currentSum = 0;
    }
  }
  return result;
}
/**
 *  This function computes the cummulative entry and size for each trade
 * @param trades The trades for which the cummulative entry and size will be computed
 * @param config The current config
 * @returns An array of trades with the cummulative entry and size
 */
export const computeTotalAverageForEachTrade = (trades: any[], config: any) => {
  let _take_profit = config.take_profit;
  let kind = config.kind;
  let entryToUse =
    kind === 'short'
      ? Math.min(config.entry, config.stop)
      : Math.max(config.entry, config.stop);
  let _currentEntry = config.currentEntry || entryToUse;
  let less = trades.filter((p) =>
    kind === 'long' ? p.entry <= _currentEntry : p.entry >= _currentEntry,
  );
  return trades.map((r, i) => {
    let considered = [];
    if (kind === 'long') {
      considered = trades.filter((p) => p.entry > _currentEntry);
    } else {
      considered = trades.filter((p) => p.entry < _currentEntry);
    }
    const x_pnl = undefined;
    const remaining = less
      // .map((o) => o.entry)
      .filter((o) => {
        if (kind === 'long') {
          return o.entry >= r.entry;
        }
        return o.entry <= r.entry;
      });
    if (remaining.length === 0) {
      return { ...r, pnl: x_pnl };
    }
    const start =
      kind === 'long'
        ? Math.max(...remaining.map((o) => o.entry))
        : Math.min(...remaining.map((o) => o.entry));
    considered = considered.map((o) => ({ ...o, entry: start }));
    //merge the two arrays
    considered = considered.concat(remaining);
    // console.log({ considered, kind, _currentEntry });
    let avg_entry = determine_average_entry_and_size(
      [
        ...considered.map((o) => ({
          price: o.entry,
          quantity: o.quantity,
        })),
        {
          price: _currentEntry,
          quantity: config.currentQty || 0,
        },
      ],
      config.decimal_places,
      config.price_places,
    );
    let _pnl = r.pnl;
    let sell_price = r.sell_price;
    let entry_pnl = r.pnl;
    if (_take_profit) {
      _pnl = AppPnl.determine_pnl(
        avg_entry.price,
        _take_profit,
        avg_entry.quantity,
        kind,
      );
      sell_price = _take_profit;
      entry_pnl = AppPnl.determine_pnl(
        r.entry,
        _take_profit,
        avg_entry.quantity,
        kind,
      );
    }
    const loss = AppPnl.determine_pnl(
      avg_entry.price,
      r.stop,
      avg_entry.quantity,
      kind,
    );
    let new_stop = r.new_stop;
    // let _previous = trades[i-1];
    // if(_previous?.stop){
    //   if(_previous !== new_stop){
    //     new_stop = _previous.stop
    //   }
    // }
    const entry_loss = AppPnl.determine_pnl(
      r.entry,
      new_stop,
      avg_entry.quantity,
      kind,
    );

    let min_profit = 0;
    let min_entry_profit = 0;
    let previous = trades[i];
    let next = trades[i + 1];
    if (config.min_profit) {
      min_profit = AppPnl.determine_close_price(
        avg_entry.price,
        config.min_profit,
        avg_entry.quantity,
        kind,
      );
      min_entry_profit = AppPnl.determine_close_price(
        r.entry,
        config.min_profit,
        avg_entry.quantity,
        kind,
      );
    }
    let x_fee = r.fee;
    if (config.fee) {
      x_fee = config.fee * r.stop * avg_entry.quantity;
    }
    let tp_close = AppPnl.determine_close_price(
      r.entry,
      Math.abs(entry_loss) * (config.rr || 1) + x_fee,
      avg_entry.quantity,
      kind,
    );
    return {
      ...r,
      x_fee: to_f(x_fee, '%.2f'),
      avg_entry: avg_entry.price,
      avg_size: avg_entry.quantity,
      entry_pnl: to_f(entry_pnl, '%.2f'),
      entry_loss: to_f(entry_loss, '%.2f'),
      min_entry_pnl: to_f(min_entry_profit, '%.2f'),
      pnl: _pnl,
      neg_pnl: to_f(loss, '%.2f'),
      sell_price,
      close_p: to_f(tp_close, '%.2f'),
      min_pnl: to_f(min_profit, '%.2f'),
      new_stop,
    };
  });
};

export function getDecimalPlaces(numberString: string | number) {
  let parts = numberString.toString().split('.');
  if (parts.length == 2) {
    return parts[1].length;
  }
  return 0;
}

export function createGapPairs<T>(arr: T[], gap: number, item?: T): [T, T][] {
  if (arr.length === 0) {
    return [];
  }

  const result: [T, T][] = [];
  const firstElement = arr[0];

  for (let i = arr.length - 1; i >= 0; i--) {
    const current = arr[i];
    const gapIndex = i - gap;
    const pairedElement = gapIndex < 0 ? firstElement : arr[gapIndex];

    if (current !== pairedElement) {
      result.push([current, pairedElement]);
    }
  }
  if (item) {
    let r = result.find((o) => o[0] === item);
    return r ? [r] : [];
  }
  return result;
}
