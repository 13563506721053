import {
  chakra,
  HTMLChakraProps,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import * as React from 'react';
export const TabLink = (rest: HTMLChakraProps<'a'> | any) => {
  let {
    kind = mode(`gray.400`, `gray.400`),
    hover = mode(`white.400`, `white.600`),
    link = mode(`white`, `white`),
    ...props
  }: any = rest;

  return (
    <chakra.a
      fontWeight="semibold"
      px="2"
      py="3"
      color={kind}
      borderBottom="2px solid transparent"
      transition="all 0.2s"
      _hover={{
        borderColor: hover,
        cursor: 'pointer',
      }}
      _activeLink={{
        color: link,
        borderColor: 'currentColor',
      }}
      {...props}
    />
  );
};
