import { Button } from '@chakra-ui/button';
import { FormControl, FormLabel } from '@chakra-ui/form-control';
import { useDisclosure } from '@chakra-ui/hooks';
import { Divider, Flex, Link, List, ListItem, Text } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import { useToast } from '@chakra-ui/toast';
import { observer } from 'mobx-react-lite';
import React from 'react';
import type { IBotStore } from '../store/botConfig';
import { DropdownMenu } from './components/DropdownMenu';
import { AddNewMarket } from './components/GenericConfigDisplay';
import Sidebar, { Navigator } from './components/Sidebar';
import JobsViews from './JobsView';

export const AccountSwitcher: React.FC<{
  onAccountSwitch: (account: string) => any;
  store: IBotStore;
}> = observer(({ onAccountSwitch, store }) => {
  const hoverStyle = { border: '1px solid teal' };
  return (
    <>
      <List spacing={3}>
        {store.accountNames.map((market) => {
          return (
            <Link
              onClick={() => {
                onAccountSwitch(market);
              }}
              _hover={{ textDecoration: 'none' }}
            >
              <ListItem
                pl={2}
                _hover={hoverStyle}
                py={2}
                {...(market == store.account?.owner ? hoverStyle : {})}
              >
                {market}
              </ListItem>
              <Divider />
            </Link>
          );
        })}
      </List>
    </>
  );
});

const AppHeading: React.FC<{
  store: IBotStore;
  newMode: boolean;
  setNewMode: any;
  page: any;
  setPage: any;
}> = observer(({ store, newMode, setNewMode, page, setPage }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const jobDisclosure = useDisclosure();
  const addDisclosure = useDisclosure();
  const btnRef: any = React.useRef();
  const toast = useToast();
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  return (
    <Navigator>
      <Text mr={2} alignSelf="center">
        {store.account?.display}
      </Text>
      <FormControl flex={1} display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb="0">
          Switch mode
        </FormLabel>
        <Switch
          isChecked={newMode}
          onChange={(e) => setNewMode(e.target.checked)}
          id="email-alerts"
        />
      </FormControl>
      <Button
        ref={btnRef}
        mr={2}
        colorScheme={page === 'jobs' ? 'orange' : 'blue'}
        onClick={() => {
          setPage(page === 'jobs' ? 'calculator' : 'jobs');
        }}
      >
        {page == 'jobs' ? `to Calculator` : `To jobs`}
      </Button>
      <Button
        mr={3}
        ref={btnRef}
        colorScheme="teal"
        onClick={() => store.setCalculatorMode(false)}
      >
        {`Switch`}
      </Button>
      <Sidebar
        placement="right"
        button={
          <Flex flexWrap="wrap" justifyContent="space-between">
            {store.account?.coins || [].length > 0 ? (
              <DropdownMenu
                options={store.account?.coins || []}
                label={store.account?.selectedCoin || 'Select coin'}
                onClick={(o) => {
                  store.account?.setSelectedCoin(o);
                }}
              />
            ) : null}
            {store.account?.markets && store.account?.markets.length > 0 && (
              // {store.account?.configs && store.account?.configs.length > 1 && (
              <DropdownMenu
                transform={(o) => o.symbol}
                options={store.account?.markets}
                label={store.account.defaultConfig?.symbol}
                onClick={(config) => {
                  store.onSymbolChange(config);
                }}
              />
            )}
            <Sidebar
              isOpen={addDisclosure.isOpen}
              onClose={addDisclosure.onClose}
              title="Create symbol to watch"
              btnRef={btnRef}
              size="full"
              button={
                <Button
                  mr={2}
                  onClick={addDisclosure.onOpen}
                  colorScheme="green"
                >
                  new Symbol
                </Button>
              }
            >
              {store.account?.configs && (
                <AddNewMarket
                  loading={store.loading}
                  array={store.account.configs.map((o) => o.symbol)}
                  onAdd={store.addNewSymbol}
                  onSelect={(market) => {
                    // store.onSymbolChange({ symbol: market }).then(() => {
                    // });
                    store.account?.setDefaultConfig(market);
                    onClose();
                  }}
                >
                  <Button
                    isLoading={store.loading}
                    loadingText="Fetching"
                    onClick={() =>
                      store
                        .fetchAccounts()
                        .then((x) => toastHelper('success', 'Updated'))
                        .catch((e) =>
                          toastHelper('error', 'Error updating accounts'),
                        )
                    }
                    p={2}
                    border="1px solid black"
                    mr={2}
                    // mx={2}
                  >
                    Refresh
                  </Button>
                </AddNewMarket>
              )}
            </Sidebar>

            <Button
              isLoading={store.loading}
              loadingText="Fetching"
              onClick={() =>
                store
                  .fetchAccounts()
                  .then((x) => toastHelper('success', 'Updated'))
                  .catch((e) => toastHelper('error', 'Error updating accounts'))
              }
              p={2}
              mt={[2, 0, 0, 0]}
              border="1px solid black"
              mr={2}
              // mx={2}
            >
              Fetch Latest
            </Button>

            {store.account && (
              <Sidebar
                placement="top"
                button={
                  null

                  // <Button
                  //   mx={2}
                  //   mt={[2, 0, 0, 0]}
                  //   onClick={jobDisclosure.onOpen}
                  //   p={2}
                  //   border="1px solid black"
                  // >
                  //   Jobs {store.account.defaultConfig.jobs.length}
                  // </Button>
                }
                title="View or Add jobs"
                {...{ ...jobDisclosure, btnRef }}
              >
                <JobsViews store={store} />
              </Sidebar>
            )}
            <Button
              mt={[2, 0, 0, 0]}
              onClick={onOpen}
              p={2}
              border="1px solid black"
            >
              Switch
            </Button>
          </Flex>
        }
        title="Switch Account"
        {...{ isOpen, onClose, btnRef }}
      >
        <AccountSwitcher
          store={store}
          onAccountSwitch={(account) => {
            //console.log(account);
            store.switchAccount(account);
            onClose();
          }}
        />
      </Sidebar>
    </Navigator>
  );
});

export default AppHeading;
