import { flow } from 'mobx';
import {
  applySnapshot,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import type { BotAdapterType } from '../adapter';
import { extractValue, getParamForField } from './utils';

const configs = [
  {
    name: 'type',
    label: 'Job Type',
    kind: 'select',
    options: [
      'new_order',
      'purchase_after_profit',
      'increase_position_with_borrow',
    ],
  },
  {
    name: 'kind',
    label: 'Trade Kind',
    kind: 'radio',
    options: ['long', 'short'],
  },
  {
    name: 'variable',
    label: 'Variable',
    kind: 'select',
    options: [
      'size',
      'entry',
      'pnl',
      'current_price',
      'liquidation',
      'last_order',
      'margin_type',
    ],
  },
  {
    name: 'operator',
    label: 'Operator',
    kind: 'select',
    options: [
      '=',
      '>',
      '<',
      '>=',
      '<=',
      '+',
      '-',
      '*',
      '/',
      '!=',
      'between',
      '>==',
      '<==',
    ],
  },
  { name: 'value', label: 'Value', kind: 'input', type: 'string' },
  { name: 'pnl', label: 'Pnl', kind: 'input', type: 'string' },
  {
    name: 'entry_target',
    label: 'Entry Target',
    kind: 'input',
    type: 'string',
  },
  { name: 'stop_price', label: 'Stop Price', kind: 'special' },
  { name: 'take_profit', label: 'Take Profit', kind: 'special' },
  { name: 'size', label: 'Maximum position size', kind: 'special' },
  { name: 'min_size', label: 'Minimum size per trade', kind: 'special' },
  {
    name: 'spread',
    label: 'Spread between trades',
    kind: 'input',
    type: 'string',
  },
  { name: 'borrow', label: 'Amount to borrow', kind: 'input' },
  { name: 'stop_range', label: 'Stop price range spread', kind: 'input' },
  { name: 'asset', label: 'Asset', kind: 'input', type: 'string' },
  { name: 'side', label: 'Side', kind: 'input', type: 'string' },
  { name: 'percent', label: 'Use percentages', kind: 'checkbox' },
  { name: 'cancel_orders', label: 'Cancel existing', kind: 'checkbox' },
  {
    name: 'isolatedSymbol',
    label: 'Isolated Symbol ticker',
    kind: 'input',
    type: 'string',
  },
];

const config_fields: any = {
  config_1: [
    'percent',
    'kind',
    'variable',
    'operator',
    'value',
    'entry_target',
    'size',
    'min_size',
    'spread',
    'cancel_orders'
  ],
  config_2: ['percent', 'kind', 'value', 'min_size', 'spread', 'entry_target'],
  config_3: [
    'kind',
    'stop_price',
    'entry_target',
    'size',
    'min_size',
    'spread',
  ],
  config_5: [
    'kind',
    'borrow',
    'pnl',
    'entry_target',
    'size',
    'min_size',
    'spread',
    'isolatedSymbol',
  ],
  config_6: ['asset', 'isolatedSymbol', 'entry_target', 'percent'],
  config_7: [
    'kind',
    'percent',
    'entry_target',
    'stop_price',
    'stop_range',
    'take_profit',
    'size',
    'min_size',
    'spread',
  ],
  config_8: ['kind', 'entry_target', 'size', 'min_size', 'spread'],
  config_9: ['value', 'isolatedSymbol'],
  config_10: ['kind', 'variable', 'operator', 'value', 'entry_target', 'side'],
  config_11: ['kind', 'min_size', 'size'],
  config_12: ['kind', 'isolatedSymbol', 'percent'],
  config_13: ['min_size', 'spread'],
  config_14: ['kind', 'entry_target', 'size', 'min_size'],
  config_15: ['value', 'min_size', 'size', 'spread'],
  config_16: ['kind', 'value', 'min_size', 'spread', 'size', 'entry_target'],
  config_17: ['kind', 'size', 'isolatedSymbol'],
  config_18: [
    'kind',
    'entry_target',
    'value',
    'stop_price',
    'borrow',
    'take_profit',
    'stop_range',
  ],
  config_19: ['entry_target', 'size', 'spread'],
};
type CONFIG_TYPE =
  | 'new_order'
  | 'purchase_after_profit'
  | 'new_order_sell'
  | 'transfer_funds'
  | 'margin_profit'
  | 'protect_profit'
  | 'market_margin_buy'
  | 'reduce_position_size'
  | 'reduce_entry_price'
  | 'minimum_entry_at_opposite_kind'
  | 'margin_future_sync'
  | 'liquidation_watcher'
  | 'isolated_mode'
  | 'watch_isolated_margin'
  | 'create_cross_trade'
  | 'create_isolated_trade'
  | 'reduce_position_size_at_entry'
  | 'pause_watcher_on_maximum_reached'
  | 'resume_watcher_on_size_reduced'
  | 'toggle_cross_mode'
  | 'run_sub_account_trade'
  | 'repay_and_borrow_cross_collateral';
// | 'increase_position_with_borrow'
// | 'new_trade_with_stop'
// | 'take_profit_with_entry'
// | 'update_profit_and_stop'
// | 'new_trade_with_profit_and_stop'

export const CreateJob = types
  .model('CreateJob', {
    type: types.optional(types.string, ''),
    kind: types.optional(types.string, 'long'),
    variable: types.optional(types.string, 'size'),
    pnl: types.optional(types.string, ''),
    operator: types.optional(types.string, 'between'),
    value: types.optional(types.string, ''),
    entry_target: types.optional(types.string, ''),
    stop_price: types.optional(types.number, 0),
    size: types.optional(types.number, 0),
    min_size: types.optional(types.number, 0.001),
    spread: types.optional(types.string, ''),
    borrow: types.optional(types.number, 10),
    isolatedSymbol: types.optional(types.string, ''),
    asset: types.optional(types.string, ''),
    stop_range: types.optional(types.number, 0),
    take_profit: types.optional(types.number, 0),
    side: types.optional(types.string, 'buy'),
    percent: types.optional(types.boolean, false),
    cancel_orders: types.optional(types.boolean,false)
  })
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      getConfigValues(config: CONFIG_TYPE) {
        let options = {
          new_order: 'config_1',
          reduce_position_size: 'config_2',
          reduce_entry_price: 'config_2',
          purchase_after_profit: 'config_3',
          increase_position_with_borrow: 'config_5',
          new_order_sell: 'config_1',
          transfer_funds: 'config_6',
          margin_profit: 'config_6',
          new_trade_with_stop: 'config_7',
          new_trade_with_profit_and_stop: 'config_7',
          update_profit_and_stop: 'config_7',
          protect_profit: 'config_9',
          take_profit_with_entry: 'config_8',
          market_margin_buy: 'config_10',
          minimum_entry_at_opposite_kind: 'config_11',
          margin_future_sync: 'config_12',
          liquidation_watcher: 'config_13',
          isolated_mode: 'config_14',
          watch_isolated_margin: 'config_14',
          create_cross_trade: 'config_15',
          toggle_cross_mode: 'config_14',
          create_isolated_trade: 'config_16',
          reduce_position_size_at_entry: 'config_11',
          pause_watcher_on_maximum_reached: 'config_17',
          resume_watcher_on_size_reduced: 'config_17',
          run_sub_account_trade: 'config_18',
          repay_and_borrow_cross_collateral: 'config_19',
        };
        let result: any = {};
        let config_value = options[config];
        config_fields[config_value].map((o: any) => {
          let _self: any = self;
          result[o] = _self[o];
        });
        result.type = config;
        result.value = extractValue(
          result.value,
          result.value?.startsWith('['),
        );
        result.spread = extractValue(
          result.spread,
          result.spread?.startsWith('['),
        );
        result.entry_target = extractValue(
          result.entry_target,
          result.entry_target?.startsWith('['),
        );
        if (isNaN(result.value) && !Array.isArray(result.value)) {
          delete result.value;
        }
        if (result.pnl) {
          try {
            let value = JSON.parse(result.pnl || '[]');
            result.pnl = value.map((o: any) => parseFloat(o));
          } catch (error) {
            //console.log(error);
          }
        }
        if (config == 'new_order') {
          result.is_margin = result.percent;
          delete result.percent;
        }
        if (config == 'transfer_funds') {
          result.amount = result.entry_target || 0;
          result.borrow = result.percent;
          delete result.entry_target;
          delete result.percent;
        }
        if (['reduce_entry_price', 'reduce_position_size'].includes(config)) {
          result.immediate = result.percent;
          result.fee_spread = result.entry_target;
          delete result.entry_target;
          delete result.percent;
        }
        if (config == 'market_margin_buy') {
          result.amount = result.entry_target || 0;
          delete result.entry_target;
        }
        // if (config == 'update_profit_and_stop') {
        //   ['spread', 'min_size', 'stop_range', 'percent'].forEach((o) => {
        //     delete result[o];
        //   });
        // }
        if (config == 'margin_future_sync') {
          result.quick = result.percent;
          delete result.percent;
        }
        if (
          [
            'pause_watcher_on_maximum_reached',
            'resume_watcher_on_size_reduced',
          ].includes(config)
        ) {
          result.profile_id = result.isolatedSymbol;
          delete result.isolatedSymbol;
        }

        if (
          [
            'isolated_mode',
            'watch_isolated_margin',
            'create_isolated_trade',
            'toggle_cross_mode',
          ].includes(config)
        ) {
          result.budget = result.entry_target;
          delete result.entry_target;
        }
        if (config === 'repay_and_borrow_cross_collateral') {
          result.price = result.entry_target;
          delete result.entry_target;
        }
        if (['run_sub_account_trade'].includes(config)) {
          result.entry = result.entry_target;
          result.stop = result.stop_price;
          result.budget = result.value;
          result.modulo = result.stop_range;
          result.account_length = result.take_profit;
          result.risk_reward = result.borrow;
          let fields = [
            'entry_target',
            'stop_price',
            'variable',
            'pnl',
            'operator',
            'size',
            'min_size',
            'spread',
            'borrow',
            'isolatedSymbol',
            'asset',
            'stop_range',
            'percent',
          ];
          fields.forEach((x: string) => {
            delete result[x];
          });
        }
        return result;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
        //   if (isGroup === 'group' && field === 'checkbox') {
        //     return configs
        //       .filter((o: any) => o.kind === field && o.group === true)
        //       .map((o) => {
        //         let _self: any = self;
        //         let value: any = _self[o.name];
        //         return { ...o, value };
        //       });
        //   }
        //   let r: any = configs.find((o) => o.name == field);
        //   if (r) {
        //     let oo: any = self;
        //     let tt: any = oo[r.name] || '';
        //     r.value = tt;
        //   }
        //   return r;
        // },
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });
export const JobInstance = types
  .model('JobInstance', {
    config: types.optional(CreateJob, {}),
    value: types.optional(
      types.enumeration('name', [
        'new_order',
        'purchase_after_profit',
        'new_order_sell',
        'transfer_funds',
        'margin_profit',
        'protect_profit',
        'market_margin_buy',
        'reduce_entry_price',
        'reduce_position_size',
        'minimum_entry_at_opposite_kind',
        'margin_future_sync',
        'liquidation_watcher',
        'isolated_mode',
        'watch_isolated_margin',
        'create_cross_trade',
        'create_isolated_trade',
        'reduce_position_size_at_entry',
        'resume_watcher_on_size_reduced',
        'pause_watcher_on_maximum_reached',
        'toggle_cross_mode',
        'run_sub_account_trade',
        'repay_and_borrow_cross_collateral',
        // 'new_trade_with_profit_and_stop',
        // 'update_profit_and_stop',
        // 'take_profit_with_entry',
        // 'new_trade_with_stop',
        // 'increase_position_with_borrow',
      ]),
      'new_order',
    ),
  })
  .views((self) => {
    return {
      get dataToSubmit() {
        return self.config.getConfigValues(self.value);
      },
      get summary() {
        let data = this.dataToSubmit;
        let ordersToPlace = data.size / data.min_size;
        if (self.value == 'new_order') {
          return `Create new order with condition ${data.kind}.${data.variable}
            ${data.operator} ${data.value} with ${ordersToPlace} orders at ${data.min_size}
            each with spread ${data.spread}`;
        }
        if (self.value == 'purchase_after_profit') {
          let action = data.kind == 'long' ? 'sold' : 'bought';
          return `After ${data.kind}.size is ${action}, place ${ordersToPlace} orders at ${data.min_size}
          each with spread ${data.spread}`;
        }
        // if (self.value == 'increase_position_with_borrow') {
        //   return `Borrow ${data.borrow} and increase ${data.kind} position with ${ordersToPlace}
        //   orders at ${data.min_size} each with spread ${data.spread}`;
        // }
        if (self.value == 'market_margin_buy') {
          return `Margin buy/sell of ${data.amount} dollar value with condition ${data.kind}.${data.variable} ${data.operator} ${data.value}`;
        }
        return '';
      },
      get configFieldsOptions() {
        return [
          'new_order',
          'new_order_sell',
          'reduce_position_size',
          'reduce_entry_price',
          'minimum_entry_at_opposite_kind',
          'purchase_after_profit',
          'transfer_funds',
          'protect_profit',
          'market_margin_buy',
          'margin_profit',
          'margin_future_sync',
          'liquidation_watcher',
          'isolated_mode',
          'watch_isolated_margin',
          'create_cross_trade',
          'create_isolated_trade',
          'reduce_position_size_at_entry',
          'resume_watcher_on_size_reduced',
          'pause_watcher_on_maximum_reached',
          'toggle_cross_mode',
          'run_sub_account_trade',
          'repay_and_borrow_cross_collateral',
          // 'increase_position_with_borrow',
          // 'new_trade_with_stop',
          // 'take_profit_with_entry',
          // 'new_trade_with_profit_and_stop',
          // 'update_profit_and_stop',
        ];
      },
    };
  })
  .actions((self) => {
    return {
      setValue(value: any) {
        self.value = value;
      },
      onConfigSelect(config: any) {
        let result = config;
        self.config.updateFields({
          type: config,
        });
        self.value = result;
        // const defaultConfig = `${self.account?.owner}_defaultConfig'`;
        // window.localStorage.setItem(defaultConfig, result);
      },
    };
  });
const Condition = types
  .model('Condition', {
    variable: types.string,
    operator: types.maybe(types.string),
    value: types.maybe(
      types.maybeNull(
        types.union(types.array(types.number), types.number, types.string),
      ),
      // types.union(types.array(types.number), types.number, types.string),
    ),
    key: types.maybe(types.string),
  })
  .views((self) => {
    return {
      get variableOptions() {
        let raw = [
          'entry',
          'current_price',
          'size',
          'pnl',
          'liquidation',
          'margin_wallet',
          'margin_type',
          'last_order',
        ];
        return [
          ...[...raw.map((o) => `long.${o}`), ...raw.map((o) => `short.${o}`)],
          'margin.long.current_price',
          'margin.short.current_price',
          'balance',
          'price',
          'loan',
        ];
      },
      get operatorOptions() {
        return ['=', '>', '<', '>=', '<=', '+', '-', '*', '/', '!=', '>=='];
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });
const DeleteAction = types.model('DeleteAction', {
  any: types.optional(types.array(Condition), []),
  and: types.optional(types.array(Condition), []),
  immediate: types.optional(types.boolean, false),
});
const ActionParams = types
  .model('ActionParams', {
    price: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    real_price: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    stop: types.union(
      types.maybeNull(types.optional(types.number, 0)),
      types.maybeNull(types.optional(types.string, '')),
    ),
    min_price: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    quantity: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    amount: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    max_size: types.union(
      types.optional(types.number, 0),
      types.optional(types.string, ''),
    ),
    modulo: types.optional(types.number, 0),
    account_length: types.optional(types.number, 10),
    risk_reward: types.optional(types.number, 1),
    budget: types.optional(types.number, 0),
    entry: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    min_size: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    margin: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    resistance: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    profit: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    loss: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    opposite_entry: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    kind: types.optional(types.string, ''),
    bias: types.optional(types.string, ''),
    side: types.union(types.maybe(types.string), types.maybeNull(types.string)),
    borrow_fund: types.optional(types.number, 0),
    isolatedSymbol: types.maybeNull(types.optional(types.string, '')),
    spot_symbol: types.maybeNull(types.optional(types.string, '')),
    symbol: types.maybeNull(types.optional(types.string, '')),
    profile_id: types.maybeNull(types.optional(types.string, '')),
    asset: types.optional(types.string, ''),
    stop_loss_percent: types.optional(types.number, 0),
    take_profit_percent: types.optional(types.number, 0),
    cancel: types.optional(types.boolean, false),
    is_margin: types.optional(types.boolean, false),
    size: types.maybeNull(
      types.union(
        types.optional(types.number, 0),
        types.optional(types.string, ''),
      ),
    ),
    is_market: types.maybe(types.boolean),
    quick: types.maybe(types.boolean),
    values: types.optional(types.array(types.number), []),
    spread: types.optional(types.number, 0),
    min_spread: types.optional(types.number, 0),
    owner: types.optional(types.string, ''),
    action: types.optional(types.string, ''),
    bias_entry: types.optional(types.string, ''),
    bias_size: types.optional(types.string, ''),
  })
  .views((self) => {
    return {
      get supportedFields() {
        return [
          'price',
          'min_price',
          'quantity',
          'amount',
          'max_size',
          'min_size',
          'size',
          'borrow_fund',
          'isolatedSymbol',
          'symbol',
          'asset',
          'entry',
          'spread',
          'stop',
          'real_price',
          'quick',
          'margin',
          'resistance',
          'action',
          'profile_id',
          'profit',
          'opposite_entry',
          'loss',
          'risk_reward',
          'modulo',
          'account_length',
          'budget',
          'bias',
          'bias_entry',
          'bias_size',
        ];
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });
const VariableType = types.model('VariableType', {
  quantity: types.maybe(Condition),
  size: types.maybe(Condition),
  price: types.maybe(Condition),
  entry: types.maybe(Condition),
  liquidation: types.maybe(Condition),
  real_price: types.maybe(Condition),
  margin: types.maybe(Condition),
  margin_wallet: types.maybe(Condition),
});
const Action = types
  .model('Action', {
    type: types.string,
    params: types.union(
      types.optional(types.array(ActionParams), []),
      ActionParams,
    ),
    variables: types.maybe(VariableType),
  })
  .views((self) => {
    return {
      get pArray() {
        return self.params ? getSnapshot(self.params) : undefined;
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });
export const JobStore = types
  .model('JobStore', {
    name: types.identifier,
    editableName: types.optional(types.string, ''),
    conditions: types.optional(types.array(Condition), []),
    conditionVariables: types.maybe(VariableType),
    deleteAction: types.optional(DeleteAction, {}),
    actions: types.optional(types.array(Action), []),
    job_id: types.optional(types.string, ''),
    job_status: types.optional(types.string, ''),
    loading: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get displayName() {
        return self.editableName || self.name;
      },
      get paused() {
        return self.job_status == 'pause';
      },
      get conditionSummary() {
        let results = self.conditions
          .map((o) => `${o.variable} ${o.operator} ${o.value}`)
          .join(', ');
        return results;
      },
      get actionSummary() {
        if (self.name.startsWith('Update liquidation for')) {
          return self.name;
        }
        let results = self.actions
          .map((action) => {
            if (['new_order', 'margin_orders'].includes(action.type)) {
              let totalQuantity: any = 0;
              let act = '';
              let maxPrice = 0;
              let minPrice = 0;
              let priceText;
              let pp: any = action.params;
              if (Array.isArray(getSnapshot(pp))) {
                totalQuantity = pp
                  .filter((o: any) => Number.isFinite(o.quantity))
                  .map((o: any) => o.quantity);
                totalQuantity = totalQuantity.reduce(
                  (a: number, b: number) => a + b,
                  0,
                );

                act = `${pp[0]?.kind} ${pp[0]?.side}`;
                maxPrice = Math.max(...pp.map((o: any) => o.price));
                minPrice = Math.min(...pp.map((o: any) => o.price));
                priceText =
                  maxPrice === minPrice
                    ? `at ${maxPrice}`
                    : `between ${minPrice} and ${maxPrice}`;
              }
              if (!act) {
                return self.name;
              }
              const isMargin = action.type == 'margin_orders';
              return `${
                isMargin ? 'Margin Trade ' : ''
              }${act} of ${totalQuantity} ${priceText}`;
            }
            // if (action.type == 'market_margin_buy') {
            //   let amount: any = action.params?.amount || 0;
            //   return `with dollar value ${amount}`;
            // }
            return '';
          })
          .join(',');
        return results
          ? `Condtion: ${this.conditionSummary} Action:${results}`
          : '';
      },
      get display() {
        return this.actionSummary || self.name;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const removeAction = flow(function* removeAction() {
      let parent: any = getParent(self, 2);
      let accountInstance: any = getParent(self, 3);
      let appInstance: any = getParent(self, 5);
      try {
        self.loading = true;
        yield adapter.deleteJob({
          job_name: self.name,
          owner: accountInstance.owner,
          symbol: parent.symbol,
        });
        yield appInstance.getBotAccounts();
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    return {
      removeAction,
      updateName(name: string) {
        self.editableName = name;
      },
    };
  });
export interface IJobStore extends Instance<typeof JobStore> {}
export interface IAction extends Instance<typeof Action> {}
export interface IVariableType extends Instance<typeof VariableType> {}
export interface ICondition extends Instance<typeof Condition> {}
export interface IDeleteAction extends Instance<typeof DeleteAction> {}
export interface IActionParam extends Instance<typeof ActionParams> {}
export interface ICreateJobConfig extends Instance<typeof CreateJob> {}
export interface IJobInstance extends Instance<typeof JobInstance> {}
