import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  SimpleGrid,
  Spacer,
  StatArrow,
  StatHelpText,
  StatLabel,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { FC, FunctionComponent, useState } from 'react';
import { loadAdapter, setAdapter } from '../adapter';
import {
  BotStore,
  IBotConfig,
  IBotStore,
  IPositionStore,
} from '../store/botConfig';
import AppHeading from './AppHeading';
import Calculator from './Calculator';
import { DropdownMenu } from './components/DropdownMenu';
import {
  CheckboxGroupElement,
  InputElement,
  InputElementEnhanced,
  RadioElement,
  SelectElement,
} from './components/FormElement';
import Config1Display from './components/GenericConfigDisplay';
import Sidebar, { AlertDialogComponent } from './components/Sidebar';
import StatsDisplay from './components/StatsDisplay';
import ConfigurationHeader from './ConfigurationHeader';

interface Window {
  APP_DATA: any;
}
declare let window: Window;
let env: 'testing' | 'production' = 'production';
if (window.APP_DATA) {
  const environment: 'testing' | 'production' = window.APP_DATA.environment;
  env = environment || 'production';
}

const Displayer: FunctionComponent<{ text: string; value: any }> = observer(
  ({ text, value }) => {
    return (
      <Flex justifyContent="space-around">
        <Text>
          {text}: {value}
        </Text>
      </Flex>
    );
  },
);

const Config4Display: FunctionComponent<{ store: IBotStore }> = observer(
  ({ store }) => {
    const toast = useToast();
    const configDisclosure = useDisclosure();
    const btnRef: any = React.useRef();
    function toastHelper(status: 'success' | 'error', title: string) {
      toast({
        title,
        status,
        description: title,
        duration: 3000,
        isClosable: true,
      });
    }
    const fields = store.config_fields.config_4;
    return (
      <SimpleGrid mt={3} columns={2} spacing={5}>
        {fields.map((field) => {
          const fieldConfig = store.getParamForField(field);
          if (fieldConfig?.kind == 'input') {
            return (
              <InputElement
                label={fieldConfig.label}
                type="number"
                onChange={(e: any) => {
                  store.account?.defaultConfig.updateFields({
                    [fieldConfig.name]: parseFloat(e.target.value),
                  });
                }}
                value={fieldConfig.value}
              />
            );
          }
          if (fieldConfig?.kind == 'special') {
            return (
              <InputElementEnhanced
                label={fieldConfig.label}
                type="number"
                onChange={(e: any) => {
                  store.account?.defaultConfig.updateFields({
                    [fieldConfig.name]: parseFloat(e.target.value),
                  });
                }}
                defaultValue={fieldConfig.value}
              />
            );
          }
          if (fieldConfig?.kind == 'checkbox') {
            return (
              <GridItem colSpan={1}>
                <Checkbox
                  onChange={(e) =>
                    store.account?.defaultConfig.updateFields({
                      [fieldConfig.name]: e.target.checked,
                    })
                  }
                  isChecked={fieldConfig.value}
                >
                  {fieldConfig.label}
                </Checkbox>
              </GridItem>
            );
          }
          if (fieldConfig?.kind == 'select') {
            return (
              <SelectElement
                label={fieldConfig.label}
                defaultText={`Select ${fieldConfig.label}`}
                value={fieldConfig.value}
                options={fieldConfig.options}
                onChange={(e: any) => {
                  store.account?.defaultConfig.updateFields({
                    [fieldConfig.name]: e.target.value,
                  });
                }}
              />
            );
          }
          return null;
        })}
        {/* <Sidebar
          placement="bottom"
          button={
            <Flex alignSelf="end" justifyContent="center">
              <Button
                onClick={() => {
                  // store
                  //   .fetchBotOrders()
                  //   .then(() => {
                  //     configDisclosure.onOpen();
                  //   })
                  //   .catch((error) => {
                  configDisclosure.onOpen();
                  // });
                }}
                mt={4}
                isLoading={store.loading}
                loadingText={'Loading'}
                colorScheme="green"
              >
                Process config orders
              </Button>
            </Flex>
          }
          title="Close or process orders"
          onSave={() => {
            // toastHelper('success', 'Updated General Config');
            configDisclosure.onClose();
            // store.positionStore.updateTradeConfig();
          }}
          {...{
            isOpen: configDisclosure.isOpen,
            onClose: configDisclosure.onClose,
            btnRef,
          }}
        >
          {<MainModeWithCalculator store={store} />}
        </Sidebar>
      */}
      </SimpleGrid>
    );
  },
);
const Config2Display: FunctionComponent<{
  store?: IBotConfig;
  positionStore: IPositionStore;
}> = observer(({ store, positionStore }) => {
  const fields = store?.config_fields.config_2 || [];
  return (
    <SimpleGrid mt={3} columns={2} spacing={5}>
      {fields.map((field) => {
        if (typeof field === 'string') {
          const fieldConfig = store?.getParamForField(field);
          if (fieldConfig?.kind == 'input') {
            return (
              <InputElement
                label={fieldConfig.label}
                type="number"
                defaultValue={fieldConfig.value}
                onChange={(e: any) => {
                  store?.updateFields({
                    [fieldConfig.name]: parseFloat(e.target.value),
                  });
                  if (fieldConfig.name === 'bias_r') {
                    positionStore.updateTradeConfig();
                  }
                }}
              />
            );
          }
        }
        if (Array.isArray(field)) {
          const fieldConfigs = store?.getParamForField('checkbox', 'group');
          return (
            <CheckboxGroupElement
              onChange={(e: any) => {
                fieldConfigs.forEach((element: any) => {
                  if (e.includes(element.name)) {
                    store?.updateFields({ [element.name]: true });
                  } else {
                    store?.updateFields({ [element.name]: false });
                  }
                });
              }}
              value={fieldConfigs
                .filter((o: any) => o.value)
                .map((o: any) => o.name)}
              options={fieldConfigs.map((o: any) => ({
                label: o.label,
                value: o.name,
              }))}
            />
          );
        }
        return null;
      })}
    </SimpleGrid>
  );
});
const GeneralConfigDisplay: FunctionComponent<{ store: IBotStore }> = observer(
  ({ store }) => {
    const fields = store.config_fields.general;
    return (
      <Grid mt={3} templateColumns="repeat(2, 1fr)" gap={5}>
        {fields.map((field) => {
          if (typeof field == 'string') {
            const fieldConfig = store.getParamForField(field);
            if (fieldConfig?.kind == 'input') {
              return (
                <GridItem colSpan={1}>
                  <InputElement
                    label={fieldConfig.label}
                    type="number"
                    onChange={(e: any) => {
                      store.account?.defaultConfig.updateFields({
                        [fieldConfig.name]: parseFloat(e.target.value),
                      });
                    }}
                    defaultValue={fieldConfig.value}
                  />
                </GridItem>
              );
            }
            if (fieldConfig?.kind == 'radio') {
              return (
                <GridItem colSpan={1}>
                  <RadioElement
                    label={fieldConfig.label}
                    value={fieldConfig.value}
                    onChange={(e: any) => {
                      store.account?.defaultConfig.updateFields({
                        [fieldConfig.name]: e,
                      });
                    }}
                    options={fieldConfig.options.map((o: any) => ({
                      label: o,
                      value: o,
                    }))}
                  />
                </GridItem>
              );
            }
            if (fieldConfig?.kind == 'select') {
              return (
                <SelectElement
                  label={fieldConfig.label}
                  defaultText={`Select ${fieldConfig.label}`}
                  value={fieldConfig.value}
                  options={fieldConfig.options}
                  onChange={(e) => {
                    store.account?.defaultConfig.updateFields({
                      [fieldConfig.name]: e.target.value,
                    });
                  }}
                />
              );
            }
            if (fieldConfig?.kind == 'checkbox') {
              return (
                <GridItem colSpan={1}>
                  <Checkbox
                    onChange={(e) =>
                      store.account?.defaultConfig.updateFields({
                        [fieldConfig.name]: e.target.checked,
                      })
                    }
                    isChecked={fieldConfig.value}
                  >
                    {fieldConfig.label}
                  </Checkbox>
                </GridItem>
              );
            }
          }
          if (Array.isArray(field)) {
            const fieldConfigs = field.map((o) => store.getParamForField(o));
            if (field.includes('acceptable_loss')) {
              const acceptable_loss = fieldConfigs.find(
                (o) => o.name === 'acceptable_loss',
              );
              return (
                <GridItem colSpan={2}>
                  <SimpleGrid columns={2} spacing={5}>
                    <Checkbox
                      onChange={(e) =>
                        store.account?.defaultConfig.updateFields({
                          [acceptable_loss.name]: e.target.checked,
                        })
                      }
                      defaultIsChecked={acceptable_loss.value}
                    >
                      {acceptable_loss.label}
                    </Checkbox>
                    {acceptable_loss.value && (
                      <InputElement
                        label={fieldConfigs[1].label}
                        type="number"
                        name={fieldConfigs[1].name}
                        onChange={(e: any) => {
                          store.account?.defaultConfig.updateFields({
                            [fieldConfigs[1].name]: parseFloat(e.target.value),
                          });
                        }}
                        defaultValue={fieldConfigs[1].value}
                      />
                    )}
                  </SimpleGrid>
                </GridItem>
              );
            } else {
              let value = false;
              if (store.account) {
                let oo: any = store.account.defaultConfig;
                value = oo[fieldConfigs[1].name] > 0;
              }
              const isChecked = value;
              return (
                <GridItem colSpan={2}>
                  <EMAToggler
                    store={store}
                    isChecked={isChecked}
                    fieldConfig1={fieldConfigs[0]}
                    fieldConfig2={fieldConfigs[1]}
                  />
                </GridItem>
              );
            }
          }
          return null;
        })}
      </Grid>
    );
  },
);

const EMAToggler: FunctionComponent<{
  isChecked: boolean;
  store: IBotStore;
  fieldConfig1: { label: string; name: string; value: any };
  fieldConfig2: { label: string; name: string; value: any };
}> = observer(({ isChecked, store, fieldConfig1, fieldConfig2 }) => {
  const [checked, setChecked] = useState(isChecked);
  function getFieldConfig(chk?: boolean) {
    const _checked = chk || checked;
    return _checked ? fieldConfig2 : fieldConfig1;
  }
  const [v, setV] = useState(
    isChecked ? fieldConfig2.value : fieldConfig1.value,
  );
  function updateFields(rr?: any, chk?: boolean) {
    const fieldConfig = getFieldConfig(chk);

    let values = {
      [fieldConfig.name]: rr,
    };
    if (!fieldConfig.name.includes('ema')) {
      values[fieldConfig2.name] = 0;
    }
    store.account?.defaultConfig.updateFields(values);
  }
  return (
    <SimpleGrid columns={2} spacing={5}>
      <InputElement
        label={getFieldConfig().label}
        type="number"
        name={getFieldConfig().name}
        onChange={(e: any) => {
          let result = parseFloat(e.target.value);
          setV(result);
          updateFields(result);
        }}
        kdy={getFieldConfig().name}
        value={v}
      />
      <Checkbox
        onChange={(e: any) => {
          let r = e.target.checked;
          setChecked(r);
          if (r) {
            setV(fieldConfig2.value);
          } else {
            setV(fieldConfig1.value);
          }
          let rst = r ? fieldConfig2.value : fieldConfig1.value;
          updateFields(rst, r);
        }}
        defaultIsChecked={checked}
      >
        Dynamic
      </Checkbox>
    </SimpleGrid>
  );
});
const store = BotStore.create(
  {},
  {
    adapter: setAdapter(loadAdapter(env)),
  },
);
const BotConfig = observer(({}) => {
  const [newMode, setNewMode] = useState(true);
  const [page, setPage] = React.useState('jobs');
  const jobDisclosure = useDisclosure();
  return (
    <Flex direction="column" m={3}>
      <AppHeading
        {...{ page, setPage }}
        store={store}
        newMode={newMode}
        setNewMode={setNewMode}
      />
      <Spacer />
      {newMode ? (
        <MainModeWithCalculator page={page} store={store} />
      ) : (
        <OldBehavior store={store} />
      )}
      <BottomButtons
        store={store}
        mode={newMode}
        onClick={() => {
          jobDisclosure.onOpen();
        }}
      />
    </Flex>
  );
});

export default BotConfig;

const LossMinimizerAnalysis: FunctionComponent<{
  store: IPositionStore;
  botStore: IBotConfig;
}> = observer(({ store, botStore }) => {
  let stats = [];
  if (store.longPosition) {
    stats.push({
      heading: `Long position`,
      body: `${store.longPosition.entryPrice.toFixed(2)}`,
      footer: <StatLabel>Size: {store.longPosition.quantity}</StatLabel>,
    });
  }
  if (store.shortPosition) {
    stats.push({
      heading: 'Short position',
      body: `${store.shortPosition.entryPrice}`,
      footer: <StatLabel>Size: {store.shortPosition.quantity}</StatLabel>,
    });
  }
  let lossInfo = store.lossInfo;
  if (lossInfo) {
    stats.push({
      heading: `size: ${lossInfo.quantity} balance: ${store.balance}`,
      body: `MM: ${lossInfo.maintenance_margin}`,
      // body: `Current/MM: ${store.currentPrice.toFixed(2)}/${lossInfo.maintenance_margin}`,
      footer: <StatLabel>Liquidation: {lossInfo.liquidation}</StatLabel>,
    });
  }
  let longConfig = store.entryPoint(botStore, 'long');
  let shortConfig = store.entryPoint(botStore, 'short');
  if (store.longPosition?.entryPrice) {
    stats.push({
      heading: longConfig.heading,
      body: longConfig.body,
      footer: (
        <StatLabel>
          {longConfig.footer == 'undefined' ? '' : longConfig.footer}
        </StatLabel>
      ),
    });
  }
  if (store.shortPosition?.entryPrice) {
    stats.push({
      heading: shortConfig.heading,
      body: shortConfig.body,
      footer: (
        <StatLabel>
          {shortConfig.footer == 'undefined' ? '' : shortConfig.footer}
        </StatLabel>
      ),
    });
  }
  React.useEffect(() => {
    store.initializeSockets();
    return function cleanup() {
      store.destroySockets();
    };
  }, []);
  return <StatsDisplay stats={stats} />;
});
const LossMinimizerEntryExit: FunctionComponent<{
  store: IPositionStore;
  botStore: IBotConfig;
}> = observer(({ store, botStore }) => {
  let stats = [];
  let { long, short } = store.profitHelper;
  let pnlAnalysis: any = store.liquidationAnalysis('pnl');
  let additionAnalysis: any = store.liquidationAnalysis('additional');
  let stopDisplay: any = store.stopDisplay;
  if (long) {
    stats.push({
      heading: `Long position`,
      body: `${long.takeProfit.toFixed(2)}`,
      footer: long && (
        <>
          <StatHelpText>
            P/L:
            {long.pnl > 0 ? (
              <StatArrow ml={1} type="increase" />
            ) : (
              <StatArrow ml={1} type="decrease" />
            )}
            {long.pnl.toFixed(2)}
          </StatHelpText>
          {long.quantity && (
            <StatLabel>Size: {long.quantity.toFixed(3)}</StatLabel>
          )}
        </>
      ),
    });
  }
  if (short) {
    stats.push({
      heading: 'Short position',
      body: `${short.takeProfit.toFixed(2)}`,
      footer: short && (
        <>
          <StatHelpText>
            P/L:
            {short.pnl > 0 ? (
              <StatArrow ml={1} type="increase" />
            ) : (
              <StatArrow ml={1} type="decrease" />
            )}
            {short.pnl.toFixed(2)}
          </StatHelpText>
          {short.quantity && (
            <StatLabel>Size: {short.quantity.toFixed(3)}</StatLabel>
          )}
        </>
      ),
    });
  }
  if (pnlAnalysis) {
    stats.push({
      heading: `Liquidation after pnl`,
      body: pnlAnalysis.liquidation && pnlAnalysis.liquidation.toFixed(2),
      footer: pnlAnalysis.balance && (
        <StatLabel>Balance: {pnlAnalysis.balance.toFixed(2)}</StatLabel>
      ),
    });
  }
  if (additionAnalysis) {
    stats.push({
      heading: `Liquidation on additional`,
      body:
        additionAnalysis.liquidation && additionAnalysis.liquidation.toFixed(2),
      footer: (
        <>
          <StatLabel>
            {additionAnalysis.long
              ? `Long entry: ${additionAnalysis.long.entry.toFixed(
                  2,
                )} size: ${additionAnalysis.long.size.toFixed(3)}`
              : ''}
          </StatLabel>
          <StatLabel>
            {additionAnalysis.short
              ? `Short entry: ${additionAnalysis.short.entry.toFixed(
                  2,
                )} size: ${additionAnalysis.short.size.toFixed(3)}`
              : ''}
          </StatLabel>
        </>
      ),
    });
  }
  if (stopDisplay) {
    stats.push({
      heading: 'Stop Market',
      footer: (
        <>
          <StatLabel>
            Long: Entry: {stopDisplay.longEntry}, Size: {stopDisplay.longSize}
          </StatLabel>
          <StatLabel>
            Short: Entry: {stopDisplay.shortEntry}, Size:{' '}
            {stopDisplay.shortSize}
          </StatLabel>
        </>
      ),
    });
  }
  React.useEffect(() => {
    store.initializeSockets();
    return function cleanup() {
      store.destroySockets();
    };
  }, []);
  return <StatsDisplay stats={stats} />;
});

const TakeProfitAnalysis: FunctionComponent<{
  store: IPositionStore;
}> = observer(({ store }) => {
  let stats = [];
  if (store.openTrade) {
    stats.push({
      heading: `ideal size: ${store.analyze.size} / pnl: ${store.analyze.pnl}`,
      body: `Entry Price: ${store.openTrade.entryPrice}`,
      footer: (
        <StatLabel>
          ideal profit: {store.analyze.takeProfit.toFixed(2)}
        </StatLabel>
      ),
    });
  }
  stats = [
    ...stats,
    {
      heading: `Current Stop | current size ${store.openTrade?.quantity}`,
      body: `${store.openTrade?.stop?.toFixed(2) || 'null'}`,
      footer: store.openTrade && (
        <StatHelpText>
          P/L:
          {store.openTrade.getStopPnl > 0 ? (
            <StatArrow ml={1} type="increase" />
          ) : (
            <StatArrow ml={1} type="decrease" />
          )}
          {store.openTrade.getStopPnl}
        </StatHelpText>
      ),
    },
    {
      heading: `Take Profit`,
      body: `${store.openTrade?.takeProfit?.toFixed(2) || 'null'}`,
      footer: store.openTrade && (
        <StatHelpText>
          P/L:
          {store.openTrade.getTakeProfitPnl > 0 ? (
            <StatArrow ml={1} type="increase" />
          ) : (
            <StatArrow ml={1} type="decrease" />
          )}
          {store.openTrade.getTakeProfitPnl}
        </StatHelpText>
      ),
    },
  ];
  return <StatsDisplay stats={stats} />;
});

const MainModeWithCalculator: FC<{ store: IBotStore; page: any }> = observer(
  ({ store, page }) => {
    const toast = useToast();
    const modalDisclosure = useDisclosure();
    const btnRef: any = React.useRef();

    function toastHelper(status: 'success' | 'error', title: string) {
      toast({
        title,
        status,
        description: title,
        duration: 3000,
        isClosable: true,
      });
    }
    return store.calculatorMode ? (
      <Calculator
        page={page}
        rootStore={store}
        store={store.calculator}
        isLoading={store.loading}
        onCreateTakeProfit={store.onCreateTakeProfit}
        buttonText={`Switch back to ${store.calculator.symbol}`}
        onClick={() => {
          store.setCalculatorMode(false);
        }}
      >
        <ConfigurationHeader store={store} />
      </Calculator>
    ) : (
      <Flex direction="column">
        <Box mb={'1.5em'}>
          {store.account && (
            <LossMinimizerEntryExit
              store={store.positionStore}
              botStore={store.account.defaultConfig}
            />
          )}
        </Box>
        <Box alignSelf="center" mb={'1.5em'}>
          <Button
            mb={[4, 0, 0, 0]}
            colorScheme="blue"
            isLoading={store.loading}
            loadingText="Updating"
            onClick={() => {
              store.updateLoading(true);
              store
                .getAccountInfo()
                .then(() => {
                  store.updateLoading(false);
                })
                .catch(() => {
                  store.updateLoading(false);
                });
            }}
          >
            Refresh trades
          </Button>
          {/* <Button
            mb={[4, 0, 0, 0]}
            onClick={() => {
              store.fetchBotOrders();
            }}
            ml={4}
            isLoading={store.loading}
            loadingText={'Loading'}
            colorScheme="green"
          >
            Update config orders
          </Button> */}
          <Button
            mb={[4, 0, 0, 0]}
            ml={4}
            colorScheme="orange"
            onClick={store.switchToCalculator}
          >
            Calculator
          </Button>
          <Button
            mb={[4, 0, 0, 0]}
            ml={4}
            colorScheme="red"
            isLoading={store.loading}
            loadingText="Pausing"
            onClick={() =>
              store.toggleJobsInstance({
                action: 'margin_future_sync',
                job_status: ['', 'running'],
              })
            }
          >
            Pause margin sync trades
          </Button>
          <Button
            mb={[4, 0, 0, 0]}
            ml={4}
            colorScheme="green"
            isLoading={store.loading}
            loadingText="Resuming"
            onClick={() =>
              store.toggleJobsInstance({
                action: 'margin_future_sync',
                job_status: ['pause'],
              })
            }
          >
            Resume margin sync trades
          </Button>
          <Button
            mb={[4, 0, 0, 0]}
            ml={4}
            colorScheme="red"
            isLoading={store.loading}
            loadingText="Pausing"
            onClick={() =>
              store.toggleJobsInstance({
                action: 'toggle_isolated_mode',
                job_status: ['', 'running'],
              })
            }
          >
            pause future margin watch
          </Button>
          <Button
            mb={[4, 0, 0, 0]}
            ml={4}
            colorScheme="green"
            isLoading={store.loading}
            loadingText="Resuming"
            onClick={() =>
              store.toggleJobsInstance({
                action: 'toggle_isolated_mode',
                job_status: ['pause'],
              })
            }
          >
            resume future margin watch
          </Button>
        </Box>

        <Config1Display
          override="config_5"
          store={store.account?.defaultConfig}
        />
        <Flex my={4}>
          <DropdownMenu
            label={store.positionStore.manualAction}
            options={['Buy', 'Sell']}
            onClick={(config: string) => {
              store.positionStore.setManualAction(config.toLowerCase());
            }}
          />
          <DropdownMenu
            label={store.positionStore.orderType}
            options={['limit', 'stop_market']}
            onClick={(config: string) => {
              store.positionStore.setOrderType(config);
            }}
          />
          <Button
            isDisabled={
              store.positionStore.manualAction == 'buy'
                ? !Boolean(store.account?.defaultConfig.long_e) ||
                  !Boolean(store.account?.defaultConfig.long_s)
                : false
            }
            colorScheme="blue"
            isLoading={store.positionStore.loading}
            loadingText={'Processing'}
            onClick={() => {
              let func = store.positionStore.placeManualOrder;
              if (store.positionStore.orderType == 'stop_market') {
                func = store.positionStore.updateStopTrade;
              }
              func('long')
                .then(() => {
                  toastHelper('success', 'Long order placed');
                })
                .catch(() => toastHelper('error', 'Long order failed'));
            }}
          >
            {store.positionStore.manualAction} Long
          </Button>
          <Spacer />
          <DropdownMenu
            label={store.positionStore.manualAction}
            options={['Buy', 'Sell']}
            onClick={(config: string) => {
              store.positionStore.setManualAction(config.toLowerCase());
            }}
          />
          <DropdownMenu
            label={store.positionStore.orderType}
            options={['limit', 'stop_market']}
            onClick={(config: string) => {
              store.positionStore.setOrderType(config);
            }}
          />
          <Button
            isDisabled={
              store.positionStore.manualAction == 'sell'
                ? !Boolean(store.account?.defaultConfig.short_e) ||
                  !Boolean(store.account?.defaultConfig.short_s)
                : false
            }
            colorScheme="red"
            isLoading={store.positionStore.loading}
            loadingText={'Processing'}
            onClick={() => {
              let func = store.positionStore.placeManualOrder;
              if (store.positionStore.orderType == 'stop_market') {
                func = store.positionStore.updateStopTrade;
              }
              func('short')
                .then(() => {
                  toastHelper('success', 'short order placed');
                })
                .catch(() => toastHelper('error', 'short order failed'));
            }}
          >
            {store.positionStore.manualAction} Short
          </Button>
        </Flex>
        {store.account && (
          <Box my={3}>
            {store.positionStore.serverOrders.map((o) => {
              let display = `${
                o.kind === 'long' ? 'Long' : 'Short'
              } trade at entry: ${o.price} size: ${o.quantity}`;
              return (
                <Flex mb={2}>
                  <Text>{display}</Text>
                  <Spacer />
                  <Button
                    isLoading={store.positionStore.loading}
                    loadingText="Creating order"
                    onClick={() => {
                      if (store.account) {
                        store.positionStore
                          .placeOrder(
                            store.account.defaultConfig,
                            {
                              ...o,
                              entry: o.price,
                            },
                            'buy',
                          )
                          .then(() => {
                            toastHelper('success', 'Long order placed');
                            // configDisclosure.onClose();
                          })
                          .catch(() =>
                            toastHelper('error', 'Long order failed'),
                          );
                      }
                    }}
                    colorScheme={o.kind === 'long' ? 'blue' : 'red'}
                  >
                    Place Trade
                  </Button>
                </Flex>
              );
            })}
          </Box>
        )}
      </Flex>
    );
  },
);

const OldBehavior: FC<{ store: IBotStore }> = observer(({ store }) => {
  const configDisclosure = useDisclosure();
  const toast = useToast();
  const btnRef: any = React.useRef();
  function configDisplay() {
    if (store.config === 'config_1') {
      return (
        <Config1Display
          key={store.account_loading.toString()}
          store={store.account?.defaultConfig}
        />
      );
    }
    if (store.config == 'config_2') {
      return (
        <Config2Display
          key={store.account_loading.toString()}
          store={store.account?.defaultConfig}
          positionStore={store.positionStore}
        />
      );
    }
    if (store.config == 'config_3') {
      return (
        <Config1Display
          key={store.account_loading.toString()}
          override="config_3"
          store={store.account?.defaultConfig}
        />
      );
    }
    if (store.config == 'config_4') {
      return (
        <Config4Display key={store.account_loading.toString()} store={store} />
      );
    }
    return null;
  }
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  console.log('condition', store.account && store.config === 'config_4');
  return (
    <>
      <Flex direction="column">
        {store.positionStore.openTrade && (
          <Box mt={3}>
            {store.account && store.config === 'config_4' ? (
              <LossMinimizerAnalysis
                store={store.positionStore}
                botStore={store.account.defaultConfig}
              />
            ) : (
              <TakeProfitAnalysis store={store.positionStore} />
            )}
          </Box>
        )}
        <SimpleGrid mt={3} columns={2} spacing={5}>
          {store.account?.generalConfig.map((o) => {
            return <Displayer text={o.text} value={o.value} />;
          })}
        </SimpleGrid>
        <Sidebar
          placement="bottom"
          button={
            <Flex justifyContent="center">
              <Button
                onClick={configDisclosure.onOpen}
                mt={4}
                colorScheme="blue"
              >
                Update General Config
              </Button>
            </Flex>
          }
          title="Update General Config"
          onSave={() => {
            toastHelper('success', 'Updated General Config');
            configDisclosure.onClose();
            store.positionStore.updateTradeConfig();
          }}
          {...{
            isOpen: configDisclosure.isOpen,
            onClose: configDisclosure.onClose,
            btnRef,
          }}
        >
          <GeneralConfigDisplay store={store} />
        </Sidebar>
      </Flex>
      <SelectElement
        controlProps={{ display: 'flex', mt: 3 }}
        labelProps={{ flexGrow: 0.5, alignSelf: 'center' }}
        selectProps={{ flex: 1 }}
        label="Select Config"
        value={store.formatConfigValue}
        defaultText="No Config Selected"
        options={store.configFieldsOptions}
        onChange={(e: any) => store.onConfigSelect(e.target.value)}
      />
      {configDisplay()}
    </>
  );
});

const BottomButtons: FC<{
  store: IBotStore;
  mode?: boolean;
  onClick?: (params?: any) => any;
}> = observer(({ store, mode = false, onClick }) => {
  const toast = useToast();
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  return (
    <Flex mt={3} flexWrap="wrap" justifyContent="space-between">
      <Button
        mb={[4, 0, 0, 0]}
        isDisabled={store.account === null}
        onClick={() => {
          store
            .updateConfig()
            .then(() => {
              toastHelper('success', 'Config Updated');
            })
            .catch((error) => {
              toastHelper('error', 'Error updating config');
            });
        }}
        colorScheme="blue"
        size="lg"
      >
        Update Config
      </Button>
      {store.account && (
        <Button
          mb={[4, 0, 0, 0]}
          isDisabled={store.account === null}
          isLoading={store.loading}
          onClick={() => {
            store
              .updateBackgroundWatch()
              .then(() => {
                toastHelper('success', 'Background status updated');
              })
              .catch((error) => {
                toastHelper('error', 'Error updating background status');
              });
          }}
          colorScheme={store.account.defaultConfig.job_id ? 'red' : 'green'}
          size="lg"
        >
          {store.account.defaultConfig.job_id
            ? 'Disable Background Running'
            : 'Enable Background Running'}
        </Button>
      )}
      {mode ? (
        <>
          <Flex mb={[4, 0, 0, 0]}>
            <DropdownMenu
              label={`Create ${store.jobCreate.value} job`}
              options={store.jobCreate.configFieldsOptions}
              onClick={(val: string) => {
                store.jobCreate.setValue(val);
                onClick && onClick();
              }}
            />
          </Flex>
        </>
      ) : (
        <Button
          isDisabled={store.account === null}
          onClick={() => {
            store
              .updateOrders()
              .then(() => {
                toastHelper('success', 'Orders Updated');
              })
              .catch((error) => {
                toastHelper('error', 'Error updating orders');
              });
          }}
          colorScheme="red"
          size="lg"
        >
          Update Orders
        </Button>
      )}
    </Flex>
  );
});
