import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  GridItem,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  SimpleGrid,
  SimpleGridProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import type { FunctionComponent } from 'react';
import { FC, useState } from 'react';
import { IAlertStore } from '../../store/alertConfig';
import type { IBotConfig, IProfileConfig } from '../../store/botConfig';
import type { ICalConfig } from '../../store/calculatorConfig';
import { IFutureFormConfig, IStopEntryStore } from '../../store/futureConfig';
import type { ICreateJobConfig } from '../../store/jobConfig';
import {
  IFutureOrderConfig,
  IMainTradeConfig,
  IMarginOrderConfig,
} from '../../store/solvedConfig';
import { IMarketTypeInstance } from '../../store/solvedConfig/types';
import type { ISpotProfileConfig } from '../../store/spotConfig';
import {
  InputElement,
  InputElementEnhanced,
  RadioElement,
  SelectElement,
} from './FormElement';

export type ISharedConfig =
  | IMainTradeConfig
  | IMarginOrderConfig
  | IFutureOrderConfig
  | IMarketTypeInstance;

const Config1Display: FunctionComponent<{
  store?:
    | IBotConfig
    | ICreateJobConfig
    | ICalConfig
    | IProfileConfig
    | IFutureFormConfig
    | ISpotProfileConfig
    | IAlertStore
    | IStopEntryStore
    | ISharedConfig;

  override?: any;
  hideFields?: string[];
  labels?: any;
  props?: SimpleGridProps;
  Container?: any;
  onChange?: any;
  extraText?: any;
  onClickText?: () => Promise<void>;
}> = observer(
  ({
    store,
    override = 'config_1',
    hideFields = [],
    labels = {},
    props = { mt: 3, columns: { base: 2, lg: 3 }, spacing: 5 },
    Container = SimpleGrid,
    onChange,
    extraText,
    onClickText,
    ...rest
  }) => {
    const fields = store?.config_fields[override] || [];
    return (
      <Container {...props}>
        {fields.map((field: any) => {
          if (hideFields.includes(field)) {
            return null;
          }
          const fieldConfig = store?.getParamForField(field);
          const inLabel = Object.keys(labels).includes(field);
          if (fieldConfig?.kind == 'subscript') {
            return (
              <VStack gap={2} textAlign={'left'}>
                <InputElementEnhanced
                  label={inLabel ? labels[field] : fieldConfig.label}
                  type={fieldConfig.type == 'string' ? 'text' : 'number'}
                  onChange={(e: any) => {
                    if (fieldConfig.type == 'string') {
                      store?.updateFields({
                        [fieldConfig.name]: e.target.value,
                      });
                    } else {
                      store?.updateFields({
                        [fieldConfig.name]: parseFloat(e.target.value) || 0,
                      });
                    }
                  }}
                  {...(fieldConfig.props || {})}
                  defaultValue={fieldConfig.value}
                />
                {/* {extraText && extraText} */}
                <Text
                  as="sup"
                  // fontSize="xs"
                  fontWeight="semibold"
                  cursor="pointer"
                  onClick={onClickText}
                  pr={"35px"}
                >
                  {extraText}
                </Text>
              </VStack>
            );
          }
          if (fieldConfig?.kind == 'special') {
            return (
              <InputElementEnhanced
                label={inLabel ? labels[field] : fieldConfig.label}
                type={fieldConfig.type == 'string' ? 'text' : 'number'}
                onChange={(e: any) => {
                  if (fieldConfig.type == 'string') {
                    store?.updateFields({
                      [fieldConfig.name]: e.target.value,
                    });
                  } else {
                    store?.updateFields({
                      [fieldConfig.name]: parseFloat(e.target.value) || 0,
                    });
                  };
                }}
                {...(fieldConfig.props || {})}
                defaultValue={fieldConfig.value}
              />
            );
          }
          if (fieldConfig?.kind == 'list') {
            return (
              <InputElement
                label={inLabel ? labels[field] : fieldConfig.label}
                type={'string'}
                onChange={(e: any) => {
                  store?.updateFields({
                    [fieldConfig.name]: e.target.value.split(','),
                  });
                }}
                value={(fieldConfig.value || []).join(',')}
              />
            );
          }
          if (fieldConfig?.kind == 'input') {
            return (
              <InputElement
                label={inLabel ? labels[field] : fieldConfig.label}
                type={fieldConfig.type == 'string' ? 'text' : 'number'}
                onChange={(e: any) => {
                  if (fieldConfig.type == 'string') {
                    store?.updateFields({
                      [fieldConfig.name]: e.target.value,
                    });
                  } else {
                    store?.updateFields({
                      [fieldConfig.name]: parseFloat(e.target.value) || 0,
                    });
                  }
                }}
                value={fieldConfig.value}
                {...(fieldConfig.props || {})}
              />
            );
          }
          if (fieldConfig?.kind == 'select') {
            return (
              <SelectElement
                label={inLabel ? labels[field] : fieldConfig.label}
                defaultText={`Select ${fieldConfig.label}`}
                value={fieldConfig.value}
                options={fieldConfig.options}
                onChange={(e: any) => {
                  store?.updateFields({
                    [fieldConfig.name]: e.target.value,
                  });
                  if (onChange) {
                    onChange(fieldConfig.name, e.target.value);
                  }
                }}
                labelProps={fieldConfig.labelStyles || {}}
                controlProps={fieldConfig.styles || {}}
                valueLayout={fieldConfig.valueLayout}
                optionLayout={fieldConfig.optionLayout}
                selectProps={fieldConfig.selectProps || {}}
              />
            );
          }
          if (fieldConfig?.kind == 'checkbox') {
            if (Array.isArray(fieldConfig.options)) {
              return (
                <CheckboxGroup
                  colorScheme="green"
                  defaultValue={fieldConfig.value}
                  onChange={(e: any) => {
                    store?.updateFields({
                      [fieldConfig.name]: e,
                    });
                  }}
                >
                  <HStack>
                    {fieldConfig.options.map((o: any) => {
                      return (
                        <Checkbox key={o} value={o}>
                          {o}
                        </Checkbox>
                      );
                    })}
                  </HStack>
                </CheckboxGroup>
              );
            }
            return (
              <GridItem alignSelf="center" colSpan={1}>
                <Checkbox
                  onChange={(e) =>
                    store?.updateFields({
                      [fieldConfig.name]: e.target.checked,
                    })
                  }
                  isChecked={fieldConfig.value}
                >
                  {inLabel ? labels[field] : fieldConfig.label}
                </Checkbox>
              </GridItem>
            );
          }
          if (fieldConfig?.kind == 'radio') {
            return (
              <GridItem colSpan={1}>
                <RadioElement
                  label={inLabel ? labels[field] : fieldConfig.label}
                  value={fieldConfig.value}
                  onChange={(e: any) => {
                    store?.updateFields({
                      [fieldConfig.name]: e,
                    });
                    if (onChange) {
                      onChange(fieldConfig.name, e);
                    }
                  }}
                  options={fieldConfig.options.map((o: any) => ({
                    label: o,
                    value: o,
                  }))}
                  labelProps={fieldConfig.labelStyles || {}}
                  controlProps={fieldConfig.styles || {}}
                />
              </GridItem>
            );
          }
          return null;
        })}
      </Container>
    );
  },
);

export default Config1Display;

export const AddNewMarket: FC<{
  array: any[];
  onAdd: (params: any) => any;
  onSelect: (params: any) => any;
  loading?: boolean;
}> = observer(({ loading, array, onAdd, onSelect, children }) => {
  let [market, setMarket] = useState('');
  return (
    <>
      <List spacing={3}>
        {array.map((market) => {
          return (
            <Link
              onClick={() => {
                onSelect(market);
              }}
              _hover={{ textDecoration: 'none' }}
            >
              <ListItem pl={2} _hover={{ border: '1px solid teal' }} py={2}>
                {market}
              </ListItem>
              <Divider />
            </Link>
          );
        })}
      </List>
      <Box my={4} />
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          placeholder="Enter market"
          onChange={(e) => setMarket(e.target.value)}
          value={market}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="md"
            isLoading={loading}
            loadingText="Adding"
            onClick={(e) => {
              onAdd(market);
              setMarket('');
            }}
          >
            Add Market
          </Button>
        </InputRightElement>
      </InputGroup>
      {children}
    </>
  );
});
