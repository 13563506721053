import { Heading, useDisclosure, useToast, Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { TableWithSearch } from '../../newBot/app-components/SolvedTrader/TableWithSearch';
import { BaseModal } from '../../newBot/app-components/primitives/ModalComponent';
import { MarketSymbol } from '../stores';
import { ConfirmationDetails } from './confirmDetail';
import { Order } from '../stores/utils';

export type OpenOrdersListProps<T = number> = {
  onCancel?: (index: T[]) => Promise<void>;
  loading?: boolean;
  orders: T[];
  canCancel?: boolean;
};

const OpenOrderBadgeEnum: Record<string, string> = {
  long: 'green',
  short: 'red',
  limit: 'yellow',
  stop: 'yellow',
};

const OrdersListcolumns = [
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Qty',
    accessor: 'quantity',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Kind',
    accessor: 'kind',
  },
];
type OrderType = {
  id: number;
  price: string;
  quantity: string;
  type: string;
  kind: string;
};

const OrderList = <T = Order,>({
  canCancel,
  loading = false,
  onCancel,
  orders = [],
}: OpenOrdersListProps<T>) => {
  const mapper = <K = T,>(order: K) => {
    const _order = order as Order;
    const isStop = _order.stopPrice != '0';
    return {
      id: _order.orderId,
      price: isStop ? _order.stopPrice : _order.price,
      quantity: _order.origQty,
      type: isStop ? 'stop' : _order.type.toLowerCase(),
      kind: _order.positionSide.toLowerCase(),
    } as OrderType;
  };
  const data = orders.map(mapper);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState<T | undefined>();
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  const toast = useToast();

  console.log(selectedRows)

  let actions = [
    {
      label: 'Cancel',
      func: (row: OrderType, index: number) => {
        const found = orders.find((o) => o.orderId === row.id) as T;
        if (found) {
          setModalData(found);
          onOpen();
        }
      },
    },
  ];

  actions = canCancel ? actions : [];

  const handleCheckboxChange = (selectedValue: OrderType) => {
    let newRows: T[] = [];
    if (
      selectedRows
        .map((o: T) => (o as Order).orderId)
        .includes(selectedValue.id)
    ) {
      newRows = selectedRows.filter(
        (i) => (i as Order).orderId !== selectedValue.id,
      );
    } else {
      const found = orders.find((o) => o.orderId === selectedValue.id) as T;
      if (found) {
        newRows = [...selectedRows, found];
      }
    }
    setSelectedRows(newRows);
  };

  const selectAll = () => {
    let newRows: T[] = [];
    const allRowsSelected = selectedRows.length === data.length;
    if (allRowsSelected) {
      newRows = [];
    } else {
      newRows = (orders as T[]) ?? [];
    }
    setSelectedRows(newRows);
  };
  return (
    <>
      <Box mb={5}>
        <Heading size="sm" my={2} textAlign="center">
          Open Orders
        </Heading>

        <TableWithSearch<OrderType>
          title="Open Orders"
          columns={OrdersListcolumns}
          data={data}
          as_select={['stop']}
          form={null}
          actions={actions as any[]}
          badgeEnum={OpenOrderBadgeEnum}
          badgeKey={['kind', 'type']}
          hasCheckbox={canCancel}
          onRowChecked={handleCheckboxChange}
          selectedRows={selectedRows.map(mapper)}
          onCheckAll={selectAll}
          selectAllChecked={selectedRows.length === data.length}
          onCancelSelected={onOpen}
        ></TableWithSearch>
      </Box>

      <BaseModal
        removeCloseButton
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <ConfirmationDetails
          text={
            selectedRows.length > 1
              ? ` Are you sure you want to cancel these ${selectedRows.length} orders?`
              : 'Please confirm that you want to cancel this trade'
          }
          buttonLabel={
            selectedRows.length > 1 ? 'Cancel Orders' : 'Cancel Order'
          }
          onClick={async () => {
            if (onCancel) {
              if (selectedRows.length > 1) {
                await onCancel(selectedRows);
              } else {
                if (modalData !== undefined && modalData !== null) {
                  await onCancel([modalData]);
                }
              }
            }
            toast({
              title: ` Cancel ${
                selectedRows.length > 1 ? 'orders' : 'order'
              }  successful`,
              description: `${
                selectedRows.length > 1 ? 'orders' : 'order'
              } have been confirmed`,
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            onClose();
          }}
        />
      </BaseModal>
    </>
  );
};

export const OpenOrdersList = observer(OrderList);
