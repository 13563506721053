import { determine_close_price } from './hedge';
import { determine_pnl } from './trade_signal';
import {
  to_f,
  determine_position_size,
  determine_remaining_entry,
} from './utils';
export type FinalOrderType = {
  side: string;
  price: number;
  quantity: number;
  kind: 'long' | 'short';
  pnl?: number;
  stop: number;
};
export class Position {
  raw_position: {
    positionAmt: any;
    entryPrice: any;
  };
  kind: 'long' | 'short';
  constructor({
    raw_position,
    kind,
  }: {
    raw_position: any;
    kind: 'long' | 'short';
  }) {
    this.raw_position = raw_position;
    this.kind = kind;
  }
  get quantity() {
    const quantity = this.raw_position.positionAmt || 0;
    return Math.abs(parseFloat(quantity));
  }
  get entry_price() {
    const entry_price = this.raw_position.entryPrice || 0;
    return parseFloat(entry_price);
  }
  display() {
    return {
      quantity: this.quantity,
      entry_price: this.entry_price,
      kind: this.kind,
    };
  }
}
type RawOrderType = {
  origQty: any;
  price: any;
  positionSide: string;
  stopPrice: any;
  closePosition?: any;
  side: string;
  orderId: any;
  reduceOnly?: any;
};
type Formatted = {
  orderId: any;
  side: string;
  kind: string;
  price: number;
  quantity: number;
  stop?: number | boolean;
};
class Order {
  raw_order: RawOrderType;
  constructor(raw_order: RawOrderType) {
    this.raw_order = raw_order;
  }
  get price() {
    if (this.closePosition) {
      return this.stop_price;
    }
    return parseFloat(this.raw_order.price);
  }
  get side() {
    return this.raw_order.side.toLowerCase();
  }
  get quantity() {
    return parseFloat(this.raw_order.origQty);
  }
  get kind() {
    return this.raw_order.positionSide.toLowerCase();
  }
  get stop_price() {
    return parseFloat(this.raw_order.stopPrice);
  }
  get closePosition() {
    return this.raw_order.closePosition;
  }
  get formatted() {
    const value: Formatted = {
      orderId: this.raw_order.orderId,
      side: this.side,
      kind: this.kind,
      price: this.price,
      quantity: this.quantity,
      stop: this.stop_price,
    };
    if (value.stop && this.raw_order.reduceOnly) {
      value.price = value.stop as number;
      value.stop = true;
    }
    if (!value.stop) {
      delete value.stop;
    }
    return value;
  }
}

class BatchOrderProcessing {
  orders: Array<Order>;
  long_position: Position;
  short_position: Position;
  constructor({
    orders,
    positions,
  }: {
    orders: Array<any>;
    positions: {
      long: any;
      short: any;
    };
  }) {
    this.orders = orders.map((o) => new Order(o));
    this.long_position = new Position({
      raw_position: positions.long,
      kind: 'long',
    });
    this.short_position = new Position({
      raw_position: positions.short,
      kind: 'short',
    });
  }
  get long_orders() {
    return this.orders.filter((o) => o.kind === 'long');
  }
  get long_buy_orders() {
    return this.long_orders.filter((o) => o.side === 'buy');
  }
  get long_take_profit() {
    return this.long_orders.filter((o) => o.side === 'sell' && !o.stop_price);
  }
  get long_stop_loss() {
    const value = this.long_orders.filter(
      (o) => o.side === 'sell' && o.formatted.stop === true,
    );
    if (value.length > 0) {
      return value[0];
    }
    return null;
  }
  get short_orders() {
    return this.orders.filter((o) => o.kind === 'short');
  }
  get short_buy_orders() {
    return this.short_orders.filter((o) => o.side === 'sell');
  }
  get short_take_profit() {
    return this.short_orders.filter((o) => o.side === 'buy' && !o.stop_price);
  }
  get short_stop_loss() {
    const value = this.short_orders.filter(
      (o) => o.side === 'buy' && o.formatted.stop === true,
    );
    if (value.length > 0) {
      return value[0];
    }
    return null;
  }
  generated_orders(flat = false) {
    const long_buy_orders = this.long_buy_orders.map((o) => o.formatted);
    const long_sell_orders = this.long_take_profit.map((o) => o.formatted);
    let long_stop: Order | null | Formatted = this.long_stop_loss;
    if (long_stop) {
      long_stop = long_stop.formatted as Formatted;
      long_stop.quantity = this.long_position.quantity;
    }
    let short_stop: Order | null | Formatted = this.short_stop_loss;
    if (short_stop) {
      short_stop = short_stop.formatted as Formatted;
      short_stop.quantity = this.short_position.quantity;
    }
    const short_buy_orders = this.short_buy_orders.map((o) => o.formatted);
    const short_sell_orders = this.short_take_profit.map((o) => o.formatted);
    const result = {
      long: {
        buy: long_buy_orders,
        sell: long_sell_orders,
      },
      short: {
        buy: short_buy_orders,
        sell: short_sell_orders,
      },
      stop: {
        long: long_stop,
        short: short_stop,
      },
    };
    if (flat) {
      const stop = [];
      if (result.stop.long) {
        stop.push(result.stop.long);
      }
      if (result.stop.short) {
        stop.push(result.stop.short);
      }
      return [
        ...result.long.buy,
        ...result.long.sell,
        ...result.short.buy,
        ...result.short.sell,
        ...stop,
      ];
    }
    return result;
  }

  get avg_long() {
    return this.determine_average_entry('long');
  }

  get avg_short() {
    return this.determine_average_entry('short');
  }

  get last_buy() {
    const orders = this.generated_orders() as any;
    const long_buys = orders.long.buy;
    if (long_buys.length > 0) {
      return hasMin(long_buys, 'price');
    }
    return null;
  }

  get last_sell() {
    const orders = this.generated_orders() as any;
    const long_sells = orders.short.buy;
    if (long_sells.length > 0) {
      return hasMax(long_sells, 'price');
    }
    return null;
  }

  determine_average_entry(
    kind: string = 'long',
    additional_orders: any[] = [],
  ) {
    const orders = this.generated_orders() as any;
    const long_buys = orders['long']['buy'];
    const short_buys = orders['short']['buy'];
    const position = kind === 'long' ? this.long_position : this.short_position;
    const selected_orders = kind === 'long' ? long_buys : short_buys;
    let _position = { entry: 0, quantity: 0 };
    if (position && position.quantity) {
      _position = { entry: position.entry_price, quantity: position.quantity };
    }
    if (additional_orders) {
      selected_orders.push(...additional_orders);
    }
    const sum_values = selected_orders.reduce(
      (sum: any, order: any) => sum + order.price * order.quantity,
      _position.entry * _position.quantity,
    );
    const total_quantity = selected_orders.reduce(
      (sum: any, order: any) => sum + order.quantity,
      _position.quantity,
    );
    const avg_price = total_quantity
      ? to_f(sum_values / total_quantity, '%.1f')
      : 0;
    return {
      entry_price: avg_price,
      quantity: total_quantity,
    };
  }
}

function hasMin(myArray: any[], key: string) {
  return myArray.reduce((prev, curr) => (prev[key] < curr[key] ? prev : curr));
}

function hasMax(myArray: any[], key: string) {
  return myArray.reduce((prev, curr) => (prev[key] > curr[key] ? prev : curr));
}

type ConfigType = {
  support: number;
  resistance: number;
  budget: number;
  split: number;
  risk?: number;
  max_size?: number;
  risk_reward?: number;
  position?: {
    entry: number;
    quantity: number;
  };
};

type OrderType = {
  stop?: number;
  price: number;
  quantity: number;
};

class Config {
  support: number;
  resistance: number;
  budget: number;
  split: number;
  risk: number = 0;
  position?: ConfigType['position'];
  max_size: number;
  risk_reward: number;
  constructor(props: ConfigType) {
    this.support = props.support;
    this.resistance = props.resistance;
    this.budget = props.budget;
    this.split = props.split;
    this.risk = props.risk || 0;
    this.position = props.position;
    this.max_size = props.max_size || 0;
    this.risk_reward = props.risk_reward || 4;
  }
  get spread() {
    const result = Math.abs(this.resistance - this.support) / (this.split + 1);
    return to_f(result, '%.1f');
  }

  get zones() {
    return Array.from({ length: this.split }, (_, x) =>
      to_f(this.support + (x + 1) * this.spread, '%.1f'),
    );
  }
  get middle() {
    const index = parseFloat((this.split / 2).toFixed(0));
    return this.zones[index - 1];
  }

  determine_quantity({
    current_price,
    kind = 'long',
    divisor = 2,
  }: {
    current_price: number;
    kind: 'long' | 'short';
    divisor?: number;
  }) {
    if (this.support < current_price && current_price < this.resistance) {
      const support = kind === 'long' ? this.support : this.resistance;
      let quantity =
        determine_position_size({
          entry: this.middle,
          stop: support,
          budget: this.budget,
        }) || 0;
      quantity = to_f(quantity / divisor, '%.3f');
      return quantity;
    }
    return 0;
  }
  generate_orders({
    current_price,
    kind = 'long',
    stop,
  }: {
    current_price: number;
    kind: 'long' | 'short';
    stop?: number;
  }) {
    if (this.support < current_price && current_price < this.resistance) {
      const quantity = this.determine_quantity({ current_price, kind });
      const side = kind === 'long' ? 'buy' : 'sell';

      const condition = ({
        zone,
        p,
        inverse = false,
      }: {
        zone: number;
        p: number;
        inverse?: boolean;
      }) => {
        if (kind === 'long') {
          if (stop) {
            if (stop >= p) {
              return zone < p;
            }
            return stop < zone && zone < p;
          }
        }
        if (stop) {
          if (p === stop) {
            return p < zone;
          }
          if (p < stop) {
            return p < zone && zone < stop;
          }
          return stop < p && p < zone;
        }
        return p < zone;
      };
      const orders = this.zones
        .filter((o) => condition({ zone: o, p: current_price }))
        .map((zone) => {
          let minimum = 0;
          let result = {
            side,
            price: zone,
            quantity,
            kind,
          };
          return {
            ...result,
            avg: minimum,
          };
        });
      return orders;
    }
    return [];
  }
  long_orders({
    current_price,
    stop,
  }: {
    current_price: number;
    stop?: number;
  }) {
    if (current_price > this.support) {
      return this.generate_orders({ current_price, kind: 'long', stop });
    }
    return [];
  }
  re_entry(orders: Array<OrderType>, position: Position) {
    const prices = orders.map((o) => o.price);
    const support = position.kind === 'long' ? this.support : this.resistance;
    let quantity =
      determine_position_size({
        entry: this.middle,
        stop: support,
        budget: this.budget,
      }) || 0;
    quantity = to_f(quantity / 2, '%.3f');
    const condition = (zone: number) => {
      if (position.quantity === 0) {
        return false;
      }
      if (position.kind === 'long') {
        return zone > position.entry_price;
      }
      return position.entry_price < zone;
    };
    const found = this.zones.filter((o) => condition(o) && !prices.includes(o));
    if (found.length > 0 && quantity > 0) {
      let value = found[0];
      if (position.kind === 'short') {
        if (value > position.entry_price) {
          value = value - this.spread;
        }
      }
      return {
        side: position.kind === 'long' ? 'buy' : 'sell',
        kind: position.kind,
        price: to_f(value, '%.1f'),
        quantity,
        stop: to_f(value, '%.1f'),
        is_market: true,
      };
    }
  }
  short_orders({
    current_price,
    stop,
  }: {
    current_price: number;
    stop?: number;
  }) {
    if (current_price < this.resistance) {
      return this.generate_orders({ current_price, kind: 'short', stop });
    }
    return [];
  }
  generate_sell_orders(
    raw_orders: Array<OrderType>,
    position: Position,
    current_price: number,
  ) {
    let orders = raw_orders.filter((o) => !o.stop);
    const stop = this.re_entry(raw_orders, position);
    if (orders.length == 0 && position.quantity > 0) {
      const func =
        position.kind === 'long' ? this.long_orders : this.short_orders;
      orders = func.bind(this)({ current_price });
    }
    if (orders.length > 0) {
      const last = orders.at(-1) as OrderType;
      let quantity = last.quantity;
      let max_order = { ...last };
      if (position.kind === 'short') {
        const first = orders[0];
        max_order = { ...first };
        quantity = first.quantity;
      }
      if (position.kind === 'long' && max_order.price < current_price) {
        max_order.price += to_f(this.spread, '%.1f');
      }
      if (position.kind === 'short' && max_order.price > current_price) {
        max_order.price -= to_f(this.spread, '%.1f');
      }
      max_order.price = to_f(max_order.price, '%.1f');
      const condition = (zone: number, p: number) => {
        if (position.kind === 'long') {
          if (p === position.entry_price) {
            return p < zone;
          }
          return p < zone && zone < position.entry_price;
        }
        if (position.quantity === 0) {
          return false;
        }
        if (position.entry_price >= p) {
          return zone <= p;
        }
        return position.entry_price < zone && zone < p;
      };
      let sell_orders = this.zones
        .filter((o) => condition(o, max_order.price))
        .map((zone) => ({
          side: position.kind === 'long' ? 'sell' : 'buy',
          price: zone,
          quantity,
          kind: position.kind,
        }));
      let start = position.quantity;
      if (position.kind === 'short') {
        sell_orders = sell_orders
          .reverse()
          .filter((o) => o.price < current_price);
      }
      if (position.kind === 'short') {
        if (sell_orders.length > 0) {
          const first_sell = sell_orders[0];
          if (first_sell.price < position.entry_price) {
            first_sell.quantity = position.quantity;
            sell_orders = [first_sell];
          }
        }
      }
      sell_orders.forEach((o) => {
        let existing_quantity;
        if (position.kind === 'short') {
          existing_quantity =
            o.price >= position.entry_price ? o.quantity : position.quantity;
        } else if (position.kind === 'long') {
          existing_quantity =
            o.price <= position.entry_price * 1.0001
              ? o.quantity
              : position.quantity;
        } else {
          existing_quantity = o.quantity;
        }
        if (start >= existing_quantity) {
          start -= existing_quantity;
        } else {
          if (start > 0) {
            o.quantity = to_f(start, '%.3f');
            start = 0;
          } else {
            o.quantity = 0;
          }
        }
      });
      sell_orders = sell_orders.filter((o) => o.quantity > 0);
      const total_quantity = sell_orders.reduce(
        (acc, o) => acc + o.quantity,
        0,
      );
      if (Math.abs(total_quantity - position.quantity) >= 0.001) {
        const remaining_quantity = Math.abs(total_quantity - position.quantity);
        if (stop) {
          sell_orders.push({
            side: position.kind === 'long' ? 'sell' : 'buy',
            price: stop.price,
            quantity: to_f(remaining_quantity, '%.3f'),
            kind: position.kind,
          });
        }
      }
      return sell_orders;
    }
    return [];
  }
}
type DefaultGeneratedOrders = {
  long: {
    buy: FinalOrderType[];
    sell: FinalOrderType[];
  };
  short: {
    buy: FinalOrderType[];
    sell: FinalOrderType[];
  };
  stop: {
    long: FinalOrderType | null;
    short: FinalOrderType | null;
  };
};
export class HedgeBot {
  _config: ConfigType;
  config: Config;
  batch: BatchOrderProcessing;
  current_price: number;
  constructor({
    config,
    orders,
    positions,
    current_price,
  }: {
    config: ConfigType;
    orders: Array<any>;
    positions: {
      long: any;
      short: any;
    };
    current_price: number;
  }) {
    this._config = config;
    this.config = new Config(config);
    this.batch = new BatchOrderProcessing({ orders, positions });
    this.current_price = current_price;
  }
  get instance_without_orders() {
    return new HedgeBot({
      config: this._config,
      orders: [],
      positions: {
        long: {},
        short: {},
      },
      current_price: 0,
    });
  }
  get min_quantity() {
    return {
      long: this.config.determine_quantity({
        current_price: this.current_price,
        kind: 'long',
      }),
      short: this.config.determine_quantity({
        current_price: this.current_price,
        kind: 'short',
      }),
    };
  }
  get long_stop() {
    return this.current_price < this.config.middle
      ? this.config.support
      : this.config.middle;
  }
  get short_stop() {
    return this.current_price > this.config.middle
      ? this.config.resistance
      : this.config.middle;
  }
  generate_orders(last_price: number, flat = false) {
    let long_current_price = Math.min(last_price, this.current_price);
    const short_current_price = Math.max(last_price, this.current_price);
    if (!this.batch.long_position.quantity) {
      if (short_current_price > this.long_stop) {
        long_current_price = short_current_price;
      }
    }
    let long_buy_orders = this.config.long_orders({
      current_price: long_current_price,
      stop: this.long_stop,
    });
    if (this.batch.long_position.quantity) {
      long_buy_orders = long_buy_orders.filter(
        (o) =>
          o.price < this.batch.long_position.entry_price * Math.pow(1.0002, -1),
      );
    }
    const long_sell_orders = this.config.generate_sell_orders(
      long_buy_orders,
      this.batch.long_position,
      short_current_price,
    );
    let long_re_entry = this.config.re_entry(
      long_buy_orders,
      this.batch.long_position,
    );
    if (long_re_entry) {
      if (long_re_entry.price > short_current_price) {
        long_buy_orders.push(long_re_entry);
      }
    }
    let short_buy_orders = this.config.short_orders({
      current_price: short_current_price,
      stop: this.short_stop,
    });
    if (this.batch.short_position.quantity) {
      short_buy_orders = short_buy_orders.filter(
        (o) =>
          o.price > this.batch.short_position.entry_price * Math.pow(1.0002, 1),
      );
    }
    const short_sell_orders = this.config.generate_sell_orders(
      short_buy_orders,
      this.batch.short_position,
      long_current_price,
    );
    let short_re_entry = this.config.re_entry(
      short_buy_orders,
      this.batch.short_position,
    );
    if (short_re_entry) {
      if (short_re_entry.price < long_current_price) {
        short_buy_orders.push(short_re_entry);
      }
    }
    const result = {
      long: {
        buy: long_buy_orders,
        sell: long_sell_orders,
      },
      short: {
        buy: short_buy_orders,
        sell: short_sell_orders,
      },
      stop: {
        long: this.build_stop_order(this.batch.long_position),
        short: this.build_stop_order(this.batch.short_position),
      },
    };
    if (flat) {
      const stop = [];
      if (result.stop.long) {
        stop.push(result.stop.long);
      }
      if (result.stop.short) {
        stop.push(result.stop.short);
      }
      return [
        ...result.long.buy,
        ...result.long.sell,
        ...result.short.buy,
        ...result.short.sell,
        ...stop,
      ];
    }
    return result;
  }
  get_entries(kind: 'long' | 'short') {
    const orders = this.batch.generated_orders() as {
      long: {
        buy: Formatted[];
        sell: Formatted[];
      };
      short: {
        buy: Formatted[];
        sell: Formatted[];
      };
      stop: {
        long: Formatted | null;
        short: Formatted | null;
      };
    };
    let long_orders = orders.long.buy;
    if (kind === 'short') {
      long_orders = orders.long.buy;
    }
    const position =
      kind === 'long' ? this.batch.long_position : this.batch.short_position;
    const side = kind === 'long' ? 'buy' : 'sell';
    const condition = (x: Formatted) => {
      return kind === 'long'
        ? x.price < position.entry_price
        : x.price > position.entry_price;
    };
    if (position.quantity > 0) {
      return long_orders.filter(
        (x) => condition(x) && x.quantity > 0 && x.side === side && !x.stop,
      );
    }
    return [];
  }
  determine_average_price(kind: 'long' | 'short') {
    return this.batch.determine_average_entry(kind);
  }
  get avg_long_position() {
    return this.batch.avg_long;
  }
  get avg_short_position() {
    return this.batch.avg_short;
  }
  get long_entries() {
    return this.get_entries('long').sort((a, b) => a.price - b.price);
  }
  summary() {
    // console.log('short_avg', this.avg_short_position);
    const long_pnl = determine_pnl(
      this.avg_long_position.entry_price,
      this.long_stop,
      this.avg_long_position.quantity,
    );
    const short_pnl = determine_pnl(
      this.avg_short_position.entry_price,
      this.short_stop,
      this.avg_short_position.quantity,
    );
    return {
      long: {
        ...this.avg_long_position,
        stop: this.long_stop,
        pnl: long_pnl,
      },
      short: {
        ...this.avg_short_position,
        stop: this.short_stop,
        pnl: short_pnl,
      },
    };
  }
  get short_entries() {
    return this.get_entries('short').sort((a, b) => b.price - a.price);
  }
  cleaned_orders({
    last_price,
    as_list = false,
    no_stop = false,
    no_sell = false,
  }: {
    last_price: number;
    as_list?: boolean;
    no_stop?: boolean;
    no_sell?: boolean;
  }) {
    const orders = this.generate_orders(last_price) as DefaultGeneratedOrders;
    const previous_orders =
      this.batch.generated_orders() as DefaultGeneratedOrders;
    // console.log('long_avg', this.avg_long_position);
    // console.log('short_avg', this.avg_short_position);
    // console.log(
    //   'long_loss',
    //   determine_pnl(
    //     this.avg_long_position.entry_price,
    //     this.long_stop,
    //     this.avg_long_position.quantity,
    //   ),
    // );
    // console.log(
    //   'short_loss',
    //   determine_pnl(
    //     this.avg_short_position.entry_price,
    //     this.short_stop,
    //     this.avg_short_position.quantity,
    //   ),
    // );
    const long_orders = orders.long;
    const short_orders = orders.short;
    const stop_orders = orders.stop;
    const previous_long = previous_orders.long;
    const previous_short = previous_orders.short;
    const previous_stop = previous_orders.stop;
    let long_stop = stop_orders.long;
    let short_stop = stop_orders.short;
    if (stop_orders.long && previous_stop.long) {
      if (stop_orders.long.price == previous_stop.long.price) {
        long_stop = null;
      }
    }
    if (stop_orders.short && previous_stop.short) {
      if (stop_orders.short.price == previous_stop.short.price) {
        short_stop = null;
      }
    }
    const long_buys = return_missing(long_orders.buy, previous_long.buy);
    let long_sells = return_missing(long_orders.sell, previous_long.sell);
    const short_buys = return_missing(short_orders.buy, previous_short.buy);
    let short_sells = return_missing(short_orders.sell, previous_short.sell);
    if (no_sell) {
      long_sells = [];
      short_sells = [];
    }
    const result = {
      long: {
        buy: long_buys,
        sell: long_sells,
      },
      short: {
        buy: short_buys,
        sell: short_sells,
      },
      stop: {
        long: long_stop,
        short: short_stop,
      },
    };
    if (as_list) {
      const stop = [];
      if (!no_stop) {
        if (result.stop.long) {
          stop.push(result.stop.long);
        }
        if (result.stop.short) {
          stop.push(result.stop.short);
        }
      }
      return [
        ...result.long.buy,
        ...result.long.sell,
        ...result.short.buy,
        ...result.short.sell,
        ...stop,
      ];
    }
    return result;
  }
  build_stop_order(position: Position) {
    if (position.quantity > 0) {
      if (position.kind === 'long') {
        if (position.entry_price === this.long_stop) {
          return null;
        }
        if (this.long_stop > this.current_price) {
          return null;
        }
      }
      if (position.kind === 'short') {
        if (position.entry_price === this.short_stop) {
          return null;
        }
        if (this.short_stop < this.current_price) {
          return null;
        }
      }
      const price =
        position.kind === 'long'
          ? to_f(this.long_stop, '%.1f')
          : to_f(this.short_stop, '%.1f');
      return {
        side: position.kind === 'long' ? 'sell' : 'buy',
        kind: position.kind,
        price,
        quantity: to_f(position.quantity, '%.3f'),
        stop: price,
        is_market: true,
      };
    }
  }
  build_entry(last_price: number) {
    const orders = this.instance_without_orders.cleaned_orders({
      last_price,
    }) as DefaultGeneratedOrders;
    const short_length = orders.short.buy.length;
    const long_length = orders.long.buy.length;
    let long_entry, short_entry;
    const middle = this.instance_without_orders.config.middle;
    if (short_length > 0) {
      let short_buy_orders;
      if (last_price > middle) {
        short_buy_orders = orders.short.buy.filter(
          (x) => x.price < this.instance_without_orders.config.resistance,
        );
      } else {
        short_buy_orders = orders.short.buy.filter((x) => x.price <= middle);
      }
      if (short_buy_orders.length > 0) {
        const ref = short_buy_orders[0];
        long_entry = {
          ...ref,
          quantity: to_f(ref.quantity * short_buy_orders.length, '%.3f'),
          stop: ref.price,
          price: to_f(ref.price * 1.0000125, '%.1f'), // so that i don't have to do this on the server
          side: 'buy',
          kind: 'long',
        };
      }
    }
    if (long_length > 0) {
      let long_buy_orders;
      if (last_price >= middle) {
        long_buy_orders = orders.long.buy.filter((x) => x.price > middle);
      } else {
        long_buy_orders = orders.long.buy.filter(
          (x) =>
            middle >= x.price &&
            x.price > this.instance_without_orders.config.support,
        );
      }
      if (long_buy_orders.length > 0) {
        const ref = long_buy_orders.at(-1) as FinalOrderType;
        short_entry = {
          ...ref,
          quantity: to_f(ref.quantity * long_buy_orders.length, '%.3f'),
          stop: ref.price,
          price: to_f(ref.price * 0.9999875, '%.1f'), // so that i don't have to do this on the server
          side: 'sell',
          kind: 'short',
        };
      }
    }
    return {
      long: long_entry,
      short: short_entry,
    };
  }
  build_entry_for_all_zones() {
    const zones: any = {};
    const working_zones = this.config.zones;
    working_zones.forEach((j, i) => {
      zones[j] = this.build_entry(j);
      if (i < working_zones.length - 2) {
        const next_value = working_zones[i + 2];
        const long_value = zones[j].long;
        if (long_value) {
          const _pnl = to_f(
            determine_pnl(long_value.price, next_value, long_value.quantity),
            '%.2f',
          );
          zones[j].long.pnl = _pnl;
          zones[j].long.take_profit = next_value;
          if (_pnl <= 0) {
            zones[j].long = null;
          }
        }
      }
      if (i > 2) {
        const next_value = working_zones[i - 2];
        const short_value = zones[j].short;
        if (short_value) {
          const _pnl = to_f(
            determine_pnl(
              short_value.price,
              next_value,
              short_value.quantity,
              'short',
            ),
            '%.2f',
          );
          zones[j].short.pnl = _pnl;
          zones[j].short.take_profit = next_value;
          if (_pnl <= 0) {
            zones[j].short = null;
          }
        }
      }
    });
    const _long = Object.values(zones)
      .map((r: any) => r.long as FinalOrderType)
      .filter(Boolean)
      .sort((a, b) => (a as FinalOrderType).price - (b as FinalOrderType).price)
      .filter((r) => (r as FinalOrderType).pnl);
    const _short = Object.values(zones)
      .map((r: any) => r.short as FinalOrderType)
      .filter(Boolean)
      .sort((a, b) => (a as FinalOrderType).price - (b as FinalOrderType).price)
      .filter((r) => (r as FinalOrderType).pnl);
    const profit = {
      'pre-middle': {
        long: _long
          .filter((r) => (r as FinalOrderType).price < this.config.middle)
          .map((r) => (r as FinalOrderType)?.pnl as number)
          .reduce((a, b) => a + b, 0),
        short: _short
          .filter((r) => (r as FinalOrderType).price < this.config.middle)
          .map((r) => (r as FinalOrderType)?.pnl as number)
          .reduce((a, b) => a + b, 0),
      },
      'post-middle': {
        long: _long
          .filter((r) => (r as FinalOrderType).price >= this.config.middle)
          .map((r) => (r as FinalOrderType)?.pnl as number)
          .reduce((a, b) => a + b, 0),
        short: _short
          .filter((r) => (r as FinalOrderType).price >= this.config.middle)
          .map((r) => (r as FinalOrderType)?.pnl as number)
          .reduce((a, b) => a + b, 0),
      },
    };
    return {
      long: _long,
      short: _short,
      profit,
    };
  }
  determine_liquidation_price(position: Position, allowed_loss: number) {
    const result = determine_close_price(
      position.entry_price,
      -allowed_loss,
      position.quantity,
      position.kind,
    );
    return to_f(result, '%.2f');
  }
}

function return_missing(
  list_1: Array<FinalOrderType>,
  list_2: Array<FinalOrderType>,
) {
  const valid: Array<FinalOrderType> = [];
  const condition = (o: any, i: any) => {
    let vv = o.price || o.stop;
    return vv === i.price;
  };
  list_1.forEach((i) => {
    const found_list_2 = list_2.filter((o) => condition(o, i));
    if (found_list_2.length === 0) {
      valid.push(i);
    }
  });
  return valid;
}
