import { Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  ButtonCollection,
  ExchangeSummaryView,
  OpenOrdersList,
  SectionSummary,
  SwiperComponent,
  TradingSectionPicker,
} from '../components';
import { Order, Root } from '../stores';

type Paths =
  | '/tp-sl/tp'
  | '/tp-sl/sl'
  | '/increase-position'
  | '/trade-section'
  | '/transfer-funds';
export type DashboardPageProps = {
  store: Root;
  navigate: (path: Paths) => void;
};

export const DashboardPage = observer(
  ({ store, navigate }: DashboardPageProps) => {
    const [loading, setLoading] = React.useState(false);
    const currentExchange = store.activeAccount;
    const actions = [
      {
        label: 'Update TP/SL',
        action: () => {
          navigate('/tp-sl/tp');
        },
        isDisabled: currentExchange?.activeTradeSection === undefined,
      },
      {
        label: 'Increase ',
        action: () => {
          navigate('/increase-position');
        },
        isDisabled: currentExchange?.activeTradeSection === undefined,
      },
      {
        label: 'Refresh',
        action: async () => {
          try {
            setLoading(true);
            await store.initializeExchange();
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.log('error', error);
          }
        },
        loading,
        buttonProps: {
          colorScheme: 'blue',
        },
        // isDisabled: currentExchange?.activeTradeSection === undefined,
      },
    ];
    // const currentExchangeTradingSection = currentExchange.tradingSection;
    React.useEffect(() => {
      store.accounts.forEach((account) => {
        account.setProp('activeMarket', `${store.symbol}:${account.owner}`);
      });
    }, []);
    React.useEffect(() => {
      if (currentExchange) {
        currentExchange.initializeSockets();
        return () => {
          currentExchange.destroySockets();
        };
      }
    }, [currentExchange?.activeMarket]);
    const defaultIndex = store.accounts.findIndex(
      (x) => x.owner === store.activeAccount?.owner,
    );
    return (
      <>
        <SwiperComponent
          // key={defaultIndex}
          symbol={store.symbol}
          items={store.items}
          defaultIndex={defaultIndex}
          onItemChange={(index) => {
            console.log('index', index);
            if (!isNaN(index) && index < store.accounts.length) {
              store.setProp('activeAccount', store.accounts[index].owner);
              // set the active market to the BTC market for the currently selected account exchange
              currentExchange?.setProp(
                'activeMarket',
                `${store.symbol}:${currentExchange.owner}`, // TODO: this is hardcoded for now
              );
            }
          }}
          renderItem={(exchange, index) => (
            <ExchangeSummaryView
              key={index}
              {...exchange}
              symbol={store.symbol}
              zones={currentExchange?.zones}
              activeZone={currentExchange?.activeZone}
              onZoneChange={(z) => {
                currentExchange?.setProp('activeTradeSection', z.id);
              }}
            />
          )}
        />
        <ButtonCollection actions={actions} />
        <ButtonCollection
          direction="row"
          actions={[
            {
              render: () => (
                <TradingSectionPicker
                  options={[
                    'BTCUSDT',
                    'BTCUSDC',
                    'BNBUSDT',
                    'BNBUSDC',
                    'ETHUSDT',
                    'ETHUSDC',
                  ].map((o) => ({ id: o }))}
                  defaultValue={store.symbol}
                  onChange={(s) => {
                    if (s?.id) {
                      store.setProp('symbol', s.id);
                    }
                    store.setProp('activeAccount', undefined);
                  }}
                  valueLayout={(u) => u.id}
                  as="select"
                />
              ),
            },
          ]}
        />
        {currentExchange?.activeMarket && (
          <>
            <SectionSummary
              labels={currentExchange.activeMarketSummary()}
              stopLossChecked={currentExchange.useStopLoss}
              onCheckChange={v=>{
                currentExchange.setProp('useStopLoss', v);
              }}
            />
            <OpenOrdersList<Order>
              orders={currentExchange.activeMarket.open_orders}
              onCancel={currentExchange.cancelOrders}
              canCancel
            />
            <ButtonCollection
              direction="row"
              actions={[
                {
                  render: () => (
                    <TradingSectionPicker
                      options={currentExchange.zones}
                      defaultValue={currentExchange.activeZone}
                      onChange={(s) => {
                        currentExchange.setProp('activeTradeSection', s.id);
                        currentExchange.createTradeConfigForm();
                        navigate('/trade-section');
                      }}
                      onCreate={() => {
                        currentExchange.setProp(
                          'activeTradeSection',
                          undefined,
                        );
                        currentExchange.createTradeConfigForm();
                        navigate('/trade-section');
                      }}
                    />
                  ),
                },
                {
                  label: 'Transfer Fund',
                  action: () => {
                    navigate('/transfer-funds');
                  },
                },
              ]}
              hasBorder
            />
          </>
        )}
      </>
    );
  },
);

export default DashboardPage;
