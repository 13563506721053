import {
  Instance,
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import { determine_pnl } from '../trade_signal';
import { determine_average_entry_and_size, to_f } from '../utils';
import { BotAdapterType } from '../../adapter';
import { calculate_liquidation } from '../hedge';

const OrderPlace = types.model('OrderPlace', {
  price: types.number,
  quantity: types.number,
  kind: types.optional(types.enumeration('Kind', ['long', 'short']), 'long'),
  type: types.enumeration('Type', [
    'take_profit',
    'stop_loss',
    'increase',
    'entry',
  ]),
});
const ExchangePosition = types.model('ExchangePosition', {
  entry: types.optional(types.number, 0),
  size: types.optional(types.number, 0),
  kind: types.optional(types.enumeration('kind', ['long', 'short']), 'long'),
});

const WalletBalance = types
  .model('WalletBalance', {
    currency: types.string,
    amount: types.optional(types.number, 0),
  })
  .views((self) => {
    return {
      get display() {
        return `${self.currency}: ${self.amount}`;
      },
    };
  });

export const ExchangeStore = types
  .model('ExchangeStore', {
    owner: types.string,
    loading: types.optional(types.boolean, false),
    long_position: types.optional(ExchangePosition, {}),
    short_position: types.optional(ExchangePosition, { kind: 'short' }),
    orders: types.optional(types.array(OrderPlace), []),
    balances: types.optional(types.array(WalletBalance), []),
    is_default: types.optional(types.boolean, false),
  })
  .views((self) => {
    return {
      get usdt_balance() {
        return self.balances.find((b) => b.currency.toLowerCase() === 'usdt');
      },
      get bnb_balance() {
        return self.balances.find((b) => b.currency.toLowerCase() === 'bnb');
      },
      get liquidation() {
        let long_position = this.entry('long');
        let short_position = this.entry('short');
        let long_increase = this.orderByType('long', 'increase');
        let short_increase = this.orderByType('short', 'increase');
        let result = calculate_liquidation(
          this.usdt_balance?.amount || 0,
          {
            entry: long_position?.price || 0,
            size: long_position?.quantity || 0,
          },
          {
            entry: short_position?.price || 0,
            size: short_position?.quantity || 0,
          },
          {
            long: {
              entry: long_increase?.price || 0,
              size: long_increase?.quantity || 0,
            },
            short: {
              entry: short_increase?.price || 0,
              size: short_increase?.quantity || 0,
            },
          },
        );
        if (result.liquidation) {
          return to_f(result.liquidation, '%.2f');
        }
        return undefined;
      },
      currency_display(currency: 'bnb' | 'usdt') {
        let value = currency === 'bnb' ? this.bnb_balance : this.usdt_balance;
        return {
          price: value?.amount || 0,
        };
      },
      entry(kind: 'long' | 'short') {
        let position =
          kind === 'long' ? self.long_position : self.short_position;
        if (position.entry && position.size) {
          return {
            price: position.entry,
            quantity: position.size,
          };
        }
        let entry_order = self.orders.find(
          (o) => o.kind === kind && o.type === 'entry',
        );
        if (entry_order) {
          return {
            entry: entry_order.price,
            size: entry_order.quantity,
            addition: '(P)',
          };
        }
        return null;
      },
      take_profit(kind: 'long' | 'short') {
        let position =
          kind === 'long' ? self.long_position : self.short_position;
        let order = self.orders.find(
          (o) => o.kind === kind && o.type === 'take_profit',
        );
        if (order) {
          let pnl = 0;
          if (position.entry && position.size) {
            pnl = determine_pnl(
              position.entry,
              order.price,
              position.size,
              kind,
            );
            return {
              price: order.price,
              quantity: order.quantity,
              addition: ` (+${to_f(pnl, '%.1f')})`,
            };
          }
        }
        return null;
      },
      stop_loss(kind: 'long' | 'short') {
        let position =
          kind === 'long' ? self.long_position : self.short_position;
        let order = self.orders.find(
          (o) => o.kind === kind && o.type === 'stop_loss',
        );
        if (order) {
          let pnl = 0;
          if (position.entry && position.size) {
            pnl = determine_pnl(
              position.entry,
              order.price,
              position.size,
              kind,
            );
            return {
              price: order.price,
              quantity: order.quantity,
              pnl: ` (${to_f(pnl, '%.1f')})`,
            };
          }
        }
      },
      orderByType(
        kind: 'long' | 'short',
        type: 'take_profit' | 'stop_loss' | 'increase' | 'entry',
      ) {
        return self.orders.find((o) => o.type === type && o.kind === kind);
      },
      revised_entry(kind: 'long' | 'short') {
        let position =
          kind === 'long' ? self.long_position : self.short_position;
        let order = this.orderByType(kind, 'increase');
        if (order) {
          if (position.entry && position.size) {
            let avg_entry = determine_average_entry_and_size([
              {
                price: position.entry,
                quantity: position.size,
              },
              {
                price: order.price,
                quantity: order.quantity,
              },
            ]);
            return `Rev: ${avg_entry.price}/${avg_entry.quantity}`;
          }
        }
      },
      get actions() {
        let allActions = [
          {
            value: 'increase_long',
            label: 'Increase Long Position',
          },
          {
            value: 'increase_short',
            label: 'Increase Short Position',
          },
          {
            value: 'long_entry',
            label: 'Enter Long Position',
          },
          {
            value: 'short_entry',
            label: 'Enter Short Position',
          },
          {
            value: 'reduce_long',
            label: 'Reduce Long Position',
          },
          {
            value: 'reduce_short',
            label: 'Reduce Short Position',
          },
          {
            value: 'increase_bnb',
            label: 'Increase BNB',
          },
          {
            value: 'transfer_usdt',
            label: 'Transfer USDT',
          },
          {
            value: 'set_long_tp',
            label: 'Set Long TP',
          },
          {
            value: 'set_short_tp',
            label: 'Set Short TP',
          },
          {
            value: 'set_default',
            label: 'Set Default',
          },
        ];
        if (self.long_position.entry && self.long_position.size) {
          allActions = allActions.filter((a) => a.value !== 'long_entry');
        } else {
          allActions = allActions.filter(
            (a) =>
              !['reduce_long', 'increase_long', 'set_long_tp'].includes(
                a.value,
              ),
          );
        }
        if (
          this.orderByType('long', 'increase') &&
          self.long_position.entry &&
          self.long_position.size
        ) {
          allActions = allActions.filter((a) => a.value !== 'increase_long');
        }
        if (self.short_position.entry && self.short_position.size) {
          allActions = allActions.filter((a) => a.value !== 'short_entry');
        } else {
          allActions = allActions.filter(
            (a) =>
              !['reduce_short', 'increase_short', 'set_short_tp'].includes(
                a.value,
              ),
          );
        }
        if (
          this.orderByType('short', 'increase') &&
          self.short_position.entry &&
          self.short_position.size
        ) {
          allActions = allActions.filter((a) => a.value !== 'increase_short');
        }
        if (self.is_default) {
          allActions = allActions.filter((a) => a.value !== 'set_default');
        }
        return allActions;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const refresh = flow(function* (symbol: string) {
      try {
        self.loading = true;
        const { long_position, short_position, orders, balances } =
          yield adapter.getExchangePosition({
            owner: self.owner,
            symbol,
          });
        applySnapshot(self, {
          ...getSnapshot(self),
          ...{
            long_position,
            short_position,
            orders,
            balances,
          },
        });
        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });
    const placeSingleOrder = flow(function* ({
      order,
      symbol,
      cancel = false,
    }: {
      order: {
        price: number;
        quantity: number;
        kind: 'long' | 'short';
        stop?: number;
        side: 'buy' | 'sell';
      };
      cancel?: boolean;
      symbol: string;
    }) {
      try {
        self.loading = true;
        yield adapter.placeSignalTrade({
          orders: [order],
          kind: order.kind,
          symbol,
          cancel,
          sub_accounts: [self.owner],
        });
        self.loading = false;
      } catch (e) {
        self.loading = false;
        throw e;
      }
    });
    const triggerAction = flow(function* (action, params) {
      if (
        [
          'increase_long',
          'increase_short',
          'long_entry',
          'short_entry',
          'reduce_long',
          'reduce_short',
          'set_long_tp',
          'set_short_tp',
        ].includes(action)
      ) {
        yield placeSingleOrder(params);
        return;
      }
      if (action === 'set_default') {
        self.is_default = true;
        return;
      }
      if (action === 'increase_bnb') {
        try {
          self.loading = true;
          yield adapter.purchase_bnb({
            amount: params.amount,
            owner: self.owner,
            source: params.source, // 'spot' | 'future'
          });
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
      if (action === 'transfer_usdt') {
        try {
          self.loading = true;
          yield adapter.transfer_usdt({
            amount: params.amount,
            owner: self.owner,
            from: params.from,
            to: params.to,
            source: params.source, // 'spot' | 'future | 'isolated_margin' | 'cross_margin
          });
          self.loading = false;
        } catch (error) {
          self.loading = false;
          throw error;
        }
      }
    });
    return {
      refresh,
      triggerAction,
    };
  });

export interface IExchangeStore extends Instance<typeof ExchangeStore> {}
