import {
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export const TableDisplay = ({ kind, data }) => {
  const columnHeader = ['Entry', 'Risk', 'Stop'];
  const style = useMultiStyleConfig('DisplayTable', {
    kind,
  });

  return (
    <>
      <TableContainer minWidth={['380px', '480px', '480px']}>
        <Table sx={style} variant="simple">
          <TableCaption>
            {kind === 'long' ? 'Long Trade' : 'Short Trade'}
          </TableCaption>
          <Thead>
            <Tr>
              {columnHeader.map((x, i) => (
                <Th>{x}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item, index) => (
              <Tr key={index}>
                <Td>{item.entry}</Td>
                <Td>{item.quantity}</Td>
                <Td>{item.stop}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
