export const getOptimumReward = (payload: {
  strategy?: 'quantity' | 'entry';
  [key: string]: any;
}) => {
  const worker = new Worker(new URL('./futureWorker.ts', import.meta.url), {
    type: 'module',
  });
  const promise = new Promise((resolve, reject) => {
    worker.postMessage({
      action: 'determine-reward',
      payload,
    });
    worker.onmessage = (e) => {
      if (e.data.action === 'determine-reward') {
        resolve(e.data);
      } else {
        reject(e.data);
      }
    };
    worker.onerror = (e) => {
      reject(e);
    };
  });
  return promise;
};

export const getOptimumRisk = (
  payload: {
    strategy?: 'quantity' | 'entry';

    [key: string]: any;
  },
  max_size: number,
  multiplier = 4,
  gap = 1,
) => {
  if (max_size) {
    const worker = new Worker(new URL('./optimumRisk.ts', import.meta.url), {
      type: 'module',
    });
    const promise = new Promise((resolve, reject) => {
      worker.postMessage({
        payload,
        max_size,
        multiplier,
        gap,
      });
      worker.onmessage = (e) => {
        console.log('optimum risk', e.data);
        resolve(e.data);
      };
      worker.onerror = (e) => {
        reject(e);
      };
    });
    return promise;
  }
  return Promise.reject('max_size is required');
};

export const getOptimumStop = (
  payload: {
    strategy?: 'quantity' | 'entry';
    [key: string]: any;
  },
  stop_target: number,
  gap: number,
) => {
  if (stop_target) {
    const worker = new Worker(new URL('./optimumStop.ts', import.meta.url), {
      type: 'module',
    });
    const promise = new Promise((resolve, reject) => {
      worker.postMessage({
        payload,
        stop_target,
        gap,
      });
      worker.onmessage = (e) => {
        resolve(e.data);
      };
      worker.onerror = (e) => {
        reject(e);
      };
    });
    return promise;
  }
  return Promise.reject('stop_target is required');
};
