import React from 'react';
import { Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import type { FunctionComponent } from 'react';

const StatsDisplay: FunctionComponent<{
  // stats: Array<{ heading: string; body: string; footer: any }>;
  stats: any[];
}> = observer(({ stats }) => {
  return (
    <StatGroup justifyContent="space-between">
      {stats.map((stat) => {
        return (
          <Stat>
            <StatLabel>{stat.heading}</StatLabel>
            <StatNumber>{stat.body}</StatNumber>
            {stat.footer}
          </Stat>
        );
      })}
    </StatGroup>
  );
});

export default StatsDisplay;
