import { Instance, cast, getEnv, types } from 'mobx-state-tree';
import { BotAdapterType } from '../adapter';
import { Order } from './oldConfig';

const OrderPlace = types.model('OrderPlace', {
  price: types.number,
  quantity: types.number,
  side: types.optional(types.enumeration('Trade', ['buy', 'sell']), 'buy'),
  kind: types.optional(types.enumeration('Kind', ['long', 'short']), 'long'),
  force_market: types.optional(types.boolean, false),
});
export const BarePositionStore = types
  .model('BarePositionStore', {
    // id: types.identifier,
    currentPrice: types.optional(types.number, 0),
    orders: types.optional(types.array(Order), []),
    openTrade: types.maybeNull(Order),
    balance: types.optional(types.number, 0),
    loading: types.optional(types.boolean, false),
    manualAction: types.optional(types.string, 'sell'),
    orderType: types.optional(types.string, 'limit'),
    serverOrders: types.optional(types.array(OrderPlace), []),
    longStop: types.maybeNull(OrderPlace),
    shortStop: types.maybeNull(OrderPlace),
  })
  .views((self) => {
    return {
      get longPosition() {
        return self.orders.find((o) => o.kind == 'long');
      },
      get shortPosition() {
        return self.orders.find((o) => o.kind == 'short');
      },
      get leverage() {
        if (self.orders.length > 0) {
          return self.orders[0].maximumLeverage;
        }
        return 125;
      },
      get stopDisplay() {
        if (self.longStop || self.shortStop) {
          let result = {
            longEntry: self.longStop?.price || 0,
            longSize: self.longStop?.quantity || 0,
            shortEntry: self.shortStop?.price || 0,
            shortSize: self.shortStop?.quantity || 0,
          };
          return result;
        }
        return null;
      },
    };
  })
  .actions((self) => {
    let socket: WebSocket;
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    function destroySockets() {
      if (socket) {
        adapter.disconnectAllStreams();
      }
    }
    return {
      updateServerOrders(values: any[]) {
        self.serverOrders = cast(values);
      },
      initializeSockets(callback?: any) {
        let v = self.longPosition || self.shortPosition;

        if (v) {
          socket = adapter.initializeSockets(
            socket,
            [v.symbol],
            this.updatePrice,
          );
          if (callback) {
            callback();
          }
        }
      },
      updatePrice(price: number) {
        self.currentPrice = price;
      },
      destroySockets,
    };
  });

export interface IBarePositionStore
  extends Instance<typeof BarePositionStore> {}
