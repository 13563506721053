import {
  allAccounts,
  allMarkets,
  SPOT_SYMBOLS,
  sampleProfileOrders,
} from './testData';
import { type TradeType, type StopLossType } from './types';
import { AccountParser } from './accountParser';
const BASE_URL = 'https://bot-sheet.now.sh';
async function fetcher(body: any) {
  try {
    let response = await fetch(`${BASE_URL}/trade-analytics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status < 400) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
  return;
}

interface Window {
  HOST_URL: any;
}
declare let window: Window;
const ROOT = typeof window !== 'undefined' ? window?.HOST_URL || '' : '';
const BASE_URL2 = ROOT + '/ft/main_account';
async function bulkTrades(symbol: any, trades: any[]) {}
async function fetcher2(path: string, body: any) {
  try {
    let response = await fetch(`${BASE_URL2}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status < 400) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
  return;
}
async function increasePosition({ entry, kind, quantity, symbol }: any) {
  return {
    entry,
    kind,
    quantity,
    symbol,
  };
}
async function getSwingInfo({
  timeFrame,
  symbol,
  count = 5000,
  kind = 'future',
}: any) {
  try {
    let result = await fetcher2(`swing-high-low`, {
      symbol,
      chart_type: timeFrame,
      kind,
      count,
    });
    return result;
  } catch (error) {
    throw error;
  }
}
async function fetchAllMarkets(owner: string) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        allMarkets: [...allMarkets.usdt, ...allMarkets.coin],
      });
    }, 1000);
  });
}
async function loadInitialData(symbol: string, kind?: 'long' | 'short') {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        pip: 13.329,
        balance: 30.07,
        stop_orders: [],
        allMarkets: [...allMarkets.usdt, ...allMarkets.coin],
        positions: [
          {
            entryPrice: 15100,
            kind: 'long',
            quantity: 0.046,
            symbol,
          },
          // {
          //     entryPrice: 15900,
          //     kind: "short",
          //     quantity: 0.038,
          //     symbol,
          // },
        ],
      });
    }, 1000);
  });
}

async function createNewTrade({
  symbol,
  entry,
  quantity,
  stop,
  kind,
  isMarketTrade,
}: TradeType) {
  try {
    let result = await fetcher({ symbol, entry, quantity, stop, kind });
    let { data } = result;
    return {
      entryPrice: data.entry,
      id: data.id,
      symbol: data.symbol,
      quantity: data.quantity,
      stop: parseFloat(data.stop) || null,
      takeProfit: parseFloat(data.take_profit) || null,
      kind,
    };
  } catch (error) {
    throw error;
  }
}
async function updateStopLoss(params: StopLossType): Promise<number> {
  try {
    let result = await fetcher({
      ...params,
      entry: params.entryPrice,
      quantity: params.quantity,
      take_profit: params.takeProfitPrice,
      stop: params.stopPrice,
      kind: params.kind,
    });
    let { data } = result;
    return data.stop;
  } catch (error) {
    throw error;
  }
}
async function updateTakeProfit(params: StopLossType) {
  try {
    let result = await fetcher({
      ...params,
      entry: params.entryPrice,
      quantity: params.quantity,
      take_profit: params.takeProfitPrice,
      stop: params.stopPrice,
      kind: params.kind,
    });
    let { data } = result;
    return {
      entryPrice: data.entry,
      id: data.id,
      symbol: data.symbol,
      quantity: data.quantity,
      stop: parseFloat(data.stop) || params.stopPrice,
      takeProfit: parseFloat(data.take_profit) || params.takeProfitPrice,
      kind: data.kind,
      pnl: parseFloat(data.pnl) || 0,
      status: data.status,
    };
  } catch (error) {
    throw error;
  }
}

async function marketCloseTrade(params: any): Promise<void> {
  try {
    let result = await fetcher({
      ...params,
      entry: params.entryPrice,
      quantity: params.quantity,
      take_profit: params.takeProfitPrice,
      stop: params.stop,
      kind: params.kind,
    });
  } catch (error) {
    throw error;
  }
}
async function getBotAccounts(): Promise<any> {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        allAccounts.map((o) => ({
          owner: o.owner,
          configs: o.config,
          exchanges: o.exchanges || [],
        })),
      );
    }, 300);
  });
}
async function updateConfig(owner: string, config: any) {
  //console.log(config);
}
const existing_payload = {
  long: [
    {
      stop: 39242.8,
      entry: 41335.79,
      size: 0.4,
      risk_reward: 186,
      risk_per_trade: 81,
    },
  ],
  short: [
    {
      stop: 44779.3,
      entry: 43828.2808,
      size: 0.4,
      risk_reward: 184,
      risk_per_trade: 56,
    },
  ],
};

async function businessLogicActions(params: any) {
  console.log(params);
  if (params.action === 'b_save_trades') {
    existing_payload[params.data.kind] = [
      ...existing_payload[params.data.kind],
      {
        stop: params.data.stop,
        entry: params.data.entry,
        size: params.data.size,
        risk_reward: params.data.risk_reward,
        risk_per_trade: params.data.risk_per_trade,
      },
    ];
  }
  return samplePromise(existing_payload, 1000);
}
async function removeSymbolFromBackground(owner: string, symbol: string) {}
async function updateStopTrade(owner: string, symbol: string, order: any) {}

async function addSymbolToBackground(owner: string, symbol: string) {}
async function createJob(params: any) {}
async function updateOrders(owner?: string) {}
async function addNewSymbol(owner: string, symbol: string) {
  return await new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve([]);
    }, 300);
  });
}
async function deleteSymbol(owner: string, symbol: string) {
  //console.log({ owner, symbol });
  return [];
}
async function deleteJob(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function pauseJob(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function runJob(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function editJob(params: any) {}
async function toggleJobsWithAction(params: any) {}
async function createProfile(params: any) {}
async function runProfile(params: any) {}
async function editProfile(params: any) {}
async function getJobStatus(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function bulkJobAction(params: any) {
  //console.log(params);
}
async function removeOpenOrders(parmas: any) {
  //console.log(parmas);
  // try {
  //   let { symbol, owner } = parmas;
  //   let result = await postFetcher(`${ROOT}/ft/${owner}/cancel-all-orders`, {
  //     symbol,
  //   });
  //   return result.msg;
  // } catch (error) {}
}
export async function getSpotSymbols(params: any) {
  let { owner, coin } = params;
  return [...new Set(SPOT_SYMBOLS)];
}
export async function createSpotProfile(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
export async function runSpotProfile(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
export async function deleteSpotProfile(params: any) {
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
export async function placeFutureOrder(params: any, kind?: string) {
  //console.log({ params, kind });
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
export async function createSingleControlledOrder(params: any) {
  //console.log(params);
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
export async function addNewControlledAccount(params: any) {
  return await samplePromise(params);
}

export function getSampleParser() {
  const instance = new AccountParser(sampleProfileOrders, 'BTCUSDT');
  return instance;
}
export async function getOrdersForAccount({ kind, ...params }: any) {
  if (kind) {
    let oo = sampleProfileOrders.future[params.symbol.toLowerCase()].position;
    console.log('payload', oo);
    return await samplePromise(oo, 10000);
  }
  const instance = new AccountParser(sampleProfileOrders, params.symbol);
  return await samplePromise(
    {
      accounts: instance.buildAccounts(),
      coin: instance.base.toUpperCase(),
      collateral: instance.collateral,
      loan: instance.loan,
      quote_loan: instance.quote_loan,
      base_collateral: instance.base_collateral,
    },
    2000,
  );
}
export async function createOrdersForAccount(
  config: any,
  account: 'future' | 'margin',
) {
  let order;
  let randomId = Math.floor(Math.random() * 100) + 1;
  let commoOrder = {
    price: 70000,
    quantity: 0.005,
    kind: config.kind,
    type: config.type,
    orderId: randomId,
    side: 'buy',
  };
  if (account === 'future') {
    order = {
      stop: undefined,
      ...commoOrder,
    };
  } else {
    order = {
      notion_value: '105',
      ...commoOrder,
    };
  }
  return await samplePromise(order);
}
function samplePromise(data = {}, timer = 300): Promise<any> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, 300);
  });
}
async function reducePositionTrade(params: any) {
  console.log(params);
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function placeStopOrders(params: any) {
  console.log({ params });
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}
async function placeSignalTrade(params: any) {
  console.log({ params });
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}

async function updateClosePrices(params: any) {
  console.log({ params });
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}

async function updateMarginClosePrices(params: any) {
  console.log('close margin ', params);
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}

async function placeMarginSignalTrade(params: any) {
  console.log('place margin signal', params);
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve({});
    }, 500);
  });
}

const BASE_URL3 = 'https://app-dev.beeola.me/ai';
async function getAIResponse(params: any) {
  console.log('get ai response', params);
  console.log('ssession', params.sessionId);
  // let action = 'stream';
  let action = '';
  //
  try {
    const response = await fetch(`${BASE_URL3}/${params.sessionId}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ message: params.message, action }),
    });
    if (response.status < 400) {
      const result = await response.json();
      const messages = result.data.messages;
      return { messages };
    }
  } catch (error) {
    throw error;
  }
  return;
}

async function getExchanges() {
  return await samplePromise(
    [
      {
        owner: 'main_account',
        long_position: {
          entry: 26789.42,
          size: 0.43,
          kind: 'long',
        },
        short_position: {
          entry: 26576.73,
          size: 0.256,
          kind: 'short',
        },
        orders: [
          {
            price: 26372.5,
            quantity: 0.256,
            kind: 'short',
            type: 'take_profit',
          },
          {
            price: 26823.6,
            quantity: 0.43,
            kind: 'long',
            type: 'take_profit',
          },
          {
            price: 26372.5,
            quantity: 0.125,
            kind: 'long',
            type: 'stop_loss',
          },
        ],
        balances: [
          {
            currency: 'usdt',
            amount: 299.62,
          },
          {
            currency: 'bnb',
            amount: 0.0539,
          },
        ],
      },
      {
        owner: 'gbozee1_sub_account',
        long_position: {
          entry: 26807.46,
          size: 0.44,
          kind: 'long',
        },
        short_position: {
          entry: 26576.71,
          size: 0.238,
          kind: 'short',
        },
        orders: [],
        balances: [
          {
            currency: 'usdt',
            amount: 299.56,
          },
          {
            currency: 'bnb',
            amount: 0.0554,
          },
        ],
      },
      {
        owner: 'sub_account',
        long_position: {
          entry: 26865.67,
          size: 0.414,
        },
        short_position: {
          entry: 26589.97,
          size: 0.205,
        },
        orders: [],
        balances: [
          {
            currency: 'usdt',
            amount: 299.54,
          },
          {
            currency: 'bnb',
            amount: 0.0583,
          },
        ],
      },
    ],
    1000,
  );
}

async function getExchangePosition({
  owner,
  symbol,
}: {
  owner: string;
  symbol: string;
}) {
  const result = await getExchanges();
  const data = result.find((o) => o.owner === owner);
  return data;
}

async function purchase_bnb(params: {
  amount: number;
  owner: string;
  source: string; //'spot' | 'margin' | 'future';
}) {
  console.log(params);
  return await samplePromise(
    [
      {
        currency: 'usdt',
        amount: 299.62,
      },
      {
        currency: 'bnb',
        amount: 0.0539,
      },
    ],
    1000,
  );
}

async function transfer_usdt(params: {
  amount: number;
  owner: string;
  from: string;
  to: string;
  source: string; //'spot' | 'margin' | 'future' | 'isolated_margin';
}) {
  console.log(params);
  return await samplePromise(
    [
      {
        currency: 'usdt',
        amount: 299.62,
      },
      {
        currency: 'bnb',
        amount: 0.0539,
      },
    ],
    1000,
  );
}
const baseUrl = 'http://localhost:5000';
const connections: { [key: string]: WebSocket } = {};

async function postFetcher<T = any>(path: string, payload: T, headers = {}) {
  const response = await fetch(`${baseUrl}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify(payload),
  });
  const { data } = await response.json();
  return data;
}
const transferFunds = async (params: TransferData) => {
  try {
    const result = await postFetcher(`http://localhost:8000/api/cross-transfer`, params);
    return result;
  } catch (error) {
    throw error;
  }
};

const adapter = {
  transferFunds,
  getExchange: async (params: any) => {
    const [data, config] = await Promise.all(
      ['b_get_exchange_info', 'b_get_config'].map((o) =>
        postFetcher('/business-logic/actions', {
          name: o,
          params: {
            owner: params.owner,
            symbol: 'BTCUSDT',
          },
        }),
      ),
    );
    const payload = Object.keys(data).map((u) => {
      let vv = data[u].payload.future[u.toLowerCase()];
      return {
        id: `BTCUSDT:${params.owner}`,
        last_order: vv.last_order,
        closed_orders: vv.closed_orders,
        account_balance: vv.account_balance,
        open_orders: vv.open_orders,
        position: vv.position,
        symbol: u,
        futureCTrader: {
          config: config,
          max_size: config.max_size,
          symbol: config.symbol,
          support: config.support,
          resistance: config.resistance,
          split: config.tradeSplit,
          price_places: config.price_places,
          zone_split: config.zone_split,
          profit_base_price: config.profit_base_price,
          profit_quote_price: config.profit_quote_price,
        },
      };
    });
    console.log('payload', payload);
    return payload;
  },
  getTradeSections: async (params: any) => {
    const data = await postFetcher('/business-logic/actions', {
      name: 'b_get_trades',
      params: {
        owner: params.owner,
        symbol: 'BTCUSDT',
      },
    });
    const values = Object.keys(data)
      .map((u) => {
        return data[u].map(
          (
            { entry, stop, size, risk_reward, risk_per_trade, name },
            index,
          ) => ({
            config: {
              entry,
              stop,
              size,
              risk_reward,
              risk_per_trade,
            },
            kind: u,
            id:
              name ||
              `${params.owner}-${params.symbols[0]}-${u}-entry:${entry}-stop:${stop}-index:${index}`,
          }),
        );
      })
      .flat();
    console.log('kk', values);
    return values;
  },
  businessLogicActions,
  purchase_bnb,
  transfer_usdt,
  getExchangePosition,
  getExchanges,
  getAIResponse,
  placeMarginSignalTrade,
  updateMarginClosePrices,
  editJob,
  placeSignalTrade,
  placeStopOrders,
  reducePositionTrade,
  updateClosePrices,
  createSpotProfile,
  runSpotProfile,
  deleteSpotProfile,
  getSpotSymbols,
  toggleJobsWithAction,
  loadInitialData,
  fetchAllMarkets,
  createNewTrade,
  updateTakeProfit,
  updateStopLoss,
  marketCloseTrade,
  increasePosition,
  getSwingInfo,
  bulkTrades,
  getBotAccounts,
  updateConfig,
  updateOrders,
  addSymbolToBackground,
  removeSymbolFromBackground,
  updateStopTrade,
  createJob,
  deleteJob,
  pauseJob,
  addNewSymbol,
  deleteSymbol,
  createProfile,
  runProfile,
  getJobStatus,
  editProfile,
  runJob,
  removeOpenOrders,
  bulkJobAction,
  placeFutureOrder,
  createSingleControlledOrder,
  addNewControlledAccount,
  getOrdersForAccount,
  createOrdersForAccount,
};

export default adapter;
