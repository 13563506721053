import * as React from 'react';
import { IFutureInstance } from '../../../store/futureConfig';
import { kindType, TradeProps } from './types';

type PriceInstance = {
  price: number;
  kind: kindType;
  index: number;
};

export const useTradingView = (store: IFutureInstance) => {
  const entry = store.config.entry;
  const kind = store.config.kind as unknown as kindType;

  const [isClickable, setIsClickable] = React.useState(true);
  const [priceArray, setPriceArray] = React.useState<Array<PriceInstance>>([]);

  const _buildTrade = (condition: number, kind: kindType) => {
    return condition
      ? store.buildTrades({
          currentPrice: condition,
          _kind: kind,
        }) || []
      : [];
  };

  const getCurrentKind = () => {
    // returns the current kind
    if (priceArray.length < 2) {
      return kind;
    }
    return priceArray.at(-2)?.kind as kindType;
  };

  const getIsClickable = () => {
    if (priceArray.length === 1) {
      setIsClickable(false);
    }
  };

  const buildTrades = (name: 'side' | 'main') => {
    if (priceArray.length === 0) {
      return [];
    }

    let _current = priceArray.at(-1) as PriceInstance;
    if (name === 'main') {
      let arraySet = _buildTrade(_current.price, _current.kind) || [];
      const currentSelectedOrder = arraySet[_current.index];
      let _kind = _current.kind === 'long' ? 'short' : 'long';

      if (currentSelectedOrder) {
        const alternatTrades = _buildTrade(
          currentSelectedOrder.entry,
          _kind as kindType,
        ).map((r) => ({
          ...r,
          kind: _kind,
        }));
        return alternatTrades;
      }
      return [];
    }
    return _buildTrade(_current.price, _current.kind).map((r) => ({
      ...r,
      kind: _current.kind,
    }));
  };

  function onTradeClick(trade: TradeProps, index: number, last_item?: number) {
    let current = priceArray.at(-1);
    let newArray = [...priceArray];
    if (current?.kind === trade.kind) {
      newArray[newArray.length - 1] = {
        ...current,
        index: index,
      };
    } else {
      newArray.push({
        price: trade.entry,
        kind: trade.kind,
        index: last_item || index,
      });
      getIsClickable();
    }
    setPriceArray(newArray);
  }

  function resetConfig() {
    setPriceArray([]);
    setIsClickable(true);
  }

  function onReset() {
    console.log({ entry, kind });
    let firstArray =
      store.buildTrades({
        currentPrice: entry,
        _kind: kind,
      }) || [];
    setPriceArray([
      {
        price: entry,
        kind: kind,
        index: firstArray.length > 0 ? firstArray.length - 1 : 0,
      },
    ]);
  }

  function handleBackButton(trade, kind) {
    let firstArray =
      store.buildTrades({
        currentPrice: entry,
        _kind: kind,
      }) || [];
    setPriceArray([
      {
        price: entry,
        kind: kind,
        index: firstArray.length > 0 ? firstArray.length - 1 : 0,
      },
    ]);
    setIsClickable(true);
  }

  const currentTrade = priceArray.at(-1);
  const currentTradeArray = buildTrades('side');
  return {
    handleBackButton,
    onTradeClick,
    isClickable,
    kind: getCurrentKind(),
    currentTrade: currentTrade
      ? currentTradeArray[currentTrade.index]
      : undefined,
    orderKind: currentTrade?.kind === 'long' ? 'short' : 'long',
    buildTrades,
    onReset,
    resetConfig,
    priceArray,
  };
};
