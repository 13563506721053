import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { TradeProps } from '../types';

interface TradeDisplayProps {
  trade: TradeProps;
  newInfo: TradeProps;
}

export const TradeDisplay = ({ trade, newInfo }: TradeDisplayProps) => {
  const keys = Object.keys(trade);
  const values = Object.values(trade);

  const newKeys = [...keys, 'stop value'];
  const newValues = [...values, newInfo.stop];

  function convertValues(values: number | string) {
    if (typeof values === 'number') {
      const formattedNum = (Math.round(values * 100) / 100).toFixed(2);
      return formattedNum;
    }
    return values;
  }

  // determining the kind based on the sell price and entry
  const kind = trade.sell_price > trade.entry ? 'long' : 'short';

  return (
    <>
      <Heading size="lg" fontWeight="extrabold" mt="6" textAlign={'center'}>
        {kind === 'short' ? 'Short Trade Summary' : 'Long Trade Summary'}
      </Heading>
      <Flex
        justifyContent={'center'}
        mx="auto"
        wrap="wrap"
        mb={5}
        borderColor="black"
      >
        {newKeys.map((key, index) => (
          <Box key={key} w="120px" borderWidth="1px" m={0}>
            <Text
              w="120px"
              backgroundColor={kind === 'short' ? 'red' : 'blue.700'}
              color="white"
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
            >
              {key}
            </Text>
            <Text textAlign="center"> {convertValues(newValues[index])}</Text>
          </Box>
        ))}
      </Flex>
    </>
  );
};
