import { Flex, useColorModeValue as mode } from '@chakra-ui/react';
import * as React from 'react';
import { TradeConfig } from './TradeConfig';

type LayoutProps = {
  children: React.ReactNode;
  store: any;
  showTrade: () => void;
  tradeConfig: boolean;
};
export const Layout = ({
  children,
  store,
  showTrade,
  tradeConfig,
}: LayoutProps) => {
  return (
    <>
      <Flex>
        <Flex
          // maxW="2560px"
          bg={mode('white', 'gray.700')}
          height="100%"
          pb="6"
          rounded={{ md: 'lg' }}
        >
          <Flex direction="column" height="full">
            {tradeConfig ? (
              <>
                <Flex
                  mx={['20px', '100px', '100px']}
                  mt={['20px', '100px', '100px']}
                >
                  <TradeConfig
                    store={store}
                    showTrade={() => {
                      showTrade();
                    }}
                  />
                </Flex>
              </>
            ) : (
              children
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
