import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import type { ITrader } from '../stores/app';
import MarketDisplay from '../app-components/MarketDisplay';
import type {
  IAccount,
  IBotConfig,
  ISpotMarketConfig,
} from '../../store/botConfig';

const DoubleMode: FC<{
  store: ITrader | IAccount;
  onAddNewMarket: (kind: 'margin' | 'future') => void;
  onMarketActionSelected: any;
  onViewMarketJobs: any;
  singleMarket?: boolean;
}> = observer(
  ({
    store,
    onAddNewMarket,
    onMarketActionSelected,
    onViewMarketJobs,
    singleMarket = false,
  }) => {
    return null;
    // return store.currentCoin ? (
    //   <>
    //     <MarketDisplay
    //       {...{
    //         onAddNewMarket: () => onAddNewMarket('margin'),
    //         onRefresh: store.refreshUserMarkets,
    //         onMarketActionSelected,
    //         onViewMarketJobs,
    //       }}
    //       coin={store.currentCoin}
    //       mb={4}
    //       label="Margin Markets"
    //       markets={store.marginMarkets}
    //     />
    //     <MarketDisplay
    //       label="Future Markets"
    //       {...{
    //         onAddNewMarket: () => onAddNewMarket('future'),
    //         onRefresh: store.refreshUserMarkets,
    //         onMarketActionSelected,
    //         onViewMarketJobs,
    //       }}
    //       coin={store.currentCoin}
    //       markets={store.futureMarkets}
    //     />
    //   </>
    // ) : null;
  },
);
const MarketTypeDisplay: FC<{
  store: ITrader | IAccount;
  onAddNewMarket: (kind: 'margin' | 'future' | 'spot') => void;
  onMarketActionSelected: any;
  onViewMarketJobs: (symbol: string) => any;
  onCreateJob: (symbol: string) => any;
  onCreateProfile: (symbol: string) => any;
  singleMarket?: boolean;
  onDuplicateProfile: (profile: any, symbol: string) => any;
  onRunProfile: any;
  onEditProfile: (profile: any, symbol: string) => any;
  onDeleteProfile: (profile: any, symbol: string) => any;
  onClosePosition: any;
  onViewStatus: any;
  onPauseJob?: (symbol: string) => any;
  toProfile?: (symbol: string) => any;
  onRefresh: () => any;
  onProtectProfit?: (symbol: string) => any;
  loading?: boolean;
  refresh_loading?: boolean;
  account_loading?: boolean;
  app_loading?: boolean;
  config_loading?: boolean;
  canAdd?: boolean;
  markets: IBotConfig[];
  spot_markets: any[];
  title?: string;
  type?: any;
  onDeleteSymbol: (symbol: string) => any;
  spotActionList?: any;
  spotActions?: any;
}> = observer(
  ({
    store,
    onAddNewMarket,
    onMarketActionSelected,
    onViewMarketJobs,
    singleMarket = false,
    canAdd = true,
    onCreateJob,
    onProtectProfit,
    onCreateProfile,
    onDuplicateProfile,
    onPauseJob,
    toProfile,
    onRunProfile,
    onEditProfile,
    onDeleteProfile,
    onRefresh,
    onDeleteSymbol,
    onClosePosition,
    onViewStatus,
    loading,
    refresh_loading,
    account_loading,
    config_loading,
    app_loading,
    title = 'Margin Markets',
    markets,
    spot_markets,
    type,
    spotActions,
    spotActionList,
  }) => {
    return (
      <>
        {type != 'spot' ? (
          <MarketDisplay
            {...{
              onAddNewMarket: () => onAddNewMarket('margin'),
              onMarketActionSelected,
              onViewMarketJobs,
              onCreateProfile,
              onCreateJob,
              onProtectProfit,
              onDuplicateProfile,
              onRunProfile,
              onEditProfile,
              onDeleteProfile,
              onPauseJob,
              toProfile,
              onRefresh,
              loading,
              onDeleteSymbol,
              type,
              onClosePosition,
              onViewStatus,
              refresh_loading,
              account_loading,
              config_loading,
              app_loading,
            }}
            coin={''}
            mb={4}
            label={title}
            canAdd={canAdd}
            markets={markets}
          />
        ) : null}
        {['spot'].includes(type) ? (
          <MarketDisplay
            {...{
              onAddNewMarket: () => onAddNewMarket('spot'),
              onMarketActionSelected,
              onViewMarketJobs,
              onCreateProfile,
              onCreateJob,
              onDuplicateProfile,
              onRunProfile,
              onEditProfile,
              onDeleteProfile,
              onRefresh,
              loading,
              onDeleteSymbol,
              type,
              onClosePosition,
              onViewStatus,
              refresh_loading,
              account_loading,
              config_loading,
              app_loading,
              market_type: 'spot',
              spotActions,
              spotActionList,
            }}
            coin={''}
            mb={4}
            label={'Spot Markets'}
            canAdd={canAdd}
            markets={spot_markets}
          />
        ) : null}
      </>
    );
  },
);

export default MarketTypeDisplay;
