import { flow } from 'mobx';
import {
  applySnapshot,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import type { BotAdapterType } from '../adapter';
import { FutureInstance } from './futureConfig';
import { AutoTraderHelper, PositionType } from './spot_builder';
import { getParamForField } from './utils';

const configs = [
  { name: 'budget', label: 'Budget', kind: 'special' },
  {
    name: 'buy_more',
    label: 'Purchase at stop loss?',
    kind: 'checkbox',
  },
  {
    name: 'is_margin',
    label: 'Use isolated margin?',
    kind: 'checkbox',
  },
  { name: 'expected_loss', label: 'Expected loss', kind: 'special' },
  { name: 'focus', label: 'Focus', kind: 'special' },
  { name: 'percent_change', label: 'Percent Change', kind: 'special' },
  { name: 'dollar_price', label: 'Dollar rate ', kind: 'special' },
  { name: 'max_size', label: 'Maximum size', kind: 'special' },
  { name: 'spread', label: 'spread ', kind: 'special' },
  { name: 'risk_reward', label: 'Risk to reward ratio', kind: 'special' },
  { name: 'entry_price', label: 'Entry Price', kind: 'special' },
  { name: 'zone_risk', label: 'Zone risk', kind: 'special' },
  {
    name: 'follow_profit',
    label: 'Re-enter after taking profit?',
    kind: 'checkbox',
  },
  {
    name: 'transfer_funds',
    label: 'Transfer funds',
    kind: 'checkbox',
  },
  {
    name: 'follow_stop',
    label: 'Re-enter after hitting stop?',
    kind: 'checkbox',
  },
  {
    name: 'loss_factor',
    label: 'Stop factor multiplier',
    kind: 'special',
  },
  {
    name: 'bull_factor',
    label: 'Long stop factor',
    kind: 'special',
  },
  {
    name: 'bear_factor',
    label: 'Short stop factor',
    kind: 'special',
  },
  {
    name: 'support',
    label: 'Support',
    kind: 'special',
  },
  {
    name: 'resistance',
    label: 'Resistance',
    kind: 'special',
  },
  {
    name: 'tradeSplit',
    label: 'tradeSplit',
    kind: 'special',
  },
  {
    name: 'zone_split',
    label: 'Zone split',
    kind: 'special',
  },
  {
    name: 'futureBudget',
    label: 'futures Budget',
    kind: 'special',
  },
  {
    name: 'min_size',
    label: 'Minimum size',
    kind: 'special',
  },
  {
    name: 'risk_per_trade',
    label: 'Risk per trade',
    kind: 'special',
  },
  {
    name: 'decimal_places',
    label: 'Decimal places',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'price_places',
    label: 'Price places',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'sub_accounts',
    label: 'Sub accounts',
    kind: 'list',
    type: 'string',
  },
  {
    name: 'margin_sub_account',
    label: 'M. Sub accounts',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'profit_quote_price',
    label: 'Profit S. Price',
    kind: 'special',
  },
  {
    name: 'gap',
    label: 'Gap',
    kind: 'special',
  },
  {
    name: 'profit_base_price',
    label: 'Profit B. Price',
    kind: 'special',
  },
  {
    name: 'fee_percent',
    label: 'Fee percent',
    kind: 'special',
  }
];

const config_fields = {
  config_1: [
    'focus',
    'price_places',
    // 'zone_risk',
    // 'percent_change',
    'budget',
    'expected_loss',
    'risk_reward',
    // 'dollar_price',
    'max_size',
    'bull_factor',
    'bear_factor',
    'entry_price',
    'loss_factor',
    'spread',
    'support',
    'resistance',
    'tradeSplit',
    'zone_split',
    'futureBudget',
    'decimal_places',
    'min_size',
    'risk_per_trade',
    'sub_accounts',
    'fee_percent',
    // 'transfer_funds',
    'gap',
    'profit_quote_price',
    'profit_base_price',
    'follow_profit',
    'follow_stop',
    'buy_more',
    'is_margin',
  ],
};

type BuildFutureType = {
  current_price: number;
  kind: 'long' | 'short';
  current_position: PositionType;
  opposite_position: PositionType;
};
const SpotProfile = types
  .model('SpotProfile', {
    focus: types.optional(types.number, 0),
    // percent_change: types.optional(types.number, 0),
    zone_risk: types.optional(types.number, 0),
    gap: types.optional(types.number, 1),
    zone_split: types.optional(types.number, 4),
    price_places: types.optional(types.string, '%.2f'),
    buy_more: types.optional(types.boolean, false),
    is_margin: types.optional(types.boolean, false),
    budget: types.optional(types.number, 0),
    expected_loss: types.optional(types.number, 0),
    risk_reward: types.optional(types.number, 1),
    spread: types.optional(types.number, 50),
    follow_profit: types.optional(types.boolean, false),
    follow_stop: types.optional(types.boolean, false),
    id: types.optional(types.string, ''),
    entry_price: types.optional(types.number, 0),
    dollar_price: types.optional(types.number, 0),
    max_size: types.optional(types.number, 0.001),
    bull_factor: types.optional(types.maybeNull(types.number), 3),
    bear_factor: types.optional(types.maybeNull(types.number), 1),
    loss_factor: types.optional(types.maybeNull(types.number), 3),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    tradeSplit: types.optional(types.number, 5),
    futureBudget: types.optional(types.number, 5),
    decimal_places: types.optional(types.string, '%.2f'),
    min_size: types.optional(types.number, 0.001),
    transfer_funds: types.optional(types.boolean, true),
    sub_accounts: types.optional(types.array(types.string), []),
    margin_sub_account: types.optional(types.string, ''),
    risk_per_trade: types.optional(types.number, 0),
    profit_quote_price: types.optional(types.number, 10000),
    profit_base_price: types.optional(types.number, 100000),
    fee_percent: types.optional(types.number, 0.0005),
  })
  .views((self) => {
    return {
      get futureSupport() {
        return self.support ? self.support - self.spread : self.support;
      },
      get futureResistance() {
        return self.resistance
          ? self.resistance + self.spread
          : self.resistance;
      },
      get data() {
        return getSnapshot(self);
      },
      get config_fields() {
        return config_fields;
      },
      get percent_change() {
        return self.expected_loss / self.budget;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        return getParamForField(self, configs, field, isGroup);
      },
      //   buildTriggerMarketOrders(
      //     params: BuildFutureType,
      //     market_type: 'future_protect' | 'future_spread_protect',
      //     contract_size?: number,
      //     recent_price?: number,
      //   ) {
      //     const instance = new AutoTraderHelper({
      //       budget: self.futureBudget,
      //       // percent_change: this.percent_change,
      //       entry: params.current_price,
      //       // focus: params.current_price, // need to change this based on current_price
      //       price_places: self.price_places,
      //       decimal_places: self.decimal_places,
      //     });
      //     const result = instance.build_special_future_orders({
      //       market_type,
      //       kind: params.kind,
      //       current_position: params.current_position,
      //       opposite_position: params.opposite_position,
      //       contract_size,
      //       recent_price,
      //       max_count: self.loss_factor || 1,
      //       stop_loss_factor: self.bear_factor || 1,
      //       min_size: self.min_size,
      //     });
      //     return result;
      //   },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export const SpotMarketStore = types
  .model('SpotMarketStore', {
    symbol: types.optional(types.string, ''),
    profile: types.optional(SpotProfile, {}),
    loading: types.optional(types.boolean, false),
    futureInstance: types.optional(FutureInstance, {}),
  })
  .views((self) => {
    return {
      get created() {
        return self.profile.budget && self.profile.expected_loss;
      },
      get parent(): any {
        const parent: any = getParent(self, 2);
        return parent;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const parent: any = getParent(self, 2);

    const editProfile = flow(function* editProfile(owner: string) {
      try {
        let obj = {
          owner,
          symbol: parent.symbol,
          spot_symbol: self.symbol,
          profile: self.profile.data,
        };
        //console.log(obj);
        yield adapter.createSpotProfile(obj);
      } catch (error) {
        throw error;
      }
    });
    const runProfile = flow(function* runProfile(owner: string) {
      try {
        self.loading = true;
        yield adapter.runSpotProfile({
          owner,
          symbol: parent.symbol,
          spot_symbol: self.symbol,
        });
        // self.loading = false;
      } catch (error) {
        throw error;
      }
    });
    const updateTPSL = flow(function* updateTPSL(owner: string) {
      try {
        self.loading = true;
        yield adapter.updateTPSL({
          owner,
          symbol: parent.symbol,
          spot_symbol: self.symbol,
        });
        // self.loading = false;
      } catch (error) {
        throw error;
      }
    });
    const initializeFuture = (owner = '') => {
      let {
        futureBudget: budget,
        // percent_change,
        min_size,
        futureSupport: support,
        futureResistance: resistance,
        tradeSplit: split,
        price_places: price_places,
      } = self.profile;
      applySnapshot(self.futureInstance, {
        support,
        resistance,
        config: {
          budget,
          // percent_change,
          min_size,
        },
        symbol: self.symbol,
        split,
        price_places,
        account: owner,
      });
    };
    return {
      initializeFuture,
      editProfile,
      runProfile,
      updateTPSL,
      toggleLoading(loading: boolean) {
        self.loading = loading;
      },
    };
  });

export interface ISpotMarketConfig extends Instance<typeof SpotMarketStore> {}
export interface ISpotProfileConfig extends Instance<typeof SpotProfile> {}
