import {
  Box,
  Button,
  Flex,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { AddIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';

const MultipleMarkets = observer(({}) => {
  const markets = ['BTCUSDT', 'ETHUSDT'];
  return (
    <Flex mt={4} direction="column">
      {markets.map((market) => {
        return (
          <Link _hover={{textDecor: "none"}} as={RouterLink} to="/advanced">
            <Flex
              _hover={{ cursor: 'pointer' }}
              pl={3}
              py={3}
              border="1px solid black"
              justifyContent="space-between"
            >
              <Text alignSelf="center" width="30%">
                {market}
              </Text>
              <SimpleGrid flex="0.8" columns={2} spacing={3}>
                <Box>Entry: 37704.78</Box>
                <Box>Stop: 36204.78</Box>
                <Box>Size: 0.01 BTC</Box>
                <Box>Profit: 39946.41</Box>
              </SimpleGrid>
            </Flex>
          </Link>
        );
      })}
      <IconButton
        position="fixed"
        bottom={0}
        colorScheme="blue"
        right={0}
        mr="3rem"
        fontSize="3rem"
        borderRadius="2.375rem"
        mb="3rem"
        minWidth={'5rem'}
        height="5rem"
        // size="lg"
        aria-label="Add Market"
        icon={<AddIcon />}
      />
      <Menu>
        <MenuButton
          position="fixed"
          bottom={0}
          left={0}
          as={Button}
          marginLeft="3rem"
          marginBottom="3rem"
          minWidth="3rem"
          height="3rem"
          fontSize="1.5rem"
          rightIcon={<ChevronDownIcon />}
        >
          Actions
        </MenuButton>
        <MenuList>
          <MenuItem>Transfer Funds</MenuItem>
          <MenuItem>Remove Profits</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
});

export default MultipleMarkets;
