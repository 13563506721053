import { to_f } from '../store/utils';
import {
  MarginPayloadType,
  FuturePayloadType,
  AccountBalanceParser,
} from './types';

export class AccountParser {
  margin_payload: MarginPayloadType;
  symbol: string;
  future_payload: FuturePayloadType;
  constructor({ margin, future }: any, symbol: string) {
    this.symbol = symbol;
    const quote = symbol.toLowerCase().includes('usdt') ? 'usdt' : 'busd';
    const base = symbol.toLowerCase().replace(quote, '');
    const margin_payload = margin[symbol.toLowerCase()];
    const future_payload = future[symbol.toLowerCase()];
    this.margin_payload = {
      last_orders: margin_payload.last_orders,
      account_balance: new AccountBalanceParser(
        margin_payload.account_balance[base.toUpperCase()],
        margin_payload.account_balance[quote.toUpperCase()],
      ),
      open_orders: margin_payload.open_orders,
      owner: margin_payload.owner,
    };
    this.future_payload = {
      owner: future_payload.owner,
      last_order: future_payload.last_order,
      account_balance: future_payload.account_balance,
      open_orders: future_payload.open_orders,
      position: {
        long: future_payload.position.long,
        short: future_payload.position.short,
      },
    };
  }
  get quote() {
    return this.symbol.toLowerCase().includes('usdt') ? 'usdt' : 'busd';
  }
  get base() {
    return this.symbol.toLowerCase().replace(this.quote, '');
  }
  get margin_balance() {
    return this.margin_payload.account_balance.assets;
  }
  get future_orders() {
    return this.future_payload.open_orders.map((o) => ({
      price: to_f(o.price, '%.1f'),
      quantity: to_f(o.origQty, '%.3f'),
      side: o.side.toLowerCase(),
      orderId: o.orderId,
      kind: o.positionSide.toLowerCase() as 'long' | 'short',
      stop: o.stopPrice && o.stopPrice !== '0' ? to_f(o.stopPrice) : undefined,
      type: o.type.toLowerCase(),
    }));
  }
  get margin_orders() {
    return this.margin_payload.open_orders.map((o) => ({
      price: to_f(o.price, '%.2f'),
      quantity: to_f(o.origQty, '%.5f'),
      side: o.side.toLowerCase(),
      orderId: o.orderId,
      kind: o.side.toLowerCase() === 'buy' ? 'long' : 'short',
      stop: o.stopPrice && o.stopPrice !== '0' ? to_f(o.stopPrice) : undefined,
      type: o.type.toLowerCase(),
    }));
  }
  buildAccounts() {
    return [
      {
        owner: this.margin_payload.owner,
        symbol: this.symbol,
        market_type: 'margin',
        margin_orders: this.margin_orders,
        currency: this.quote.toUpperCase(),
        collateral: this.margin_payload.account_balance.assets.quote,
        future_orders: [],
      },
      {
        owner: this.future_payload.owner,
        symbol: this.symbol,
        market_type: 'future',
        margin_orders: [],
        future_orders: this.future_orders,
        collateral: this.future_payload.account_balance,
        currency: this.quote.toUpperCase(),
        longPosition: this.future_payload.position.long,
        shortPosition: this.future_payload.position.short,
      },
    ];
  }
  get collateral() {
    return {
      amount: this.margin_payload.account_balance.assets.quote,
      currency: this.quote.toUpperCase(),
    };
  }
  get loan() {
    return {
      amount: this.margin_payload.account_balance.borrowed.base,
      currency: this.base.toUpperCase(),
    };
  }
  get quote_loan() {
    return {
      amount: this.margin_payload.account_balance.borrowed.quote,
      currency: this.quote.toUpperCase(),
    };
  }
  get base_collateral() {
    return {
      amount: this.margin_payload.account_balance.assets.base,
      currency: this.base.toUpperCase(),
    };
  }
}
