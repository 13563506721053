import {
  Box,
  Divider,
  Flex,
  Text,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react';
import { Root } from '../stores';

export type CreateAccountPageProps = {
  store: Root;
  navigate: (path: string) => void;
};
export const CreateAccountPage = (props: CreateAccountPageProps) => {
  return <>Create Account</>;
};

export default CreateAccountPage;
