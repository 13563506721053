import { ComponentStyleConfig } from '@chakra-ui/theme';

export const DisplayTable: ComponentStyleConfig = {
  baseStyle: (props) => {
    //console.log('props', props);
    return {
      backgroundColor: 'red',
      mx: '0px',
      th: {
        color: '#FFFFFF',
      },
      tr: {
        _hover: {
          // backgroundColor: '#4FA095',
          backgroundColor: props.kind === 'long' ? '#2B6CB0' : 'red.300',
          color: '#FFFFFF',
        },
      },
      td: {
        // paddingLeft: '5px',
        // paddingRight: '8px',
        borderBottom: 'none',
        // textAlign: 'center',
      },
      borderWidth: '2px',
      borderRadius: 'lg',
      thead: {
        mx: 'auto',
        backgroundColor: props.kind === 'long' ? '#3182ce' : 'red.500',
        color: '#FAD6A5',
      },
      tbody: {
        // backgroundColor: '#F0E9D2',
        backgroundColor: '#FFFFFF',
      },

      '@media screen and (max-width:30em)': {
        td: {
          paddingLeft: '5px',
          paddingRight: '1px',
        },
        th: {
       
        },
        '.chakra-table': {
          whiteSpace: 'normal',
          thead: {
            px: '600px',
          },
          td: {
            borderBottom: 'none',
            textAlign: 'center',
          },
        },
      },
    };
  },
  defaultProps: {
    size: 'lg',
  },
};
