import {
  Avatar,
  Box,
  Center,
  CenterProps,
  Container,
  Flex,
  HStack,
  HTMLChakraProps,
  Icon,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useColorModeValue as mode,
  useMenuButton,
  UseMenuButtonProps,
  useToken,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { FaBell } from 'react-icons/fa';
import {
  HiBell,
  HiHome,
  HiViewGrid,
  HiBriefcase,
  HiAdjustments,
} from 'react-icons/hi';
import CIcon from '../components/main/Icon';
import { ApplicationShell } from './ApplicationShell';
import { TabLink } from './primitives/TabLink';

export const Logo = observer(
  (props: HTMLChakraProps<'svg'> & { iconColor?: string; coin: string }) => {
    const { iconColor = 'currentColor', coin, ...rest }: any = props;
    const color = useToken('colors', iconColor);
    return (
      <HStack spacing={4} {...rest}>
        <Icon as={CIcon} boxSize="20px" name={coin.toLowerCase()} />
        <Text as="span">{coin.toUpperCase()}</Text>
      </HStack>
    );
  },
);

interface NavItemProps {
  href?: string;
  active?: boolean;
  label: string;
  onClick?: any;
}

interface DesktopNavItemProps extends NavItemProps {
  icon: React.ReactNode;
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const { icon, label, href = '#', active, onClick } = props;
  return (
    <HStack
      as="a"
      // href={href}
      onClick={onClick}
      aria-current={active ? 'page' : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color="gray.200"
      _hover={{ bg: 'whiteAlpha.200', cursor: 'pointer' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

const MobileNavItem = (props: NavItemProps) => {
  const { label, href = '#', active } = props;
  return (
    <Box
      as="a"
      display="block"
      href={href}
      px="3"
      py="3"
      rounded="md"
      fontWeight="semibold"
      aria-current={active ? 'page' : undefined}
      _hover={{ bg: 'whiteAlpha.200' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
    >
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};

const NotificationBadge = (props: CenterProps) => (
  <Center
    bg="red.500"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="full"
    textAlign="center"
    top="-2px"
    insetEnd="0"
    w="18px"
    h="18px"
    {...props}
  />
);

export const Notification = (props: CenterProps) => (
  <Center
    as="button"
    outline="0"
    w="8"
    h="8"
    position="relative"
    rounded="md"
    _hover={{ bg: 'whiteAlpha.200' }}
    _focus={{ shadow: 'outline' }}
    {...props}
  >
    <Box srOnly>Click to see 9 notifications</Box>
    <NotificationBadge>9</NotificationBadge>
    <Box as={FaBell} fontSize="lg" />
  </Center>
);

const UserAvatar = () => (
  <Avatar
    size="sm"
    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    name="Manny Brooke"
  />
);

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: 'outline' }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar />
    </Flex>
  );
};

export const ProfileDropdown = () => (
  <Menu>
    <ProfileMenuButton />
    <MenuList
      rounded="md"
      shadow="lg"
      py="1"
      color={mode('gray.600', 'inherit')}
      fontSize="sm"
    >
      <HStack px="3" py="4">
        <UserAvatar />
        <Box lineHeight="1">
          <Text fontWeight="semibold">Manny Broke</Text>
          <Text mt="1" fontSize="xs" color="gray.500">
            manny@chakra-ui.com
          </Text>
        </Box>
      </HStack>
      <MenuItem fontWeight="medium">Your Profile</MenuItem>
      <MenuItem fontWeight="medium">Feedback & Support</MenuItem>
      <MenuItem fontWeight="medium">Account Settings</MenuItem>
      <MenuItem fontWeight="medium" color={mode('red.500', 'red.300')}>
        Sign out
      </MenuItem>
    </MenuList>
  </Menu>
);

type AppTabProps = {
  currentPage: string;
  onTabClick: (p?: any) => void;
  toggleSidebar: any;
  isSidebarOpen?: boolean;
  coin: string;
  tabs: string[];
  openMobileMarketList?: () => void;
  [key: string]: any;
};
export const AppTabs: React.FC<AppTabProps> = ({
  currentPage,
  onTabClick,
  toggleSidebar,
  isSidebarOpen,
  children,
  tabs,
  coin,
  ...rest
}) => {
  return (
    <ApplicationShell
      desktopTabs={tabs.map((x, i) => {
        if (x === 'Jobs') {
          return { title: x, icon: <HiBriefcase /> };
        }
        if (x === 'Alerts') {
          return { title: x, icon: <HiBell /> };
        }
        if (x === 'Markets') {
          return { title: x, icon: <HiViewGrid /> };
        }
        if (x === 'Spot') {
          return { title: x, icon: <HiAdjustments /> };
        }
        if (x === 'Home') {
          return { title: x, icon: <HiHome /> };
        }
        return { title: x };
      })}
      {...{ currentPage, onTabClick, toggleSidebar, isSidebarOpen, coin }}
      {...rest}
    >
      {/* Page Header */}
      <Box bg={mode('white', 'gray.900')} shadow="sm" >
        <Container maxW="7xl" >
          <Stack
            display={{ base: 'flex', md: 'none', lg: 'none' }}
            direction="row"
            spacing={"4"}
          >
            {tabs.map((page, i) => {
              return (
                <TabLink
                  kind={mode(`gray.600`, `gray.600`)}
                  hover={mode(`gray.400`, `gray.600`)}
                  link={mode('blue.600', 'blue.400')}
                  key={`${page}-${i}`}
                  onClick={() => onTabClick(page)}
                  aria-current={page === currentPage ? 'page' : undefined}
                  fontSize={["xs", "sm", "md"]}
                >
                  {page}
                </TabLink>
              );
            })}
          </Stack>
        </Container>
      </Box>

      {/* Main content area */}
      {children}
    </ApplicationShell>
  );
};

export default observer(AppTabs);
