import React from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { observer } from 'mobx-react-lite';
import type { FC } from 'react';

export const DropdownMenu: FC<{
  onClick: (param: any) => void;
  options: any[];
  label: string;
  [key: string]: any;
  transform?: (x: any) => string;
  component?: any;
  rightIcon?: any;
}> = observer(
  ({
    onClick,
    options,
    label,
    component = Button,
    transform = (x: any) => x,
    rightIcon = true,
    ...rest
  }) => {
    const [currentLabel, setCurrentLabel] = React.useState(label || '')
    let Component = component;
    let icons = rightIcon ? <ChevronDownIcon /> : null;
    return (
      <Menu>
        <MenuButton mr={2} as={Component} rightIcon={icons} {...rest}>
          {currentLabel}
        </MenuButton>
        <MenuList>
          {options.map((config) => {
            return (
              <MenuItem onClick={() => {
                const newLabel = transform(config)
                setCurrentLabel(newLabel)
                onClick(config)}
              }>
                {transform(config)}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    );
  },
);
