import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { useToast } from '@chakra-ui/toast';
import { observer } from 'mobx-react-lite';
import { useNavigationHook } from './hooks';
import React from 'react';
import MarketTypeDisplay from '../newBot/app-components/MarketTypeDisplay';
import { BaseDrawer } from '../newBot/app-components/primitives/ModalComponent';
import type {
  IAccount,
  IBotConfig,
  IBotStore,
  IProfileConfig,
  ISpotMarketConfig,
} from '../store/botConfig';
import Config1Display from './components/GenericConfigDisplay';
import { CreateJobComponent } from './components/NewJobComponent';
import AddNewSymbols from '../newBot/app-components/AddNewSymbols';
import TightFutureTraderAnalyzer from '../newBot/app-components/TightFutureTraderAnalyzer';

const MarketList: React.FC<{
  title?: string;
  owners?: string[];
  account: IAccount;
  store: IBotStore;
  switchToJobsView: () => any;
  switchToHomeView?: () => any;
  markets: IBotConfig[] | any[];
  type?: 'regular' | 'usd' | 'coin' | 'spot';
  switchToTraderOrderView?: (symbol: string) => void;
}> = observer(
  ({
    account,
    store,
    owners = [],
    switchToJobsView,
    switchToHomeView,
    markets,
    title,
    type = 'regular',
    switchToTraderOrderView,
  }) => {
    const [modalType, setModalType] = React.useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    let [spotSymbol, setSpotSymbol] = React.useState<string>('');
    const [selectdMarkets, setSelectedMarkets] = React.useState<any>([]);
    function onMarketActionSelected(symbol: string, value: string) {
      if (
        ['Pause all jobs', 'Resume all jobs', 'Delete all jobs'].includes(value)
      ) {
        return store.bulkJobAction(value, symbol);
      } else if (value == 'Remove open orders') {
        return store.removeOpenOrders(symbol);
      }
      return Promise.resolve();
    }
    function onViewMarketJobs(symbol: string) {
      account.setDefaultConfig(symbol);
      switchToJobsView();
    }
    const toast = useToast();
    function toastHelper(status: 'success' | 'error', title: string) {
      toast({
        title,
        status,
        description: title,
        duration: 3000,
        isClosable: true,
      });
    }
    function onClosePosition(symbol: any, params: IProfileConfig) {
      //console.log(params.tradeKind); // the first parameter
      //console.log(symbol, params.tradeKind);
      return store.closePosition(symbol, params.tradeKind[0]);
    }
    function onViewStatus(symbol: any, params: any) {
      //console.log({ symbol, params });
    }

    function modalTitle() {
      let config: any = store.account?.defaultConfig || { symbol: '' };
      if (modalType == 'newJob') {
        return `Create Job for ${config.symbol}`;
      }
      if (modalType == 'newProfile') {
        if (config.editProfile) {
          return `Edit ${config.editProfile.name} Profile for ${config.symbol}`;
        }
        return `Create Profile for ${config.symbol}`;
      }
      if (modalType == 'newMarket') {
        return `Add new markets`;
      }
      if (modalType == 'newSpotProfile') {
        return `Edit spot profile for ${spotSymbol}`;
      }
      if (modalType == 'newSpotMarket') {
        return `Add new spot markets`;
      }
      if (modalType === 'newFutureTrader') {
        return `Future trader for ${config.symbol}`;
      }
      return ``;
    }
    let m_type =
      type == 'spot' ? account.spotMarketsToAdd : account.marketsToAdd;
    return (
      <>
        <MarketTypeDisplay
          type={type}
          markets={markets}
          spot_markets={account.spot_markets}
          spotActionList={(market: ISpotMarketConfig) => {
            let other = market.created
              ? [
                  { type: 'edit', color: 'orange', label: 'Edit' },
                  {
                    type: 'run',
                    color: 'blue',
                    label: 'Run',
                    loading: market.loading,
                    confirm: true,
                  },
                ]
              : [
                  {
                    type: 'create',
                    color: 'green',
                    label: 'Create',
                    loading: false,
                  },
                ];
            let actions = [
              ...other,
              {
                type: 'transfer',
                color: 'teal',
                label: 'TP/SL',
                loading: market.loading,
              },
              {
                type: 'view',
                color: 'purple',
                label: 'View Orders',
                loading: false,
              },
              {
                type: 'delete',
                color: 'red',
                label: 'Delete',
                confirm: true,
                loading: account.loading,
              },
            ];

            return actions;
          }}
          spotActions={(market: ISpotMarketConfig) => ({
            edit: () => {
              setModalType('newSpotProfile');
              setSpotSymbol(market.symbol);
              onOpen();
            },
            transfer: () => account.updateSpotupdateTPSL(market.symbol),
            view: () => {
              setModalType('newFutureTrader');
              account.initializeFuture(market.symbol);
              if (switchToTraderOrderView) {
                switchToTraderOrderView(market.symbol);
              }
            },
            create: () => {
              setModalType('newSpotProfile');
              setSpotSymbol(market.symbol);
              onOpen();
            },
            run: () => account.runSpotProfile(market.symbol),
            delete: () => account.deleteSpotProfile(market.symbol),
          })}
          title={title}
          canAdd={m_type.length > 0}
          store={account}
          {...{ onMarketActionSelected, onViewMarketJobs }}
          singleMarket={true}
          onAddNewMarket={(kind: 'margin' | 'future' | 'spot') => {
            if (kind === 'spot') {
              setModalType('newSpotMarket');
            } else {
              setModalType('newMarket');
            }
            onOpen();
          }}
          onProtectProfit={(symbol) => {
            account.onProtectProfit(symbol);
          }}
          onPauseJob={(symbol: string) => {
            account.tempPauseMarket(symbol);
          }}
          toProfile={(symbol: string) => {
            account.setDefaultConfig(symbol);
            switchToHomeView && switchToHomeView();
          }}
          onCreateJob={(symbol: string) => {
            account?.setDefaultConfig(symbol);
            setModalType('newJob');
            onOpen();
          }}
          onClosePosition={onClosePosition}
          onViewStatus={onViewStatus}
          onCreateProfile={(symbol) => {
            account?.setDefaultConfig(symbol);
            account.defaultConfig.setEditProfile(null);
            setModalType('newProfile');
            onOpen();
          }}
          onEditProfile={(profile: any, symbol: string) => {
            account?.setDefaultConfig(symbol);
            account?.defaultConfig.setEditProfile(profile);
            setModalType('newProfile');
            onOpen();
          }}
          onDeleteProfile={(profile: any, symbol: string) => {
            account?.setDefaultConfig(symbol);
            //console.log(profile.data);
            store.onDeleteProfile(profile);
          }}
          onDuplicateProfile={(profile: any, symbol: string) => {
            account?.setDefaultConfig(symbol);
            account?.defaultConfig.duplicateProfile(profile);
            // store.onEditProfile();
          }}
          onDeleteSymbol={(symbol: string) => {
            account?.setDefaultConfig(symbol);
            return store.deleteSymbol(symbol);
          }}
          onRunProfile={(
            symbol: string,
            profile: IProfileConfig,
            resume?: boolean,
          ) => {
            account?.defaultConfig.setEditProfile(profile);
            // run in parallel the profile update as well as deleting
            // existing open orders.
            let promises = [
              profile.runProfile(symbol, account?.owner, resume),
              store.removeOpenOrders(symbol),
            ];
            Promise.all(promises).then(() => {
              if (profile.pause_jobs) {
                account.tempPauseMarket(symbol);
              }
            });
            // store.bulkJobAction('', account?.defaultConfig.symbol || '');
          }}
          refresh_loading={store.account_loading}
          account_loading={account.loading}
          config_loading={account.defaultConfig.loading}
          app_loading={store.loading}
          loading={store.account_loading || store.loading}
          onRefresh={() =>
            store
              .fetchAccounts()
              .then((x) => toastHelper('success', 'Updated'))
              .catch((e) => toastHelper('error', 'Error updating accounts'))
          }
        />
        <BaseDrawer
          placement="top"
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          title={modalTitle()}
          onSave={() => {
            if (modalType == 'newProfile') {
              store
                .onEditProfile()
                .then(() => {
                  onClose();
                })
                .catch((error) => {
                  //console.log(error);
                });
            } else if (modalType == 'newSpotProfile') {
              account.editSpotProfile(spotSymbol).then(() => {
                onClose();
              });
            } else if (modalType == 'newSpotMarket') {
              account.addSpotSymbols(selectdMarkets).then(() => {
                onClose();
              });
            } else if (modalType == 'newMarket') {
              store
                .addNewSymbol(selectdMarkets)
                .then(() => {
                  account?.setDefaultConfig(selectdMarkets[0]);
                  onClose();
                })
                .catch((e) => {
                  //console.log(e);
                });
            } else {
              onClose();
            }
            // store.addMarket('margin').then(() => {
            // });
          }}
          footerProps={{ justifyContent: 'center' }}
          buttonText="Save"
          displayCancelButton
          // buttonText={`Add ${store.selectedMarkets.length} Selected Markets`}
          buttonProps={{
            isLoading:
              store.loading || account.loading || store.account_loading,
            loadingText: 'Saving',
          }}
          closeOnOverlayClick={true}
        >
          {modalType == 'newFutureTrader' ? (
            <TightFutureTraderAnalyzer
              accounts={owners}
              store={account.futureCTrader}
              positionStore={store.positionStore}
            />
          ) : null}
          {modalType == 'newMarket' ? (
            <AddNewSymbols
              onItemChecked={(e: any[]) => {
                let arr: any = new Set(e);
                setSelectedMarkets([...arr]);
              }}
              markets={account?.marketsToAdd || []}
            />
          ) : null}
          {modalType == 'newSpotMarket' ? (
            <AddNewSymbols
              onItemChecked={(e: any[]) => {
                let arr: any = new Set(e);
                setSelectedMarkets([...arr]);
              }}
              markets={account?.spotMarketsToAdd || []}
            />
          ) : null}
          {modalType == 'newJob' ? (
            <CreateJobComponent
              toJobsPage={switchToJobsView}
              store={store}
              flex={[1, 1, 1, 0.9]}
              config={account?.defaultConfig}
            />
          ) : null}
          {modalType == 'newSpotProfile' ? (
            <Flex direction="column">
              <Flex justifyContent="space-around">
                <Config1Display
                  override="config_1"
                  store={account.spotInstance(spotSymbol)?.profile}
                />
              </Flex>
            </Flex>
          ) : null}
          {modalType == 'newProfile' ? (
            <Flex flexDirection="column">
              <Flex justifyContent="space-around">
                <Config1Display
                  override="config_1"
                  store={account?.defaultConfig.currentProfile}
                />
              </Flex>
            </Flex>
          ) : null}
        </BaseDrawer>
      </>
    );
  },
);

export default MarketList;

export const SpotMarketList = observer((props) => {
  const { history, match } = useNavigationHook();
  function switchToTraderOrderView(symbol: string) {
    const url = `${match.url}/${symbol}`;
    history.push(url);
  }
  return <MarketList {...props} {...{ switchToTraderOrderView }} />;
});
