import { Instance, applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import { withSetPropAction } from './helpers';
import { TradeSectionStore } from './trade-section';

export function getParamForField(
  self: any,
  configs: any[],
  field: string,
  isGroup?: string,
): any | any[] {
  if (isGroup === 'group' && field === 'checkbox') {
    return configs
      .filter((o) => o.kind === field && o.group === true)
      .map((o) => {
        let _self: any = self;
        let value: any = _self[o.name];
        return { ...o, value };
      });
  }
  let r: any = configs.find((o) => o.name == field);
  if (r) {
    let oo: any = self;
    let tt: any = oo[r.name] || '';
    r.value = tt;
  }
  return r;
}

const config_fields: any = {
  config_1: [
    'kind',
    'support',
    'resistance',
    'risk',
    'entry',
    'stop',
    'strategy',
    'max_size',
    'gap',
  ],
};

const selectProps = {
  valueLayout: (v: any) => v.label,
  optionLayout: (v: any) => v.value,
};
const configs = [
  {
    name: 'kind',
    label: 'Trade Kind',
    kind: 'radio',
    options: ['long', 'short'],
  },
  {
    name: 'strategy',
    label: 'Strategy',
    kind: 'select',
    options: ['risk_reward', 'entry'],
  },
  {
    name: 'resistance',
    label: 'Resistance',
    kind: 'subscript',
    ...selectProps,
  },

  {
    name: 'support',
    label: 'Support',
    kind: 'special',
    ...selectProps,
  },

  {
    name: 'entry',
    label: 'Entry',
    kind: 'special',
    controlProps: {
      maxW: 'full',
    },
    ...selectProps,
  },
  {
    name: 'stop',
    label: 'Stop',
    kind: 'special',
    ...selectProps,
  },

  {
    name: 'risk',
    label: 'Risk',
    kind: 'special',
  },
  {
    name: 'max_size',
    label: 'Max size',
    kind: 'special',
  },
  {
    name: 'gap',
    label: 'Gap',
    kind: 'special',
  },
];

export const MobileTradeEditSection = types
  .model('MobileTradeEditSection', {
    entry: types.optional(types.number, 0),
    stop: types.optional(types.number, 0),
    risk: types.optional(types.number, 0),
    risk_reward: types.optional(types.number, 0),
    kind: types.optional(types.enumeration(['long', 'short']), 'long'),
    max_size: types.optional(types.number, 0.1),
    gap: types.optional(types.number, 0.1),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
    strategy: types.optional(
      types.enumeration(['risk_reward', 'entry']),
      'entry',
    ),
    tradeSection: types.maybe(types.reference(TradeSectionStore)),
  })
  .actions(withSetPropAction)
  .views((self) => {
    return {
      get config_fields() {
        return config_fields;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let result = getParamForField(self, configs, field, isGroup);
        if (self.tradeSection) {
          if (['entry', 'stop'].includes(field)) {
            let value = self.tradeSection.getTradeZones();
            result = {
              ...result,
              kind: 'select',
              options: value.map((o: any) => ({
                label: field === 'entry' ? o.entry : o.stop,
                value: field === 'entry' ? o.entry : o.stop,
              })),
            };
          }
        }
        return result;
      },
    };
  })
  .actions((self) => {
    return {
      updateFields(obj: any) {
        if (obj.resistance) {
          obj.resistance = parseFloat(obj.resistance);
        }
        if (obj.support) {
          obj.support = parseFloat(obj.support);
        }
        if (obj.risk) {
          obj.risk = parseFloat(obj.risk);
        }
        if (obj.entry) {
          obj.entry = parseFloat(obj.entry);
        }
        if (obj.stop) {
          obj.stop = parseFloat(obj.stop);
        }
        applySnapshot(self, { ...getSnapshot(self), ...obj });
        self.tradeSection?.updateConfig(obj);
      },
      onCreateSection(obj: any) {
        self.stop = 0;
      },
    };
  });

//   export const Exchanges = types.model({
// exchange:
// Orders.
//   })
export interface ZoneConfig extends Instance<typeof MobileTradeEditSection> {}
