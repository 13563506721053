import {
  Box,
  Badge,
  Circle,
  Flex,
  Heading,
  HStack,
  Progress,
  Text,
  useColorModeValue as mode,
  Button,
} from '@chakra-ui/react';
import * as React from 'react';
import { FiArrowDownLeft, FiArrowUpRight } from 'react-icons/fi';
import Icon from '../../components/main/Icon';
import { determine_pnl } from '../../../store/trade_signal';
import { to_f } from '../../../store/utils';
import { observer } from 'mobx-react-lite';
import { SelectElement } from '../../../currentBot/components/FormElement';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { NewEntryControl } from './NewEntryControl';
import { IEntryStore } from '../../../store/solvedConfig/types';

interface IndicatorProps {
  type: 'up' | 'down';
}

const types = {
  up: { icon: FiArrowUpRight, color: 'green.500' },
  down: { icon: FiArrowDownLeft, color: 'red.500' },
};

const SymbolBadgeEnum: Record<string, string> = {
  long: 'green',
  short: 'red',
};

export const Indicator = (props: IndicatorProps) => {
  const { type } = props;
  const { color, icon } = types[type];
  return <Box as={icon} fontSize="lg" color={color} />;
};

export interface FutureTradeStatProps {
  data: {
    kind: 'long' | 'short';
    symbol: string;
    entry: {
      price: number;
      quantity: number;
    };
    stop: number;
    take_profit: number;
    max_size: number;
    store: IEntryStore;
  };
}

function format(value: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
  }).format(value);
}
export const FutureTradeStat: React.FC<FutureTradeStatProps> = observer(
  (props) => {
    const { data } = props;
    const { kind, entry, stop, take_profit, max_size, symbol, store } = data;

    const isUsdt = symbol.toLowerCase().includes('usdt');
    const quote = isUsdt ? 'usdt' : 'busd';
    const base = symbol.toLowerCase().replace(quote, '');
    const info = entry ? `${entry.quantity} / ${max_size}` : null;
    const currency = '$';
    const value = entry ? entry.price : 0;
    function determinePnl(price: number) {
      let quantity = entry.quantity;
      if (kind === 'long' && price < entry.price) {
        quantity = max_size;
      }
      if (kind === 'short' && price > entry.price) {
        quantity = max_size;
      }
      const _val = determine_pnl(entry.price, price, quantity, kind);
      return to_f(_val, '%.2f');
    }
    return (
      <>
        {/* <Box>hiihi</Box> */}
        <Flex
          bg={mode('white', 'gray.700')}
          shadow="base"
          rounded="lg"
          overflow="hidden"
          flexDirection="column"
        >
          <Flex px="6" py="4" flexDirection="column" flex="1">
            <Box>
              <Flex
                justify="space-between"
                align="center"
                fontWeight="medium"
                fontSize="sm"
              >
                <HStack>
                  <Circle color="white" rounded="full" size="6">
                    <Icon name={base.toLowerCase()} />
                  </Circle>
                  <Text
                    fontWeight="medium"
                    color={mode('gray.500', 'gray.400')}
                  >
                    {symbol}
                  </Text>
                </HStack>
                {store.entry ? (
                  <Text color="gray.400" fontWeight={'bold'}>
                    Ref.entry: {store.entry}
                  </Text>
                ) : null}
                {info ? <Text color="gray.400">{info}</Text> : null}
              </Flex>

              {value ? (
                <Heading as="h4" size="lg" my="3" fontWeight="extrabold">
                  {currency}
                  {format(value)}
                </Heading>
              ) : (
                <NewEntryControl store={store} />
              )}
            </Box>

            <Flex
              justify="space-between"
              align="center"
              fontWeight="medium"
              fontSize="sm"
              marginTop="auto"
            >
              {stop && (
                <HStack spacing="0" color={mode('gray.500', 'gray.400')}>
                  <Indicator type={'down'} />
                  <Text>
                    {format(stop)} ({determinePnl(stop)})
                  </Text>
                </HStack>
              )}
              {take_profit && (
                <HStack spacing="0" color={mode('gray.500', 'gray.400')}>
                  <Indicator type={'up'} />
                  <Text>
                    {format(take_profit)} ({determinePnl(take_profit)})
                  </Text>
                </HStack>
              )}
              <Badge colorScheme={SymbolBadgeEnum[kind.toLowerCase()]}>
                {kind.toUpperCase()}
              </Badge>
            </Flex>
          </Flex>
          {entry && max_size ? (
            <Progress
              marginTop="auto"
              aria-labelledby={'id'}
              value={entry.quantity}
              max={max_size}
              min={0}
              size="xs"
              colorScheme="blue"
            />
          ) : null}
        </Flex>
      </>
    );
  },
);

interface CollateralStatProps {
  data: {
    balance: string;
    pnl?: number;
    current_price?: number;
    liquidation?: number;
    additionalText?: string;
    label?: string;
  };
}

export const CollateralStat: React.FC<CollateralStatProps> = observer(
  (props) => {
    const {
      balance,
      pnl,
      current_price,
      liquidation,
      additionalText,
      label = 'Collateral',
    } = props.data;

    return (
      <Box
        px="6"
        py="4"
        bg={mode('white', 'gray.700')}
        borderRadius="8px"
        boxShadow="md"
        color={mode('gray.800', 'white')}
      >
        <Flex
          justify="space-between"
          align="center"
          fontWeight="medium"
          fontSize="sm"
        >
          <Text
            fontWeight="medium"
            color={mode('gray.500', 'gray.400')}
            fontSize="sm"
          >
            {label}
          </Text>
          {pnl && <Badge colorScheme="green">{`Pnl: ${pnl}`}</Badge>}
        </Flex>
        <HStack spacing="4" mt="2">
          <Heading as="h4" size="xl" lineHeight="1" letterSpacing="tight">
            {balance}
          </Heading>
        </HStack>
        {(liquidation || current_price) && (
          <Flex
            justify="space-between"
            align="center"
            fontWeight="medium"
            fontSize="sm"
          >
            <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
              Liquidation: {liquidation}
            </Text>
            {current_price && (
              <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
                Current Price: {current_price}
              </Text>
            )}
          </Flex>
        )}
        {additionalText && (
          <Flex
            justify="space-between"
            align="center"
            fontWeight="medium"
            fontSize="sm"
          >
            <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
              {additionalText}
            </Text>
          </Flex>
        )}
      </Box>
    );
  },
);

interface AccountTradeStatProps {
  data: {
    balance: string;
    current_price?: number;
    liquidation?: number;
    additionalText?: string;
    label?: string;
    kind: 'long' | 'short';
  };
}

export const AccountTradeStat: React.FC<AccountTradeStatProps> = observer(
  (props) => {
    const {
      balance,
      current_price,
      liquidation,
      additionalText,
      label,
      kind,
    } = props.data;

    return (
      <Box
        px="6"
        py="4"
        bg={mode('white', 'gray.700')}
        borderRadius="8px"
        boxShadow="md"
        color={mode('gray.800', 'white')}
      >
        <Flex
          justify="space-between"
          align="center"
          fontWeight="medium"
          fontSize="sm"
        >
          <Text
            fontWeight="medium"
            color={mode('gray.500', 'gray.400')}
            fontSize="sm"
          >
            {label}
          </Text>
          {kind && (
            <Badge
              colorScheme={kind === 'long' ? 'green' : 'red'}
            >{`${kind}`}</Badge>
          )}
        </Flex>
        <HStack spacing="4" mt="2">
          <Heading as="h4" size="xl" lineHeight="1" letterSpacing="tight">
            {balance}
          </Heading>
        </HStack>
        {(liquidation || current_price) && (
          <Flex
            justify="space-between"
            align="center"
            fontWeight="medium"
            fontSize="sm"
          >
            <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
              Liquidation: {liquidation}
            </Text>
            {current_price && (
              <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
                Current Price: {current_price}
              </Text>
            )}
          </Flex>
        )}
        {additionalText && (
          <Flex
            justify="space-between"
            align="center"
            fontWeight="medium"
            fontSize="sm"
          >
            <Text fontSize="sm" mt="4" color={mode('gray.500', 'gray.400')}>
              {additionalText}
            </Text>
          </Flex>
        )}
      </Box>
    );
  },
);
