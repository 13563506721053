import {
  Box,
  Flex,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

interface StatCardProps {
  label: string;
  value: string;
}
export const StatWithScore: React.FC<{ data: StatCardProps }> = observer(
  (props) => {
    const { label, value } = props.data;
    const score = parseFloat(value);
    let scoreStatus;
    let color;

    if (score > 2) {
      scoreStatus = 'Good';
      color = 'green.500';
    } else if (score > 1.3 && score <= 2) {
      scoreStatus = 'Fair';
      color = 'yellow.500';
    } else {
      scoreStatus = 'Margin Call';
      color = 'red.500';
    }
    return (
      <Stat
        px={{ base: 4, sm: 6 }}
        py="5"
        bg={mode('white', 'gray.700')}
        shadow="base"
        rounded="lg"
      >
        <StatLabel
          fontWeight="medium"
          isTruncated
          color={mode('gray.500', 'gray.400')}
          position="relative"
        >
          {label}
          <Text
            position="absolute"
            right="0"
            top="0"
            fontSize="15"
            color={color}
          >
            {scoreStatus}
          </Text>
        </StatLabel>
        <Box textAlign="center">
          <CircularProgress value={100} color={color}>
            <CircularProgressLabel>{score}</CircularProgressLabel>
          </CircularProgress>
          <Flex color="white" mt="5">
            <Box height="1" flex="1" bg="red.500"></Box>
            <Box height="1" flex="1" bg="yellow.500"></Box>
            <Box height="1" flex="1" bg="green.500"></Box>
          </Flex>
        </Box>
      </Stat>
    );
  },
);
