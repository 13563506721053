import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import type { FunctionComponent } from 'react';

export const SelectElement: FunctionComponent<{
  label?: string;
  labelProps?: any;
  controlProps?: any;
  selectProps?: any;
  onChange?: (e: any) => any;
  value?: any;
  options?: any[];
  defaultText?: string;
  valueLayout?: (e: any) => any;
  optionLayout?: (e: any) => any;
}> = observer(
  ({
    controlProps = {},
    labelProps = {},
    selectProps = {},
    label,
    onChange,
    value,
    options = [],
    defaultText,
    valueLayout = (u: any) => u,
    optionLayout = (u: any) => u,
  }) => {
    return (
      <FormControl {...controlProps}>
        {label ? <FormLabel {...labelProps}>{label}</FormLabel> : null}
        <Select onChange={onChange} value={value} {...selectProps}>
          {defaultText && <option>{defaultText}</option>}
          {options.map((u: any) => {
            return <option value={optionLayout(u)}>{valueLayout(u)}</option>;
          })}
        </Select>
      </FormControl>
    );
  },
);

export const InputElement: FunctionComponent<{
  label?: string;
  labelProps?: any;
  controlProps?: any;
  [key: string]: any;
}> = observer(({ label, controlProps = {}, labelProps = {}, ...rest }) => {
  return (
    <FormControl {...controlProps}>
      <FormLabel {...labelProps}>{label}</FormLabel>
      <Input {...rest} />
    </FormControl>
  );
});
export const InputElementEnhanced: FunctionComponent<{
  label?: string;
  controlProps?: any;
  [key: string]: any;
}> = observer(({ label, controlProps = {}, ...rest }) => {
  let { defaultValue, value: oldValue, onChange, ...props } = rest;
  let [value, setValue] = useState(defaultValue || oldValue);
  useEffect(() => {
    onChange({ target: { value } });
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return (
    <FormControl {...controlProps}>
      <FormLabel>{label}</FormLabel>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        {...props}
      />
    </FormControl>
  );
});

export const CheckboxGroupElement: FunctionComponent<{
  [key: string]: any;
  options: Array<{ value: any; label: any }>;
}> = observer(({ options, ...rest }) => {
  return (
    <CheckboxGroup {...rest}>
      <Flex alignSelf="end" direction="column">
        {options.map((o) => (
          <Checkbox key={o.value} value={o.value}>
            {o.label}
          </Checkbox>
        ))}
      </Flex>
    </CheckboxGroup>
  );
});

export const RadioElement: FunctionComponent<{
  controlProps?: any;
  label?: string;
  labelProps?: any;
  onChange?: (val: any) => any;
  value?: any;
  options?: Array<{ label: any; value: any }>;
  radioProps?: any;
}> = observer(
  ({
    radioProps = {},
    controlProps = {},
    label,
    labelProps = {},
    onChange,
    value,
    options = [],
  }) => {
    return (
      <FormControl {...controlProps}>
        <FormLabel {...labelProps}>{label}</FormLabel>
        <RadioGroup onChange={onChange} value={value}>
          <Stack direction="row">
            {options.map((i, u) => (
              <Radio value={i.value.toString()} {...radioProps}>
                {i.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </FormControl>
    );
  },
);
