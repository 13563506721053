import { Button } from '@chakra-ui/button';
import { Box, BoxProps, Flex } from '@chakra-ui/layout';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/modal';
import * as React from 'react';

interface BaseModalProps extends BoxProps {
  title?: string;
  buttonText?: string;
  isOpen: boolean;
  market?: 'future' | 'margin';
  rightElement?: any;
  onSave?: () => void;
  onClose: () => void;
  [key: string]: any;
  removeCloseButton?: boolean;
}

export function BaseModal(props: BaseModalProps) {
  const {
    isOpen,
    onClose,
    onSave,
    removeCloseButton,
    title,
    children,
    buttonText,
    modalProps = {},
    ...rest
  } = props;

  return (
    <Modal isOpen={isOpen} size="xl" onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>
            <Box flex="1">{title}</Box>
            {rest.rightElement}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        {buttonText && <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onSave}>
            {buttonText}
          </Button>
          {!removeCloseButton ? (
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          ) : null}
        </ModalFooter>}
      </ModalContent>
    </Modal>
  );
}

export const BaseDrawer: React.FC<{
  isOpen?: any;
  onClose?: any;
  btnRef?: any;
  title?: string;
  size?: string;
  button?: any;
  displayCancelButton?: boolean;
  displayTextButton?: boolean;
  buttonText?: string;
  [key: string]: any;
  onSave?: any;
}> = ({
  size = 'sm',
  title,
  isOpen,
  onClose,
  button,
  btnRef,
  onSave,
  children,
  displayCancelButton = false,
  displayTextButton = true,
  buttonText = 'Save',
  footerProps = {},
  buttonProps = {},
  ...rest
}) => {
  return (
    <>
      {button}
      <Drawer
        size={size}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        {...rest}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader textTransform="capitalize">{title}</DrawerHeader>

            <DrawerBody>{children}</DrawerBody>

            <DrawerFooter {...footerProps}>
              {displayCancelButton ? (
                <Button variant="outline" mr={3} onClick={onClose}>
                  Cancel
                </Button>
              ) : null}
              {displayTextButton ? (
                <Button onClick={onSave} color="blue" {...buttonProps}>
                  {buttonText}
                </Button>
              ) : null}
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

export function ConfirmDialog({
  button,
  heading,
  text = "Are you sure? You can't undo this action afterwards.",
  confirmAction,
  loading = false,
  actionText = 'Delete',
  colorScheme = 'red',
}: {
  button: any;
  heading?: string;
  text?: string;
  loading?: boolean;
  confirmAction: () => any;
  actionText?: string;
  colorScheme?: string;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef: any = React.useRef();

  return (
    <>
      {React.cloneElement(button, { onClick: () => setIsOpen(true) })}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {heading}
            </AlertDialogHeader>

            <AlertDialogBody>{text}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={colorScheme}
                isLoading={loading}
                loadingText="deleting"
                onClick={() => {
                  confirmAction();
                  onClose();
                }}
                ml={3}
              >
                {actionText}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
