import { Box, Flex, Heading, useBreakpointValue } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { IFutureInstance } from '../../../store/futureConfig';
import { TradeDisplay } from './components/TradeDisplay';
import { Footer } from './components/TradeFooter';
import { Layout } from './components/TradeLayout';
import { TradeView } from './components/TradeView';
import { TradingCrumb } from './components/tradingBreadCrump';
import { MobileMenu } from './components/TradingMenu';
import { useTradingView } from './hooks';
import { TradeProps } from './types';

export const TradingView: React.FC<{
  store: IFutureInstance;
}> = observer(({ store }) => {
  const {
    onTradeClick,
    handleBackButton,
    currentTrade,
    orderKind,
    buildTrades,
    onReset,
    resetConfig, // to reset the config when setttings button is clicked
    priceArray,
    isClickable,
  } = useTradingView(store);

  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [tradeConfig, setTradeConfig] = React.useState(true);
  const handleSetConfig = () => {
    setTradeConfig(true);
    resetConfig();
  };
  const showTrade = () => {
    setTradeConfig(false);
    onReset();
  };

  const trades = buildTrades('side');

  const options = trades.map((x) => x.entry);

  const onClick = (entry: number) => {
    const index = options.findIndex((x: number) => x === entry);
    if (index > -1) {
      onTradeClick(trades[index], index);
    }
  };

  const currentTrades = buildTrades('side');
  const alternateTrades = buildTrades('main');

  return (
    <Layout store={store} showTrade={showTrade} tradeConfig={tradeConfig}>
      {currentTrade && (
        <TradingCrumb
          config={handleSetConfig}
          priceArray={priceArray}
          back={handleBackButton}
        />
      )}
      <Flex direction={'column'}>
        {currentTrade && isMobile ? (
          <MobileMenu
            currentTrade={currentTrade}
            onClick={(entry) => onClick(entry)}
            options={options}
          />
        ) : (
          <Box textAlign={'center'} mx={20} my={5}>
            {currentTrades.map((trade: TradeProps, index: number) => (
              <TradeView
                isMenu
                currentTrade={currentTrade}
                trade={trade}
                key={index}
                onClick={() => {
                  onTradeClick(trade, index);
                }}
              />
            ))}
          </Box>
        )}

        <Flex direction="column">
          {currentTrade && (
            <>
              <TradeDisplay trade={currentTrade} newInfo={alternateTrades[0]} />
              <Heading
                size="lg"
                fontWeight="extrabold"
                my="1"
                textAlign={'center'}
              >
                {orderKind === 'long' ? 'Long Trade' : 'Short Trade'}
              </Heading>
              <Box
                borderWidth="3px"
                borderStyle="dashed"
                rounded="xl"
                textAlign="center"
                w={['100%', '80%', '80%']}
                ml={['', '80px', '80px']}
                mt={['', '50px', 5]}
              >
                <Box>
                  {alternateTrades.map((trade: TradeProps, index: number) => (
                    <TradeView
                      isClickable={isClickable}
                      currentTrade={currentTrade}
                      trade={trade}
                      key={index}
                      onClick={() =>
                        onTradeClick(trade, index, alternateTrades.length - 1)
                      }
                    />
                  ))}
                </Box>

                <Footer
                  onUpdateProfit={() => console.log('update')}
                  onCancelTrade={() => console.log('cancel')}
                  onPlaceTrade={() => console.log('place')}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Layout>
  );
});
