import { Box, Text } from '@chakra-ui/react';
import { ButtonCollection } from './ButtonCollection';

type ConfirmationMessageProps = {
  text: string;
  hasBorder?: boolean;
};

export const ConfirmationMessage = ({
  text,
  hasBorder,
}: ConfirmationMessageProps) => {
  return (
    <>
      <Box
        mt={10}
        borderRadius="10px"
        p="5px"
        border={hasBorder ? ' 1px dashed #000' : undefined}
      >
        <Text fontSize={'16px'} lineHeight={'27px'}>
          {text}
        </Text>
      </Box>
    </>
  );
};

export const ConfirmationDetails = ({
  onClick,
  text,
  buttonLabel = 'Confirm Trade',
  loading= false,
}: {
  onClick: () => void;
  text: string;
  buttonLabel?: string;
  loading?:boolean;
}) => {
  return (
    <>
      <ConfirmationMessage text={text} />
      <ButtonCollection
        actions={[
          {
            label: buttonLabel,
            action: onClick,
            loading: loading,
          },
        ]}
        direction={'column'}
      />
    </>
  );
};
