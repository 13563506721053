import {
  Box,
  Container,
  Flex,
  Heading,
  HStack,
  Stack,
  Center,
  CenterProps,
  useBoolean,
  useBreakpointValue,
  Avatar,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  UseMenuButtonProps,
  useColorModeValue as mode,
  Icon,
} from '@chakra-ui/react';
import * as React from 'react';
import {
  HiDuplicate,
  HiMail,
  HiOutlineMenu,
  HiRefresh,
  HiTemplate,
  HiViewGrid,
  HiX,
} from 'react-icons/hi';
import { FaBell } from 'react-icons/fa';
import { chakra, HTMLChakraProps, useToken } from '@chakra-ui/react';
import { useNavigationHook } from '../../currentBot/hooks';
import { TabLink } from './primitives/TabLink';
import CIcon from '../components/main/Icon';
import { observer } from 'mobx-react-lite';

export const Logo = observer(
  (props: HTMLChakraProps<'svg'> & { iconColor?: string; coin: string }) => {
    const { iconColor = 'currentColor', coin, ...rest }: any = props;
    const color = useToken('colors', iconColor);
    return (
      <HStack spacing={4} {...rest}>
        <Icon as={CIcon} boxSize="20px" name={coin.toLowerCase()} />
        <Text as="span">{coin.toUpperCase()}</Text>
      </HStack>
    );
  },
);

interface NavItemProps {
  href?: string;
  active?: boolean;
  label: string;
  onClick?: any;
}

interface DesktopNavItemProps extends NavItemProps {
  icon: React.ReactNode;
}

const DesktopNavItem = (props: DesktopNavItemProps) => {
  const { icon, label, href = '#', active, onClick } = props;
  return (
    <HStack
      as="a"
      // href={href}
      onClick={onClick}
      aria-current={active ? 'page' : undefined}
      spacing="2"
      px="3"
      py="2"
      rounded="md"
      transition="all 0.2s"
      color="gray.200"
      _hover={{ bg: 'whiteAlpha.200', cursor: 'pointer' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
    >
      {icon && (
        <Box aria-hidden fontSize="md">
          {icon}
        </Box>
      )}
      <Box fontWeight="semibold">{label}</Box>
    </HStack>
  );
};

const MobileNavItem = (props: NavItemProps) => {
  const { label, href = '#', active } = props;
  return (
    <Box
      as="a"
      display="block"
      href={href}
      px="3"
      py="3"
      rounded="md"
      fontWeight="semibold"
      aria-current={active ? 'page' : undefined}
      _hover={{ bg: 'whiteAlpha.200' }}
      _activeLink={{ bg: 'blackAlpha.300', color: 'white' }}
    >
      {label}
    </Box>
  );
};

export const NavItem = {
  Desktop: DesktopNavItem,
  Mobile: MobileNavItem,
};

const NotificationBadge = (props: CenterProps) => (
  <Center
    bg="red.500"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="full"
    textAlign="center"
    top="-2px"
    insetEnd="0"
    w="18px"
    h="18px"
    {...props}
  />
);

export const Notification = (props: CenterProps) => (
  <Center
    as="button"
    outline="0"
    w="8"
    h="8"
    position="relative"
    rounded="md"
    _hover={{ bg: 'whiteAlpha.200' }}
    _focus={{ shadow: 'outline' }}
    {...props}
  >
    <Box srOnly>Click to see 9 notifications</Box>
    <NotificationBadge>9</NotificationBadge>
    <Box as={FaBell} fontSize="lg" />
  </Center>
);

const UserAvatar = () => (
  <Avatar
    size="sm"
    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
    name="Manny Brooke"
  />
);

const ProfileMenuButton = (props: UseMenuButtonProps) => {
  const buttonProps = useMenuButton(props);
  return (
    <Flex
      {...buttonProps}
      as="button"
      flexShrink={0}
      rounded="full"
      outline="0"
      _focus={{ shadow: 'outline' }}
    >
      <Box srOnly>Open user menu</Box>
      <UserAvatar />
    </Flex>
  );
};

export const ProfileDropdown = () => (
  <Menu>
    <ProfileMenuButton />
    <MenuList
      rounded="md"
      shadow="lg"
      py="1"
      color={mode('gray.600', 'inherit')}
      fontSize="sm"
    >
      <HStack px="3" py="4">
        <UserAvatar />
        <Box lineHeight="1">
          <Text fontWeight="semibold">Manny Broke</Text>
          <Text mt="1" fontSize="xs" color="gray.500">
            manny@chakra-ui.com
          </Text>
        </Box>
      </HStack>
      <MenuItem fontWeight="medium">Your Profile</MenuItem>
      <MenuItem fontWeight="medium">Feedback & Support</MenuItem>
      <MenuItem fontWeight="medium">Account Settings</MenuItem>
      <MenuItem fontWeight="medium" color={mode('red.500', 'red.300')}>
        Sign out
      </MenuItem>
    </MenuList>
  </Menu>
);

type AppTabProps = {
  currentPage: string;
  onTabClick: (p?: any) => void;
  toggleSidebar: any;
  isSidebarOpen?: boolean;
  openMobileMarketList?: () => void;
  coin: string;
  desktopTabs: Array<{ title: string; icon?: any }>;
};
export const ApplicationShell: React.FC<AppTabProps> = ({
  currentPage,
  onTabClick,
  toggleSidebar,
  isSidebarOpen,
  children,
  coin,
  desktopTabs,
  openMobileMarketList,
  ...rest
}) => {
  const { location } = useNavigationHook();
  return (
    <Flex
      direction="column"
      bg={mode('gray.100', 'gray.800')}
      height={{ base: '105vh', xs: '120vh' }}
      width="full"
      {...rest}
    >
      <Flex align="center" bg="blue.600" color="white" px="6" h="16">
        <Flex justify="space-between" align="center" w="full">
          {/* Mobile Market List Hamburger  Menu */}
          {location.pathname.includes('home') && <Box ms="-4" display={{ xl: 'none' }}>
            <Box as="button" onClick={openMobileMarketList} p="2" fontSize="xl">
              <Box
                aria-hidden
                as={HiOutlineMenu}
              />
              <Box
                  srOnly
              >Open menu</Box>
            </Box>
          </Box>}

          {/* Desktop Logo placement */}
          <Logo
            coin={coin}
            display={{ base: 'none', lg: 'flex', md: 'flex' }}
            flexShrink={0}
            h="5"
            marginEnd="10"
          />

          {/* Desktop Navigation Menu */}
          <HStack
            spacing="3"
            flex="1"
            display={{ base: 'none', lg: 'flex', md: 'flex' }}
          >
            {desktopTabs.map((dTab, i) => {
              return (
                <NavItem.Desktop
                  onClick={() => onTabClick(dTab.title)}
                  key={`${dTab.title}-${i}`}
                  active={dTab.title === currentPage}
                  icon={dTab.icon}
                  label={dTab.title}
                />
              );
            })}
          </HStack>

          {/* Mobile Logo placement */}
          <Logo
            coin={coin}
            flex={{ base: '1', lg: '0', md: '0' }}
            display={{ base: 'flex', lg: 'none', md: 'none' }}
            flexShrink={0}
            onClick={toggleSidebar}
            h="5"
          />

          <HStack spacing="3">
            {/* <Notification
              display={{ base: 'none', md: 'inline-flex', lg: 'inline-flex' }}
            />
            <ProfileDropdown /> */}
            <Box
              me="-4"
              display={{ xl: 'none' }}
            >
              <Box as="button" onClick={toggleSidebar} p="2" fontSize="xl">
                <Box aria-hidden as={isSidebarOpen ? HiX : HiOutlineMenu} />
                <Box srOnly>{isSidebarOpen ? 'Close menu' : 'Open menu'}</Box>
              </Box>
            </Box>
          </HStack>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

export default observer(ApplicationShell);
