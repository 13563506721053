import { Button, ButtonGroup, IconButton } from '@chakra-ui/button';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';
import { useBreakpoint, useBreakpointValue, useMediaQuery } from '@chakra-ui/media-query';
import type { FlexboxProps } from '@chakra-ui/styled-system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import {
  HiOutlineDuplicate,
  HiOutlineEye,
  HiOutlineInformationCircle,
  HiOutlinePlay,
  HiOutlineSwitchHorizontal,
  HiOutlineTrash
} from 'react-icons/hi';
import { GrChapterAdd } from 'react-icons/gr';
import type { IProfileConfig } from '../../store/profileConfig';
import type { IJobStore } from '../../store/jobConfig';
import { ConfirmDialog } from './primitives/ModalComponent';

interface ProfileProps extends FlexboxProps {
  profile: IJobStore | any;
  func: any;
  big?: boolean;
  actions: Array<{
    type: string;
    color: string;
    label: string;
    confirm?: boolean;
    full?: string;
    loading?: boolean;
  }>;
  loading?: boolean;
  refresh_loading?: boolean;
  account_loading?: boolean;
  app_loading?: boolean;
  [key: string]: any;
}

const IconText = (text: string) => (
  <Text fontSize="md" textAlign="center" mt="1">
    {text}
  </Text>
);
function SpecialButton({ label, icon, fullText, big = false, ...props }: any) {
  // const [isLesserThan700] = useMediaQuery('(max-width: 650px)');
  const isMobile = useBreakpointValue({ base: true, md: false });
  // let condition = big ? false : isLesserThan700;
  return isMobile ? (
    // <Button size="xs" leftIcon={icon} {...props}>
    //   {label}
    // </Button>
    <Box>
      <IconButton aria-label={label} title={label} icon={icon} {...props}/>
      {IconText(label)}
    </Box>
  ) : (
    <Button leftIcon={icon} {...props}>
      {fullText || label}
    </Button>
  );
}
export const ProfileItem = observer(
  ({ profile, func, actions, big = false, ...props }: ProfileProps) => {
    const iconSize: any = 'md';
    // const iconSize: any = isLesserThan700 ? 'xs' : 'md';
    const icons: any = {
      edit: <EditIcon />,
      duplicate: <HiOutlineDuplicate />,
      run: <HiOutlinePlay />,
      close: <DeleteIcon />,
      info: <HiOutlineInformationCircle />,
      view: <HiOutlineEye />,
      create: <GrChapterAdd />,
      transfer:<HiOutlineSwitchHorizontal/>,
      delete:<HiOutlineTrash/>
    };
    return (
      // <Flex
      //   direction={isMobile ? 'column' : 'column'}
      //   justifyContent={['initial', 'space-between']}
      //   flexFlow={['row wrap', 'initial']}
      //   // border={'1px solid black'}
      //   padding={1}
      //   {...props}
      //   mb="1"
      // >
      <Stack
// spacing={3}
        direction={[ 'column', 'row', 'row']}
        justifyContent={['initial', 'space-between']}
        flexFlow={['row wrap', 'initial']}
        padding={1}
        {...props}
        mb="1"
      >
        <Text fontWeight={"bold"} alignSelf="center" fontSize={'md'} {...(profile.style || {})}>
          {profile.name}
        </Text>
        <ButtonGroup variant="outline" spacing={[5, 6, 3]}>
          {actions.map((action) => {
            if (action.confirm) {
              return (
                <ConfirmDialog
                  key={`${action.type}`}
                  heading={`${action.label} ${profile.name}`}
                  confirmAction={func[action.type]}
                  actionText={`${action.label} ${profile.name}`}
                  button={
                    <SpecialButton
                      big={big}
                      colorScheme={action.color}
                      label={action.label}
                      isLoading={action.loading}
                      icon={icons[action.type]}
                      fullText={action.full}
                      size={iconSize}
                    />
                  }
                />
              );
            }
            return (
              <SpecialButton
                fullText={action.full}
                label={action.label}
                colorScheme={action.color}
                icon={icons[action.type]}
                isLoading={action.loading}
                big={big}
                onClick={func[action.type]}
                size={iconSize}
              />
            );
          })}
        </ButtonGroup>
      </Stack>
    );
  },
);

const ProfileList: React.FC<{
  profiles: IProfileConfig[] | any[];
  onEdit: (profile: any) => any;
  onDelete?: (profile: any) => any;
  onDuplicate: (profile: any) => any;
  onRun: (profile: any, resume?: boolean) => any;
  onClose?: (profile?: any) => any;
  onStatus?: (profile?: any) => any;
  onViewJobs?: () => any;
  loading?: boolean;
  type?: 'regular' | 'coin' | 'usd';
  defaultProfile?: any;
  refresh_loading?: boolean;
  account_loading?: boolean;
  config_loading?: boolean;
  app_loading?: boolean;
}> = ({
  profiles,
  onEdit,
  onDuplicate,
  onRun,
  onClose,
  onStatus,
  onViewJobs,
  onDelete,
  loading = false,
  account_loading = false,
  config_loading = false,
  type = 'regular',
  defaultProfile,
}) => {
  let firstProfile = defaultProfile || profiles[0];
  const func: any = {
    edit: () => onEdit(firstProfile),
    close: () => onClose && onClose(firstProfile),
    run: () => onRun(firstProfile, true),
    info: () => onStatus && onStatus(firstProfile),
    view: () => onViewJobs && onViewJobs(),
  };

  return (
    <Flex direction="column">
      {type !== 'regular' && firstProfile ? (
        <>
          <ProfileItem
            mb={2}
            func={func}
            actions={[
              { type: 'edit', color: 'blue', label: 'Edit' },
              {
                type: 'run',
                color: 'green',
                confirm: true,
                label: 'Run',
                loading: firstProfile.loading,
              },
              {
                type: 'close',
                color: 'red',
                confirm: true,
                label: 'Close',
                full: 'Close position',
                loading: account_loading,
              },
              {
                type: 'info',
                color: 'purple',
                label: 'Info',
                loading: account_loading,
              },
              { type: 'view', color: 'teal', label: 'View', full: 'View jobs' },
            ]}
            key={firstProfile.id}
            profile={firstProfile}
            loading={loading}
          />
        </>
      ) : (
        <>
          {profiles.map((profile: any, index: number) => {
            let func2: any = {
              edit: () => onEdit(profile),
              duplicate: () => onDuplicate(profile),
              run: () => onRun(profile),
              delete: () => onDelete && onDelete(profile),
            };
            return (
              <ProfileItem
                mb={2}
                func={func2}
                actions={[
                  { type: 'edit', color: 'blue', label: 'Edit' },
                  {
                    type: 'duplicate',
                    color: 'teal',
                    label: 'Duplicate',
                    loading: config_loading,
                  },
                  {
                    type: 'run',
                    color: 'green',
                    confirm: true,
                    label: 'Run',
                    loading: profile.loading,
                  },
                  {
                    type: 'delete',
                    color: 'red',
                    confirm: true,
                    label: 'Delete',
                    loading: config_loading,
                  },
                ]}
                key={`${profile.name}-${index}`}
                profile={profile}
                // {...{ refresh_loading, account_loading, app_loading }}
                big
              />
            );
          })}
        </>
      )}
    </Flex>
  );
};

export default observer(ProfileList);
