import { TransferData } from '../mobileAppView/stores';
import { AccountParser } from './accountParser';
import RemoteAction from './remoteAction';

import type { TradeType, StopLossType } from './types';
import { getOptimumReward, getOptimumRisk, getOptimumStop } from './workers';
interface Window {
  HOST_URL: any;
}
declare let window: Window;
const ROOT = typeof window !== 'undefined' ? window.HOST_URL || '' : '';
const BASE_URL = ROOT + '/ft/main_account';
async function getFetcher(path: string) {
  try {
    let response = await fetch(`${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status < 400) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
  return;
}
async function postFetcher(path: string, body: any) {
  try {
    let response = await fetch(`${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status < 400) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
  return;
}
async function fetcher(path: string, body: any, rootPath = '/ft/main_account') {
  try {
    let response = await fetch(`${ROOT + rootPath}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (response.status < 400) {
      return await response.json();
    }
  } catch (error) {
    throw error;
  }
  return;
}
async function bulkTrades(owner: string, symbol: string, trades: any[]) {
  try {
    let result = await fetcher(
      `bulk-margin-future-orders`,
      {
        symbol,
        future_orders: trades,
        margin_orders: [],
      },
      `/ft/${owner}`,
    );
    return result;
  } catch (error) {
    throw error;
  }
}
async function increasePosition({ entry, kind, quantity, symbol }: any) {
  try {
    let result = await fetcher(`simple-order`, {
      symbol,
      kind,
      entry,
      quantity,
      side: kind == 'long' ? 'buy' : 'sell',
    });
    let position = result;
    let openTrade = {
      symbol: position.symbol,
      entryPrice: position.entryPrice,
      kind: kind,

      quantity: Math.abs(position.positionAmt),
    };
    return openTrade;
  } catch (error) {
    throw error;
  }
}
type SwingInfo = {
  timeFrame: string;
  symbol: string;
  count?: number;
  kind?: string;
};
async function getSwingInfo({
  timeFrame,
  symbol,
  count = 500,
  kind = 'future',
}: SwingInfo) {
  let _count = 500;
  if (timeFrame.includes('minute')) {
    _count = 300;
  }
  return {
    highs: [],
    lows: [],
  };
  try {
    let result = await fetcher(`swing-high-low`, {
      symbol,
      chart_type: timeFrame,
      kind,
      count: _count,
    });
    return result;
  } catch (error) {
    throw error;
  }
}
async function loadInitialData(
  symbol: string,
  kind?: 'long' | 'short',
  rootPath = '/ft/main_account',
) {
  try {
    let result = {
      pip: 0,
      balance: 0,
      places: 2,
      positions: [],
      stop_orders: [],
    };
    // would enable later
    // let result = await fetcher(
    //   `get-account-information`,
    //   {
    //     symbol,
    //     kind,
    //     raw: true,
    //   },
    //   rootPath,
    // );
    let { pip, balance, positions, places, stop_orders } = result as any;
    return {
      pip,
      balance,
      places,
      positions: positions.map((o: any) => ({
        symbol: o.symbol,
        entryPrice: o.entryPrice,
        kind: o.positionSide?.toLowerCase(),
        quantity: Math.abs(o.positionAmt || 0),
        leverage: o.leverage,
        maximumLeverage: o.maximumLeverage,
      })),
      stop_orders: {
        long: stop_orders?.long,
        short: stop_orders?.short,
      },
    };
  } catch (error) {
    throw error;
  }
}
async function createNewTrade({
  symbol,
  entry,
  quantity,
  stop,
  kind,
  isMarketTrade,
}: TradeType) {
  try {
    let result = await fetcher('create-new-trade', {
      symbol,
      entry,
      quantity,
      stop,
      kind,
      is_market_trade: isMarketTrade,
    });
    return {
      symbol: result.symbol,
      entryPrice: result.entryPrice,
      kind,
      quantity: Math.abs(result.positionAmt),
      stop: result.stop,
      takeProfit: null,
    };
  } catch (error) {
    throw error;
  }
}

async function updateStopLoss(params: StopLossType): Promise<number> {
  try {
    let result = await fetcher('market-update-stop-loss', {
      symbol: params.symbol,
      kind: params.kind,
      pip: params.pip,
      price: params.stopPrice,
      trade: true,
      delete: true,
      style: 'stop',
    });
    return result.stop;
  } catch (error) {
    throw error;
  }
}
async function updateTakeProfit(params: StopLossType) {
  try {
    let result = await fetcher(`simple-order`, {
      symbol: params.symbol,
      kind: params.kind,
      entry: params.takeProfitPrice,
      quantity: params.quantity,
      side: params.kind == 'long' ? 'sell' : 'buy',
    });
    return {
      ...result,
      takeProfit: params.takeProfitPrice,
    };
  } catch (error) {
    throw error;
  }
}

async function marketCloseTrade(params: StopLossType): Promise<any> {
  try {
    let result = await fetcher(`simple-order`, {
      symbol: params.symbol,
      kind: params.kind,
      entry: params.stopPrice,
      quantity: params.quantity,
      side: params.kind == 'long' ? 'sell' : 'buy',
    });
    let openTrade = {
      symbol: result.symbol,
      entryPrice: result.entryPrice,
      kind: params.kind,
      quantity: Math.abs(result.positionAmt),
    };
    return openTrade;
  } catch (error) {
    throw error;
  }
}
async function fetchAllMarkets(owner: string) {
  try {
    let result = await getFetcher(`${ROOT}/ft/${owner}/tradeable-markets`);
    return {
      allMarkets: [...result.usdt, ...result.coin],
    };
  } catch (error) {
    throw error;
  }
}
async function getBotAccounts(): Promise<
  Array<{ owner: string; configs: any[] }>
> {
  try {
    let today = new Date();
    let result = await getFetcher(
      ROOT + `/get-bots/all?timestamp=${today.getTime()}`,
    );
    return result.data.map((o: any) => ({
      owner: o.owner,
      // config: o.config[0],
      configs: o.config,
      exchanges: o.exchanges || [],
    }));
  } catch (error) {
    throw error;
  }
}
async function updateConfig(owner: string, config: any) {
  //console.log(config);
  try {
    let result = await postFetcher(ROOT + '/save-watch-config', {
      owner,
      data: config,
    });
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function updateOrders(owner: string, symbol = 'btcusdt', action = true) {
  try {
    let variable = action ? 't' : '';
    let result = await getFetcher(
      `${ROOT}/check-if-trade-can-be-placed/${owner}/${symbol}?trade=${variable}`,
    );
    return result.result;
  } catch (error) {
    throw error;
  }
}

async function updateStopTrade(owner: string, symbol: string, order: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/ft/${owner}/replace-stop-market-trade`,
      {
        symbol,
        entry: order.entry,
        size: order.size,
        kind: order.kind,
      },
    );
    return result.status;
  } catch (error) {
    throw error;
  }
}

async function addSymbolToBackground(owner: string, symbol: string) {
  try {
    let result = await postFetcher(`${ROOT}/api/scheduler/add-symbol`, {
      owner,
      symbol,
    });
    return result.status;
  } catch (error) {
    throw error;
  }
}

async function removeSymbolFromBackground(owner: string, symbol: string) {
  try {
    let result = await postFetcher(`${ROOT}/api/scheduler/delete-symbol`, {
      owner,
      symbol,
    });
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function createJob(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/run-future-job`,
      params,
    );
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function addNewSymbol(owner: string, symbol: string) {
  try {
    let result = await getFetcher(
      `${ROOT}/ft/${owner}/get_bot_config/${symbol}`,
    );
    let response = await postFetcher(`${ROOT}/save-watch-config`, {
      owner,
      data: result,
    });
    return response.status;
  } catch (error) {
    throw error;
  }
}
async function deleteSymbol(owner: string, symbol: string) {
  try {
    let response = await getFetcher(
      `${ROOT}/delete-watch-config/${owner}/${symbol}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}
async function deleteJob(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/delete-future-job`,
      params,
    );
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function pauseJob(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/toggle-future-job`,
      params,
    );
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function runJob(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/process-future-job`,
      params,
    );
    return result.status;
  } catch (error) {
    throw error;
  }
}
async function editJob(params: any) {
  try {
    let result = await postFetcher(`${ROOT}/api/edit-job-config`, params);
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function toggleJobsWithAction(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/toggle-jobs-with-action`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function bulkJobAction(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/symbol-action`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function getJobStatus(job_id: string) {
  try {
    let result = await getFetcher(`${ROOT}/api/scheduler/job-info/${job_id}`);
    return result?.data;
  } catch (error) {
    throw error;
  }
}

async function createProfile(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/create-profile`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function editProfile(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/edit-profile`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function deleteProfile(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/delete-profile`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function tempPauseMarket(params: any) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/scheduler/reduce-position`,
      params,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function runProfile(params: any) {
  try {
    let {
      owner,
      symbol,
      profile_id,
      start = '',
      old_mode = '',
      new_future = '',
    } = params;
    let result = await getFetcher(
      `${ROOT}/api/scheduler/run-profile/${owner}/${symbol}/${profile_id}?start=${start}&old_mode=${old_mode}&new_future=${new_future}`,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
async function removeOpenOrders(parmas: any) {
  try {
    let { symbol, owner } = parmas;
    let result = await postFetcher(`${ROOT}/ft/${owner}/cancel-all-orders`, {
      symbol,
    });
    return result.msg;
  } catch (error) {
    throw error;
  }
}
export async function getSpotSymbols(params: any) {
  try {
    let { owner, coin } = params;
    let result = await getFetcher(`${ROOT}/ft/${owner}/spot/${coin}/symbols`);
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function createSpotProfile(params: any) {
  try {
    let { owner, symbol, spot_symbol, profile } = params;
    let result = await postFetcher(`${ROOT}/api/spot/create-profile`, {
      owner,
      symbol,
      spot_symbol,
      profile,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function updateTPSL(params: any) {
  try {
    let { owner, symbol, spot_symbol } = params;
    let result = await postFetcher(`${ROOT}/api/spot/update-take-profit-stop`, {
      owner,
      symbol,
      spot_symbol,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function businessLogicActions(params: {
  action: string;
  owner: string;
  symbol: string;
  config_owner: string;
  data?: any;
}) {
  try {
    let { owner, symbol, action, config_owner, data = {} } = params;
    // let result = await postFetcher(
    // `https://app-dev.beeola.me/ai/business-logic/actions`,
    // {
    let result = await postFetcher(`${ROOT}/ai/business-logic/actions`, {
      name: action,
      params: {
        owner,
        symbol,
        config_owner,
        data,
      },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function deleteSpotProfile(params: any) {
  try {
    let { owner, symbol, spot_symbol } = params;
    let result = await postFetcher(`${ROOT}/api/spot/delete-spot-symbol`, {
      owner,
      symbol,
      spot_symbol,
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function runSpotProfile(params: any) {
  try {
    let { owner, symbol, spot_symbol } = params;
    let result = await getFetcher(
      `${ROOT}/api/spot/run-profile/${owner}/${symbol}/${spot_symbol}`,
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}
export async function closePosition(params: any) {
  try {
    let { symbol, kind, owner } = params;
    let result = await postFetcher(`${ROOT}/ft/${owner}/close-position`, {
      symbol,
      kind,
    });
    return result;
  } catch (error) {
    throw error;
  }
}
export async function updateProtectProfit(params: any) {
  try {
    let { symbol, owner } = params;
    let result = await postFetcher(
      `${ROOT}/api/scheduler/update-protect-profit`,
      {
        symbol,
        owner,
      },
    );
    return result;
  } catch (error) {
    throw error;
  }
}
export async function placeFutureOrder(params: any, kind: any) {
  let { symbol } = params;
  try {
    if (['spot', 'margin'].includes(kind)) {
      return await postFetcher(
        `${ROOT}/api/${kind}/${symbol}/place-oco-orders`,
        params,
      );
    }
    let result = await postFetcher(
      `${ROOT}/api/multi-trade/${symbol}/place-orders`,
      {
        ...params,
        multipier: null,
        place_order: true,
        cancel_orders: false,
        another_order: null,
        new: true,
        start_price: null,
      },
    );
    return result;
  } catch (error) {
    throw error;
  }
}
export async function createSingleControlledOrder(params: any) {
  let { symbol } = params;
  //console.log(params);
  try {
    let result = await postFetcher(
      `${ROOT}/api/single-trade/${symbol}/place-orders`,
      {
        ...params,
      },
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function analyzePosition({ owner, symbol, kind, ...params }: any) {
  let instance = new RemoteAction(owner, ROOT);
  return await instance.analyzeCurrentPosition(symbol, kind);
}

export async function placeSignalTrade({
  symbol,
  kind,
  cancel,
  orders,
  append,
  sub_accounts,
}: {
  append?: boolean;
  symbol: string;
  kind: 'long' | 'short';
  cancel: boolean;
  orders: any[];
  sub_accounts: string[];
}) {
  try {
    const payload = {
      symbol: symbol.toUpperCase(),
      kind,
      cancel,
      append,
      orders,
      sub_accounts,
    };
    let result = await postFetcher(`${ROOT}/api/signals/place-orders`, payload);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function reducePositionTrade({
  symbol,
  kind,
  owner,
}: {
  symbol: string;
  kind: 'long' | 'short';
  owner: string;
}) {
  try {
    const payload = {
      symbol: symbol.toUpperCase(),
      kind,
      owner,
    };
    let result = await postFetcher(
      `${ROOT}/api/signals/reduce-position`,
      payload,
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function placeStopOrders({
  symbol,
  orders,
  owner,
}: {
  symbol: string;
  orders: any[];
  owner: string;
}) {
  try {
    const payload = {
      symbol: symbol.toUpperCase(),
      orders,
      owner,
    };
    let result = await postFetcher(
      `${ROOT}/api/signals/place-stop-orders`,
      payload,
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateClosePrices({
  symbol,
  orders,
  kind,
  sub_accounts,
  reduce,
}: {
  kind: 'long' | 'short';
  symbol: string;
  orders: any[];
  sub_accounts: string[];
  reduce?: boolean;
}) {
  try {
    let result = await postFetcher(`${ROOT}/api/signals/update-close-prices`, {
      trades: {
        [kind]: {
          [symbol.toUpperCase()]: orders,
        },
      },
      sub_accounts,
      reduce,
    });
    return result;
  } catch (error) {
    throw error;
  }
}

export async function placeMarginSignalTrade({
  symbol,
  size,
  entry,
  kind,
  stop,
  cancel,
  owner,
}: {
  symbol: string;
  size: number;
  stop: number;
  entry: number;
  kind: 'long' | 'short';
  cancel: boolean;
  owner: string;
}) {
  try {
    let result = await postFetcher(`${ROOT}/api/signals/place-margin-order`, {
      symbol: symbol.toUpperCase(),
      size,
      entry,
      kind,
      stop,
      cancel,
      owner,
    });
    return result;
  } catch (error) {
    throw error;
  }
}

export async function updateMarginClosePrices({
  symbol,
  size,
  take_profit,
  kind,
}: {
  symbol: string;
  size: number;
  take_profit: number;
  kind: 'long' | 'short';
}) {
  try {
    let result = await postFetcher(
      `${ROOT}/api/signals/update-margin-close-prices`,
      {
        symbol: symbol.toUpperCase(),
        size,
        take_profit,
        kind,
      },
    );
    return result;
  } catch (error) {
    throw error;
  }
}

export async function getOrdersForAccount({
  kind,
  ...params
}: {
  owner: string;
  symbol: string;
  kind?: 'long' | 'short';
}) {
  try {
    let result = await postFetcher(`${ROOT}/api/all-alerts/action`, {
      action: 'data',
      style: 'new',
      data: {
        margin: {
          [params.symbol]: [],
        },
        future: {
          [params.symbol]: [],
        },
      },
      owner: params.owner,
      from_profile: false,
      from_cache: true,
    });
    const { data } = result;
    if (kind) {
      return data.future[params.symbol.toLowerCase()].position;
    }
    const instance = new AccountParser(data, params.symbol);
    return {
      accounts: instance.buildAccounts(),
      coin: instance.base.toUpperCase(),
      collateral: instance.collateral,
      loan: instance.loan,
      quote_loan: instance.quote_loan,
      base_collateral: instance.base_collateral,
      // margin_orders:
    };
  } catch (error) {
    throw error;
  }
}

export async function createOrdersForAccount(
  config: any,
  account: 'future' | 'margin',
) {
  try {
    let result = await postFetcher(`${ROOT}/api/create-orders`, {
      config,
      account,
    });
    return result;
  } catch (error) {
    throw error;
  }
}

export async function purchaseBnb(params: {
  amount: number;
  owner: string;
  source: string; //'spot' | 'margin' | 'future';
}) {
  try {
    const result = await postFetcher(`${ROOT}/api/purchase_bnb`, params);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function transferUsdt(params: {
  amount: number;
  owner: string;
  from: string;
  to: string;
  source: string;
}) {
  try {
    const result = await postFetcher(`${ROOT}/api/transfer_usdt`, params);
    return result;
  } catch (error) {
    throw error;
  }
}

async function getExchanges(params: { accounts: string[]; symbol: string }) {
  try {
    const result = await Promise.all(
      params.accounts.map((o) =>
        getExchange({
          owner: o,
          symbol: params.symbol,
        }),
      ),
    );
    const rr = result.map((j, i) => ({
      owner: params.accounts[i],
      markets: j,
      symbol: params.symbol,
    }));
    return rr;
  } catch (error) {
    throw error;
  }
}

const getExchange = async (params: any) => {
  const symbol = params.symbol || 'BTCUSDT';
  const [data, config] = await Promise.all(
    ['b_get_exchange_info', 'b_get_config'].map((o) =>
      businessLogicActions({
        action: o,
        symbol,
        owner: params.owner,
        config_owner: params.owner,
      }),
    ),
  );
  const payload = Object.keys(data).map((u) => {
    let vv = data[u].payload.future[u.toLowerCase()];
    return {
      id: `${symbol}:${params.owner}`,
      last_order: vv.last_order,
      closed_orders: vv.closed_orders,
      account_balance: vv.account_balance,
      open_orders: vv.open_orders,
      position: vv.position,
      symbol: u,
      futureCTrader: {
        config: config,
        max_size: config.max_size,
        symbol: config.symbol,
        support: config.support,
        resistance: config.resistance,
        split: config.tradeSplit,
        price_places: config.price_places,
        zone_split: config.zone_split,
        profit_base_price: config.profit_base_price,
        profit_quote_price: config.profit_quote_price,
      },
    };
  });
  return payload;
};
const getTradeSections = async (params: any) => {
  const symbol = params.symbol || 'BTCUSDT';
  const data = await businessLogicActions({
    action: 'b_get_trades',
    symbol,
    owner: params.owner,
    config_owner: params.owner,
  });
  const values = Object.keys(data || {})
    .map((u) => {
      return data[u].map(
        ({ entry, stop, size, risk_reward, risk_per_trade, name }:any, index:number) => ({
          config: {
            entry,
            stop,
            size,
            risk_reward,
            risk_per_trade,
          },
          kind: u,
          id:
            name ||
            `${params.owner}-${symbol}-${u}-entry:${entry}-stop:${stop}-index:${index}`,
        }),
      );
    })
    .flat();
  return values;
};

const transferFunds = async (params: TransferData) => {
  try {
    const result = await postFetcher(`${ROOT}/api/cross-transfer`, params);
    return result;
  } catch (error) {
    throw error;
  }
};

const adapter = {
  transferFunds,
  getExchange,
  getTradeSections,
  businessLogicActions,
  getOptimumReward,
  getOptimumRisk,
  getOptimumStop,
  purchaseBnb,
  transferUsdt,
  getExchanges,
  getOrdersForAccount,
  updateMarginClosePrices,
  placeMarginSignalTrade,
  placeStopOrders,
  reducePositionTrade,
  placeSignalTrade,
  updateClosePrices,
  tempPauseMarket,
  editJob,
  updateTPSL,
  createSpotProfile,
  updateProtectProfit,
  runSpotProfile,
  deleteSpotProfile,
  toggleJobsWithAction,
  loadInitialData,
  createNewTrade,
  updateTakeProfit,
  updateStopLoss,
  marketCloseTrade,
  increasePosition,
  getSwingInfo,
  bulkTrades,
  getBotAccounts,
  fetchAllMarkets,
  updateConfig,
  updateOrders,
  addSymbolToBackground,
  removeSymbolFromBackground,
  updateStopTrade,
  createJob,
  deleteJob,
  pauseJob,
  addNewSymbol,
  deleteSymbol,
  runJob,
  bulkJobAction,
  getJobStatus,
  createProfile,
  deleteProfile,
  editProfile,
  runProfile,
  removeOpenOrders,
  closePosition,
  getSpotSymbols,
  placeFutureOrder,
  createSingleControlledOrder,
  analyzePosition,
};

export default adapter;
