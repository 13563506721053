import { Instance, getEnv, types } from 'mobx-state-tree';
import { ExchangeStore, Exchange } from './exchange';
import { withSetPropAction } from './helpers';
import { BotAdapterType } from '../../adapter';

export type TransferData = {
  from: {
    owner: string;
    wallet: string;
  };
  to: {
    owner: string;
    wallet: string;
  };
  asset: string;
  amount: number;
};
export const RootStore = types
  .model('RootStore', {
    symbol: types.optional(types.string, 'BTCUSDT'),
    accounts: types.optional(types.array(ExchangeStore), []),
    activeAccount: types.maybe(types.reference(ExchangeStore)),
  })
  .views((self) => ({
    get items() {
      return (
        self.accounts
          .filter((o) => o.activeMarket)
          .map((o) => {
            const activeMarket = o.activeMarket as NonNullable<
              Exchange['activeMarket']
            >;
            const longTp = activeMarket.getTakeProfit({
              kind: 'long',
              stats: true,
            }) as ReturnType<typeof activeMarket.orders.stats.close_orders>;
            const longSl = activeMarket.getStopLoss({
              kind: 'long',
              stats: true,
            }) as ReturnType<typeof activeMarket.orders.stats.close_orders>;

            const shortTp = activeMarket.getTakeProfit({
              kind: 'short',
              stats: true,
            }) as ReturnType<typeof activeMarket.orders.stats.close_orders>;
            const shortSl = activeMarket.getStopLoss({
              kind: 'short',
              stats: true,
            }) as ReturnType<typeof activeMarket.orders.stats.close_orders>;
            return {
              balance: activeMarket.dollarBalance,
              liquidation: activeMarket.liquidationPrice,
              currentPrice: activeMarket.currentPrice,
              owner: o.owner,
              positions: {
                long_position: {
                  entry: activeMarket.getPosition('long'),
                  stop_loss: {
                    price: longSl?.sell_price ?? '-',
                    quantity: longSl?.quantity ?? '-',
                    addition: longSl?.incurred ? `(${longSl?.incurred})` : null,
                  },
                  take_profit: {
                    price: longTp?.sell_price ?? '-',
                    quantity: longTp?.quantity ?? '-',
                    addition: longTp?.incurred ? `(${longTp?.incurred})` : null,
                  },
                },
                short_position: {
                  entry: activeMarket.getPosition('short'),
                  stop_loss: {
                    price: shortSl?.sell_price ?? '-',
                    quantity: shortSl?.quantity ?? '-',
                    addition: shortSl?.incurred
                      ? `(${shortSl?.incurred})`
                      : null,
                  },
                  take_profit: {
                    price: shortTp?.sell_price ?? '-',
                    quantity: shortTp?.quantity ?? '-',
                    addition: shortTp?.incurred
                      ? `(${shortTp?.incurred})`
                      : null,
                  },
                },
              },
              zone: o.activeTradeSection?.display,
            };
          }) ?? []
      );
    },
    get transferData() {
      return self.accounts.map((o) => {
        return {
          owner: o.owner,
          wallets: o.markets.map((i) => i.account_balance).flat(),
        };
      });
    },
  }))
  .actions(withSetPropAction)
  .actions((self) => {
    const adapter = getEnv<{ adapter: BotAdapterType }>(self).adapter;
    const onTransfer = async (data: TransferData) => {
      await adapter.transferFunds(data);
      if (self.activeAccount) {
        await self.activeAccount.initializeExchange();
      }
    };
    const initializeExchange = async () => {
      if (self.activeAccount) {
        await self.activeAccount.initializeExchange();
      } else {
        const result = await adapter.getExchanges({
          symbol: self.symbol,
          accounts: [
            'main_account',
            'sub_account',
            'feyisayo_account',
            'gbozee1_sub_account',
            'tomi_account',
          ],
          // accounts: ['main_account', 'sub_account', 'gbozee1_sub_account'],
        });
        self.setProp(
          'accounts',
          result.map((j) => ({ ...j, symbol: self.symbol })),
        );
        await Promise.all(self.accounts.map((o) => o.fetchTradeSections()));
        self.accounts.forEach((o) => {
          o.setProp('activeMarket', `${self.symbol}:${o.owner}`);
        });
        self.setProp('activeAccount', result[0].owner);
      }
    };
    return {
      initializeExchange,
      onTransfer,
    };
  });

export interface Root extends Instance<typeof RootStore> {}
