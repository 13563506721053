import { Link, useDisclosure, useToast } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { SelectElement } from '../../currentBot/components/FormElement';
import { BaseModal } from '../../newBot/app-components/primitives/ModalComponent';
import {
  ButtonCollection,
  ConfirmationDetails,
  ConfirmationMessage,
  IncreasePostionFormField,
  TradingSectionPicker,
} from '../components';
import { Exchange } from '../stores';

export type UpdateTPSLPageProps = {
  store: Exchange;
  orderType: 'sl' | 'tp';
  onGoBack: () => void;
  navigate: (path: string) => void;
};

export const UpdateTPSLPage = observer(
  ({ store, orderType, onGoBack, navigate }: UpdateTPSLPageProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [kind, setKind] = useState<'long' | 'short'>('long');
    const toast = useToast();
    const [loading, setLoading] = useState(false);
    const type = orderType === 'sl' ? 'stop loss' : 'take profit';
    const price = store?.getConfirmationMessage(kind, orderType);
    return (
      <>
        <IncreasePostionFormField
          actions={[
            {
              label: 'Trade section',
              renderItem: (
                <>
                  <TradingSectionPicker
                    as="select"
                    options={store.zones}
                    defaultValue={store.activeZone}
                    onChange={(s) => {
                      store.setProp('activeTradeSection', s.id);
                    }}
                    onEdit={() => {
                      navigate('/trade-section');
                    }}
                  />
                </>
              ),
            },
            {
              label: 'Select Postion Kind ',
              renderItem: (
                <SelectElement
                  label=""
                  // controlProps={{
                  //   display: 'flex',
                  //   py: 2,
                  //   justifyContent: 'space-between',
                  // }}
                  value={kind}
                  options={['long', 'short']}
                  onChange={(e) => {
                    setKind(e.target.value);
                  }}
                  labelProps={{
                    fontWeight: 'bold',
                    marginTop: '2',
                    width: '370px',
                  }}
                  controlProps={{
                    display: 'flex',
                    py: 2,
                    justifyContent: 'space-between',
                  }}
                />
              ),
            },
          ]}
        />

        {price ? (
          <ConfirmationMessage
            text={` You would be setting a ${type} trade for the ${kind} position at ${price}.Click the button at the below to confirm.`}
            hasBorder
          />
        ) : null}
        <ButtonCollection
          actions={[
            {
              label: `Confirm ${orderType.toUpperCase()} Order`,
              action: onOpen,
              loading: loading,
            },
          ]}
          direction={'column'}
        />
        <BaseModal
          removeCloseButton
          isOpen={isOpen}
          onClose={onClose}
          closeOnOverlayClick={false}
        >
          <ConfirmationDetails
            loading={loading}
            text="Please confirm your intention to proceed with this order."
            onClick={async () => {
              try {
                setLoading(true);
                await store.placeTPSLOrder(kind);
                setLoading(false);
                toast({
                  title: `${
                    orderType === 'sl' ? 'Stop Loss' : 'Take Profit'
                  } order successful`,
                  description: 'Order have been confirmed',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
                onClose();
              } catch (error) {
                toast({
                  title: 'Error',
                  description: error?.message,
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
          />
        </BaseModal>
      </>
    );
  },
);

export default UpdateTPSLPage;
