import { calculate_liquidation } from '../../store/hedge';
import { determine_pnl } from '../../store/trade_signal';
import { getDecimalPlaces, to_f } from '../../store/utils';

export type Order = {
  orderId: number;
  symbol: string;
  clientOrderId: string;
  price: string;
  avgPrice: string;
  origQty: string;
  executedQty: string;
  cumQuote: string;
  timeInForce: string;
  type: string;
  reduceOnly: boolean;
  closePosition: boolean;
  side: 'BUY' | 'SELL';
  positionSide: string;
  stopPrice: string;
  workingType: string;
  priceProtect: boolean;
  origType: string;
  priceMatch: string;
  selfTradePreventionMode: string;
  goodTillDate: number;
  time: number;
  updateTime: number;
};

type Position = {
  symbol: string;
  positionAmt: number;
  entryPrice: number;
  breakEvenPrice: string;
  markPrice: number;
  unRealizedProfit: number;
  liquidationPrice: number;
  leverage: number;
  maxNotionalValue: number;
  marginType: string;
  isolatedMargin: number;
  isAutoAddMargin: string;
  positionSide: 'BOTH' | 'LONG' | 'SHORT';
  notional: string;
  isolatedWallet: number;
  updateTime: number;
  isolated: boolean;
  adlQuantile: number;
};

export type PositionTypes = {
  long: Position;
  short: Position;
  both?: Position;
};

class OrderStats {
  maker: number;
  taker: number;

  constructor({ maker, taker }: { maker: number; taker: number }) {
    this.maker = maker;
    this.taker = taker;
  }

  determine_liquidation({
    position,
    balance,
    sl_size = 0,
  }: {
    sl_size?: number;
    position: Position;
    balance: number;
  }) {
    const empty_position = {
      entry: 0,
      size: 0,
    };
    const _position = {
      entry: position.entryPrice,
      size: Math.abs(position.positionAmt),
    };
    const long_position =
      position.positionSide.toLowerCase() == 'long'
        ? _position
        : empty_position;
    const short_position =
      position.positionSide.toLowerCase() == 'short'
        ? _position
        : empty_position;
    const result = calculate_liquidation(
      balance,
      long_position,
      short_position,
      undefined,
      undefined,
      undefined,
      position.leverage,
      undefined,
      position.symbol,
    );
    return result.liquidation as number;
  }

  getKey({ orders, position }: { orders: Order[]; position?: Position }) {
    if (position) {
      return position.entryPrice;
    }
    return orders.length > 0 ? orders[0].price : 0;
  }

  getPlaces({ orders, position }: { orders: Order[]; position?: Position }) {
    const getKey = () => this.getKey({ orders, position });
    const places = getDecimalPlaces(getKey());
    const decimal_places =
      orders.length > 0 ? getDecimalPlaces(orders[0].origQty) : 0;
    return { places: `%.${places}f`, decimal_places: `%.${decimal_places}f` };
  }

  open_orders({
    balance = 0,
    orders,
    position: _position,
    price_places,
    decimal_places: _decimal_places,
    average,
    sl_size = 0,
  }: {
    average?: { entry: number; size: number };
    sl_size?: number;
    orders: Order[];
    position?: Position;
    balance?: number;
    price_places?: string;
    decimal_places?: string;
  }) {
    let position = _position ? { ..._position } : undefined;
    if (average && average.size && position) {
      position.positionAmt = average.size;
      position.entryPrice = average.entry;
    }
    const rr = this.getPlaces({ orders, position });
    const places = price_places || rr.places;
    const decimal_places = _decimal_places || rr.decimal_places;
    let size = orders.reduce((acc, order) => {
      return acc + parseFloat(order.origQty);
    }, 0);
    let total_notional_value = orders.reduce((acc, order) => {
      return acc + parseFloat(order.origQty) * parseFloat(order.price);
    }, 0);
    if (position) {
      size += Math.abs(position.positionAmt);
      total_notional_value +=
        Math.abs(position.positionAmt) * position.entryPrice;
    }
    const avg_entry = total_notional_value / (size || 1);
    const last_order =
      orders.length > 0 ? parseFloat(orders.at(-1)?.price as string) : null;
    const fees = orders.reduce((acc, order) => {
      const r =
        parseFloat(order.price) * parseFloat(order.origQty) * this.maker;
      return acc + r;
    }, 0);
    const liquidation = position
      ? this.determine_liquidation({ position, balance, sl_size })
      : 0;
    return {
      size: to_f(size - sl_size, decimal_places),
      count: orders.length,
      avg: to_f(avg_entry, places),
      last: last_order,
      fees: to_f(fees, places),
      liquidation: to_f(liquidation, places),
      loss:
        last_order && position
          ? to_f(
              determine_pnl(
                avg_entry,
                last_order,
                size,
                position.positionSide.toLowerCase(),
              ),
              places,
            )
          : 0,
    };
  }

  close_orders({
    orders,
    position,
    price_places,
    decimal_places: _decimal_places,
    is_sl = false,
  }: {
    orders: Order[];
    position: Position;
    price_places?: string;
    decimal_places?: string;
    is_sl?: boolean;
  }) {
    const rr = this.getPlaces({ orders, position });
    const places = price_places || rr.places;
    const decimal_places = _decimal_places || rr.decimal_places;
    const pnl = determine_pnl(
      position.entryPrice,
      parseFloat(orders[0].price),
      Math.abs(position.positionAmt),
      position.positionSide.toLowerCase(),
    );
    const ratio =
      parseFloat(orders[0].origQty) < Math.abs(position.positionAmt)
        ? to_f(
            parseFloat(orders[0].origQty) / Math.abs(position.positionAmt),
            decimal_places,
          )
        : 1;
    const feeRate = parseFloat(orders[0].stopPrice) ? this.taker : this.maker;
    const quantity = is_sl
      ? parseFloat(orders[0].origQty)
      : parseFloat(orders[0].origQty) * ratio;
    return {
      pnl: to_f(pnl, places),
      remaining_size: to_f(
        (1 - ratio) * Math.abs(position.positionAmt),
        decimal_places,
      ),
      quantity: to_f(quantity, decimal_places),
      incurred: to_f(pnl * ratio, places),
      sell_price: to_f(orders[0].price, places),
      fees: to_f(
        orders.reduce((acc, order) => {
          const r =
            parseFloat(order.price) * parseFloat(order.origQty) * feeRate;
          return acc + r;
        }, 0),
        places,
      ),
    };
  }
}

export class OrderControl {
  orders: Order[] = [];
  positions?: PositionTypes;
  maker_rate: number = 0.0002;
  taker_rate: number = 0.0005;
  stats = new OrderStats({
    maker: this.maker_rate,
    taker: this.taker_rate,
  });
  price_places: string = '%.1f';
  decimal_places: string = '%.3f';

  constructor({
    order_lists,
    positions,
    price_places,
    decimal_places,
  }: {
    order_lists: Order[];
    positions?: PositionTypes;
    price_places?: string;
    decimal_places?: string;
  }) {
    this.orders = order_lists;
    this.positions = positions;
    if (price_places) {
      this.price_places = price_places;
    }
    if (decimal_places) {
      this.decimal_places = decimal_places;
    }
  }
  get_orders({ kind, side }: { deduct_sl?:boolean; kind: 'long' | 'short'; side: 'buy' | 'sell' }) {
    return this.orders.filter((order) => {
      return (
        order.positionSide.toLowerCase() == kind &&
        order.side.toLowerCase() == side
      );
    });
  }
  get_open_orders({
    kind,
    stats,
    limit=true,
    deduct_sl = false,
    balance = 0,
    avg,
  }: {
    avg?: { entry: number; size: number };
    deduct_sl?: boolean;
    kind: 'long' | 'short';
    limit?: boolean;
    stats?: boolean;
    balance?: number;
  }) {
    const side = {
      long: 'buy',
      short: 'sell',
    };
    let result = this.get_orders({
      kind,
      side: side[kind] as 'buy' | 'sell',
      deduct_sl
    }).filter(r=>{
      if (deduct_sl){
        return parseFloat(r.stopPrice) > 0
      }
      return parseFloat(r.stopPrice) == 0
    }).sort((a, b) => {
      //Todo: check if this is correct
      return kind === 'short'
        ? parseFloat(a.price) - parseFloat(b.price)
        : parseFloat(b.price) - parseFloat(a.price);
    }).filter(o=>{
      if(!limit){
        return true
      }
      return true
      // return parseFloat(o.stopPrice) == 0
    });
    console.log('result',result)
    if (stats) {
      let oo = 0;
      if (deduct_sl) {
        oo = this.get_sl_orders({ kind, stats })?.quantity;
        console.log('oo', oo);
      }
      const position = this.positions?.[kind];
      return this.stats.open_orders({
        orders: result,
        position,
        average: avg,
        balance,
        price_places: this.price_places,
        decimal_places: this.decimal_places,
        // sl_size: oo,
      });
    }
    return result;
  }
  get_tp_orders({
    kind,
    stats,
    balance = 0,
  }: {
    kind: 'long' | 'short';
    stats?: boolean;
    balance?: number;
  }) {
    const side = {
      long: 'sell',
      short: 'buy',
    };
    let result = this.get_orders({
      kind,
      side: side[kind] as 'buy' | 'sell',
    })
      .filter((u) => parseFloat(u.stopPrice) == 0)
      .sort((a, b) => {
        //Todo: check if this is correct
        return kind === 'long'
          ? parseFloat(b.price) - parseFloat(a.price)
          : parseFloat(a.price) - parseFloat(b.price);
      });
    const position = this.positions?.[kind];
    if (stats && result.length > 0 && position) {
      return this.stats.close_orders({
        orders: result,
        position,
        price_places: this.price_places,
        decimal_places: this.decimal_places,
      });
    }
    return result;
  }
  get_sl_orders({
    kind,
    stats,
  }: {
    kind: 'long' | 'short';
    stats?: boolean;
    balance?: number;
  }) {
    const side = {
      long: 'sell',
      short: 'buy',
    };
    let result = this.get_orders({
      kind,
      side: side[kind] as 'buy' | 'sell',
    })
      .filter((u) => parseFloat(u.stopPrice) > 0)
      .sort((a, b) => {
        //Todo: check if this is correct
        return kind === 'long'
          ? parseFloat(b.price) - parseFloat(a.price)
          : parseFloat(a.price) - parseFloat(b.price);
      });
    const position = this.positions?.[kind];
    if (stats && result.length > 0 && position) {
      return this.stats.close_orders({
        orders: result,
        position,
        price_places: this.price_places,
        decimal_places: this.decimal_places,
        is_sl: true,
      });
    }
    return result;
  }
}

// export class Queue {
//   constructor() {
//     this.elements = {};
//     this.head = 0;
//     this.tail = 0;
//   }
//   enqueue(element) {
//     this.elements[this.tail] = element;
//     this.tail++;
//   }
//   dequeue() {
//     const item = this.elements[this.head];
//     delete this.elements[this.head];
//     this.head++;
//     return item;
//   }
//   peek() {
//     return this.elements[this.head];
//   }
//   get length() {
//     return this.tail - this.head;
//   }
//   get isEmpty() {
//     return this.length === 0;
//   }
// }

// let q = new Queue();
// for (let i = 1; i <= 7; i++) {
//   q.enqueue(i);
// }
// // get the current item at the front of the queue
// console.log(q.peek()); // 1

// // get the current length of queue
// console.log(q.length); // 7

// // dequeue all elements
// while (!q.isEmpty) {
//   console.log(q.dequeue());
// }
