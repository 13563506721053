import { ChevronLeftIcon } from '@chakra-ui/icons';
import { Button, Flex } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import { SelectElement } from '../../../currentBot/components/FormElement';
import React from 'react';
import { IEntryStore } from '../../../store/solvedConfig/account';

export const NewEntryControl: React.FC<{
  store: IEntryStore;
}> = observer(({ store }) => {
  return (
    <Flex>
      {store.entry > 0 && (
        <Button
          leftIcon={<ChevronLeftIcon />}
          // mr={2}
          alignSelf={'center'}
          onClick={() => {
            store.goBack();
          }}
        >
          Back
        </Button>
      )}
      <SelectElement
        label={store.entry === 0 ? 'Entry' : ''}
        value={store.entry !== 0 ? store.tradeEntry : ''}
        selectProps={{ placeholder: 'Select Entry' }}
        options={store.selectOptions}
        onChange={(e) => {
          const result = store.onChange(e.target.value);
          if (result) {
          }
        }}
        labelProps={{
          fontWeight: 'bold',
          alignSelf: 'center',
          marginBottom: '0',
        }}
        controlProps={{ display: 'flex', py: 2 }}
      />
      {store.entry !== 0 && (
        <Button
          isDisabled={store.tradeEntry === 0}
          ml={2}
          colorScheme="green"
          alignSelf={'center'}
        >
          Place
        </Button>
      )}
    </Flex>
  );
});
