import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import Config1Display from '../../../currentBot/components/GenericConfigDisplay';

export const TradeConfig: React.FC<{
  // config: IFutureFormConfig;
  store: any;
}> = observer(({ store }) => {
  const config = store.config;
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Flex direction={'column'}>
      <Flex>
        <Config1Display
        hideFields={['kind']}
          props={
            isMobile
              ? { mt: 3, columns: 2, spacing: 3 }
              : { mt: 3, columns: 3, spacing: 5 }
          }
          override="config_1"
          store={config}
        />
      </Flex>
      <Flex dir="row" mt={4}>
        <FormControl>
          <Text>Input Type</Text>
          <Checkbox
            colorScheme={'red'}
            defaultChecked={store.as_select}
            onChange={store.toggleField}
          >
            Checkbox
          </Checkbox>
        </FormControl>
      </Flex>
    </Flex>
  );
});
