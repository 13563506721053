class RemoteAction {
  account: string;
  host: string;

  constructor(account: string, host?: string) {
    this.account = account;
    this.host = host || '';
  }
  async apiCall(action: string, payload: any) {
    let url = `${this.host}/api/${this.account}/remote-actions`;
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...payload, action }),
      });
      if (response.ok) {
        return await response.json();
      }
      throw new Error('Error fetching result');
    } catch (error) {
      throw error;
    }
  }
  async getPosition(symbol: string, kind?: 'long' | 'short') {
    let result = await this.apiCall('get_position', {
      symbol,
      params: { args: [], kwargs: { skip: true } },
    });
    let response = result.data;
    let values: any = {};
    Object.keys(result.data).forEach((key) => {
      values[key] = {
        size: Math.abs(response[key].positionAmt),
        entry: response[key].entryPrice,
        kind: response[key].positionSide.toLowerCase(),
      };
    });
    return kind ? values[kind.toLowerCase()] : values;
  }
  async analyzeCurrentPosition(symbol: string, kind: 'long' | 'short') {
    let result = await this.apiCall('analyze_current_position', {
      symbol,
      params: { args: [], kwargs: {} },
    });
    let response = result.data;
    return response[kind];
  }
}

export default RemoteAction;
