import { Box, useColorModeValue as mode } from '@chakra-ui/react';
import { GrAnalytics } from 'react-icons/gr';
import { TradeProps } from '../types';
import { TradeItem } from './TradeItem';

interface TradeViewProps {
  trade: TradeProps;
  onClick?: (trade: TradeProps) => void;
  currentTrade?: any;
  isClickable?: boolean;
  isMenu?: boolean;
  key?: number;
}

export const TradeView = ({
  isMenu,
  key,
  trade,
  onClick,
  currentTrade,
  isClickable,
}: TradeViewProps) => {
  console.log('isClickable', isClickable);
  const handleClick = () => {
    if (isClickable) {
      onClick?.(trade);
    }
  };

  return (
    <>
      <Box
        _hover={
          isClickable
            ? { color: 'white', bg: mode('blue.800', 'gray.600') }
            : undefined
        }
        className="group"
        fontWeight="bold"
        transition="background .1s ease-out"
        minW={isMenu ? '100px' : ['300px', '200px', '200px']}
        as="button"
        py={isMenu ? '1px' : [3, 2, 2]}
        px={isMenu ? '1px' : 4}
        borderWidth={isMenu ? 1 : 2}
        borderColor="black"
        borderRadius="lg"
        m={isMenu ? 1 : 3}
        onClick={isMenu ? () => onClick?.(trade) : handleClick}
        backgroundColor={isMenu ? 'gray.100' : 'undefined'}
        bg={
          trade.entry === currentTrade.entry && isMenu ? 'blue.800' : undefined
        }
        cursor={isMenu ? 'pointer' : 'auto'}
        color={isMenu && trade.entry === currentTrade.entry ? 'white' : 'black'}
      >
        {trade && <TradeItem trade={trade} key={key} />}
      </Box>
    </>
  );
};
