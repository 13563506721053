function between(low: number, position_size: number, high: number) {
  return low < position_size && position_size <= high;
}

export function leverage_25(
  position_size: number,
  symbol?: string,
  m_rate = 1,
) {
  return leverage_50(position_size, symbol, m_rate);
}

export function coin_leverage_25(
  position_size: number,
  symbol?: string,
  m_rate = 2.4,
) {
  const coin_25: { [key: string]: number[] } = {
    EGLD: [1, 2, 5, 10, 15, 20].map((o) => o * 10000),
  };
  return coin_leverage_50(position_size, symbol, m_rate, coin_25, 'EGLD');
}
const coin_20: {
  [key: string]: number[];
} = {
  DOGE: [1, 5, 25, 100, 250, 1000].map((o) => o * 1000),
};
export function coin_leverage_20(
  position_size: number,
  symbol = 'DOGEUSD_PERP',
  m_rate = 1.2,
) {
  const marketOnly = symbol.split('USD_')[0];
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  let func = coin_20[marketOnly];
  if (!func) {
    func = coin_20.DOGE;
  }
  if (between(func[0], position_size, func[1])) {
    value['m_rate'] = 2.5;
    base_value = func[0];
  } else if (between(func[1], position_size, func[2])) {
    value['m_rate'] = 5;
    base_value = func[1];
  } else if (between(func[2], position_size, func[3])) {
    value['m_rate'] = 10;
    base_value = func[2];
  } else if (between(func[3], position_size, func[4])) {
    value['m_rate'] = 12.5;
    base_value = func[3];
  } else if (between(func[4], position_size, func[5])) {
    value['m_rate'] = 25;
    base_value = func[4];
  } else if (position_size > func[4]) {
    value['m_rate'] = 50;
    base_value = func[5];
  }
  return { minimum: func[0], value: value, base_value: base_value };
}
export function leverage_20(
  position_size: number,
  symbol?: string,
  m_rate = 1.2,
) {
  return leverage_25(position_size, symbol, m_rate);
}
const coin_50: {
  [key: string]: number[];
} = {
  FIL: [1, 2, 5, 10, 15, 20].map((o) => o * 10000),
};
export function coin_leverage_50(
  position_size: number,
  symbol = 'FILUSD_PERP',
  m_rate = 2.2,
  array = coin_50,
  defaultString = 'FIL',
  last = 25,
) {
  const marketOnly = symbol.split('USD_')[0];
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  let func = array[marketOnly];
  if (!func) {
    func = array[defaultString];
  }
  if (between(func[0], position_size, func[1])) {
    value['m_rate'] = 4.9;
    base_value = func[0];
  } else if (between(func[1], position_size, func[2])) {
    value['m_rate'] = 5;
    base_value = func[1];
  } else if (between(func[2], position_size, func[3])) {
    value['m_rate'] = 10;
    base_value = func[2];
  } else if (between(func[3], position_size, func[4])) {
    value['m_rate'] = 12.5;
    base_value = func[3];
  } else if (between(func[4], position_size, func[5])) {
    value['m_rate'] = 15;
    base_value = func[4];
  } else if (position_size > func[5]) {
    value['m_rate'] = last;
    base_value = func[5];
  }
  return { minimum: func[0], value: value, base_value: base_value };
}
export function leverage_50(
  position_size: number,
  symbol?: string,
  m_rate = 1,
) {
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  if (between(5000, position_size, 25000)) {
    value['m_rate'] = 2.5;
    base_value = 5000;
  } else if (between(25000, position_size, 100000)) {
    value['m_rate'] = 5;
    base_value = 25000;
  } else if (between(100000, position_size, 250000)) {
    value['m_rate'] = 10;
    base_value = 100000;
  } else if (between(250000, position_size, 1000000)) {
    value['m_rate'] = 12.5;
    base_value = 250000;
  } else if (position_size > 1000000) {
    value['m_rate'] = 50;
    base_value = 1000000;
  }
  return { minimum: 5000, value: value, base_value: base_value };
}

export function leverage_75(
  position_size: number,
  symbol?: string,
  m_rate = 0.65,
) {
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  if (between(10000, position_size, 50000)) {
    value['m_rate'] = 1;
    base_value = 10000;
  } else if (between(50000, position_size, 250000)) {
    value['m_rate'] = 2;
    base_value = 50000;
  } else if (between(250000, position_size, 1000000)) {
    value['m_rate'] = 5;
    base_value = 250000;
  } else if (between(1000000, position_size, 2000000)) {
    value['m_rate'] = 10;
    base_value = 1000000;
  } else if (between(2000000, position_size, 5000000)) {
    value['m_rate'] = 12.5;
    base_value = 2000000;
  } else if (between(5000000, position_size, 10000000)) {
    value['m_rate'] = 15;
    base_value = 5000000;
  } else if (position_size > 10000000) {
    value['m_rate'] = 25;
    base_value = 10000000;
  }
  return { minimum: 10000, value: value, base_value: base_value };
}
export function coin_leverage_75(
  position_size: number,
  symbol = 'BNBUSD_PERP',
  // m_rate = 0.75,
  m_rate = 1.85,
) {
  const marketOnly = symbol.split('USD_')[0];
  const coins_75: {
    [key: string]: number[];
  } = {
    BNB: [4, 8, 20, 40, 80, 120, 200].map((o) => o * 1000),
    LTC: [5, 10, 20, 50, 100, 150, 200].map((o) => o * 1000),
    BCH: [1, 2, 4, 10, 20, 30, 40].map((o) => o * 1000),
    ADA: [5, 10, 20, 40, 80, 140, 200].map((o) => o * 100000),
    XRP: [5, 10, 20, 50, 100, 150, 200].map((o) => o * 100000),
    ETC: [5, 10, 20, 50, 100, 150, 200].map((o) => o * 100000),
    LINK: [10, 20, 40, 80, 160, 280, 400].map((o) => o * 1000),
    DOT: [5, 10, 20, 50, 100, 150, 200].map((o) => o * 10000),
    EOS: [5, 10, 20, 50, 100, 150, 200].map((o) => o * 10000),
    TRX: [5, 10, 20, 50, 100, 200, 300].map((o) => o * 1000000),
  };
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  //console.log('params is ', { marketOnly });
  let func = coins_75[marketOnly];
  if (!func) {
    func = coins_75.BNB;
  }
  if (between(func[0], position_size, func[1])) {
    value['m_rate'] = 3.4;
    base_value = func[0];
  } else if (between(func[1], position_size, func[2])) {
    value['m_rate'] = 4.9;
    base_value = func[1];
  } else if (between(func[2], position_size, func[3])) {
    value['m_rate'] = 5;
    base_value = func[2];
  } else if (between(func[3], position_size, func[4])) {
    value['m_rate'] = 10;
    base_value = func[3];
  } else if (between(func[4], position_size, func[5])) {
    value['m_rate'] = 12.5;
    base_value = func[4];
  } else if (between(func[5], position_size, func[6])) {
    value['m_rate'] = 15;
    base_value = func[5];
  } else if (position_size > func[6]) {
    value['m_rate'] = 25;
    base_value = func[6];
  }
  return { minimum: func[0], value: value, base_value: base_value };
}
export function leverage_100(
  position_size: number,
  symbol?: string,
  m_rate = 0.5,
) {
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  if (between(10000, position_size, 100000)) {
    value['m_rate'] = 0.65;
    base_value = 10000;
  } else if (between(100000, position_size, 500000)) {
    value['m_rate'] = 1;
    base_value = 100000;
  } else if (between(500000, position_size, 1000000)) {
    value['m_rate'] = 2;
    base_value = 500000;
  } else if (between(1000000, position_size, 2000000)) {
    value['m_rate'] = 5;
    base_value = 1000000;
  } else if (between(2000000, position_size, 5000000)) {
    value['m_rate'] = 10;
    base_value = 2000000;
  } else if (between(5000000, position_size, 10000000)) {
    value['m_rate'] = 12.5;
    base_value = 5000000;
  } else if (between(10000000, position_size, 20000000)) {
    value['m_rate'] = 15;
    base_value = 10000000;
  } else if (position_size > 20000000) {
    value['m_rate'] = 25;
    base_value = 20000000;
  }
  return { minimum: 10000, value: value, base_value: base_value };
}
export function coin_leverage_100(
  position_size: number,
  symbol?: string,
  m_rate = 0.5,
) {
  let value = { m_rate: m_rate, m_a: 0 };
  let base_value = 0;
  if (between(100, position_size, 500)) {
    value['m_rate'] = 0.65;
    base_value = 100;
  } else if (between(500, position_size, 1000)) {
    value['m_rate'] = 1;
    base_value = 500;
  } else if (between(1000, position_size, 2000)) {
    value['m_rate'] = 2;
    base_value = 1000;
  } else if (between(2000, position_size, 4000)) {
    value['m_rate'] = 5;
    base_value = 2000;
  } else if (between(4000, position_size, 6000)) {
    value['m_rate'] = 10;
    base_value = 4000;
  } else if (between(6000, position_size, 8000)) {
    value['m_rate'] = 12.5;
    base_value = 6000;
  } else if (between(8000, position_size, 10000)) {
    value['m_rate'] = 15;
    base_value = 8000;
  } else if (position_size > 10000) {
    value['m_rate'] = 25;
    base_value = 10000;
  }
  return { minimum: 100, value: value, base_value: base_value };
}

export function coin_leverage_125(
  position_size: number,
  symbol?: string,
  m_rate = 0.4,
) {
  let value = { m_rate: 0.4, m_a: 0, m_t: 0 };
  let base_value = 0;
  if (between(10, position_size, 20)) {
    value.m_rate = 0.5;
    base_value = 10;
  } else if (between(20, position_size, 30)) {
    value.m_rate = 1;
    base_value = 20;
  } else if (between(30, position_size, 50)) {
    value.m_rate = 2.5;
    base_value = 30;
  } else if (between(50, position_size, 100)) {
    value.m_rate = 5;
    base_value = 50;
  } else if (between(100, position_size, 200)) {
    value.m_rate = 10;
    base_value = 100;
  } else if (between(200, position_size, 400)) {
    value.m_rate = 12.5;
    base_value = 200;
  } else if (between(400, position_size, 1000)) {
    value.m_rate = 15;
    base_value = 400;
  } else if (position_size > 1000) {
    value.m_rate = 25;
    base_value = 1000;
  }

  return { minimum: 10, base_value: base_value, value: value };
}
export function leverage_125(
  position_size: number,
  symbol?: string,
  m_rate = 0.4,
) {
  let value = { m_rate: 0.4, m_a: 0, m_t: 0 };
  let base_value = 0;
  if (between(50000, position_size, 250000)) {
    value.m_rate = 0.5;
    base_value = 50000;
  } else if (between(250000, position_size, 1000000)) {
    value.m_rate = 1;
    base_value = 250000;
  } else if (between(1000000, position_size, 5000000)) {
    value.m_rate = 2.5;
    base_value = 1000000;
  } else if (between(5000000, position_size, 20000000)) {
    value.m_rate = 5;
    base_value = 5000000;
  } else if (between(20000000, position_size, 50000000)) {
    value.m_rate = 10;
    base_value = 20000000;
  } else if (between(50000000, position_size, 100000000)) {
    value.m_rate = 12.5;
    base_value = 50000000;
  } else if (between(100000000, position_size, 200000000)) {
    value.m_rate = 15;
    base_value = 100000000;
  } else if (position_size > 200000000) {
    value.m_rate = 25;
    base_value = 200000000;
  }

  return { minimum: 50000, base_value: base_value, value: value };
}
