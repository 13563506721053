import { HStack, Text } from '@chakra-ui/react';
import { IoChevronBackOutline } from 'react-icons/io5';

export type BackButtonProps = {
  header?: string;
  onGoBack: () => void;
};

export const BackButton = ({ header, onGoBack }: BackButtonProps) => {
  return (
    <HStack gap={{ base: '90px', md: '200px' }} my={5}>
      <IoChevronBackOutline fontSize={'30px'} onClick={onGoBack} />
      <Text fontWeight="bold">{header}</Text>
    </HStack>
  );
};
