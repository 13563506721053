import { Flex, Text, Box } from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import type { IBotStore, ICalculatorStore } from '../store/botConfig';
import Config1Display from './components/GenericConfigDisplay';
import LossMinimizerAnalysis from './components/LossMinimizerAnalysis';
import { CreateJobComponent } from './components/NewJobComponent';
import { Navigator } from './components/Sidebar';
import JobsViews from './JobsView';

const Calculator: FunctionComponent<{
  store: ICalculatorStore;
  rootStore?: IBotStore;
  buttonText?: string;
  page?: string;
  onClick: (params?: any) => any;
  isLoading?: boolean;
  onCreateTakeProfit?: any;
}> = observer(
  ({
    store,
    rootStore,
    children,
    page = 'jobs',
    isLoading,
    onCreateTakeProfit,
  }) => {
    const btnRef: any = React.useRef();
    return (
      <Flex direction="column">
        <Navigator>
          <Text alignSelf="center">
            {store.symbol} Calculation (Leverage: {store.leverage})
          </Text>

          {children}
        </Navigator>
        {page != 'jobs' ? (
          <>
            <LossMinimizerAnalysis
              store={store}
              isLoading={isLoading}
              createTakeProfit={onCreateTakeProfit}
            />

            <Config1Display
              store={store.defaultConfig}
              override="config_6"
              hideFields={store.hideFields}
              props={{ mt: 3, columns: 2, spacing: 5 }}
            />
          </>
        ) : rootStore ? (
          <Flex mt={2} flexDirection={['column', 'column', 'row']}>
            <Box width={['100%', '100%', '50%']}>
              <JobsViews store={rootStore} />
            </Box>
            <CreateJobComponent
              store={rootStore}
              // flex={[1, 1, 1, 0.9]}
              width={['100%', '100%', '50%']}
              config={rootStore.account?.defaultConfig}
            />
          </Flex>
        ) : null}
      </Flex>
    );
  },
);

export default Calculator;
