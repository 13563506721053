import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  Instance,
  types,
} from 'mobx-state-tree';
import type { TradeType } from '../apiHelper';
import type { OpenTradeType } from '../types';

const Trade = types
  .model('Trades', {
    price: types.number,
    quantity: types.number,
    kind: types.enumeration('TradeK', ['long', 'short']),
    side: types.enumeration('TradeS', ['buy', 'sell']),
    stop: types.optional(types.number, 0),
    type: types.optional(types.string, ''),
    market_type: types.enumeration('MarketType', ['margin', 'future']),
    borrow: types.maybeNull(types.boolean),
    repay: types.maybeNull(types.boolean),
  })
  .views((self) => {
    return {
      get as_json() {
        let result: any = {
          price: self.price,
          quantity: self.quantity,
          kind: self.kind,
          side: self.side,
        };
        if (self.market_type === 'margin') {
          if (self.borrow) {
            result.borrow = self.borrow;
          }
          if (self.repay) {
            result.repay = self.repay;
          }
          if (self.stop) {
            result.stop = true;
          }
        } else {
          if (self.type) {
            result.type = self.type;
          }
          if (self.stop) {
            result.stop = self.stop;
          }
        }
        return result;
      },
    };
  })
  .actions((self) => {
    return {
      updateSelf(params: any, market_type: 'future' | 'margin') {
        self.price = params.price;
        // self.
      },
    };
  });

const FutureMarket = types
  .model('FutureMarket', {
    symbol: types.string,
    currentPrice: types.optional(types.number, 0),
    pip: types.optional(types.number, 0),
    places: types.optional(types.number, 2),
  })
  .views((self) => {
    return {
      get increment() {
        return 1;
      },
    };
  })
  .actions((self) => {
    return {
      setCurrentPrice(price: number) {
        self.currentPrice = price;
      },
      setPip(pip: number) {
        self.pip = pip;
      },
      setSymbol(symbol: string) {
        self.symbol = symbol;
      },
      setPlaces(places: number) {
        self.places = places;
      },
    };
  });
const CandleResult = types.model('CandleResult', {
  index: types.number,
  value: types.number,
});
const FibonacciTrader = types
  .model('FibonacciTrader', {
    selectedHigh: types.optional(types.number, 0),
    selectedLow: types.optional(types.number, 0),
    timeFrame: types.optional(types.string, 'minutes_5'),
    swingHighs: types.optional(types.array(CandleResult), []),
    swingLows: types.optional(types.array(CandleResult), []),
  })
  .views((self) => {
    function calculateFibValue(percent: number, high: number, low: number) {
      return percent * (high - low) + low;
    }
    return {
      getSwingValue(kind: 'high' | 'low', currentPrice: number, action = '>') {
        let v = kind === 'high' ? self.swingHighs : self.swingLows;
        let result = v.filter((o) => {
          if (action === '>') {
            return o.value > currentPrice;
          }
          if (action === '>=') {
            return o.value >= currentPrice;
          }
          if (action === '<=') {
            return o.value <= currentPrice;
          }
          return o.value < currentPrice;
        });
        let clone = result.map((o) => o.value);
        // clone.sort((a: number, b: number) => {
        //     if (a < b) {
        //         return 1
        //     }
        //     if (a > b) {
        //         return -1
        //     }
        //     return 0
        // })
        return clone.reverse();
      },
      fibValues(high: number, low: number) {
        let fibNumbers = [0, 0.236, 0.382, 0.618, 0.789, 1];
        if (high && low) {
          return fibNumbers.map((o) => calculateFibValue(o, high, low));
        }
        return [];
      },
      get timeFrameOptions() {
        return [
          // { label: '3 min', value: 'minutes_3' },
          { label: '5 min', value: 'minutes_5' },
          { label: '15 min', value: 'minutes_15' },
          // { label: '30 min', value: 'minutes_30' },
          { label: '1 hr', value: 'hours_1' },
          // { label: '4 hr', value: 'hours_4' },
          // { label: '1 day', value: 'daily' },
          // { label: '1 wk', value: 'weekly' },
        ];
      },
      getFibValues(kind: 'long' | 'short') {
        let high = self.selectedHigh;
        let low = self.selectedLow;
        if (kind === 'long') {
          high = self.selectedLow;
          low = self.selectedHigh;
        }
        let clone = [...this.fibValues(high, low)];
        clone.sort((a: number, b: number) => {
          if (a < b) {
            return kind === 'long' ? -1 : 1;
          }
          if (a > b) {
            return kind == 'long' ? 1 : -1;
          }
          return 0;
        });
        return clone.map((o) => ({ label: o, value: o }));
      },
    };
  })
  .actions((self) => {
    const fetchSwingInfo: any = flow(function* fetchSwingInfo() {
      let parent: IStore = getParent(self, 1);
      try {
        let result = yield parent.getSwingInfo(self.timeFrame);
        //console.log('Fib results', result);
        self.swingHighs = cast(result.highs);
        self.swingLows = cast(result.lows);
      } catch (error) {}
    });
    return {
      setTimeFrame(timeFrame: string) {
        self.timeFrame = timeFrame;
        fetchSwingInfo();
      },
      setHigh(swingHigh: number) {
        self.selectedHigh = swingHigh;
      },
      setLow(swingLow: number) {
        self.selectedLow = swingLow;
      },
      fetchSwingInfo,
    };
  });
const Trader = types
  .model('Trader', {
    entryPrice: types.optional(types.number, 0),
    riskPercent: types.optional(types.number, 100),
    returns: types.optional(types.number, 2),
    sellPercent: types.optional(types.number, 100),
    walletBalance: types.optional(types.number, 0),
    pip: types.optional(types.number, 1),
    tradeKind: types.optional(
      types.enumeration('TradeKind', ['long', 'short']),
      'long',
    ),
    newTradeRisk: types.optional(types.number, 3),
    support: types.optional(types.number, 0),
    resistance: types.optional(types.number, 0),
  })
  .views((self) => {
    function getSpread(pipValue: number): number {
      let v = self.pip * pipValue;
      return parseFloat(v.toFixed(2));
    }
    function risk(_risk = self.riskPercent) {
      return (_risk * self.walletBalance) / 100;
    }
    function analyzeExistingTrade(openTrade: IOrder, market: IMarket) {
      const contracts = openTrade.quantity * openTrade.entryPrice;
      const stopPercent = risk() / contracts;
      let extryStop = stopPercent * openTrade.entryPrice;
      let stop =
        openTrade.kind === 'long'
          ? openTrade.entryPrice - extryStop
          : openTrade.entryPrice + extryStop;
      let sig = openTrade.kind === 'long' ? self.support : self.resistance;
      if (sig) {
        return { stop: sig, pip: openTrade.stopPipUsed(market.pip, sig) };
      }
      return { stop, pip: openTrade.stopPipUsed(market.pip, stop) };
    }
    function determinePnl(
      close_price: number,
      entry: number,
      quantity: number,
    ) {
      const difference =
        self.tradeKind === 'long' ? close_price - entry : entry - close_price;
      const result = difference * quantity;
      return result.toFixed(4);
    }
    function reverseClose(
      close_price: number,
      entry: number,
      quantity: number,
    ) {
      let pnl = parseFloat(determinePnl(close_price, entry, quantity));
      let openFee = (entry * quantity * 0.04) / 100;
      let closeFee = (close_price * quantity * 0.04) / 100;
      let totalFee = openFee + closeFee;
      let difference = (pnl + totalFee) / quantity;
      let result =
        self.tradeKind == 'long' ? difference + entry : entry - difference;
      return result;
    }
    return {
      determinePnl,
      get significantArea() {
        return self.tradeKind == 'long' ? self.support : self.resistance;
      },
      get risk() {
        return risk();
      },
      get newTradeRiskValue() {
        return risk(self.newTradeRisk);
      },
      getNewEntryAndSize(
        market: IMarket,
        openTrade?: IOrder | null,
        price?: number,
      ) {
        let currentSize = this.getSize(market, price);
        let p = price || market.currentPrice;
        if (openTrade) {
          let quantity = openTrade.quantity + currentSize;
          let avg =
            (openTrade.quantity * openTrade.entryPrice + currentSize * p) /
            quantity;
          return { entryPrice: avg, quantity, currentSize };
        }
        return { entryPrice: p, quantity: currentSize, currentSize };
      },
      getStop(openTrade: IOrder, market: IMarket) {
        return analyzeExistingTrade(openTrade, market);
      },
      getSpread,
      getSize(market: IMarket, price?: number) {
        const currentPrice = price || market.currentPrice;
        const stopPercent = getSpread(market.pip) / currentPrice;
        const contractSize = risk() / stopPercent;
        const size = contractSize / currentPrice;
        return parseFloat(size.toFixed(4));
      },
      getSupportSize(price: number, kind?: any) {
        let _kind = kind || self.tradeKind;
        let stop = _kind === 'long' ? self.support : self.resistance;
        const stopPercent = Math.abs(stop - price) / price;
        const contractSize = risk() / stopPercent;
        const size = contractSize / price;
        return parseFloat(size.toFixed(4));
      },
      getFibSupport(
        price: number,
        stop: number,
        kind?: any,
        returns = false,
        openTrade: IOrder | undefined = undefined,
      ) {
        let _kind = kind || self.tradeKind;
        const spread = Math.abs(stop - price);
        const stopPercent = Math.abs(stop - price) / price;
        const contractSize = risk() / stopPercent;
        let size = contractSize / price;
        // if (openTrade) {
        //     size += size + openTrade.quantity
        // }
        if (returns) {
          let r_factor = spread * self.returns;
          let close = _kind == 'long' ? price + r_factor : price - r_factor;
          let newEntry = price;
          if (openTrade) {
            let newSize = size + openTrade.quantity;
            newEntry =
              (openTrade.entryPrice * openTrade.quantity + price * size) /
              newSize;
            close = _kind == 'long' ? newEntry + r_factor : newEntry - r_factor;
            // size = newSize
          }
          let sell_size = (size * self.sellPercent) / 100;
          let oo = reverseClose(close, newEntry, parseFloat(size.toFixed(4)));
          return {
            close: oo,
            size: parseFloat(size.toFixed(4)),
            pnl: determinePnl(oo, newEntry, sell_size),
          };
        }
        return parseFloat(size.toFixed(4));
      },
      getStopLoss(market: IMarket, price?: number) {
        const spread = getSpread(market.pip);
        const currentPrice = price || market.currentPrice;
        return self.tradeKind === 'long'
          ? currentPrice - spread
          : currentPrice + spread;
      },
    };
  })
  .actions((self) => {
    return {
      updateSignificantAreas(value: { support: number; resistance: number }) {
        self.support = value.support;
        self.resistance = value.resistance;
      },
      setSignificantArea(value: number): any {
        if (self.tradeKind === 'long') {
          self.support = value;
        } else {
          self.resistance = value;
        }
        const parent: IStore = getParent(self, 1);
        parent.saveSignificantAreas({
          support: self.support,
          resistance: self.resistance,
        });
      },
      setBalance(balance: number) {
        self.walletBalance = balance;
      },
      setRiskPercent(percent: number) {
        self.riskPercent = percent;
      },
      setSellPercent(percent: number) {
        self.sellPercent = percent;
      },
      setReturns(returns: number) {
        self.returns = returns;
      },
      setPip(pip: number) {
        self.pip = pip;
      },
      setTradeKind(kind: 'long' | 'short') {
        self.tradeKind = kind;
      },
      prepareNewTrade(risk: number) {
        self.newTradeRisk = risk;
      },
    };
  });
export const Order = types
  .model('Order', {
    id: types.maybeNull(types.number),
    symbol: types.string,
    entryPrice: types.number,
    kind: types.string,
    quantity: types.number,
    leverage: types.optional(types.number, 20),
    stop: types.maybeNull(types.number),
    takeProfit: types.maybeNull(types.number),
    closed: types.optional(types.boolean, false),
    status: types.optional(
      types.enumeration('TradeStatus', ['', 'stop', 'profit']),
      '',
    ),
    pnl: types.optional(types.number, 0),
    sellPercent: types.optional(types.number, 100),
    maximumLeverage: types.optional(types.number, 125),
  })
  .actions((self) => {
    function determinePnl(close_price: number) {
      const difference =
        self.kind === 'long'
          ? close_price - self.entryPrice
          : self.entryPrice - close_price;
      const result = difference * self.quantity;
      return result.toFixed(4);
    }
    function risk(close_price: number) {
      let stopPercent =
        Math.abs(self.entryPrice - close_price) / self.entryPrice;
      let contract = self.quantity * self.entryPrice;
      return contract * stopPercent;
    }

    return {
      setPnl(currentPrice: number) {
        self.pnl = parseFloat(determinePnl(currentPrice));
      },
      setSellPercent(sellPercent: number) {
        self.sellPercent = sellPercent;
      },
      setStopLoss(value: number) {
        self.stop = value;
      },
      setTakeProfit(value: number) {
        self.takeProfit = value;
      },
      updateSelf(value: any) {
        applySnapshot(self, value);
      },
      setClosed(currentPrice: number) {
        if (currentPrice > 0) {
          if (self.kind == 'long') {
            if (self.stop) {
              self.closed = currentPrice <= self.stop;
              self.status = 'stop';
              self.pnl = parseFloat(determinePnl(self.stop));
            }
            if (self.takeProfit) {
              if (!self.closed) {
                self.closed = currentPrice >= self.takeProfit;
                self.status = 'profit';
                self.pnl = parseFloat(determinePnl(self.takeProfit));
              }
            }
          } else {
            if (self.stop) {
              self.closed = currentPrice >= self.stop;
            }
            if (self.takeProfit) {
              if (!self.closed) {
                self.closed = currentPrice <= self.takeProfit;
              }
            }
          }
        }
      },
    };
  })
  .views((self) => {
    function determinePnl(
      close_price: number,
      entry = self.entryPrice,
      quantity = self.quantity,
    ) {
      const difference =
        self.kind === 'long' ? close_price - entry : entry - close_price;
      const result = difference * quantity;
      return result.toFixed(4);
    }
    function reverseClose(close_price: number, places: number) {
      let pnl = parseFloat(determinePnl(close_price));
      let openFee = (self.entryPrice * self.quantity * 0.04) / 100;
      let closeFee = (close_price * self.quantity * 0.04) / 100;
      let totalFee = openFee + closeFee;
      let difference = (pnl + totalFee) / self.quantity;
      let result =
        self.kind == 'long'
          ? difference + self.entryPrice
          : self.entryPrice - difference;
      return result.toFixed(places);
    }
    return {
      getStopValue(
        pip: number,
        market: IMarket,
        kind: 'stop' | 'profit',
        withFee = false,
      ): number {
        let value = this.getPriceValue(
          market.pip,
          self.kind === 'long' ? pip : -1 * pip,
          kind,
        );
        //console.log(`value is ${value} and pip is ${market}`);
        // if (withFee) {
        //     return this.idealClose(value)
        // }
        return value;
      },
      idealClose(close_price: number, places = 2) {
        return reverseClose(close_price, places);
      },
      getPriceValue(pipValue: number, pip: number, type: 'stop' | 'profit') {
        let spread = pipValue * pip;
        return type === 'profit'
          ? self.entryPrice + spread
          : self.entryPrice - spread;
      },
      takeProfitPnl(type: 'stop' | 'profit', market: IMarket, pip: number) {
        let close_price = this.getStopValue(pip, market, type);
        let feeClose = this.idealClose(close_price, market.places);
        let result = this.determinePnl(
          parseFloat(feeClose),
          self.entryPrice,
          this.sizeToSell,
        );
        return {
          pnl: result,
          close: feeClose,
          quantity: this.sizeToSell,
        };
      },
      get sizeToSell() {
        return (self.quantity * self.sellPercent) / 100;
      },

      risk() {
        if (self.stop) {
          let stopPercent =
            Math.abs(self.entryPrice - self.stop) / self.entryPrice;
          let contract = self.quantity * self.entryPrice;
          return contract * stopPercent;
        }
        return 0;
      },
      stopPipUsed(pipValue: number, stop?: number) {
        if (stop) {
          return Math.round(Math.abs(self.entryPrice - stop) / pipValue);
        }
        if (!self.stop) {
          return 1;
        }
        return Math.round(Math.abs(self.entryPrice - self.stop) / pipValue);
      },
      takeProfitPipUsed(pipValue: number) {
        if (!self.takeProfit) {
          return 1;
        }
        return Math.round(
          Math.abs(self.entryPrice - self.takeProfit) / pipValue,
        );
      },
      get getStopPnl() {
        if (!self.stop) {
          return 0;
        }
        return determinePnl(self.stop);
      },
      get getTakeProfitPnl() {
        if (!self.takeProfit) {
          return 0;
        }
        return determinePnl(self.takeProfit);
      },
      determinePnl,
    };
  });
export type AdapterType = {
  getSwingInfo: (
    params: any,
  ) => Promise<{
    highs: Array<{ index: number; value: number }>;
    lows: Array<{ index: number; value: number }>;
  }>;
  increasePosition: (params: any) => Promise<any>;
  getConnections: (streams: Array<string>) => WebSocket;
  disconnectAllStreams: () => void;
  bulkTrades: (symbol: string, trades: any[]) => any;
  loadInitialData: (
    symbol: string,
    kind?: 'long' | 'short',
  ) => Promise<{ pip: number; balance: number; openTrade?: OpenTradeType }>;
  createNewTrade: (params: TradeType) => Promise<IOrder>;
  updateStopLoss: (params: any) => Promise<number>;
  updateTakeProfit: (params: any) => Promise<IOrder>;
  marketCloseTrade: (params: any) => Promise<any>;
  onSocketMessageCallback: (callback: (price: number) => any) => any;
  initializeSockets: (
    socket: any,
    streams: Array<string>,
    callback: any,
  ) => any;
};
export const AddNewSymbolStore = types
  .model('AddNewSymbol', {
    symbols: types.optional(types.array(types.string), ['BTCUSDT']),
  })
  .actions((self) => {
    const tradeSymbols = `TRADE_SYMBOLS`;
    function initializeStorage() {}
    return {
      afterCreate() {
        let symbols = window.localStorage.getItem(tradeSymbols);
        if (symbols && symbols !== 'undefined' && symbols !== 'null') {
          this.setSymbols(JSON.parse(symbols));
          // self.symbols = ;
        }
        // initializeStorage()
      },
      addWatchSymbol(symbol: string) {
        let clean = [...new Set([...self.symbols, symbol.toUpperCase()])];
        window.localStorage.setItem(tradeSymbols, JSON.stringify(clean));
        self.symbols = cast(clean);
      },
      setSymbols(symbols: string[]) {
        self.symbols = cast(symbols);
      },
    };
  });
export const Store = types
  .model('Store', {
    market: FutureMarket,
    trader: types.optional(Trader, {}),
    fibTrader: types.optional(FibonacciTrader, {}),
    orders: types.optional(types.array(Order), []),
    currentMarket: types.optional(types.string, 'BTCUSDT'),
    // openTrade: types.maybeNull(Order),
    currentTradeIndex: types.optional(types.number, -1),
    autoUpdate: types.optional(types.number, 45),
    entryPrice: types.optional(types.number, 0),
    kind: types.optional(types.string, 'future'),
    // symbols: types.optional(types.array(types.string), ['BTCUSDT']),
    currentStrategy: types.optional(
      types.enumeration('Strategy', ['pip', 'fibonacci']),
      'pip',
    ),
    addSymbol: types.optional(AddNewSymbolStore, {}),
  })
  .views((self) => {
    return {
      // get market(){
      //     return FutureMarket.create({symbol:self})
      // },
      get symbols() {
        return self.addSymbol.symbols;
      },
      get orderOptions() {
        // let labels = self.orders.map(i => i.kind)
        // if (labels.length == 2) {
        //     return labels.map((i, k) => ({ label: i, value: k }))
        // }
        // if (labels.length == 1) {
        //     let v = labels[0]
        //     if (v === "long") {
        //         return [{ label: "long", value: 0 }, { label: "short", value: 1 }]
        //     }
        //     if (v === 'short') {
        //         return [{ label: "long", value: 1 }, { label: "short", value: 0 }]
        //     }
        // }
        return [
          { label: 'long', value: 0 },
          { label: 'short', value: 1 },
        ];
        // return self.orders.map((i, j) => ({ label: i.kind, value: j }))
      },
      get openTrade() {
        return self.orders[self.currentTradeIndex] || null;
      },
      get getEntryPrice() {
        return self.entryPrice || self.market.currentPrice;
      },
      get getNewEntryAndSize() {
        return self.trader.getNewEntryAndSize(
          self.market,
          this.openTrade,
          self.entryPrice,
        );
      },
      get strategies() {
        return ['pip', 'fibonacci'];
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{
      adapter: AdapterType;
    }>(self);
    const owner = 'main_account';
    let socket: any;
    let interval: any;
    let secondInterval: any;
    function removeCurrentOrder() {
      self.orders = cast(
        self.orders.filter((x, i) => i != self.currentTradeIndex),
      );
      self.currentTradeIndex = -1;
    }
    const failedOrSuccessfulTrade = flow(function* failedOrSuccessfulTrade() {
      try {
        if (self.openTrade) {
          // if (self.openTrade.closed) {
          //     let data = getSnapshot(self.openTrade)
          //     let risk = self.openTrade.risk()
          //     removeCurrentOrder()
          //     yield adapter.marketCloseTrade({ ...data, risk })
          // }
        }
      } catch (error) {
        throw error;
      }
    });
    function initializeSockets(callback?: any) {
      socket = adapter.initializeSockets(
        socket,
        [self.market.symbol],
        (price: number) => {
          self.market.setCurrentPrice(price);
        },
      );
      if (callback) {
        callback();
      }
    }
    function initializeCurrentOrder(openTrade: any) {
      const orders = self.orders.map((x, i) => {
        if (i === self.currentTradeIndex) {
          return openTrade;
        }
        return getSnapshot(x);
      });
      self.orders = cast(orders);
      // self.openTrade = Order.create(openTrade);
    }
    const getAccountInfo = flow(function* getAccountInfo() {
      try {
        const {
          pip,
          balance,
          positions,
          places,
        } = yield adapter.loadInitialData(
          self.market.symbol,
          self.trader.tradeKind,
        );
        self.trader.setBalance(balance);
        self.market.setPip(pip);
        self.market.setPlaces(places);
        //console.log('places', places);
        self.orders = cast(positions);
        let openTrade;
        if (positions.length > 0) {
          if (self.currentTradeIndex < 0) {
            self.currentTradeIndex = 0;
          }
          openTrade = positions[self.currentTradeIndex];
        }
        if (openTrade) {
          if (self.openTrade) {
            let snapshot = getSnapshot(self.openTrade);
            self.openTrade.updateSelf({ ...snapshot, ...openTrade });
          } else {
            initializeCurrentOrder(openTrade);
          }
          updateTradeConfig();
        }
      } catch (error) {
        console.error('Error occured in getAccountInfo', error);
      }
    });
    function updateTradeConfig() {
      if (self.openTrade) {
        let stopConfig = self.trader.getStop(self.openTrade, self.market);
        self.trader.setPip(stopConfig.pip);
        if (!self.openTrade.stop) {
          self.openTrade.setStopLoss(stopConfig.stop);
        }
      }
    }
    const initializeState = flow(function* initializeState() {
      try {
        yield getAccountInfo();
        initializeSockets();
      } catch (error) {}
    });
    const createNewTrade = flow(function* createNewTrade(
      price: number,
      isMarketTrade: boolean,
      setStop: boolean,
    ) {
      try {
        const createdTrade = yield adapter.createNewTrade({
          symbol: self.market.symbol,
          entry: price,
          quantity: self.trader.getSize(self.market, price),
          stop: setStop ? self.trader.getStopLoss(self.market, price) : null,
          kind: self.trader.tradeKind,
          isMarketTrade,
        });
        if (createdTrade.entryPrice > 0) {
          yield getAccountInfo();
          // initializeCurrentOrder(createdTrade)
        }
      } catch (error) {
        //console.log(error);
        throw error;
      }
    });
    const updateStopLoss = flow(function* updateStopLoss(
      pip: number,
      stopPrice?: number,
    ) {
      try {
        if (self.openTrade) {
          const snapshot = getSnapshot(self.openTrade);
          //console.log('stopPrice', { stopPrice });
          const stopLoss = yield adapter.updateStopLoss({
            ...snapshot,
            stopPrice,
            pip,
          });
          self.openTrade.setStopLoss(stopLoss);
        }
      } catch (error) {
        throw error;
      }
    });
    const updateTakeProfit = flow(function* updateTakeProfit(
      pip: number,
      takeProfitPrice?: number,
      percent?: number,
    ) {
      try {
        if (self.openTrade) {
          const snapshot = getSnapshot(self.openTrade);
          const tk = self.openTrade.takeProfitPnl('profit', self.market, pip);
          //console.log('stopPrice', { takeProfitPrice, ...tk });
          const tradeInstance = yield adapter.updateTakeProfit({
            ...snapshot,
            pip,
            takeProfitPrice,
            // takeProfitPrice: parseFloat(tk.close),
            quantity: tk.quantity,
          });
          self.openTrade.setTakeProfit(tradeInstance.takeProfit);
          // self.openTrade.updateSelf(tradeInstance);
        }
      } catch (error) {
        throw error;
      }
    });
    const marketCloseTrade = flow(function* marketCloseTrade(
      currentPrice: number,
    ) {
      try {
        if (self.openTrade) {
          self.openTrade.setPnl(currentPrice);
          let risk = self.openTrade.risk();
          const snapshot = getSnapshot(self.openTrade);
          const quantity = self.openTrade.sizeToSell;
          yield adapter.marketCloseTrade({
            ...snapshot,
            stop: currentPrice,
            stopPrice: currentPrice,
            risk,
            quantity,
          });
          if (quantity === self.openTrade.quantity) {
            removeCurrentOrder();
          }
        }
      } catch (error) {
        throw error;
      }
    });
    const controlledEntryTrade = flow(function* controlledEntryTrade(
      quantity?: number,
    ) {
      try {
        let _quantity =
          quantity || self.trader.getSize(self.market, self.getEntryPrice);
        if (!quantity) {
          if (self.currentStrategy === 'fibonacci') {
            _quantity = self.trader.getSupportSize(self.getEntryPrice);
          }
        }
        let params = {
          entry: self.getEntryPrice,
          kind: self.trader.tradeKind,
          quantity: _quantity,
          symbol: self.market.symbol,
        };
        //console.log(params);
        yield adapter.increasePosition(params);
      } catch (error) {
        throw error;
      }
    });
    const getSwingInfo = flow(function* getSwingInfo(timeFrame: string) {
      return yield adapter.getSwingInfo({
        timeFrame,
        symbol: self.market.symbol,
        kind: self.kind,
      });
    });
    function buildFutureTrades(
      entry: number,
      future_quantity: number,
      future_kind: 'long' | 'short',
      future_side: 'buy' | 'sell',
      current_price: number,
      close: number,
      close_quantity: number,
    ) {
      let od: any = {
        price: entry,
        quantity: future_quantity,
        kind: future_kind,
        side: future_side,
        stop: entry,
      };
      if (future_side == 'buy' && current_price > entry) {
        delete od.stop;
      }
      if (future_side == 'sell' && current_price < entry) {
        delete od['stop'];
      }
      let future_orders = [od];

      let _type = 'STOP';
      let zide = future_side == 'sell' ? 'buy' : 'sell';
      if (zide == 'sell' && current_price < close) {
        _type = 'TAKE_PROFIT';
      }
      if (zide == 'buy' && current_price > close) {
        _type = 'TAKE_PROFIT';
      }
      future_orders.push({
        price: close,
        quantity: close_quantity,
        kind: future_kind,
        side: zide,
        stop: close,
        type: _type,
      });
      return future_orders;
    }
    
    const increasePosition = flow(function* increasePosition(
      params: any,
      currentPrice: number,
    ) {
      try {
        if (params) {
          let { size, close } = params;
          let close_size = (size * self.trader.sellPercent) / 100;
          let buy_kind: 'buy' | 'sell' =
            self.trader.tradeKind === 'long' ? 'buy' : 'sell';
          let result = buildFutureTrades(
            self.entryPrice,
            size,
            self.trader.tradeKind,
            buy_kind,
            self.market.currentPrice,
            close,
            close_size,
          );
          //console.log(result);
          yield adapter.bulkTrades(self.market.symbol, result);
        } else {
          if (self.openTrade) {
            let newTrade = self.trader.getNewEntryAndSize(
              self.market,
              self.openTrade,
            );
            let quantity = newTrade.currentSize;
            if (self.currentStrategy === 'fibonacci') {
              quantity = self.trader.getSupportSize(self.getEntryPrice);
            }
            //console.log('New Trade', {
            //   entry: self.getEntryPrice,
            //   ...newTrade,
            //   quantity,
            // });
            let openTrade = yield adapter.increasePosition({
              entry: self.getEntryPrice,
              kind: self.openTrade.kind,
              quantity,
              symbol: self.openTrade.symbol,
            });
            if (openTrade) {
              let snapshot = getSnapshot(self.openTrade);
              self.openTrade.updateSelf({ ...snapshot, ...openTrade });
            }
          }
        }
      } catch (error) {
        console.error('Error occured in increasePosition', error);
        throw error;
      }
    });
    const strategyKey = `TRADER_STRATEGY`;
    const tradeSymbols = `TRADE_SYMBOLS`;
    const lastMarket = `LAST_MARKET`;
    function initializeStorage() {
      const significantKey = `${self.market.symbol}_TRADER_SIGNIFICANT_AREA`;
      let item = window.localStorage.getItem(significantKey);
      if (item && item !== 'undefined' && item !== 'null') {
        self.trader.updateSignificantAreas(JSON.parse(item));
      }
      let strategy = window.localStorage.getItem(strategyKey);
      if (strategy && strategy !== 'undefined' && strategy !== 'null') {
        self.currentStrategy = strategy as 'pip' | 'fibonacci';
      }
      let symbols = window.localStorage.getItem(tradeSymbols);
      if (symbols && symbols !== 'undefined' && symbols !== 'null') {
        self.addSymbol.setSymbols(JSON.parse(symbols));
        // self.symbols = ;
      }
      let lM = window.localStorage.getItem(lastMarket);
      if (lM && lM !== 'undefined' && lM !== 'null') {
        if (self.market.symbol !== lM) {
          self.market.setSymbol(lM);
        }
      }
    }
    return {
      refreshSockets(destroyCallback?: any, callback?: any) {
        if (socket) {
          if (destroyCallback) {
            destroyCallback();
          }
        }
        initializeSockets(callback);
      },
      initializeState,
      getAccountInfo: () => {
        getAccountInfo();
      },
      afterCreate() {
        self.fibTrader.fetchSwingInfo();
        initializeStorage();
        initializeState();
        // interval = setInterval(this.getAccountInfo, self.autoUpdate * 1000);
        // secondInterval = setInterval(this.watchOpenTrade, 1000);
      },
      watchOpenTrade() {
        failedOrSuccessfulTrade();
      },
      beforeDestroy() {
        if (socket) {
          adapter.disconnectAllStreams();
        }
        if (interval) {
          clearInterval(interval);
        }
        if (secondInterval) {
          clearInterval(secondInterval);
        }
      },
      initializeSockets,
      createNewTrade,
      updateStopLoss,
      updateTakeProfit,
      marketCloseTrade,
      increasePosition,
      controlledEntryTrade,
      setEntryPrice(value: number) {
        self.entryPrice = value;
      },
      switchOpenOrder(index: number) {
        let selectedOption = self.orderOptions.find((o, i) => i === index);
        self.currentTradeIndex = index;
        if (selectedOption) {
          self.trader.setTradeKind(selectedOption.label as 'long' | 'short');
        }
        updateTradeConfig();
        getAccountInfo();
      },
      getSwingInfo,
      saveSignificantAreas(value: any) {
        const significantKey = `${self.market.symbol}_TRADER_SIGNIFICANT_AREA`;
        window.localStorage.setItem(significantKey, JSON.stringify(value));
      },
      setKind(kind: string) {
        self.kind = kind;
      },
      switchStrategy(value: 'pip' | 'fibonacci') {
        self.currentStrategy = value;
        window.localStorage.setItem(strategyKey, self.currentStrategy);
      },
      changeMarket(market: string) {
        if (self.market.symbol != market) {
          self.market.setSymbol(market);
          self.market.setPip(0);
          self.market.setCurrentPrice(0);
          window.localStorage.setItem(lastMarket, market);
          this.beforeDestroy();
          this.afterCreate();
        }
      },
      addWatchSymbol(symbol: string) {
        self.addSymbol.addWatchSymbol(symbol);
      },
    };
  });

export interface IOrder extends Instance<typeof Order> {}
export interface IStore extends Instance<typeof Store> {}
export interface IMarket extends Instance<typeof FutureMarket> {}
export interface IAddSymbol extends Instance<typeof AddNewSymbolStore> {}
export default Store;

// "minutes_15": root_kls.KLINE_INTERVAL_15MINUTE,
// "minutes_30": root_kls.KLINE_INTERVAL_30MINUTE,
// "hours_4": root_kls.KLINE_INTERVAL_4HOUR,
// "hours_1": root_kls.KLINE_INTERVAL_1HOUR,
// "weekly": root_kls.KLINE_INTERVAL_1WEEK,
// "daily": root_kls.KLINE_INTERVAL_1DAY,
