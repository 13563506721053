import {
  Checkbox,
  useBreakpointValue,
  Flex,
  Box,
  Grid,
  Spinner,
} from '@chakra-ui/react';
import Config1Display from '../../currentBot/components/GenericConfigDisplay';
import { TextValueDisplay } from './ExchangeSummaryView';
import { Exchange, ZoneConfig } from '../stores';
import { observer } from 'mobx-react-lite';

export type SectionConfigProps = {
  onGetLatestResistance: () => Promise<void>;
  config: ZoneConfig;
  loading: boolean;
};

export const SectionConfig = observer(
  ({ onGetLatestResistance, config, loading }: SectionConfigProps) => {
    const isMobile = useBreakpointValue({ base: true, lg: false });
    const defaultText = 'Get Value from server';
    return (
      <Config1Display
        extraText={loading ? <Spinner size={'xs'} /> : defaultText}
        onClickText={onGetLatestResistance}
        props={
          isMobile
            ? { mt: 3, columns: 2, spacing: 3 }
            : { mt: 3, columns: 3, spacing: 5 }
        }
        override="config_1"
        store={config}
      />
    );
  },
);

type OrdersProps = {
  entry: number;
  price: number;
  size: number;
  pnl: number;
  liquidation: number;
};

type LabelProps = {
  Title: string;
  accessor: string;
};

export type SectionSummaryProps = {
  labels?: Array<{
    title: string;
    value: string;
  }>;
  stopLossChecked?: boolean;
  onCheckChange?: () => void;
};

export const SectionSummary = observer(
  ({ labels = [], stopLossChecked, onCheckChange }: SectionSummaryProps) => {
    return (
      <Box
        border="1px"
        borderRadius="lg"
        borderStyle="dashed"
        rounded="xl"
        my={10}
      >
        <Checkbox
          pl={2}
          isChecked={stopLossChecked}
          onChange={(e) => {
            onCheckChange && onCheckChange(e.target.checked);
          }}
        >
          Stop loss direction{' '}
        </Checkbox>
        <Grid
          templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={2}
          m={3}
        >
          {labels.map((label, i) => (
            <TextValueDisplay
              key={label.title}
              text={label.title}
              value={label.value}
            />
          ))}
        </Grid>
      </Box>
    );
  },
);
