import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Heading,
  Stack,
  Switch,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FaCreditCard, FaPlus, FaRedo } from 'react-icons/fa';
import { RxReset } from 'react-icons/rx';
import Config1Display from '../../../currentBot/components/GenericConfigDisplay';
import { IPositionStore } from '../../../store/botConfig';
import { ISolvedTraderConfig } from '../../../store/solvedConfig';
import {
  IFutureOrderConfig,
  IMarginOrderConfig,
  IMarketTypeInstance,
} from '../../../store/solvedConfig/types';
import { ConfirmDialog } from '../primitives/ModalComponent';
import { DynamicTable } from './DynamicTable';
import { StatWithIndicator } from './StatWithIndicator';
import { TradeZoneControl } from './TradeZoneControl';

const SwitchControl = ({
  onMarketChange,
  market_type,
}: {
  onMarketChange: () => void;
  market_type: 'margin' | 'future';
}) => {
  return (
    <FormControl
      ml={2}
      display={{ base: 'none', lg: 'flex' }}
      width="auto"
      alignSelf={'flex-end'}
    >
      <HStack>
        <Switch
          size="lg"
          onChange={onMarketChange}
          isChecked={market_type === 'future'}
        />

        {market_type === 'margin' ? (
          <Text color="gray" size="sm">
            Switch to Future
          </Text>
        ) : (
          <Text color="gray" size="sm">
            Switch to Margin
          </Text>
        )}
      </HStack>
    </FormControl>
  );
};

type TradeControl = {
  isMobile?: boolean;
  config: IMarginOrderConfig | IFutureOrderConfig;
  onCreate: () => void;
};
const MarginTradeControl = ({ isMobile, config, onCreate }: TradeControl) => {
  return (
    <>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Config1Display
          override="margin_orders"
          store={config}
          Container={isMobile ? VStack : HStack}
        />
        <ConfirmDialog
          heading={`Creating the trade confirmation`}
          confirmAction={() => {
            // account?.onCreate(config, 'margin');
            onCreate();
          }}
          actionText="Create"
          colorScheme="green"
          button={
            <Button
              leftIcon={<FaPlus />}
              size="md"
              alignSelf={{ md: 'flex-end' }}
              colorScheme="green"
            >
              Create
            </Button>
          }
        />
      </Stack>
    </>
  );
};

const FutureTradeControl = ({ isMobile, config, onCreate }: TradeControl) => {
  return (
    <>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Config1Display
          override="future_orders"
          store={config}
          Container={isMobile ? VStack : HStack}
        />
        <ConfirmDialog
          heading={`Creating the trade confirmation`}
          confirmAction={() => {
            // account?.onCreate(config, 'margin');
            onCreate();
          }}
          actionText="Create"
          colorScheme="green"
          button={
            <Button
              leftIcon={<FaPlus />}
              size="md"
              alignSelf={{ md: 'flex-end' }}
              colorScheme="green"
            >
              Create
            </Button>
          }
        />
      </Stack>
    </>
  );
};

type OrderButtonTypes = {
  account: IMarketTypeInstance;
  onTransfer: any;
  marketType: 'margin' | 'future';
  newData: any;
  onReset: () => void;
  onCreateAccount?: () => void;
};

export const OrdersButton = ({
  account,
  onTransfer,
  marketType,
  newData,
  onReset,
  onCreateAccount
}: OrderButtonTypes) => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      alignSelf={{ md: 'flex-end' }}
      marginTop={{ base: 4, '2xl': 0 }}
    >
      <Button
        isLoading={account?.loading}
        loadingText="Refreshing"
        colorScheme="yellow"
        leftIcon={<FaRedo />}
        onClick={account?.onRefresh}
        size="sm"
      >
        Refresh
      </Button>
      <Button
        onClick={onTransfer}
        leftIcon={<FaCreditCard />}
        size="sm"
        colorScheme="blue"
      >
        Transfer
      </Button>
      {marketType === 'future' ? (
        newData ? (
          <Button
            onClick={onReset}
            leftIcon={<RxReset />}
            size="sm"
            colorScheme="red"
          >
            Reset
          </Button>
        ) : null
      ) : (
        <Button
          onClick={onTransfer}
          leftIcon={<FaCreditCard />}
          size="sm"
          colorScheme="green"
        >
          Place margin order
        </Button>
      )}
      {onCreateAccount ? (
        <Button
          onClick={onCreateAccount}
          leftIcon={<FaPlus />}
          size="sm"
          colorScheme="green"
        >
          Create Account
        </Button>
      ) : null}
    </Stack>
  );
};

export const MarginTradeSection: React.FC<{
  onTransfer: any;
  store: ISolvedTraderConfig;
  positionStore?: IPositionStore; // we would be using the positions for the account
  viewOrders?: () => void;
  accountInfo: any;
  onAccountSwitch: (s: string) => void;
}> = ({ onTransfer, store, viewOrders, accountInfo, onAccountSwitch }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const market_type = tabIndex === 1 ? 'margin' : 'future';
  const config =
    market_type === 'margin' ? store.margin_config : store.future_config;
  const account =
    market_type === 'margin' ? store.marginAccount : store.futureAccount;
  const { stats } = store;
  const kind = store.futureCTrader.config.kind;
  const newEntry = kind === 'long' ? account?.longEntry : account?.shortEntry;

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const newEntryTrade =
    newEntry?.trades?.map((x, i) => {
      const { entry, ...rest } = x;
      return {
        price: entry,
        kind: kind,
        side: 'buy',
        ...rest,
      };
    }) || undefined;

  return (
    <>
      <StatWithIndicator
        type="order"
        stats={
          isMobile && stats
            ? stats.filter((i) => i.market_type === market_type)
            : stats
        }
        tabList={['Future', 'Margin']}
        onTabChange={setTabIndex}
        viewOrders={viewOrders}
        store={store}
        accountInfo={accountInfo}
      />
      <Box pt="6" bg="white">
        <Flex
          maxW="7xl"
          m="auto"
          px={{ base: '6', md: '8' }}
          direction={{ base: 'column', '2xl': 'row' }}
          justifyContent="space-between"
        >
          <PlaceTradeAction
            config={config}
            account={account}
            isMobile={isMobile}
            market_type={market_type}
            onMarketChange={() => {
              if (tabIndex === 0) {
                setTabIndex(1);
              } else {
                setTabIndex(0);
              }
            }}
          />
          <OrdersButton
            onTransfer={onTransfer}
            account={account}
            marketType={market_type}
            newData={newEntryTrade}
            onReset={() => newEntry?.onReset()}
          />
        </Flex>
      </Box>
      <TradeZoneControl store={store} account={accountInfo} />
      <DynamicTable
        store={store}
        market_type={market_type === 'future' ? 'zones' : market_type}
        edit={true}
        newData={newEntryTrade}
      />
    </>
  );
};

type PlaceTradeActionProps = {
  config: IMarginOrderConfig;
  account?: IMarketTypeInstance;
  market_type: 'margin' | 'future';
  isMobile?: boolean;
  onMarketChange: () => void;
};
export const PlaceTradeAction = observer(
  ({
    config,
    account,
    isMobile,
    market_type,
    onMarketChange,
  }: PlaceTradeActionProps) => {
    return (
      <Box pt={4}>
        <Heading
          alignSelf="flex-start"
          fontSize={{ base: '1.25rem', md: '1.5rem' }}
          fontWeight="semibold"
          display="flex"
          color={useColorModeValue('black', 'white')}
        >
          {market_type === 'margin' ? 'Margin' : 'Futures'} Orders
          <SwitchControl
            market_type={market_type}
            onMarketChange={onMarketChange}
          />
        </Heading>
        {market_type === 'future' ? (
          <FutureTradeControl
            isMobile={isMobile}
            onCreate={() => account?.onCreate(config, 'future')}
            config={config}
          />
        ) : (
          <MarginTradeControl
            isMobile={isMobile}
            onCreate={() => account?.onCreate(config, 'margin')}
            config={config}
          />
        )}
      </Box>
    );
  },
);

export default observer(MarginTradeSection);
