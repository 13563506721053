import { observer } from 'mobx-react-lite';

import {
  Box,
  Text,
  Drawer,
  Divider,
  DrawerContent,
  DrawerOverlay,
  Flex,
  List,
  ListItem,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import type { IBotStore, ISpotMarketConfig } from '../../../store/botConfig';
import MarginTradeSection from './MarginTradeSection';
import { useNavigationHook } from '../../../currentBot/hooks';

const MarketList: React.FC<{
  markets?: Array<ISpotMarketConfig>;
  selectedMarket?: string;
  onMarketChange?: (market: string) => void;
  closeMobileMarketList?: () => void;
}> = observer(
  ({ markets, selectedMarket, onMarketChange, closeMobileMarketList }) => (
    <Box p="2">
      <Box pt={{ base: '4', xl: '0' }}>
        <Text as="h3" fontWeight="semibold" px="2" fontSize="md" pb="3">
          Markets
        </Text>
      </Box>
      <Divider mb={3} borderColor="whiteAlpha.400" />
      <List spacing={2}>
        {markets?.map((spot, index) => (
          <ListItem
            key={`${spot.symbol}-${index}`}
            fontSize="md"
            rounded="base"
            cursor="pointer"
            fontWeight={selectedMarket === spot.symbol ? 'bold' : ''}
            color={selectedMarket === spot.symbol ? 'white' : ''}
            bg={selectedMarket === spot.symbol ? 'blue.700' : ''}
            _hover={{
              bg: 'blue.500',
              color: 'white',
            }}
            mt="0"
            py="2"
            px="4"
            onClick={() => {
              onMarketChange && onMarketChange(spot.symbol);
              closeMobileMarketList && closeMobileMarketList();
            }}
          >
            {spot.symbol}
          </ListItem>
        ))}
      </List>
    </Box>
  ),
);

export const HomeSummarySection: React.FC<{
  store: IBotStore;
  isMarketListOpen: boolean;
  closeMobileMarketList: () => void;
}> = observer(({ store, isMarketListOpen, closeMobileMarketList }) => {
  const { history, match } = useNavigationHook();
  const viewOrders = () => {
    const storeAccount = store.account;
    const symbol = storeAccount?.selectedMarket;
    if (symbol) {
      storeAccount?.initializeFuture(symbol);
      history.push(`/spot/${symbol}`);
    }
  };

  useEffect(() => {
    if (store.account) {
      store.account.initializedSolvedTrader('BTCUSDT');
    }
  }, []);
  const solvedStore = store.account?.solvedTrader;
  return (
    <Flex overflow={{ lg: 'hidden' }}>
      <Flex
        display={{ base: 'none', xl: 'block' }}
        width="200px"
        direction="column"
        overflowY="auto"
        borderRightWidth="1px"
        py="2"
        bg="blue.600"
        color="white"
      >
        <Box h="full">
          <MarketList
            markets={store?.account?.spot_markets}
            selectedMarket={store?.account?.selectedMarket}
            onMarketChange={store?.account?.setDefaultConfig}
          />
        </Box>
      </Flex>
      <Flex flex="1" width="full">
        <Box
          borderWidth="2px"
          rounded="base"
          borderStyle="dashed"
          w="full"
          overflow="auto"
          display="flex"
          flexDirection="column"
        >
          <MarginTradeSection
            store={solvedStore}
            viewOrders={viewOrders}
            loading={solvedStore?.loading}
            accountInfo={store?.account?.accountInfo}
            positionStore={store?.positionStore}
            accountStore={() =>{}}
            onTransfer={() => {}}
          />
        </Box>
      </Flex>
      <Drawer
        size="xs"
        placement="left"
        isOpen={isMarketListOpen}
        blockScrollOnMount={false}
        onClose={closeMobileMarketList}
      >
        <DrawerOverlay onClick={closeMobileMarketList} />
        <DrawerContent
          bg="blue.600"
          color="white"
          shadow="none"
          position="relative"
          maxW="64"
        >
          <MarketList
            markets={store?.account?.spot_markets}
            selectedMarket={store?.account?.selectedMarket}
            closeMobileMarketList={closeMobileMarketList}
            onMarketChange={store?.account?.setDefaultConfig}
          />
        </DrawerContent>
      </Drawer>
    </Flex>
  );
});
