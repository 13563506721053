import {
  Button,
  Checkbox,
  Flex,
  GridItem,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { IAlertStore } from '../../store/alertConfig';
import type { IBotStore } from '../../store/botConfig';
import type { ICondition, IJobStore } from '../../store/jobConfig';
import { DropdownMenu } from './DropdownMenu';
import { InputElement, SelectElement } from './FormElement';
import Config1Display from './GenericConfigDisplay';
import { AlertDialogComponent } from './Sidebar';

const options = [
  { name: 'Equal', value: '=' },
  { name: 'Greater than', value: '>' },
  { name: 'less than', value: '<' },
  { name: 'greater than or equal', value: '>=' },
  { name: 'less than or equal', value: '<=' },
  { name: 'addition', value: '+' },
  { name: 'subtraction', value: '-' },
  { name: 'multiplication', value: '*' },
  { name: 'division', value: '/' },
  { name: 'not equal', value: '!=' },
  { name: 'value if greater than or equal', value: '>==' },
  { name: 'value if greater than 0 and less than or equal', value: '<==' },
];

const ActionParamInput: FC<{
  field: string;
  actionParam: any;
}> = observer(({ field, actionParam }) => {
  if (field == 'quick') {
    return (
      <GridItem colSpan={1}>
        <Checkbox
          onChange={(e) =>
            actionParam.updateFields({
              [field]: e.target.checked,
            })
          }
          isChecked={actionParam[field]}
        >
          {field}
        </Checkbox>
      </GridItem>
    );
  }
  return (
    <InputElement
      controlProps={{ width: '30%' }}
      label={field}
      type={typeof actionParam[field] === 'number' ? 'number' : 'text'}
      onChange={(e: any) => {
        let isNumber = typeof actionParam[field] == 'number';
        actionParam.updateFields({
          [field]: isNumber ? parseFloat(e.target.value) : e.target.value,
        });
      }}
      defaultValue={actionParam[field]}
    />
  );
});

const ActionParamComponent: FC<{ actionParam: any }> = observer(
  ({ actionParam }) => {
    //console.log(actionParam);
    return (
      <Flex mt={2} flexDirection="column">
        <Flex flexDirection={'row'} flexWrap="wrap">
          {actionParam.supportedFields.map((o: string) => {
            let field = actionParam[o];
            return <ActionParamInput field={o} actionParam={actionParam} />;
          })}
        </Flex>
        <Flex>
          <SelectElement
            label={'Kind'}
            controlProps={{}}
            defaultText={`Select Kind`}
            value={actionParam.kind}
            options={['long', 'short']}
            onChange={(e) => {
              actionParam.updateFields({ kind: e.target.value });
            }}
          />
          <SelectElement
            label={'Side'}
            controlProps={{ ml: 2 }}
            defaultText={`Select Side`}
            value={actionParam.side}
            options={['buy', 'sell']}
            onChange={(e) => {
              actionParam.updateFields({ side: e.target.value });
            }}
          />
        </Flex>

        {/* <Button
        colorScheme="blue"
        alignSelf="flex-end"
        width={'30%'}
        ml={2}
        isDisabled={
          !['price', 'quantity', 'kind', 'side'].every((o) => {
            let y: any = currentCondition || {};
            return Boolean(y[o]);
          })
        }
        onClick={() => {
          if (currentCondition) {
            onClick(currentCondition);
            setCurrentCondition(undefined);
          }
        }}
      >
        Add Params
      </Button> */}
      </Flex>
    );
  },
);
const ConditionComponent: FC<{ condition: ICondition }> = observer(
  ({ condition }) => {
    return (
      <Flex mt={2} flexDirection={['column', 'column', 'row']}>
        <SelectElement
          label={'Variable'}
          defaultText={`Select Variable`}
          value={condition.variable || ''}
          options={condition.variableOptions}
          onChange={(e) => {
            condition.updateFields({ variable: e.target.value });
          }}
        />
        <SelectElement
          controlProps={{ ml: 2 }}
          label={'Operator'}
          defaultText={`Select Operator`}
          value={condition.operator || ''}
          options={condition.operatorOptions}
          onChange={(e) => {
            condition.updateFields({ operator: e.target.value });
          }}
        />
        <InputElement
          controlProps={{ ml: 2 }}
          label={'Value'}
          onChange={(e: any) => {
            condition.updateFields({ value: parseFloat(e.target.value) });
          }}
          defaultValue={condition.value}
        />
        {/* <Button
        colorScheme="blue"
        alignSelf="flex-end"
        ml={2}
        width={'30%'}
        isDisabled={
          !['variable', 'operator', 'value'].every((o) => {
            let y: any = currentCondition || {};
            return Boolean(y[o]);
          })
        }
        onClick={() => {
          if (currentCondition) {
            onClick(currentCondition);
            setCurrentCondition(undefined);
          }
        }}
      >
        Add Condition
      </Button> */}
      </Flex>
    );
  },
);
export const NewAlertComponent: FC<{
  alert: IAlertStore;
  loading: boolean;
  onClose: any;
}> = observer(({ alert, loading, onClose, ...rest }) => {
  return (
    <Flex direction="column" mt={4} {...rest}>
      <Config1Display override="config_1" store={alert} />
      <Button
        isLoading={loading}
        loadingText="Updating Job"
        colorScheme="blue"
        mt={4}
        size="lg"
        alignSelf="center"
        onClick={onClose}
      >
        Edit Alert
      </Button>
    </Flex>
  );
});
export const NewJobComponent: FC<{
  job: any;
  onClose: any;
  loading: boolean;
  [key: string]: any;
}> = observer(({ job, onClose, loading, ...rest }) => {
  const alertMarketTypes = ['margin', 'future'];
  const jobType = job.alert ? 'Alert' : 'Job';
  return (
    <Flex direction="column" mt={4} {...rest}>
      <InputElement
        controlProps={{ mb: 2 }}
        label={'Name'}
        type={'text'}
        onChange={(e: any) => {
          job.updateName(e.target.value);
        }}
        defaultValue={job.displayName}
      />
      {!job.alert ? (
        <Heading mb={2} size={'md'}>
          Conditions
        </Heading>
      ) : (
        <>
          <SelectElement
            controlProps={{ mb: 2 }}
            label={'Market Type'}
            defaultText={`Select Market`}
            value={job.market_type || ''}
            options={alertMarketTypes}
            onChange={(e) => {
              job.updateMarketType(e.target.value);
            }}
          />
          <InputElement
            controlProps={{ mb: 2 }}
            label={'Price'}
            onChange={(e: any) => {
              job.updatePrice(parseFloat(e.target.value));
            }}
            defaultValue={job.price}
          />
        </>
      )}
      {job.conditions &&
        job.conditions.map((condition: any, i: any) => {
          return <ConditionComponent condition={condition} />;
        })}
      {!job.alert ? (
        <Heading mt={2} mb={2} size={'md'}>
          Actions
        </Heading>
      ) : null}
      {Array.isArray(job.actions) &&
        job.actions.map((action: any, i: number) => {
          let paramsArray = action.params as any;
          return (
            <Flex flexDirection="column">
              <ActionParamInput field="type" actionParam={action} />
              {action.params && Array.isArray(action.pArray) ? (
                paramsArray.map((o: any) => {
                  return <ActionParamComponent actionParam={o} />;
                })
              ) : (
                <ActionParamComponent actionParam={action.params} />
              )}

              <Heading size="sm" my={2}>
                Variables
              </Heading>
              {action.variables ? (
                Array.isArray(action.variables) ? (
                  action.variables.map((o: any) => (
                    <VariableComponent variables={o} />
                  ))
                ) : (
                  <VariableComponent variables={action.variables} />
                )
              ) : null}
            </Flex>
          );
        })}
      <Button
        isLoading={loading}
        loadingText="Updating Job"
        colorScheme="blue"
        mt={4}
        size="lg"
        alignSelf="center"
        onClick={onClose}
      >
        Edit {`${jobType}`}
      </Button>
    </Flex>
  );
});

const VariableComponent: FC<{ variables: any }> = observer(({ variables }) => {
  return (
    <>
      {Object.keys(variables).map((o) => {
        let condition = variables[o];
        return condition ? <ConditionComponent condition={condition} /> : null;
      })}
    </>
  );
});

export default NewJobComponent;

export const CreateJobComponent: FC<{
  store: IBotStore;
  // config: IBotConfig;
  [key: string]: any;
  toJobsPage?: any;
}> = observer(({ toJobsPage, store, ...rest }) => {
  const toast = useToast();
  const createStore = store.jobCreate;
  function configDisplay() {
    if (['new_order_sell', 'new_order'].includes(createStore.value)) {
      return (
        <Config1Display
          override="config_1"
          labels={{ percent: 'Is margin trade' }}
          key={createStore.value}
          store={createStore.config}
        />
      );
    }
    if (
      [
        'new_trade_with_stop',
        'new_trade_with_profit_and_stop',
        'update_profit_and_stop',
      ].includes(createStore.value)
    ) {
      return (
        <Config1Display
          override="config_7"
          key={createStore.value}
          store={createStore.config}
        />
      );
    }
    // if (createStore.value === 'take_profit_with_entry') {
    //   return (
    //     <Config1Display
    //       override="config_8"
    //       key={createStore.value}
    //       store={createStore.config}
    //     />
    //   );
    // }
    if (createStore.value === 'minimum_entry_at_opposite_kind') {
      return (
        <Config1Display
          override="config_11"
          key={createStore.value}
          labels={{
            min_size: 'Entry size',
            size: 'Condition size. (use 0 for entry at opposite entry price)',
          }}
          store={createStore.config}
        />
      );
    }
    if (createStore.value === 'protect_profit') {
      return (
        <Config1Display
          override="config_9"
          key={createStore.value}
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'purchase_after_profit') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_3"
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'create_cross_trade') {
      return (
        <Config1Display
          key={createStore.value}
          labels={{
            value: 'Maximum size',
          }}
          override="config_15"
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'create_isolated_trade') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_16"
          labels={{
            value: 'Maximum purchase size',
            entry_target: 'Budget',
          }}
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'reduce_position_size_at_entry') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_11"
          labels={{
            size: 'Max size to be greater than',
          }}
          store={createStore.config}
        />
      );
    }
    // if (createStore.value == 'increase_position_with_borrow') {
    //   return (
    //     <Config1Display
    //       key={createStore.value}
    //       override="config_5"
    //       store={createStore.config}
    //     />
    //   );
    // }
    if (
      ['reduce_position_size', 'reduce_entry_price'].includes(createStore.value)
    ) {
      return (
        <Config1Display
          key={createStore.value}
          override="config_2"
          labels={{
            percent: 'Trigger once?',
            value: 'Maximum size',
            entry_target: 'Fee spread',
          }}
          store={createStore.config}
        />
      );
    }
    if (['transfer_funds', 'margin_profit'].includes(createStore.value)) {
      return (
        <Config1Display
          key={createStore.value}
          override="config_6"
          labels={{
            percent:
              createStore.value === 'transfer_funds'
                ? 'To Borrow?'
                : 'From spot',
          }}
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'market_margin_buy') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_10"
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'margin_future_sync') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_12"
          labels={{
            percent: 'Use entry instead of buy price',
          }}
          store={createStore.config}
        />
      );
    }
    if (
      ['isolated_mode', 'watch_isolated_margin', 'toggle_cross_mode'].includes(
        createStore.value,
      )
    ) {
      return (
        <Config1Display
          key={createStore.value}
          override="config_14"
          labels={{
            entry_target: 'Budget',
            size: 'Minimum entry size',
          }}
          store={createStore.config}
        />
      );
    }
    if (
      [
        'pause_watcher_on_maximum_reached',
        'resume_watcher_on_size_reduced',
      ].includes(createStore.value)
    ) {
      return (
        <Config1Display
          key={createStore.value}
          override="config_17"
          labels={{
            size: 'Maximum size',
            isolatedSymbol: 'Profile id or name',
          }}
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'run_sub_account_trade') {
      return (
        <Config1Display
          labels={{
            entry_target: 'Entry',
            stop_price: 'Stop',
            value: 'Budget',
            stop_range: 'Modulo',
            take_profit: 'Account length',
            borrow: 'Risk reward',
          }}
          key={createStore.value}
          override="config_18"
          store={createStore.config}
        />
      );
    }
    if (createStore.value == 'liquidation_watcher') {
      return (
        <Config1Display
          key={createStore.value}
          override="config_13"
          store={createStore.config}
        />
      );
    }
    if (createStore.value === 'repay_and_borrow_cross_collateral') {
      return (
        <Config1Display
          key={createStore.value}
          labels={{
            entry_target: 'Price',
          }}
          override="config_19"
          store={createStore.config}
        />
      );
    }
    return null;
  }
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  return (
    <Flex direction="column" {...rest}>
      <SelectElement
        controlProps={{ display: 'flex', mt: 3 }}
        labelProps={{ flexGrow: 0.5, alignSelf: 'center' }}
        selectProps={{ flex: 1 }}
        label="Select Config"
        value={createStore.value}
        defaultText="No Config Selected"
        options={createStore.configFieldsOptions}
        onChange={(e: any) => createStore.onConfigSelect(e.target.value)}
      />
      {configDisplay()}
      <Flex justifyContent="center">
        {store.account && store.account.exchanges.length > 0 ? (
          <DropdownMenu
            alignSelf="flex-end"
            label={store.account?.currentExchange || 'Default (Binance)'}
            options={['', ...store.account.exchanges]}
            onClick={(config: string) => {
              store.account?.setCurrentExchange(config.toLowerCase());
            }}
          />
        ) : null}
        <AlertDialogComponent
          buttonText="Submit Job"
          heading={'Create Job'}
          confirmSummary={createStore.summary}
          confirmText="Submit Job"
          onActionClick={() => {
            store
              .createJobInstance()
              .then(() => {
                toastHelper('success', 'Job Created');
              })
              .catch((error) => {
                toastHelper('error', 'Error creating job');
              });
          }}
          isLoading={store.loading}
          loadingText="Submitting Job"
          mt={4}
          colorScheme="blue"
        />
        <Button
          ml={2}
          isLoading={store.account_loading}
          loadingText="Loading"
          onClick={() => {
            store
              .getBotAccounts()
              .then(() => {})
              .catch((error) => {
                //console.log(error);
                toastHelper('error', 'Error Fetching jobs');
              });
          }}
          mt={4}
          colorScheme="orange"
        >
          Refresh jobs available
        </Button>
        <Button ml={2} onClick={toJobsPage} mt={4} colorScheme="teal">
          To jobs page
        </Button>
      </Flex>
    </Flex>
  );
});

export const CreateAlertComponent: FC<{
  store: IBotStore;
  [key: string]: any;
  toAlertPage?: any;
}> = observer(({ toAlertPage, store, ...rest }) => {
  const toast = useToast();
  const createStore = store.alertCreate;
  function configDisplay() {
    return <Config1Display override="config_1" store={createStore} />;
  }
  function toastHelper(status: 'success' | 'error', title: string) {
    toast({
      title,
      status,
      description: title,
      duration: 3000,
      isClosable: true,
    });
  }
  return (
    <Flex direction="column" {...rest}>
      {configDisplay()}
      <Flex justifyContent="center">
        <AlertDialogComponent
          buttonText="Submit Alert"
          heading={'Create Alert'}
          confirmSummary={createStore.summary}
          confirmText="Submit Alert"
          onActionClick={() => {
            store
              .createAlertInstance()
              .then(() => {
                toastHelper('success', 'Alert Created');
              })
              .catch((error) => {
                toastHelper('error', 'Error creating Alert');
              });
          }}
          isLoading={store.loading}
          loadingText="Submitting Alerts"
          mt={4}
          colorScheme="blue"
        />
        <Button
          ml={2}
          isLoading={store.account_loading}
          loadingText="Loading"
          onClick={() => {
            store
              .getBotAccounts()
              .then(() => {})
              .catch((error) => {
                //console.log(error);
                toastHelper('error', 'Error Fetching Alerts');
              });
          }}
          mt={4}
          colorScheme="orange"
        >
          Refresh Alert available
        </Button>
        <Button ml={2} onClick={toAlertPage} mt={4} colorScheme="teal">
          To Alerts page
        </Button>
      </Flex>
    </Flex>
  );
});
