import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getParent,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import { BotAdapterType } from '../../adapter';
import { formatPrice, getParamForField, to_f } from '../utils';
import { IFutureOrderConfig, IMarginOrderConfig } from './margin_type_config';
import { ISolvedTraderConfig } from './rootStore';
import { IFutureInstance } from '../futureConfig';

export const newAccountDefault = {
  owner: '',
  market_type: '',
  currency: '',
  symbol: '',
  collateral: 0,
};

const configs = [
  {
    name: 'owner',
    label: 'Owner',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'market_type',
    label: 'Market Type',
    kind: 'select',
    options: ['isolated_margin', 'future', 'cross_margin'],
  },
  {
    name: 'currency',
    label: 'Currency',
    kind: 'input',
    type: 'string',
  },
  {
    name: 'collateral',
    label: 'Collateral',
    kind: 'special',
  },
];

const config_fields: any = {
  config_1: ['owner', 'market_type', 'currency', 'collateral'],
};

const Position = types
  .model('Position', {
    entryPrice: types.number,
    positionAmt: types.number,
    positionSide: types.enumeration('Kind', ['LONG', 'SHORT']),
    leverage: types.number,
  })
  .views((self) => {
    return {
      get quantity() {
        return Math.abs(self.positionAmt);
      },
      get kind() {
        return self.positionSide.toLowerCase();
      },
    };
  });

const OrderType = types
  .model('OrderType', {
    price: types.number,
    orderId: types.number,
    quantity: types.number,
    side: types.string,
    kind: types.string,
    stop: types.maybe(types.number),
  })
  .views((self) => {
    return {
      get notion_value() {
        return to_f(self.price * self.quantity, '%.2f');
      },
    };
  });

const EntryStore = types
  .model('EntryStore', {
    entry: types.optional(types.number, 0),
    tradeEntry: types.optional(types.number, 0),
    kind: types.optional(types.enumeration('Kind', ['long', 'short']), 'long'),
    trades: types.optional(types.frozen(), null),
  })
  .views((self) => {
    return {
      get futureCTrader(): IFutureInstance {
        return this.parent.parent.futureCTrader;
      },
      get parent(): IMarketTypeInstance {
        return getParent(self, 1);
      },
      get stop() {
        const entries = this.entryOptions;
        return self.kind === 'long' ? entries[0] : entries.at(-1);
      },

      get selectOptions() {
        return this.entryOptions.filter((i) => i !== this.stop);
      },
      get entryOptions() {
        const result = this.futureCTrader.getTradingZones({
          // kind: self.kind
          kind: 'short',
        });
        const merged = [
          ...result.map((i:any) => i.entry),
          ...result.map((i:any) => i.stop),
        ];
        const noDuplicate = Array.from(new Set(merged));
        // sort from lowest to highest if long else highest to lowest
        return self.kind === 'long'
          ? noDuplicate.sort((a, b) => a - b)
          : noDuplicate.sort((a, b) => b - a);
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    function onReset() {
      self.trades = undefined;
      goBack();
    }
    function goBack() {
      self.entry = 0;
      self.tradeEntry = 0;
      self.futureCTrader.resetSupportResistance();
    }
    const updateRiskReward = flow(function* updateRiskReward(stop: number) {
      const { result } = yield adapter.getOptimumReward({
        ...(self as any).futureCTrader.config.toJSON(),
        kind: self.kind,
        entry: self.entry,
        stop,
      });
      self.futureCTrader.config.updateFields({
        risk_reward: result,
      });
    });
    function onChange(value: string) {
      console.log(value);
      if (!self.entry) {
        self.entry = Number(value);
        const stop =
          self.kind === 'long'
            ? self.selectOptions.filter((i) => i < self.entry).at(-1)
            : self.selectOptions.filter((i) => i > self.entry).at(0);
        console.log('stop', stop);
        self.futureCTrader.config.updateFields({
          entry: self.entry,
          stop,
          support: Math.min(self.entry, stop),
          resistance: Math.max(self.entry, stop),
        });
        updateRiskReward(stop);
      } else {
        self.tradeEntry = Number(value);
        self.futureCTrader.config.updateFields({
          currentEntry: self.tradeEntry,
        });
        const originalTrades = self.futureCTrader.buildConfig({
          take_profit: self.futureCTrader.config.take_profit,
          entry: self.entry,
          stop: self.futureCTrader.config.stop,
          increase: true,
          kind: self.kind as any,
        });
        const trades = self.futureCTrader
          .update_open_prices({
            trades: originalTrades,
            kind: self.kind,
          })
          .filter((o) => o.avg_size);
        self.trades = trades;
        return trades;
      }
    }
    return {
      goBack,
      onChange,
      onReset,
    };
  });

export const MarketTypeInstances = types
  .model('MarketTypeInstance', {
    owner: types.optional(types.string, ''),
    symbol: types.optional(types.string, ''),
    market_type: types.string,
    currency: types.string,
    collateral: types.number,
    loading: types.optional(types.boolean, false),
    margin_orders: types.optional(types.array(OrderType), []),
    future_orders: types.optional(types.array(OrderType), []),
    longPosition: types.maybeNull(Position),
    shortPosition: types.maybeNull(Position),
    longEntry: types.optional(EntryStore, {
      kind: 'long',
    }),
    shortEntry: types.optional(EntryStore, {
      kind: 'short',
    }),
  })
  .views((self) => {
    return {
      get parent() {
        let parent: ISolvedTraderConfig = getParent(self, 2);
        return parent;
      },
      get accountDetails() {
        let { owner, market_type, currency, collateral } = getSnapshot(self);
        return { owner, market_type, currency, collateral };
      },
      get config_fields() {
        return config_fields;
      },
      get orders() {
        return self.market_type.includes('future')
          ? self.future_orders
          : self.margin_orders;
      },

      get futuresStats(): any[] {
        const results = [];
        const cTrader = this.parent.futureCTrader;
        if (self.longPosition) {
          results.push({
            market_type: 'future',
            id: 'long',
            trade_type: 'future',
            symbol: 'BTCUSDT',
            kind: 'long',
            entry: {
              price: self.longPosition.entryPrice,
              quantity: self.longPosition.quantity,
            },
            stop: cTrader.config.support, // to change use support for now
            take_profit: cTrader.config.resistance, // to change use resistance for now
            max_size: cTrader.max_size,
            store: self.longEntry,
          });
        }
        if (self.shortPosition) {
          results.push({
            market_type: 'future',
            id: 'short',
            trade_type: 'future',
            symbol: 'BTCUSDT',
            kind: 'short',
            entry: {
              price: self.shortPosition.entryPrice,
              quantity: self.shortPosition.quantity,
            },
            stop: cTrader.config.resistance,
            take_profit: cTrader.config.support,
            max_size: cTrader.max_size,
            store: self.shortEntry,
          });
        }
        return results;
        return [
          // {
          //   market_type: 'future',
          //   id: 'collateral',
          //   trade_type: 'collateral',
          //   balance: `$400`,
          //   pnl: 5,
          //   current_price: 29032.32,
          //   liquidation: 40000,
          // },
        ];
      },
      get marginProfits(): string | undefined {
        const orders = self.margin_orders.find((i) => {
          return (
            i.kind === 'long' &&
            (i.price === this.parent.futureCTrader.profit_quote_price ||
              i.price === this.parent.futureCTrader.profit_base_price)
          );
        });
        return formatPrice(this.parent.profitMade);
      },
      get marginStats(): any[] {
        const results = [];
        if (self.collateral > 0) {
          let profits = this.marginProfits;
          results.push({
            market_type: 'margin',
            label: 'Margin collateral',
            id: 'collateral',
            trade_type: 'collateral',
            balance: `${formatPrice(this.parent.collateralBalance)}`,
            additionalText: profits
              ? `You made an extra ${profits} so far`
              : undefined,
          });
        }
        if (this.parent.loanBaseBalance > 0) {
          results.push({
            market_type: 'margin',
            label: 'Margin trade Size',
            id: 'collateral',
            trade_type: 'collateral',
            balance: `${to_f(this.parent.loanBaseBalance, '%.5f')} ${
              this.parent.loan.currency
            }`,
          });
        }
        if (this.parent.marginLevel) {
          results.push({
            market_type: 'margin',
            id: 'score',
            label: 'Your Margin Score',
            value: to_f(this.parent.marginLevel, '%.2f'),
            kind: 'score',
          });
        }
        return results;
      },
      get stats() {
        return self.market_type === 'future'
          ? this.futuresStats
          : this.marginStats;
      },
      getParamForField(field: string, isGroup?: string): any | any[] {
        let parent: ISolvedTraderConfig = getParent(self, 1);
        let result = getParamForField(self, configs, field, isGroup);
        if (parent.zones.length > 0) {
          if (['zones'].includes(field)) {
            result.kind = 'select';
            result.options = parent.zones;
            // result = { ...result, kind: 'select', options: parent.zones };
          }
        }
        return result;
      },
    };
  })
  .actions((self) => {
    const { adapter } = getEnv<{ adapter: BotAdapterType }>(self);
    const onRefresh: any = flow(function* onRefresh() {
      // let parent = getParent(self, 2); // an array so go 2 steps back
      try {
        self.loading = true;
        self.margin_orders = cast([]);
        self.future_orders = cast([]);
        const result = yield self.parent.onFetchAccounts({
          owner: self.owner,
          market_type: self.market_type,
        });
        // let result = yield adapter.getOrdersForAccount({
        //   owner: self.owner,
        //   symbol: self.symbol,
        //   market_type: self.market_type,
        // });
        if (result) {
          self.margin_orders = cast(result.margin_orders);
          self.future_orders = cast(result.future_orders);
        }
        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    const onCreate = flow(function* onCreate(
      config: IMarginOrderConfig | IFutureOrderConfig,
      account: 'margin' | 'future',
    ) {
      try {
        self.loading = true;
        let result = yield adapter.createOrdersForAccount(config, account);
        account === 'future'
          ? self.future_orders.push(result)
          : self.margin_orders.push(result);

        self.loading = false;
      } catch (error) {
        self.loading = false;
        throw error;
      }
    });
    return {
      reset() {
        applySnapshot(self, newAccountDefault);
      },
      onRefresh,
      onCreate,
      updateFields(obj: any) {
        applySnapshot(self, { ...getSnapshot(self), ...obj });
      },
    };
  });

export default MarketTypeInstances;
interface IOrderType extends Instance<typeof OrderType> {}
export interface IEntryStore extends Instance<typeof EntryStore> {}
export interface IMarketTypeInstance
  extends Instance<typeof MarketTypeInstances> {}
